import React from "react";

const ImagesBlog = ({ title, images }) => {
  return (
    <div>
      {" "}
      <img src={images[0].url1} width="350" height="350" alt={title} />
      <img
        src={images[1].url2}
        width="350"
        height="350"
        alt={title}
        style={{ padding: 5 }}
      />
    </div>
  );
};

export default ImagesBlog;
