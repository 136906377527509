import React from "react";
import LayoutOthers from "../layouts/LayoutOthers";
import AllProducts from "../products/AllProducts";
import { Container } from "react-bootstrap";
import AllCameras from "./AllCameras";

const AllPaapi = () => {
  return (
    <LayoutOthers>
      <Container style={{ minHeight: 500, marginTop: 50 }}>
        <AllCameras />
      </Container>
    </LayoutOthers>
  );
};

export default AllPaapi;
