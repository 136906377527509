import React from 'react'

const OtherPageHeaders = () => {
  return (
    <header className="main-banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="main-banner-content">
              <h1>FIND THE BEST PRODUCTS</h1>
              <p>
                Looking for New Trending and best rating products, Check out
                before you buy those..
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default OtherPageHeaders