import React from "react";
import { NavLink } from "react-router-dom";
import slugifyUrl from "../util/slugifyUrl";

const BlogList = (props) => {
  const { id, imageMain, topicTitle, intro, category, subCategory } =
    props.post;
  return (
    <div key={id}>
      {" "}
      <NavLink
        to={`/blogs/${category}/${slugifyUrl(topicTitle)}/${id}`}
        className="list-group-item course-list-item"
      >
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <img
              src={imageMain}
              className="rounded"
              alt={topicTitle}
              style={{ width: 200, height: 150 }}
            />
          </div>
          <div className="col-lg-8 col-md-8">
            <h5>{topicTitle}</h5>
            <p>{intro.substring(0, 305) + "..."}</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default BlogList;
