import React from "react";
import { NavLink } from "react-router-dom";
import RecentPosts from "../blog/RecentPosts";
import ScrollButton from "../commons/ScrollButton";
import { categories } from "../data/blogData";

const Copy = () => {
  return (
    <section className="copy">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p>
              Copyright &copy;{new Date().getFullYear()}. All Rigts Reserved
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const Footers = () => {
  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6">
              <aside className="footer-widget">
                <h4>Follow us</h4>
                <ul>
                  <li>
                    <NavLink to="/">Facebook</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">LinkedIn</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">Twitter</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">YouTube</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">Tumblr</NavLink>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <aside className="footer-widget">
                <h4>Site Map</h4>
                <ul>
                  <li>
                    <NavLink to="/">HOME</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">ABOUT</NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy">PRIVACY POLICY</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">CONTACT</NavLink>
                  </li>
                  <li>
                    <NavLink to="/affiliate">AFFILIATE DISCLOSURE</NavLink>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <aside className="footer-widget">
                <RecentPosts categories={categories} />
                {/* <h4>Recent Updates</h4>
                <ul>
                  <li>
                    <NavLink to="/">Update One</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">Update Two</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">Update Three</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">Update Four</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">Update Five</NavLink>
                  </li>
                </ul> */}
              </aside>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <aside className="footer-widget">
                <address>
                  <h2>COMPANY</h2>
                  <p>
                    The Digital Specs is owned by Ecronsoft App Pvt Ltd, Our
                    Team and We are the persons behind The Digital Specs. In our
                    blog, we used to share our research and help you to choose
                    best eccomerce/digital products ..{" "}
                    <NavLink to="/about">Read More</NavLink>
                  </p>
                  <p>
                    <strong>Email: </strong>{" "}
                    <NavLink to="mailto:contact@thedigitalspecs.com">
                      contact@thedigitalspecs.com
                    </NavLink>
                  </p>
                  {/* <p>
                    <strong>Phone: </strong>{" "}
                    <NavLink to="tel:+919876543210">+91 987 654 3210</NavLink>
                  </p> */}
                </address>
              </aside>
            </div>
          </div>
        </div>
        <div
          style={{ alignContent: "center", margin: "20px", fontSize: "12px" }}
        >
          AS AN AMAZON AND OTHER ASSOCIATE THE THEDIGITALSPECS EARN FROM
          QUALIFYING PURCHASES OR OTHER REFFEREL.{" "}
          <NavLink to="/">WWW.THEDIGITALSPECS.COM</NavLink> IS AN AFFILIATE
          PROGRAM DESIGNED TO PROVIDE A MEANS FOR SITES TO EARN ADVERTISING FEES
          BY ADVERTISING AND LINKING TO AMAZON.COM OR OTHER AFFILIATED LINK. THE
          THEDIGITALSPECS IS READER-SUPPORTED. WHEN YOU BUY OR REGISTER THROUGH
          LINKS ON OUR SITE, WE MAY EARN AN AFFILIATE COMMISSION
        </div>
      </footer>
      <Copy />
    </>
  );
};

export default Footers;
