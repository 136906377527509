import React from "react";

import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiTrendingUp } from "react-icons/bi";
import { FcRating } from "react-icons/fc";
import { GiRank3 } from "react-icons/gi";

const FeatureCard = ({ title, content, icons }) => {
  return (
    <div className="col-lg-3 col-md-3 col-sm-6">
      <div className="feature-holder">
        {" "}
        <div style={{ fontSize: "1.8rem", fontFamily: "sans-serif" }}>
          {icons === "feature" && <MdOutlineFeaturedVideo />}
        </div>
        <div style={{ fontSize: "1.8rem", fontFamily: "sans-serif" }}>
          {icons === "BiTrendingUp" && <BiTrendingUp />}
        </div>
        <div style={{ fontSize: "1.8rem", fontFamily: "sans-serif" }}>
          {icons === "FcRating" && <FcRating />}
        </div>
        <div style={{ fontSize: "1.8rem", fontFamily: "sans-serif" }}>
          {icons === "GiRank3" && <GiRank3 />}
        </div>
        <div style={{ fontSize: "1.8rem", fontFamily: "sans-serif" }}>
          {title}
        </div>
        <div style={{ fontSize: "1.2rem" }}>{content}</div>
      </div>
    </div>
  );
};

export default FeatureCard;
