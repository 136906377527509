import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollButton from "../commons/ScrollButton";
import Banner from "./Banner";
import Footers from "./Footer";
import Headers from "./Headers";
import Navber from "./Navber";
import SideBar from "./SideBar";

const LayoutOthers = ({ children }) => {
  return (
    <>
      <Navber />
      <Container style={{ minHeight: "500px" }}>
        <Row>
          <Col xs={12} md={9}>
            <main>{children}</main>
          </Col>
          <Col xs={12} md={3}>
            <SideBar />
          </Col>
        </Row>
      </Container>

      <Footers />
      <div style={{ marginRight: "200px" }}>
        {" "}
        <ScrollButton />
      </div>
    </>
  );
};

export default LayoutOthers;
