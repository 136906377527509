import React, { useEffect, useState } from "react";
import history from "../commons/history";
import { Link, useParams, Redirect } from "react-router-dom";

import { AiFillStar } from "react-icons/ai";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

import SkeleTon from "react-loading-skeleton";
import LayoutOthers from "../layouts/LayoutOthers";
import { bestRankingData } from "../data/firstPageData";
import SEO from "../commons/SEO";

import { Col, Container, NavLink, Row } from "react-bootstrap";
import { Button } from "bootstrap";
import slugifyUrl from "../util/slugifyUrl";

const SingleProduct = () => {
  const { id, category } = useParams();
  const [products, setProducts] = useState(bestRankingData);
  const [loading, setLoading] = useState(false);
  const [relatedCategory, setRelatedCategory] = useState([]);

  const max = Math.max(...bestRankingData.map((o) => o.id));
  const [maxLim, setMaxLim] = useState(false);
  const [minLim, setMinLim] = useState(false);

  const [idNextPre, setIdNextPre] = useState(parseInt(id));

  const [product, setProduct] = useState({});

  useEffect(() => {
    let codition = idNextPre;
    const getProducts = () => {
      setLoading(true);
      if (idNextPre <= max && codition > 0) {
        const productItem = products.filter(
          (item) => item.id === parseInt(codition)
        );
        productItem && setProduct(productItem[0]);
        setMaxLim(false);
        setMinLim(false);
      }

      setLoading(false);
    };
    if (idNextPre >= max) {
      setMaxLim(true);
    }
    if (idNextPre < 1) {
      setMinLim(true);
    }
    getProducts();
  }, [idNextPre]);

  useEffect(() => {
    let codition = idNextPre;
    const getProducts = () => {
      setLoading(true);
      if (idNextPre <= max && codition > 0) {
        const productItem = products.filter(
          (item) => item.id === parseInt(codition)
        );
        productItem && setProduct(productItem[0]);
        setMaxLim(false);
        setMinLim(false);
      }

      setLoading(false);
    };
    if (idNextPre >= max) {
      setMaxLim(true);
    }
    if (idNextPre < 1) {
      setMinLim(true);
    }
    getProducts();
  }, [id]);

  useEffect(() => {
    const productItem = bestRankingData.filter(
      (item) => item.category === category && item.id !== id
    );
    productItem && setRelatedCategory(productItem.slice(0, 6));
  }, [category]);

  const navigate = (val) => {
    let idTemp = parseInt(id);
    setLoading(true);
    const productItem = products.find((item) => item.id === idTemp + 1);
    productItem && setProduct(productItem);
    //console.log("productItem", productItem);
    setTimeout(() => {
      history.push(
        `/products/${productItem.category}/${productItem.id}/${productItem.slug}`
      );
    }, 1000);
  };

  const Loading = () => {
    return (
      <LayoutOthers>
        <div className="container">
          <div className="row mt-5 mb-5 py-4">
            <div className="col-md-6">
              <SkeleTon height={400} />;
            </div>
            <div className="col-md-6">
              <SkeleTon height={50} width={300} />
              <SkeleTon count={200} />
              <SkeleTon height={25} width={150} />
              <SkeleTon height={50} />
              <SkeleTon height={150} />
              <SkeleTon height={50} width={100} />
              <SkeleTon height={50} width={100} style={{ marginLeft: 6 }} />
            </div>
          </div>
        </div>
      </LayoutOthers>
    );
  };

  const ShowProduct = () => {
    const { keywords, brandName, category, title, descriptions } = product;
    let descriptionsSubs = descriptions && descriptions.substring(0, 300);
    return (
      <div className="container">
        {loading ? (
          <Loading />
        ) : (
          <>
            {product.id ? (
              <div className="row mt-5 mb-5 py-3">
                <SEO
                  title={product.title}
                  description={product.descriptions}
                  //image={imageLink}
                  article={true}
                  keywords={[keywords, brandName, category, title]}
                />
                <h2
                  style={{
                    fontSize: "1rem",
                    textTransform: "uppercase",
                    marginTop: "2rem",
                    marginLeft: "2rem",
                  }}
                >
                  <b>CATEGORY/ {category}</b>
                </h2>
                <div className="col-md-6">
                  <img
                    src={product.imageLink}
                    alt={(product.title, product.category)}
                    height="300px"
                    width="300px"
                  />
                </div>
                <div className="col-md-6">
                  <p className="text-uppercase text-black-50">{product.tag}</p>
                  <h3>{product.title}</h3>
                  <p className="lead fw-bolder">
                    Rating {product.rating && product.rating}
                    <AiFillStar />
                  </p>
                  <h4>
                    <b>
                      Price :{" "}
                      <a
                        className="btn btn-sm btn-info"
                        target="_blank"
                        href={product.productLink}
                        rel="noreferrer"
                      >
                        Check Price
                      </a>
                    </b>
                  </h4>
                  <p className="lead">
                    {descriptionsSubs}{" "}
                    <a
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      href={product.productLink}
                      style={{ textDecoration: "none" }}
                    >
                      <b>.........READMORE</b>
                    </a>
                  </p>
                  <Row>
                    <Col>
                      <a
                        className="fasc-button fasc-size-medium fasc-type-glossy fasc-rounded-medium btn"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        href={product.productLink}
                        style={{ width: "200px" }}
                      >
                        VIEW ON AMAZON
                      </a>
                    </Col>
                    <Col>
                      {" "}
                      <img
                        src={require("../../assets/amazon-icon.jpg")}
                        // className="img-fluid"
                        alt="card"
                        height="80px"
                        width="150px"
                      />
                    </Col>
                  </Row>

                  {/* <NavLink
                  to="/cart"
                  className="btn btn-dark mx-2 px-3 py-2"
                  disabled
                >
                  Go To Cart
                </NavLink> */}
                </div>
                <hr className="mt-4" />
                <div>
                  <Row className="mt-5">
                    <Col xs={12} md={3} style={{ marginTop: "0.5rem" }}>
                      <Link
                        to="/"
                        className="btn btn-outline-success"
                        // onClick={() => {
                        //   history.push(`/`);
                        // }}
                        // disabled
                      >
                        Back To Home
                      </Link>
                    </Col>

                    <Col xs={6} md={2}></Col>
                    <Col xs={12} md={2}></Col>
                    <Col xs={6} md={2}>
                      <NavLink
                        //to="/cart"
                        style={{ marginTop: "0.5rem" }}
                        className="btn btn-outline-success"
                        disabled={minLim}
                        onClick={() => {
                          setIdNextPre(idNextPre - 1);
                        }}
                      >
                        <GrFormPrevious /> Previous
                      </NavLink>
                    </Col>
                    <Col xs={6} md={2}>
                      <NavLink
                        style={{ marginTop: "0.5rem" }}
                        className="btn btn-outline-success"
                        disabled={maxLim}
                        //onClick={navigate}
                        onClick={() => {
                          setIdNextPre(idNextPre + 1);
                        }}
                        // onClick={() => {
                        //   //
                        // }}
                      >
                        Next
                        <MdNavigateNext />
                      </NavLink>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <Container
                style={{
                  maxWidth: "1260px",
                  minHeight: "500px",
                  marginTop: "100px",
                }}
              >
                <Row className="row mt-5">
                  <Row>
                    <h2
                      style={{
                        fontSize: "2rem",
                        textTransform: "uppercase",
                        marginTop: "2rem",
                        marginLeft: "2rem",
                      }}
                    >
                      <b>PRODUCT NOT FOUND</b>
                    </h2>
                  </Row>
                  <Row>
                    <img
                      src="https://images.pexels.com/photos/365637/pexels-photo-365637.jpeg?cs=srgb&dl=pexels-aphiwat-chuangchoem-365637.jpg&fm=jpg"
                      alt={(product.title, product.category)}
                      height="500px"
                      width="400px"
                    />
                  </Row>
                </Row>
              </Container>
              // <div className="row mt-5 not-found">
              //   <div className="col-md-12 mt-5">
              //     <div>NOT FOUND</div>
              //   </div>
              // </div>
            )}
            <hr />

            <div style={{ fontSize: "2rem" }}>
              <b>Related Products</b>
            </div>

            <Row>
              {relatedCategory &&
                relatedCategory.map((item, index) => (
                  <Col
                    xs={12}
                    md={4}
                    key={index}
                    //style={{ border: "2px solid gray" }}
                  >
                    <h6>{item.title.substring(0, 27) + "..."}</h6>
                    <img
                      src={item.imageLink}
                      alt={(item.title, item.category)}
                      height="150px"
                      width="200px"
                    />
                    <div
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        marginBottom: "1rem",
                      }}
                    >
                      {item.rating} <AiFillStar /> out of 5
                    </div>
                    <a
                      href={`/products/${item.category}/${item.id}/${slugifyUrl(
                        item.title
                      )}`}
                      className="btn btn-secondary mx-5 px-3 py-2 mb-2"
                    >
                      See Products
                    </a>
                  </Col>
                ))}
            </Row>
          </>
        )}
      </div>
    );
  };

  return (
    <LayoutOthers>
      <Container
        style={{
          minHeight: 500,
          alignContent: "center",
        }}
      >
        {loading ? <Loading /> : <ShowProduct />}
      </Container>
    </LayoutOthers>
  );
};

export default SingleProduct;
