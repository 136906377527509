import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LayoutOthers from "../layouts/LayoutOthers";
import OtherPageHeaders from "../layouts/OtherPageHeaders";

const Affiliates = () => {
  return (
    <div>
      <LayoutOthers>
        <OtherPageHeaders />
        <Container
          style={{
            maxWidth: "1000px",
            minHeight: "500px",
            marginTop: "50px",
            marginBottom: "50px",
            alignItems: "center",
          }}
        >
          <Row>
            <Col as={Col}>
              <p>
                {" "}
                FTC Disclosure Agreement Rules In 2015, the Federal Trade
                Commission released their new instructions/rules for Disclosure
                Compliance. These rules are set in place to ensure that readers
                or viewers of web media are aware if the blogger or publisher is
                sponsored, endorsed, or partnered with a diverse company. The
                readers need to recognize if the content publisher is making
                money by sharing a link or product. As per agreement with the
                FTC guidelines, please assume the following about links and
                posts on this site: Any/all of the links on TheDigitalSpecs.com
                are affiliate links for which I receive a small reward from
                sales of certain items. What Are Affiliate Links? Purchases are
                made on external affiliate company websites: when a visitor or
                reader clicks on an affiliate link located on the
                TheDigitalSpecs.com to purchase an item, the visitor or reader
                buys the item from the seller directly (not from the The Digital
                Specs). Amazon and/or other companies pay The Digital Specs a
                small commission or other compensation for helping to bring
                customers to their website. The Digital Specs devotes lots of
                hours for investigating, evaluating, and analysing products to
                recommend the best picks for most consumers. Prices are exactly
                the same for readers whether they purchase is through an
                affiliate link or a non-affiliate link. Clicking an affiliate
                link and clicking a non-affiliate link does not change the price
                or anything else for the reader. TheDigitalSpecs.com uses two
                main types of affiliate programs: Amazon affiliate links. The
                Digital Specs is a participant in the Amazon Services LLC
                Associates Program, an affiliate advertising program designed to
                provide a means for website owners to earn fees by linking to
                Amazon.com and other affiliated sites, as well as to other
                websites that may be affiliated with Amazon Service LLC
                Associates Program. Product affiliate links. If you click a
                product affiliate link and buy the product, then We may earn a
                small commission or refferel comission at no cost to you, and
                also, we never receive free products from manufacturers. Yet
                again, prices are not different if you use these affiliate
                links. You will not pay not at all more by clicking through to
                the link. These links are not “pay per click.” What about
                sponsored content? I/we do not write sponsored posts. However,
                if a company would like to publish sponsored content on The
                Digital Specs, I will inform this matter clearly in the
                beginning of the post when it publishes. The bottommost line is
                that I/we only recommend products I/we use myself or that I/we
                would recommend to family and friends. Your obtaining/visiting
                helps support my research efforts. Thank you very, very much for
                ! I appreciate you! This Disclosure is adapted from M. Shantu
                and Team.
              </p>
            </Col>
          </Row>
        </Container>
      </LayoutOthers>
    </div>
  );
};

export default Affiliates;
