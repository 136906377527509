export const amazonData = {
  SearchResult: {
    Items: [
      {
        ASIN: "B06WW64YM6",
        id: 1,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Film Cameras",
                DisplayName: "Film Cameras",
                Id: "1389183031",
              },
              ContextFreeName: "Instant Film Cameras",
              DisplayName: "Instant Cameras",
              Id: "1389185031",
              IsRoot: false,
              SalesRank: 1,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 410,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B06WW64YM6?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/41WfBNBSq1L._SL500_.jpg",
              Width: 484,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/41WfBNBSq1L._SL160_.jpg",
              Width: 154,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/41WfBNBSq1L._SL75_.jpg",
              Width: 72,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41Tkc0eppxL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41Tkc0eppxL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41Tkc0eppxL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/312GekBioIL._SL500_.jpg",
                Width: 484,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/312GekBioIL._SL160_.jpg",
                Width: 154,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/312GekBioIL._SL75_.jpg",
                Width: 72,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41LpEOWEYML._SL500_.jpg",
                Width: 475,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41LpEOWEYML._SL160_.jpg",
                Width: 152,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41LpEOWEYML._SL75_.jpg",
                Width: 71,
              },
            },
            {
              Large: {
                Height: 334,
                URL: "https://m.media-amazon.com/images/I/31-2ZRKKT-L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31-2ZRKKT-L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 50,
                URL: "https://m.media-amazon.com/images/I/31-2ZRKKT-L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 334,
                URL: "https://m.media-amazon.com/images/I/31OPC3QbOTL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31OPC3QbOTL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 50,
                URL: "https://m.media-amazon.com/images/I/31OPC3QbOTL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/41bIg0dZHlL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/41bIg0dZHlL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/41bIg0dZHlL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/51ITN3zaZPL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/51ITN3zaZPL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/51ITN3zaZPL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Fujifilm",
              Label: "Brand",
              Locale: "en_IN",
            },
            Contributors: [
              {
                Locale: "en_IN",
                Name: "FUJIFILM",
                Role: "Designer",
                RoleType: "designer",
              },
            ],
            Manufacturer: {
              DisplayValue: "Fujifilm Corporation",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [
                "4547410349474",
                "0074101033267",
                "7109157781879",
              ],
              Label: "EAN",
              Locale: "en_US",
            },
            UPCs: {
              DisplayValues: ["074101033267"],
              Label: "UPC",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "New Selfie Mirror and close-up lense attachment for perfect selfies; Produces instant credit card size photos ; Focusing: 0.6m - ∞ ; Auto power off time: 5 min ; Power Supply: Two AA-size 1.5V alkaline batteries Capacity: 100 shots(Approximately 10 instax mini film packs with new AA batteries) ; Film Feeding out: Automatic",
              "Built-in Flash and Automatic exposure Measurement: The camera signals the recommended aperture setting with a flashing LED. This helps capture the perfect photo every time",
              "High-Key mode : Take brighter pictures with a soft look - perfect for portraits; Viewfinder-Real image finder, 0.37x, with target spot",
              "Uses Instax Mini Film, print size 54 (w) x 86 (H)mm, image size 46 (w) x 62 (H)mm",
              "instax mini 9 camera - Smoky white instant camera, Hand Strap, Close-up Lens Attachment, AA Batteries x2, Instruction Manual and 1 year manufacturer warranty for camera",
              "New Selfie Mirror and close-up lense attachment for perfect selfies; Produces instant credit card size photos",
              "Built-in Flash and Automatic exposure Measurement: The camera signals the recommended aperture setting with a flashing LED. This helps capture the perfect photo every time",
              "High-Key mode : Take brighter pictures with a soft look - perfect for portraits; Viewfinder-Real image finder, 0.37x, with target spot",
              "Uses Instax Mini Film, print size 54 (w) x 86 (H)mm, image size 46 (w) x 62 (H)mm",
              "instax mini 9 camera - Smoky white instant camera, Hand Strap, Close-up Lens Attachment, AA Batteries x2, Instruction Manual and 1 year manufacturer warranty for camera",
              "Manufacturer Detail: FUJIFILM Corporation, 26-30, Nishiazabu, 2-Chome, Minato-ku, Tokyo 1068620 Japan",
              "Packer Detail: FUJIFILM Corporation, 26-30, Nishiazabu, 2-Chome, Minato-ku, Tokyo 1068620 Japan",
              "Importer Details: Fujifilm India Pvt Ltd, Reg Off: Business Centre Office, No.521, Level V, Caddie, Commercial Tower, Hospitality District Aerocity, IGI Airport, New Delhi - 110037",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "Instax Mini 9 - Ice Blue",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "Instax Mini 9 - Ice Blue",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1 year manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Ice Blue",
              Label: "Color",
              Locale: "en_IN",
            },
            IsAdultProduct: {
              DisplayValue: false,
              Label: "IsAdultProduct",
              Locale: "en_US",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 5.5,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 3,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.8,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 5,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            ReleaseDate: {
              DisplayValue: "2017-04-23T00:00:01Z",
              Label: "ReleaseDate",
              Locale: "en_US",
            },
            Size: {
              DisplayValue: "2.7x4.7x4.6",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue: "Fujifilm Instax Mini 9 Instant Camera (Ice Blue)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 1,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi0W9G2tXddInNu87S2j6dsqLvhwHotcyMnIMn0TluS0DU9Kvya%2BVlrnL3ETRaLSiYthyvo0tO25dPJakten8vudOYv0CT8kJoMMHQGS%2BYIr1SXt7DYVUaxDZPqHmdFmp6YHmf7jrdj8E",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 3998,
                Currency: "INR",
                DisplayAmount: "₹3,998.00",
                Savings: {
                  Amount: 1532,
                  Currency: "INR",
                  DisplayAmount: "₹1,532.00 (28%)",
                  Percentage: 28,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 5530,
                Currency: "INR",
                DisplayAmount: "₹5,530.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 5540,
                Currency: "INR",
                DisplayAmount: "₹5,540.00",
              },
              LowestPrice: {
                Amount: 3998,
                Currency: "INR",
                DisplayAmount: "₹3,998.00",
              },
              OfferCount: 3,
            },
          ],
        },
        ParentASIN: "B08T2W8P3L",
      },
      {
        ASIN: "B00IHS3RGQ",
        id: 2,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Point & Shoot Digital Cameras",
              DisplayName: "Point & Shoot Digital Cameras",
              Id: "1389181031",
              IsRoot: false,
              SalesRank: 1,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 1548,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B00IHS3RGQ?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 287,
              URL: "https://m.media-amazon.com/images/I/51EFnthLYPL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 91,
              URL: "https://m.media-amazon.com/images/I/51EFnthLYPL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 43,
              URL: "https://m.media-amazon.com/images/I/51EFnthLYPL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 348,
                URL: "https://m.media-amazon.com/images/I/410gwuSu8lL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 111,
                URL: "https://m.media-amazon.com/images/I/410gwuSu8lL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 52,
                URL: "https://m.media-amazon.com/images/I/410gwuSu8lL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 348,
                URL: "https://m.media-amazon.com/images/I/41orZt5aVJL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 111,
                URL: "https://m.media-amazon.com/images/I/41orZt5aVJL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 52,
                URL: "https://m.media-amazon.com/images/I/41orZt5aVJL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 348,
                URL: "https://m.media-amazon.com/images/I/41psKDgkNnL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 111,
                URL: "https://m.media-amazon.com/images/I/41psKDgkNnL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 52,
                URL: "https://m.media-amazon.com/images/I/41psKDgkNnL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 348,
                URL: "https://m.media-amazon.com/images/I/41H5ocPAG9L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 111,
                URL: "https://m.media-amazon.com/images/I/41H5ocPAG9L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 52,
                URL: "https://m.media-amazon.com/images/I/41H5ocPAG9L._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Sony",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Sony Imaging Products & Solutions Inc.",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [4905524972504, 4548736016217],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "Super HAD CCD sensor with 20.1 effective megapixels : Included Components - 1 U (Including-Rechargeable battery, AC Adaptor, Multi USB Cable, Wrist Strap,User Manual )",
              "720p MP4 movie mode the camera shoots 1280 x 720 high definition movies at 30 fps, Focal length f=4.5-36mm. Exposure Compensation: +/- 2.0 EV, 1/3 EV step",
              "8x optical zoom Carl Zeiss Vario Tessar lens.Refer user manual ; Screen type: 6.7cm(2.7-type)(4:3) / 230,400 dots / ClearPhoto / TFT LCD ; Brightness Control: 5 (Bright) / 4 / 3 / 2 / 1 (Dark)",
              "Equipped with sweep panorama, intelligent auto and picture effect, Self-Timer: Off , 10sec., 2sec., portrait1, portrait2",
              "Power Consumption (Camera Mode): Approx. 1.2W; Power Source: DC3.6V (supplied battery)/DC5.0V (supplied AC adaptor)",
              "Memory card to be purchased separately",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "SNY_W830PS_BLK",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "SNY_W830PS_BLK",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "2 years Manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 3.66141,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.08661,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.2645547144,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 0.90551,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            ReleaseDate: {
              DisplayValue: "2017-01-01T00:00:01Z",
              Label: "ReleaseDate",
              Locale: "en_US",
            },
            UnitCount: {
              DisplayValue: 6,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Sony DSC W830 Cyber-Shot 20.1 MP Point and Shoot Camera (Black) with 8X Optical Zoom",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 1,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi4DLGePqt5fAaCgJ9n6YkYB%2B41FDaY0W9MVhkVEsRiMNkcS386o0UzpFPKjaBecRHVL%2FYXslblWmHvefyzVmIO8gGkkK92pdb3%2BPHxfv6ylkIO0Yr%2FhDOIZkvB449%2F1cU21kAICk2mm4",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 11790,
                Currency: "INR",
                DisplayAmount: "₹11,790.00",
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 11790,
                Currency: "INR",
                DisplayAmount: "₹11,790.00",
              },
              LowestPrice: {
                Amount: 11790,
                Currency: "INR",
                DisplayAmount: "₹11,790.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B0891CTLRH",
      },
      {
        ASIN: "B07HJD1KH4",
        id: 3,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Security Cameras",
                DisplayName: "Security Cameras",
                Id: "1389203031",
              },
              ContextFreeName: "Dome Cameras",
              DisplayName: "Dome Cameras",
              Id: "1389205031",
              IsRoot: false,
              SalesRank: 1,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 197,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07HJD1KH4?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/31dnfAXqe9L._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/31dnfAXqe9L._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/31dnfAXqe9L._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41LfTa9xYRL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41LfTa9xYRL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41LfTa9xYRL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41zk02nFVqL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41zk02nFVqL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41zk02nFVqL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31Lu1Ddy9sL._SL500_.jpg",
                Width: 372,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31Lu1Ddy9sL._SL160_.jpg",
                Width: 119,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31Lu1Ddy9sL._SL75_.jpg",
                Width: 55,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31ZqGZS8XzL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31ZqGZS8XzL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31ZqGZS8XzL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31fQzolQkUL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31fQzolQkUL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31fQzolQkUL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "MI",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Mi",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [6934177703157, 6934177713989],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "AI motion detection alert. The camera angle is 110 degree and the connectivity is Wi-Fi IEEE 802.11 b/g/n 2.4 GHz. Storage:MicroSD card (support 16GB -64GB, Class 10 and above storage card)",
              "Input Power: 5V ; Working temperature: -10℃ ~ 50℃; Support Devices : Android 4.4 above or IOS 9.0 above",
              "Infrared Night Vision",
              "Talkback Feature. Video encoding : H.265",
              "The Mi Camera 360° has perfect picture quality. With Mi Camera's 20 megapixels, 1080p resolution and wide dynamic range, even distant backgrounds are clear and detailed",
              "Inverted installation (optional)",
              "Up to 64GB SD card storage",
              "Customer Care Detail : 1800 103 6286",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "QDJ4030IN",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "MJSXJ02CM",
              Label: "Model",
              Locale: "en_US",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "White",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 4.64566,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 3.07086,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.52690480618,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 3.07086,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "1 Count (Pack of 1)",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Mi 360° Home Security Camera 1080P l Full HD Picture l AI Powered Motion Detection l Infrared Night Vision | 360° Panorama | Talk Back Feature (2-Way Audio)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 2,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi7RCaFmK%2F2HBCfqkVjYSqVfWIZjtjkQWJaz2ZIx%2FLST%2FX35uQbn0wOFYARdyRkYZEhH7uedsDvYbu3SQpssPng4NptKVT%2BDQh2IMF0Nxz2OoaJlEWQYnRp%2BpiSCBqHudnwg6qH4Ha%2Fmk",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 3199,
                Currency: "INR",
                DisplayAmount: "₹3,199.00",
                Savings: {
                  Amount: 800,
                  Currency: "INR",
                  DisplayAmount: "₹800.00 (20%)",
                  Percentage: 20,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 3999,
                Currency: "INR",
                DisplayAmount: "₹3,999.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 3199,
                Currency: "INR",
                DisplayAmount: "₹3,199.00",
              },
              LowestPrice: {
                Amount: 3199,
                Currency: "INR",
                DisplayAmount: "₹3,199.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B09Q6JXNC7",
      },
      {
        ASIN: "B08TS8R2NN",
        id: 4,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Film Cameras",
                DisplayName: "Film Cameras",
                Id: "1389183031",
              },
              ContextFreeName: "Instant Film Cameras",
              DisplayName: "Instant Cameras",
              Id: "1389185031",
              IsRoot: false,
              SalesRank: 4,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 1505,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B08TS8R2NN?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 457,
              URL: "https://m.media-amazon.com/images/I/41Hpm62040L._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 146,
              URL: "https://m.media-amazon.com/images/I/41Hpm62040L._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 68,
              URL: "https://m.media-amazon.com/images/I/41Hpm62040L._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41-KNefvIjL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41-KNefvIjL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41-KNefvIjL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51+wSAU3IPL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51+wSAU3IPL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51+wSAU3IPL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/412R8SLyBoL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/412R8SLyBoL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/412R8SLyBoL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41SK1qfyyXL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41SK1qfyyXL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41SK1qfyyXL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41BzCqzEsPL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41BzCqzEsPL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41BzCqzEsPL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Fujifilm",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Fujifilm Corporation",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [8908014041939],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "In Gift Box - Instax Mini 9 Camera + Instax Mini Film Pack 10x1 + Instax Photo Bunting + Instax Photo Album + Batteries + Camera Strap + Warranty Card + Instruction Manual",
              "Selfie mirror - you can check your framing with the mirror next to the lens",
              "High-key mode - setting the brightness adjustment dial to the high-key mode mark allows you to take pictures with a softer impression",
              "Simple operation for instant pictures, brightness adjustment dial",
              "Instax mini 9 automatically determines the best brightness for taking a picture, and lights up the most suitable setting based on your surroundings",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "Instax Mini 9 Instant Camera",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "Instax Mini 9 Instant Camera",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1 year manufacturer",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Ice Blue",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 4.64566,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.67716,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.67681914434,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 4.56692,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            ReleaseDate: {
              DisplayValue: "2021-01-20T00:00:01Z",
              Label: "ReleaseDate",
              Locale: "en_US",
            },
            Size: {
              DisplayValue: "Compact",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 5,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Fujifilm Instax Mini 9 Instant Camera (Ice Blue) Gift Box",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 2,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi8XJPzURqUw0DWOGgQ1PyEeUImq1Q8PsCjxpZEZhS1ho1ZtB1x4lsiPAlI%2FlQZDgmyL%2FC1ZdWUzpD1ppBgjfKyQCpO1w7Tzty4dVBOxTqbh5cqHxb03n1N6QuFM%2F4e%2FTm6%2F1%2FnybjFOO",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 4999,
                Currency: "INR",
                DisplayAmount: "₹4,999.00",
                Savings: {
                  Amount: 1000,
                  Currency: "INR",
                  DisplayAmount: "₹1,000.00 (17%)",
                  Percentage: 17,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 5999,
                Currency: "INR",
                DisplayAmount: "₹5,999.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 4999,
                Currency: "INR",
                DisplayAmount: "₹4,999.00",
              },
              LowestPrice: {
                Amount: 4999,
                Currency: "INR",
                DisplayAmount: "₹4,999.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B08VFS1D38",
      },
      {
        ASIN: "B07XLML2YS",
        id: 5,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Security Cameras",
                DisplayName: "Security Cameras",
                Id: "1389203031",
              },
              ContextFreeName: "Dome Cameras",
              DisplayName: "Dome Cameras",
              Id: "1389205031",
              IsRoot: false,
              SalesRank: 2,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 491,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07XLML2YS?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/31pcbVy11RL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/31pcbVy11RL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/31pcbVy11RL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31NVRWeceqL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31NVRWeceqL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31NVRWeceqL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51XZuIEPYbL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51XZuIEPYbL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51XZuIEPYbL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41DIztWZJ0L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41DIztWZJ0L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41DIztWZJ0L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41TYWIWLBAL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41TYWIWLBAL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41TYWIWLBAL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41yjIJnuvdL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41yjIJnuvdL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41yjIJnuvdL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "TP-Link",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "TP-link",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ContentInfo: {
            Languages: {
              DisplayValues: [
                {
                  DisplayValue: "German",
                  Type: "Manual",
                },
                {
                  DisplayValue: "English",
                  Type: "Manual",
                },
                {
                  DisplayValue: "Dutch",
                  Type: "Dictionary",
                },
                {
                  DisplayValue: "German",
                  Type: "Subtitled",
                },
                {
                  DisplayValue: "German",
                  Type: "Original Language",
                },
              ],
              Label: "Language",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [6935364053239, 6935364088095],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "High-Definition Video —— Records every image in crystal-clear 1080p definition;Pan and Tilt —— 360º horizontal and 114º vertical range; Advanced Night Vision —— Provides a visual distance of up to 30 ft;Motion Detection and Notifications —— Notifies you when the camera detects movement",
              "Sound and Light Alarm —— Trigger light and sound effects to frighten away unwanted visitors.;Two-Way Audio —— Enables communication through a built-in microphone and speaker",
              "Safe Storage —— Locally stores up to 128 GB on a microSD card, equal to 384 hours (16 days) of footage. (Based on laboratory conditions)",
              "Voice Control —— Free Up Your Hands with Voice Control —— Works with the Google Assistant and Amazon Alexa. (Google Assistant and Amazon Alexa are not available in all languages and countries)",
              "Specific Uses For Product: Surveillance; Included Components: Tapo Pan/Tilt Home Security Wi-Fi Camera, Power Adapter, Quick Start Guide ,Mounting Screws, Mounti",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "Tapo C200",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "Tapo C200",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1 Year Domestic Warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "White",
              Label: "Color",
              Locale: "en_IN",
            },
            IsAdultProduct: {
              DisplayValue: false,
              Label: "IsAdultProduct",
              Locale: "en_US",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 4.3307,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 7.4803,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.260145469375,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 6.2992,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "1 Count (Pack of 1)",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "TP-LINK 360° 2MP 1080p Full HD Pan/Tilt Home Security Wi-Fi Smart Camera| Alexa Enabled| 2-Way Audio| Night Vision| Motion Detection| Sound and Light Alarm| Indoor CCTV (Tapo C200)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 2,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi0II10T35KMC5eCMFFSnSoeDuHGIaQp1ncQ5RVwAKBEbE%2Fy%2Fvw1TumTT8kH4hullSW75x8Ad%2BQdFTM5Dr12rHIGu44VJdres4gWp8lXHScRLrR14gyg9xu23Fom9wB1Sz7otPObBqY7s",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 2499,
                Currency: "INR",
                DisplayAmount: "₹2,499.00",
                Savings: {
                  Amount: 800,
                  Currency: "INR",
                  DisplayAmount: "₹800.00 (24%)",
                  Percentage: 24,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 3299,
                Currency: "INR",
                DisplayAmount: "₹3,299.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 2499,
                Currency: "INR",
                DisplayAmount: "₹2,499.00",
              },
              LowestPrice: {
                Amount: 2499,
                Currency: "INR",
                DisplayAmount: "₹2,499.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B09HH5Q5GC",
      },
      {
        ASIN: "B083V41T6M",
        id: 6,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Security Cameras",
                DisplayName: "Security Cameras",
                Id: "1389203031",
              },
              ContextFreeName: "Dome Cameras",
              DisplayName: "Dome Cameras",
              Id: "1389205031",
              IsRoot: false,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 967,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B083V41T6M?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/21MujAozRLL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/21MujAozRLL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/21MujAozRLL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41AZLC8mggL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41AZLC8mggL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41AZLC8mggL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31nWJg8HpAL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31nWJg8HpAL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31nWJg8HpAL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51h1LUPA7sL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51h1LUPA7sL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51h1LUPA7sL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51WD6D7tB5L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51WD6D7tB5L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51WD6D7tB5L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41p6yMFVEpL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41p6yMFVEpL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41p6yMFVEpL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31hJnmJvvGL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31hJnmJvvGL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31hJnmJvvGL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41pweRRaOeL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41pweRRaOeL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41pweRRaOeL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "TP-Link",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "TP-Link Technologies Co.,Ltd",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ContentInfo: {
            Languages: {
              DisplayValues: [
                {
                  DisplayValue: "German",
                  Type: "Manual",
                },
                {
                  DisplayValue: "English",
                  Type: "Manual",
                },
              ],
              Label: "Language",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["0767531764145", 6935364053222],
              Label: "EAN",
              Locale: "en_US",
            },
            UPCs: {
              DisplayValues: [767531764145],
              Label: "UPC",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "High Definition Video —— Records every image in crystal-clear 1080p definition;Advanced Night Vision —— Provides a visual distance of up to 30 ft",
              "Motion Detection and Notifications —— Notifies you when the camera detects movement;Sound and Light Alarm —— Trigger light and sound effects to frighten away unwanted visitors",
              "Two-Way Audio —— Enables communication through a built-in microphone and speaker",
              "Safe Storage —— Locally stores up to 128 GB on a microSD card, equal to 384 hours (16 days) of footage (based on laboratory conditions); Easy Setup and Management —— Manage everything with Tapo app",
              "Included Components: Tapo C100 Camera, Dc Power Adapter, Quick Start Guide, Mounting Screws, Mounting Template",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "1",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "Tapo C100",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue:
                "1 year warranty provided by the manufacturer from date of purchase",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "White",
              Label: "Color",
              Locale: "en_IN",
            },
            IsAdultProduct: {
              DisplayValue: false,
              Label: "IsAdultProduct",
              Locale: "en_US",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 2.51968,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 5.70865,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.5732018812,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 6.88975,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "1 Count (Pack of 1)",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "TP-LINK 2MP 1080p Full HD Home Security Wi-Fi Smart Camera| Alexa Enabled| 2-Way Audio| Night Vision| Motion Detection| Sound and Light Alarm| Indoor CCTV (Tapo C100)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 2,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi9W5aZflPi62u2it5WqgQ4JNm84uJdQbsujAz02S84W4wUm%2BIkfCm9AmMp2NiSmVp0Oqczau8HIkLyAH%2Bjl29tLl8aBL61mRzahQu124O2UtaVJic0HpQQOrIf6cnXfEfn81Ytse%2Fh3B",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 1849,
                Currency: "INR",
                DisplayAmount: "₹1,849.00",
                Savings: {
                  Amount: 1050,
                  Currency: "INR",
                  DisplayAmount: "₹1,050.00 (36%)",
                  Percentage: 36,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 2899,
                Currency: "INR",
                DisplayAmount: "₹2,899.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 1849,
                Currency: "INR",
                DisplayAmount: "₹1,849.00",
              },
              LowestPrice: {
                Amount: 1849,
                Currency: "INR",
                DisplayAmount: "₹1,849.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B09HH5Q5GC",
      },
      {
        ASIN: "B095J2WYN4",
        id: 7,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 4,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 4164,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B095J2WYN4?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/517kYSdT8qL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/517kYSdT8qL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/517kYSdT8qL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/61PTnhmsCyS._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/61PTnhmsCyS._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/61PTnhmsCyS._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51M8ofVGlgS._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51M8ofVGlgS._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51M8ofVGlgS._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51x3p8QUH+L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51x3p8QUH+L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51x3p8QUH+L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 450,
                URL: "https://m.media-amazon.com/images/I/51JHJ+gMnRS._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 144,
                URL: "https://m.media-amazon.com/images/I/51JHJ+gMnRS._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 67,
                URL: "https://m.media-amazon.com/images/I/51JHJ+gMnRS._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51LXt6ntCHL._SL500_.jpg",
                Width: 475,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51LXt6ntCHL._SL160_.jpg",
                Width: 152,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51LXt6ntCHL._SL75_.jpg",
                Width: 71,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51E2rqT9yJS._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51E2rqT9yJS._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51E2rqT9yJS._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "CASON",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "CASON",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "4K 24 MP Action Camera: CASON Sports Camera, with 4k/ 30fps smooth video resolution and photographs at 24 MP, can better help you capture every excellent moment while vlog recording,skiing, skating,swimming,snorkeling,diving,and more.",
              "External Microphone: Action cameras with microphone will significantly improve the sound quality of your footage and record a wider range of frequencies for Vlogging,which can capture sound from all directions.(The external mic and waterproof case can't be used together, because the case is totally sealed.)",
              "Electronic Image Stabilization: 4K Camera With EIS tech can counteract any bump, shake, or camera tilt during use, promising your images and videos clear and stable . Just turn on the EIS, and you will never sigh for the blurry shot or video during the bumps and knocks even under the roughest conditions.",
              "High Quality Waterproof Case and Muti-accessories:Sports Action Camera with High-quality waterproof case can make this camera work normally for even underwater 30 meters. Dual 1050mAh batteries and multi accessories can meet your needs for any outdoor activities",
              "Multi-functions & Modes -Awesome functions like 170° wide angle, loop recording, time lapse, burst photo, slow motion, screen saver will satisfy you in all aspects. Recording your wonderful life with CASON CN10 action camera.",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "CSN_CN10_ACTION_CAMERA",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "CN10 4K Action Camera With Mic",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue:
                "1 Year If Manufacturing Defect , Carry In warranty , Not valid if damaged or misused",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 0.9842519675,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.362204722,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Width: {
                DisplayValue: 1.574803148,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "6 x 4 x 2.5 Cm",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          TechnicalInfo: {
            Formats: {
              DisplayValues: ["4K"],
              Label: "Format",
              Locale: "en_IN",
            },
          },
          Title: {
            DisplayValue:
              "Cason CN10 Proffessional 4K 60fps HD 24MP Action Camera For Vlogging with EIS Anti Shake Touch Screen Waterproof Sports Camera With External Mic , 2 x 1350 mAh Battery ,Remote and Accessories Kit (Black)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemix98QzH1xXE9g3PCvSVxPPIs5%2BWpAp42GLuzGCIahJ704kKz0v15cWviNRNPBwT7u8keAqQpMHU4RzqgtoT5u7JGdvZzuc6L1308aIR8GIzkeBJzKiXrBqc%2BlCwFYJSi%2B1OonmKWtsi9Xzf0xyQ%2B2r%2FJlXPRsTOsRxkqPhVui5b3",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 333,
                FeedbackRating: 4.52,
                Id: "A3TGE6ND60CUYK",
                Name: "DOCOSS INDIA",
              },
              Price: {
                Amount: 7889,
                Currency: "INR",
                DisplayAmount: "₹7,889.00",
                Savings: {
                  Amount: 5106,
                  Currency: "INR",
                  DisplayAmount: "₹5,106.00 (39%)",
                  Percentage: 39,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 12995,
                Currency: "INR",
                DisplayAmount: "₹12,995.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 7889,
                Currency: "INR",
                DisplayAmount: "₹7,889.00",
              },
              LowestPrice: {
                Amount: 7889,
                Currency: "INR",
                DisplayAmount: "₹7,889.00",
              },
              OfferCount: 1,
            },
          ],
        },
      },
      {
        ASIN: "B07JLXC5BR",
        id: 8,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Mirrorless System Cameras",
              DisplayName: "Mirrorless System Cameras",
              Id: "1389179031",
              IsRoot: false,
              SalesRank: 1,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 3105,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07JLXC5BR?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/51RCaRoUPKL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/51RCaRoUPKL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/51RCaRoUPKL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 375,
                URL: "https://m.media-amazon.com/images/I/413jPKGPkxL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/413jPKGPkxL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/413jPKGPkxL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51JCSvcKxdL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51JCSvcKxdL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51JCSvcKxdL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 375,
                URL: "https://m.media-amazon.com/images/I/41HKPcq0AxL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/41HKPcq0AxL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/41HKPcq0AxL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 375,
                URL: "https://m.media-amazon.com/images/I/41hYzgn-WaL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/41hYzgn-WaL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/41hYzgn-WaL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 375,
                URL: "https://m.media-amazon.com/images/I/51-5R6KHJiL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/51-5R6KHJiL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/51-5R6KHJiL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 375,
                URL: "https://m.media-amazon.com/images/I/51+you+7hzL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/51+you+7hzL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/51+you+7hzL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 375,
                URL: "https://m.media-amazon.com/images/I/51Nc1gk4cvL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/51Nc1gk4cvL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/51Nc1gk4cvL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 375,
                URL: "https://m.media-amazon.com/images/I/41F+SeZGYwL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/41F+SeZGYwL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/41F+SeZGYwL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Panasonic",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Panasonic Corporation",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [8887549715662],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "PROFESSIONAL PHOTO AND VIDEO PERFORMANCE: 16 megapixel Micro Four Thirds sensor with no low pass filter to confidently capture sharp images with a high dynamic range and artifact free performance; WiFi enabled",
              "4K VIDEO CAPTURE: 4K QHD video recording (3840 x 2160) with three 4K Ultra HD capture at 25p and Full HD ( 1920 * 1080 ) at 50p",
              "4K PHOTO MODE : 8MP PHOTO BURST MODE at 30 fps, extracts individual high resolution images from 4K Ultra HD video filmed at 30 frames per second to capture split second moments",
              "INTUITIVE CONTROLS: Easily control aperture and shutter settings with the front and rear dials while making white balance and ISO adjustments on the fly; Assign favorite settings to any of the six function buttons (six on body, five on menu)",
              "HIGH RESOLUTION VIEWFINDER AND LCD DISPLAY: High resolution (2,360K dot) OLED Live View Finder and rear touch enabled 3 inch tilt/swivel LCD display (1,040 dot) are clear even in bright sunlight, Image sensor size 17.3 x 13.0 mm (in 4:3 aspect ratio)",
              "CONNECTIVITY AND PORTS: 3.5mm external mic port, 2.5mm remote port, USB 2.0 and micro HDMI Type D; Compatible with newer UHS I/UHS II SDXC/SDHC SD cards capable of storing high resolution 4K videos",
              "Manufacturer Detail: Panasonic AVC Networks Xiamen Co. Ltd., Hi-Tech Industrial Development Zone, Xiamen, Fujian, China",
              "Packer Detail: Panasonic AVC Networks Xiamen Co. Ltd., Hi-Tech Industrial Development Zone, Xiamen, Fujian, China",
              "Importer Details: Panasonic India Pvt Ltd, 12th Floor, Ambience Tower, Ambience Island, NH-8, Gurgaon - 122002, Haryana, India",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "DMC-G7KGW-K",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "DMC-G7KGW-K",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "2 years Manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 3.393694,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 3.047238,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 1.4219815899,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 4.917313,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              'Panasonic LUMIX G7 16.00 MP 4K Mirrorless Interchangeable Lens Camera Kit with 14-42 mm Lens (Black)"',
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 2,
                Message: "Usually dispatched in 3 days.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi6xqGyIfJ1hTRRE8NH%2B%2BD1UNWGa9V6ipkLjE%2BMLAlLZmd3SpHg1nR%2B%2FWW6J7Ix0n1XDqhmDfBgufK0YNb32DvoRFnMCz3To2kWVejxGvq5inz621ujch%2F3gz7LTaCVcHgXbL5nxUO3ro",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 38489,
                Currency: "INR",
                DisplayAmount: "₹38,489.00",
                Savings: {
                  Amount: 16501,
                  Currency: "INR",
                  DisplayAmount: "₹16,501.00 (30%)",
                  Percentage: 30,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 54990,
                Currency: "INR",
                DisplayAmount: "₹54,990.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 38489,
                Currency: "INR",
                DisplayAmount: "₹38,489.00",
              },
              LowestPrice: {
                Amount: 38489,
                Currency: "INR",
                DisplayAmount: "₹38,489.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B083ZW3PRX",
      },
      {
        ASIN: "B07X25MNXF",
        id: 9,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 8,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 5936,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07X25MNXF?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/31Fe6PUgPfL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/31Fe6PUgPfL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/31Fe6PUgPfL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51MCe6OxjEL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51MCe6OxjEL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51MCe6OxjEL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/416mJ3Xy1HL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/416mJ3Xy1HL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/416mJ3Xy1HL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/61dZzhGr+4L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/61dZzhGr+4L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/61dZzhGr+4L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41psd5UGaTL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41psd5UGaTL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41psd5UGaTL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/61VOHukbgBL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/61VOHukbgBL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/61VOHukbgBL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41sKjvaT6ML._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41sKjvaT6ML._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41sKjvaT6ML._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41w4VS83fhL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41w4VS83fhL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41w4VS83fhL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41LqBpqt51L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41LqBpqt51L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41LqBpqt51L._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "PROCUS",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Procus",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "Latest Chipset: Procus Viper comes with the latest chipset that helps capture high- quality videos and images.",
              "Waterproof up to 30m: With the included waterproof case on, you are free to take your Viper Action cam to the swimming pool or in rain.",
              "Supports HDMI and Wi-Fi: Download the XDV app on your smartphone and connect it with Viper Action camera to view all your files right on your Android and iOS devices.",
              "Wide Angle lens: Procus Viper action camera boasts a 140 ° wide angle lens to ensure maximum field coverage.",
              "Powerful Battery: Bid goodbye to all your power woes with a 1050 mAh battery that comes with Procus Viper.",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "PR08",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "PR08",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "6 months",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "New Design",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 1.61417,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 1.173226,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.12786811196,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 2.330704,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "New Design",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Procus Viper 16MP 4K HD Action Camera Waterproof with Wi-Fi",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi4w5Z93W5J6FJZL0jwzJCijUS8ALaQ6HVExJzLRrRe%2FL0Ba7veNa0LPU1r4cOwtBLkf72mMIK1XaSKl%2BUh0T8bSOpPNAX7DwYxXeYo7XD8ByTQ3N3nYlnVnr58O0TiCCxjxNjLzgDLPg",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 4500,
                Currency: "INR",
                DisplayAmount: "₹4,500.00",
                Savings: {
                  Amount: 3499,
                  Currency: "INR",
                  DisplayAmount: "₹3,499.00 (44%)",
                  Percentage: 44,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 7999,
                Currency: "INR",
                DisplayAmount: "₹7,999.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 4899,
                Currency: "INR",
                DisplayAmount: "₹4,899.00",
              },
              LowestPrice: {
                Amount: 4500,
                Currency: "INR",
                DisplayAmount: "₹4,500.00",
              },
              OfferCount: 2,
            },
          ],
        },
        ParentASIN: "B0891F979W",
      },
      {
        ASIN: "B08H58G91N",
        id: 10,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Security Cameras",
                DisplayName: "Security Cameras",
                Id: "1389203031",
              },
              ContextFreeName: "Dome Cameras",
              DisplayName: "Dome Cameras",
              Id: "1389205031",
              IsRoot: false,
              SalesRank: 12,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 1706,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B08H58G91N?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 478,
              URL: "https://m.media-amazon.com/images/I/317TFjK6+GL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 152,
              URL: "https://m.media-amazon.com/images/I/317TFjK6+GL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 71,
              URL: "https://m.media-amazon.com/images/I/317TFjK6+GL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51kolAHPwUL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51kolAHPwUL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51kolAHPwUL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51WZKKZ-DrL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51WZKKZ-DrL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51WZKKZ-DrL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51rq79Ah1qL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51rq79Ah1qL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51rq79Ah1qL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41-iB7LQZlL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41-iB7LQZlL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41-iB7LQZlL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51SDwUw6QBL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51SDwUw6QBL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51SDwUw6QBL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51f4Sfoj5HL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51f4Sfoj5HL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51f4Sfoj5HL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51P0NmqZeLL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51P0NmqZeLL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51P0NmqZeLL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41Io3ihQoFL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41Io3ihQoFL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41Io3ihQoFL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "ZEBRONICS",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Zebronics",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [8906108618791],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "2.0 Megapixel",
              "Advanced Motion Detection",
              "2-Way Audio, H. 264 Video Compression",
              "Alexa support, OK google support and more",
              "Monitored Remotely from anywhere",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "Zeb Smart Cam 100",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "Zeb Smart Cam 100",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1 YEAR CARRY IN TO SERVIEC CENTER",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            ItemDimensions: {
              Height: {
                DisplayValue: 5.9055,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 1.9685,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.440924524,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 3.5433,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "1 Count (Pack of 1)",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Zebronics Zeb Smart Cam 100 Smart Home Automation WiFi Camera with Remote Monitoring, Advanced Motion Detection, Day/Night Mode, Live Streaming, Micro SD Card Slot, 2 Way Audio, works with Android and iOS Smartphones",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 2,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "cLKkCCXGAjIsRZMtKuemi6MAg9zC00FbMyTebR2pMRD4jjgBvgZPtL3wwa0vGIqn%2BPSGZeskElLIBnctaDmAsOOuh0x%2BUbml%2FmUH0foLBBgu0B8HTJJnqq1f35y0wL67IWhBrzkJqCHMdQ72gjfK%2FXEEeG8aZWYt",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 65460,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 1398,
                Currency: "INR",
                DisplayAmount: "₹1,398.00",
                Savings: {
                  Amount: 1101,
                  Currency: "INR",
                  DisplayAmount: "₹1,101.00 (44%)",
                  Percentage: 44,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 2499,
                Currency: "INR",
                DisplayAmount: "₹2,499.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 1499,
                Currency: "INR",
                DisplayAmount: "₹1,499.00",
              },
              LowestPrice: {
                Amount: 1398,
                Currency: "INR",
                DisplayAmount: "₹1,398.00",
              },
              OfferCount: 4,
            },
          ],
        },
      },
      {
        ASIN: "B07BS4TJ43",
        id: 11,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Digital SLR Cameras",
              DisplayName: "Digital SLRs",
              Id: "1389177031",
              IsRoot: false,
              SalesRank: 1,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 5220,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07BS4TJ43?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 396,
              URL: "https://m.media-amazon.com/images/I/51UHoxzInpL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 126,
              URL: "https://m.media-amazon.com/images/I/51UHoxzInpL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 59,
              URL: "https://m.media-amazon.com/images/I/51UHoxzInpL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 465,
                URL: "https://m.media-amazon.com/images/I/51+oD1BPYdL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 148,
                URL: "https://m.media-amazon.com/images/I/51+oD1BPYdL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 69,
                URL: "https://m.media-amazon.com/images/I/51+oD1BPYdL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41Y795fKa3L._SL500_.jpg",
                Width: 431,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41Y795fKa3L._SL160_.jpg",
                Width: 137,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41Y795fKa3L._SL75_.jpg",
                Width: 64,
              },
            },
            {
              Large: {
                Height: 375,
                URL: "https://m.media-amazon.com/images/I/41A2-5m87uL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/41A2-5m87uL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/41A2-5m87uL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 370,
                URL: "https://m.media-amazon.com/images/I/41uQLiAN-PL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 118,
                URL: "https://m.media-amazon.com/images/I/41uQLiAN-PL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 55,
                URL: "https://m.media-amazon.com/images/I/41uQLiAN-PL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 440,
                URL: "https://m.media-amazon.com/images/I/51yFk+hRprL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 140,
                URL: "https://m.media-amazon.com/images/I/51yFk+hRprL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 66,
                URL: "https://m.media-amazon.com/images/I/51yFk+hRprL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/417tBi2vteL._SL500_.jpg",
                Width: 472,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/417tBi2vteL._SL160_.jpg",
                Width: 151,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/417tBi2vteL._SL75_.jpg",
                Width: 70,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Canon",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Canon Inc.",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["4549292111910"],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "Sensor: APS-C CMOS Sensor with 24.1 MP (high resolution for large prints and image cropping). Transmission frequency (central frequency):Frequency: 2 412 to 2 462MHz. Standard diopter :-2.5 - +0.5m-1 (dpt)",
              "ISO: 100-6400 sensitivity range (critical for obtaining grain-free pictures, especially in low light)",
              "Image Processor: DIGIC 4+ with 9 autofocus points (important for speed and accuracy of autofocus and burst photography)",
              "Video Resolution: Full HD video with fully manual control and selectable frame rates (great for precision and high-quality video work)",
              "Connectivity: WiFi, NFC and Bluetooth built-in (useful for remotely controlling your camera and transferring pictures wirelessly as you shoot)",
              "Lens Mount: EF-S mount compatible with all EF and EF-S lenses (crop-sensor mount versatile and compact, especially when used with EF-S lenses)",
              "Country of Origin: Taiwan",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "CN1500DDBB",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "Eos 1500D",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1+1 years Manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 5.511811018,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 8.661417314,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 2.7337320488,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "pounds",
              },
              Width: {
                DisplayValue: 6.4960629855,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            UnitCount: {
              DisplayValue: 6,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Canon EOS 1500D 24.1 Digital SLR Camera (Black) with EF S18-55 is II Lens",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 1,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEgl3vYykpXRCtXPmXc4A717wAy16DuJoLStjQhIrfRXtbfrwT6bQQGOtPXFVa8t2qPvrtBl%2B%2BEEeOzs%2FNCi9aJGvHpTO9bafjK4cCdP4NZbTSeBnubON42tPTxmdbisM77lVzKGLjJu4wY",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64318,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 41995,
                Currency: "INR",
                DisplayAmount: "₹41,995.00",
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 49990,
                Currency: "INR",
                DisplayAmount: "₹49,990.00",
              },
              LowestPrice: {
                Amount: 41995,
                Currency: "INR",
                DisplayAmount: "₹41,995.00",
              },
              OfferCount: 2,
            },
          ],
        },
        ParentASIN: "B07GYM4BVJ",
      },
      {
        ASIN: "B07BRWY6XV",
        id: 12,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Digital SLR Cameras",
              DisplayName: "Digital SLRs",
              Id: "1389177031",
              IsRoot: false,
              SalesRank: 16,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 77666,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07BRWY6XV?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 397,
              URL: "https://m.media-amazon.com/images/I/51yulnXQYmL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 127,
              URL: "https://m.media-amazon.com/images/I/51yulnXQYmL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 59,
              URL: "https://m.media-amazon.com/images/I/51yulnXQYmL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 439,
                URL: "https://m.media-amazon.com/images/I/51BVijIWgrL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 140,
                URL: "https://m.media-amazon.com/images/I/51BVijIWgrL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 65,
                URL: "https://m.media-amazon.com/images/I/51BVijIWgrL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 461,
                URL: "https://m.media-amazon.com/images/I/51K4TfrFXZL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 147,
                URL: "https://m.media-amazon.com/images/I/51K4TfrFXZL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 69,
                URL: "https://m.media-amazon.com/images/I/51K4TfrFXZL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41IUkLtS+ZL._SL500_.jpg",
                Width: 431,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41IUkLtS+ZL._SL160_.jpg",
                Width: 137,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41IUkLtS+ZL._SL75_.jpg",
                Width: 64,
              },
            },
            {
              Large: {
                Height: 378,
                URL: "https://m.media-amazon.com/images/I/41g5I1Hol1L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 120,
                URL: "https://m.media-amazon.com/images/I/41g5I1Hol1L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 56,
                URL: "https://m.media-amazon.com/images/I/41g5I1Hol1L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 373,
                URL: "https://m.media-amazon.com/images/I/41qrMLxYo-L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 119,
                URL: "https://m.media-amazon.com/images/I/41qrMLxYo-L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 55,
                URL: "https://m.media-amazon.com/images/I/41qrMLxYo-L._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Canon",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Canon",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["4549292107777"],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "18-megapixel APS-C-size CMOS sensor and DIGIC 4+ image processor",
              "9-point AF with 1 centre cross-type AF point",
              "Standard ISO 100 - 6400 (expandable to 12800)",
              "Wi-Fi supported",
              "18.0 megapixels",
              "Canon EF lenses (including EF-S lenses) compaitable.",
              "CMOS sensor",
              "Recording format : Design rule for Camera File System (DCF) 2.0",
              "JPEG, RAW (14-bit Canon original)",
              "Auto Lighting Optimizer",
              "Flash color temperature information transmission enabled",
              "9-point AF with 1 centre cross-type AF point",
              "Wi-Fi supported",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "MC2629C0110",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "EOS 3000D",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "2 years Manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 6.6141732216,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 5.4330708606,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 3.3951188348,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 8.8188976288,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            UnitCount: {
              DisplayValue: 6,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Canon EOS 3000D 18MP Digital SLR Camera (Black) with 18-55mm is II Lens, 16GB Card and Carry Case",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 3,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEgl%2FlHqtdcbL%2BCSVoRKdxBb0ZWrf%2FdzPOqFiudh3fhgPBx%2B3J1j5OM7YwlOWIYwB4ePlj7z2pIHJ%2FcqCJXR8MgKK6n2B9jYEnndpu%2FTJjooqMOj5GJ6vokwywl%2BoQDURpaZl0Ikb5EVQ18xaOCDRzXkO40MOyqHhFHRjSN1XhHcs3I",
              IsBuyBoxWinner: false,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 68,
                FeedbackRating: 3.76,
                Id: "A10SM47GMKYKBA",
                Name: "thegadgetstore",
              },
              Price: {
                Amount: 33750,
                Currency: "INR",
                DisplayAmount: "₹33,750.00",
                Savings: {
                  Amount: 245,
                  Currency: "INR",
                  DisplayAmount: "₹245.00 (1%)",
                  Percentage: 1,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 33995,
                Currency: "INR",
                DisplayAmount: "₹33,995.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 33750,
                Currency: "INR",
                DisplayAmount: "₹33,750.00",
              },
              LowestPrice: {
                Amount: 33750,
                Currency: "INR",
                DisplayAmount: "₹33,750.00",
              },
              OfferCount: 1,
            },
          ],
        },
      },
      {
        ASIN: "1681983214",
        id: 13,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    ContextFreeName: "Books",
                    DisplayName: "Books",
                    Id: "976389031",
                  },
                  ContextFreeName: "Books",
                  DisplayName: "Categories",
                  Id: "976390031",
                },
                ContextFreeName: "Arts, Film & Photography",
                DisplayName: "Arts, Film & Photography",
                Id: "1318052031",
              },
              ContextFreeName: "Photography Books",
              DisplayName: "Photography",
              Id: "1318061031",
              IsRoot: false,
              SalesRank: 1036,
            },
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    ContextFreeName: "Books",
                    DisplayName: "Books",
                    Id: "976389031",
                  },
                  ContextFreeName: "Books",
                  DisplayName: "Categories",
                  Id: "976390031",
                },
                ContextFreeName: "Computers & Internet",
                DisplayName: "Computers & Internet",
                Id: "1318105031",
              },
              ContextFreeName: "Graphics & Design",
              DisplayName: "Graphics & Design",
              Id: "1318111031",
              IsRoot: false,
              SalesRank: 449,
            },
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      Ancestor: {
                        ContextFreeName: "Books",
                        DisplayName: "Books",
                        Id: "976389031",
                      },
                      ContextFreeName: "Books",
                      DisplayName: "Categories",
                      Id: "976390031",
                    },
                    ContextFreeName: "Higher Education Textbooks",
                    DisplayName: "Higher Education Textbooks",
                    Id: "4149418031",
                  },
                  ContextFreeName: "Humanities",
                  DisplayName: "Humanities",
                  Id: "4149480031",
                },
                ContextFreeName: "Visual Arts",
                DisplayName: "Visual Arts",
                Id: "4149537031",
              },
              ContextFreeName: "Photography Textbooks",
              DisplayName: "Photography",
              Id: "4149540031",
              IsRoot: false,
              SalesRank: 122,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Books",
            DisplayName: "Books",
            SalesRank: 303927,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/1681983214?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/510JfVKu02L._SL500_.jpg",
              Width: 383,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/510JfVKu02L._SL160_.jpg",
              Width: 122,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/510JfVKu02L._SL75_.jpg",
              Width: 57,
            },
          },
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Rocky Nook",
              Label: "Brand",
              Locale: "en_IN",
            },
            Contributors: [
              {
                Locale: "en_IN",
                Name: "Busch, David D.",
                Role: "Author",
                RoleType: "author",
              },
            ],
            Manufacturer: {
              DisplayValue: "Rocky Nook",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Paperback",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Book",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ContentInfo: {
            Languages: {
              DisplayValues: [
                {
                  DisplayValue: "English",
                  Type: "Published",
                },
                {
                  DisplayValue: "English",
                  Type: "Original Language",
                },
                {
                  DisplayValue: "English",
                  Type: "Unknown",
                },
              ],
              Label: "Language",
              Locale: "en_IN",
            },
            PagesCount: {
              DisplayValue: 528,
              Label: "NumberOfPages",
              Locale: "en_US",
            },
            PublicationDate: {
              DisplayValue: "2018-01-05T00:00:01Z",
              Label: "PublicationDate",
              Locale: "en_US",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["9781681983219"],
              Label: "EAN",
              Locale: "en_US",
            },
            ISBNs: {
              DisplayValues: ["1681983214"],
              Label: "ISBN",
              Locale: "en_US",
            },
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "Illustrations Throughout",
              Label: "PartNumber",
              Locale: "en_US",
            },
          },
          ProductInfo: {
            ItemDimensions: {
              Height: {
                DisplayValue: 9,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 7,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 2.5,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 1,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "David Busch's Nikon D7500 Guide to Digital SLR Photography (The David Busch Camera Guide)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 30,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9i2zz%2FlOUOwq6mgiYX24AlWaixFgKu4ntg8O0bwYOK0niJ8USMhwxHb0YPLoT7B7qsi8vmCoz%2BbvySafGbZFopgzPUPg2OEH7eMkT3iBmWfYg98cU2LAUvBM3qjb%2F1lAndjpizylfTHE2PAKosiTGmQx5VbBqoEI6jrQ4N8pqZG3Q%3D%3D",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 569,
                FeedbackRating: 4.6,
                Id: "A1S46CEHK621UY",
                Name: "Atlantic Publishers and Distributors",
              },
              Price: {
                Amount: 2251,
                Currency: "INR",
                DisplayAmount: "₹2,251.00",
                Savings: {
                  Amount: 897,
                  Currency: "INR",
                  DisplayAmount: "₹897.00 (28%)",
                  Percentage: 28,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 3148,
                Currency: "INR",
                DisplayAmount: "₹3,148.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 5594,
                Currency: "INR",
                DisplayAmount: "₹5,594.00",
              },
              LowestPrice: {
                Amount: 2251,
                Currency: "INR",
                DisplayAmount: "₹2,251.00",
              },
              OfferCount: 5,
            },
          ],
        },
      },
      {
        ASIN: "B081JS1Q2Y",
        id: 14,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Mirrorless System Cameras",
              DisplayName: "Mirrorless System Cameras",
              Id: "1389179031",
              IsRoot: false,
              SalesRank: 2,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 13201,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B081JS1Q2Y?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 377,
              URL: "https://m.media-amazon.com/images/I/41yLzmYC7eL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 120,
              URL: "https://m.media-amazon.com/images/I/41yLzmYC7eL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 56,
              URL: "https://m.media-amazon.com/images/I/41yLzmYC7eL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 388,
                URL: "https://m.media-amazon.com/images/I/51F1rd6CD+L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 124,
                URL: "https://m.media-amazon.com/images/I/51F1rd6CD+L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 58,
                URL: "https://m.media-amazon.com/images/I/51F1rd6CD+L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41VMD627NWL._SL500_.jpg",
                Width: 374,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41VMD627NWL._SL160_.jpg",
                Width: 119,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41VMD627NWL._SL75_.jpg",
                Width: 56,
              },
            },
            {
              Large: {
                Height: 203,
                URL: "https://m.media-amazon.com/images/I/31esnypkhGL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 64,
                URL: "https://m.media-amazon.com/images/I/31esnypkhGL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 30,
                URL: "https://m.media-amazon.com/images/I/31esnypkhGL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 334,
                URL: "https://m.media-amazon.com/images/I/41eJGxMoCFL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/41eJGxMoCFL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 50,
                URL: "https://m.media-amazon.com/images/I/41eJGxMoCFL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 330,
                URL: "https://m.media-amazon.com/images/I/41i5pbM8ZTL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 105,
                URL: "https://m.media-amazon.com/images/I/41i5pbM8ZTL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/41i5pbM8ZTL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 334,
                URL: "https://m.media-amazon.com/images/I/41pA-WAl+1L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/41pA-WAl+1L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 50,
                URL: "https://m.media-amazon.com/images/I/41pA-WAl+1L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/315tnD3PlML._SL500_.jpg",
                Width: 327,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/315tnD3PlML._SL160_.jpg",
                Width: 104,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/315tnD3PlML._SL75_.jpg",
                Width: 49,
              },
            },
            {
              Large: {
                Height: 413,
                URL: "https://m.media-amazon.com/images/I/418eBBpalrL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 132,
                URL: "https://m.media-amazon.com/images/I/418eBBpalrL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 61,
                URL: "https://m.media-amazon.com/images/I/418eBBpalrL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 470,
                URL: "https://m.media-amazon.com/images/I/41Coj4Mfp6L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 150,
                URL: "https://m.media-amazon.com/images/I/41Coj4Mfp6L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 70,
                URL: "https://m.media-amazon.com/images/I/41Coj4Mfp6L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51SdPmtgtgL._SL500_.jpg",
                Width: 379,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51SdPmtgtgL._SL160_.jpg",
                Width: 121,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51SdPmtgtgL._SL75_.jpg",
                Width: 56,
              },
            },
            {
              Large: {
                Height: 206,
                URL: "https://m.media-amazon.com/images/I/31ePd0LHpNL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 65,
                URL: "https://m.media-amazon.com/images/I/31ePd0LHpNL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 30,
                URL: "https://m.media-amazon.com/images/I/31ePd0LHpNL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Sony",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Sony Imaging Products & Solutions Inc.",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["4548736108929"],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "Real time eye AF for still + Animal Eye AF",
              "Real time tracking",
              "World fastest 0.02 Sec AF speed with 425 phase detection and contrast points",
              "24.2MP, EXMOR CMOS sensor with outstanding light sensitivity",
              "11 FPS continuous shooting with AF/AE",
              "180 degree tilt able touch LCD screen",
              "ISO sensitivity up to 102400",
              "High resolution, sensitivity and colour reproduction capability",
              "Smooth and stable AF",
              "Country of Origin: China",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "ILCE-6100L/B IN5",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "ILCE-6100L/B IN5",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "2 years Manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 2.59842,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 4.7244,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.87303055752,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 2.32283,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Sony Alpha ILCE 6100L 24.2 MP Mirrorless Digital SLR Camera with 16-50 mm Power Zoom Lens (APS-C Sensor, Fast Auto Focus, Real-time Eye AF, Real-time Tracking, 4K Vlogging Camera & Tiltable Screen)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 1,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEgl68uheftiNgkFyFw%2FOrN5NeBg8w0%2F64T82d7gyKwG4mVWNunMlRkjMZEXws5TF2ZSvhEV5Sew%2BZNu1LMlDTJF8r7SSU0vQMbGNRCZ%2BMdJ866Cx8gUvP6Pz%2BWn3CRy4rF7b7WAd4qvaXA",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64318,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 66990,
                Currency: "INR",
                DisplayAmount: "₹66,990.00",
                Savings: {
                  Amount: 9000,
                  Currency: "INR",
                  DisplayAmount: "₹9,000.00 (12%)",
                  Percentage: 12,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 75990,
                Currency: "INR",
                DisplayAmount: "₹75,990.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 66990,
                Currency: "INR",
                DisplayAmount: "₹66,990.00",
              },
              LowestPrice: {
                Amount: 66990,
                Currency: "INR",
                DisplayAmount: "₹66,990.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B095DMHM9B",
      },
      {
        ASIN: "B0749MNH83",
        id: 15,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Digital SLR Cameras",
              DisplayName: "Digital SLRs",
              Id: "1389177031",
              IsRoot: false,
              SalesRank: 14,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 63808,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B0749MNH83?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 333,
              URL: "https://m.media-amazon.com/images/I/41dUOIhdH+L._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 106,
              URL: "https://m.media-amazon.com/images/I/41dUOIhdH+L._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 49,
              URL: "https://m.media-amazon.com/images/I/41dUOIhdH+L._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/41gO1947mBL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/41gO1947mBL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/41gO1947mBL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41AbyZMhMhL._SL500_.jpg",
                Width: 333,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41AbyZMhMhL._SL160_.jpg",
                Width: 106,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41AbyZMhMhL._SL75_.jpg",
                Width: 49,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Canon",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Canon",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["4549292083989"],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "26.2MP full-frame CMOS sensor; Dual pixel CMOS AF",
              "45-point all cross-type AF up to 6.5fps continuous shooting",
              "ISO: 100 to 40000 (expandable to 102400)",
              "GPS, Wi-Fi, NFC and Bluetooth low energy",
              "Compatible Mountings: Canon Ef; Photo Sensor Technology: Size:[Unit:Frames Per Second, Value:Full Frame (35mm) ], Technology:[Value:Cmos ]; Hardware Interface: Secure Digital",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "1897C013AA",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "EOS 6D Mark II",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1+1 years Manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 4.37007,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.95275,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 1.6865363043,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 5.66928,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            ReleaseDate: {
              DisplayValue: "2017-07-27T00:00:01Z",
              Label: "ReleaseDate",
              Locale: "en_US",
            },
            UnitCount: {
              DisplayValue: 2,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Canon EOS 6D Mark II 26.2MP Digital SLR Camera with EF24-105 mm f/4L is II USM Lens",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 1,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEgl0BbXsqPkx%2BzhlgrHGeukJ%2BVhSwO40cvuzsj3Hb7QPQHUPmA3owuf9murYQ07T90ln9b3dbu8bJa1S1qE4HjmTB4%2FNZTgoiKregyZyIj5mCP7Hpfjm414knxOjC5oGfvicSjb8cckHGV",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64318,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 172546,
                Currency: "INR",
                DisplayAmount: "₹1,72,546.00",
                Savings: {
                  Amount: 20449,
                  Currency: "INR",
                  DisplayAmount: "₹20,449.00 (11%)",
                  Percentage: 11,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 192995,
                Currency: "INR",
                DisplayAmount: "₹1,92,995.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 181995,
                Currency: "INR",
                DisplayAmount: "₹1,81,995.00",
              },
              LowestPrice: {
                Amount: 172546,
                Currency: "INR",
                DisplayAmount: "₹1,72,546.00",
              },
              OfferCount: 2,
            },
          ],
        },
        ParentASIN: "B07YHZ3BXZ",
      },
      {
        ASIN: "B07MXK3M9X",
        id: 16,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      Ancestor: {
                        ContextFreeName: "Electronics",
                        DisplayName: "Electronics",
                        Id: "976419031",
                      },
                      ContextFreeName: "Electronics & Photo",
                      DisplayName: "Categories",
                      Id: "976420031",
                    },
                    ContextFreeName: "Cameras & Photography",
                    DisplayName: "Cameras & Photography",
                    Id: "1388977031",
                  },
                  ContextFreeName: "Camera & Photo Accessories",
                  DisplayName: "Accessories",
                  Id: "1388978031",
                },
                ContextFreeName: "Cases & Bags for Camera & Photo",
                DisplayName: "Cases & Bags",
                Id: "1389018031",
              },
              ContextFreeName: "Camera Backpacks",
              DisplayName: "Camera Backpacks",
              Id: "4175441031",
              IsRoot: false,
              SalesRank: 1,
            },
          ],
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07MXK3M9X?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/41TvvLDdwSL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/41TvvLDdwSL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/41TvvLDdwSL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 403,
                URL: "https://m.media-amazon.com/images/I/416s6oQ-yVL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 128,
                URL: "https://m.media-amazon.com/images/I/416s6oQ-yVL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 60,
                URL: "https://m.media-amazon.com/images/I/416s6oQ-yVL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 403,
                URL: "https://m.media-amazon.com/images/I/41g7puxbiUL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 128,
                URL: "https://m.media-amazon.com/images/I/41g7puxbiUL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 60,
                URL: "https://m.media-amazon.com/images/I/41g7puxbiUL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 403,
                URL: "https://m.media-amazon.com/images/I/41MGHbwp1qL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 128,
                URL: "https://m.media-amazon.com/images/I/41MGHbwp1qL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 60,
                URL: "https://m.media-amazon.com/images/I/41MGHbwp1qL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/4144fCHI56L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/4144fCHI56L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/4144fCHI56L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41mXtBlIyWL._SL500_.jpg",
                Width: 464,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41mXtBlIyWL._SL160_.jpg",
                Width: 148,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41mXtBlIyWL._SL75_.jpg",
                Width: 69,
              },
            },
            {
              Large: {
                Height: 409,
                URL: "https://m.media-amazon.com/images/I/51UbniQxUHL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 130,
                URL: "https://m.media-amazon.com/images/I/51UbniQxUHL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 61,
                URL: "https://m.media-amazon.com/images/I/51UbniQxUHL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "FUR JADEN",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Fur Jaden",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Apparel",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Luggage",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "Customizable - No matter what equipment you use, the removable interior of the lightweight dslr camera case can be adjusted to fit multiple dslr bodies, lenses, and accessories. The bag also features adjustable straps on the exterior to attach and carry a tripod.",
              "Capacity: 20 liters; Weight: 550 grams; Dimensions: 30 cms x 16 cms x 40 cms (LxWxH)",
              "Laptop & Tablet Pocket - Now you don't need multiple backpacks for each accessory as this backpack comes with a pocket made especially for a Laptop as well as a Tablet. Laptop Compatibility: Upto 14 Inch",
              "Camera Compatibility - Compatible With Canon, Nikon, Sony, Olympus, Pentax and Other DSLR's and Photographic Equipment.",
              "Warranty - 10 Days No Questions Asked Return Policy. 6 Month Warranty Against Manufacturing Defects Only",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "BM41_Black",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "BM41",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue:
                "6 Months Manufacturer Warranty against Defects Only",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 15.74803148,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 11.81102361,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 1.10231131,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 6.299212592,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "15.6 Inches",
              Label: "Size",
              Locale: "en_IN",
            },
          },
          Title: {
            DisplayValue:
              "FUR JADEN DSLR SLR Camera Lens Shoulder 14 Inch Laptop Backpack for Canon Nikon Sigma Olympus with Tripod Holder",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 4,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEgl1%2BTFpKE7yF4quGCnIRKO06XPVfOomdzkE%2F%2BZYu2xELr0SnYeeO1TUdtnJ0yabPd99LkvTUgIkirNpfA8VL4OaTADAlYiX45uLQGgtSY5PflLlEeBBUAZ3XXU0Mb9g25iq17hGgVwwsD",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 7201,
                FeedbackRating: 3.73,
                Id: "AXOGFIT0PZZ7G",
                Name: "RetailEZ Pvt Ltd",
              },
              Price: {
                Amount: 999,
                Currency: "INR",
                DisplayAmount: "₹999.00",
                Savings: {
                  Amount: 3001,
                  Currency: "INR",
                  DisplayAmount: "₹3,001.00 (75%)",
                  Percentage: 75,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 4000,
                Currency: "INR",
                DisplayAmount: "₹4,000.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 999,
                Currency: "INR",
                DisplayAmount: "₹999.00",
              },
              LowestPrice: {
                Amount: 999,
                Currency: "INR",
                DisplayAmount: "₹999.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B09MJ7D1LW",
      },
      {
        ASIN: "B088RW9XVX",
        id: 17,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Digital SLR Cameras",
              DisplayName: "Digital SLRs",
              Id: "1389177031",
              IsRoot: false,
              SalesRank: 30,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 111447,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B088RW9XVX?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 377,
              URL: "https://m.media-amazon.com/images/I/41CTU1Bss6L._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 120,
              URL: "https://m.media-amazon.com/images/I/41CTU1Bss6L._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 56,
              URL: "https://m.media-amazon.com/images/I/41CTU1Bss6L._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31sXxlS5BUL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31sXxlS5BUL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31sXxlS5BUL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31DVtbvYc8L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31DVtbvYc8L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31DVtbvYc8L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31QQCuZJLYL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31QQCuZJLYL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31QQCuZJLYL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51WP1TsscOL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51WP1TsscOL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51WP1TsscOL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Canon",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Canon",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Product Bundle",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "Product 1: High image quality with 32.5 Megapixel CMOS (APS-C) sensor;Product 1: High-speed continuous shooting of up to 10 fps",
              "Product 1: 4K UHD 30P/ Full HD 120P video;Product 1: 45-Point All Cross-type AF System* supports up to 27 points with an F/8 metering",
              "Product 2: Make sure this fits by entering your model number.;Product 2: Shot speeds up to 60MB/s*, transfer speeds up to 150MB/s* and other factors. 1Mb=1, 000, 000 bytes. X = 150Kb/sec.",
              "Product 2: Perfect for shooting 4K UHD video) and sequential burst mode photography; Product 2: Capture uninterrupted video with UHS speed Class 3 (U3) and video Speed Class 30 (v30)(2)",
              "Hardware Interface: Secure Digital",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            Model: {
              DisplayValue: "3616C019AA",
              Label: "Model",
              Locale: "en_US",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "black",
              Label: "Color",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Canon EOS 90D Digital SLR Camera with 18-135 is USM Lens with 16GB Card + SanDisk 128GB Extreme Pro SDXC UHS-I Card - C10, U3, V30, 4K UHD, SD Card",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 1,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: false,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEgl3nrZCTayoSFi%2BqtB6rpDyewzov6fdXdJUZgR23dvWPHBOY1AMV1QIkq%2BJb2Hmss3qx9C5aNyzd0NNDICpxdd9v%2FAXdLKoxlCH5INgGhSKgPwYZ9RctZFhmAlzecTcD7yAOq23h9zaPk64gCSLat70k%3D",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64318,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 122559,
                Currency: "INR",
                DisplayAmount: "₹1,22,559.00",
                Savings: {
                  Amount: 21226,
                  Currency: "INR",
                  DisplayAmount: "₹21,226.00 (15%)",
                  Percentage: 15,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 143785,
                Currency: "INR",
                DisplayAmount: "₹1,43,785.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 122559,
                Currency: "INR",
                DisplayAmount: "₹1,22,559.00",
              },
              LowestPrice: {
                Amount: 122559,
                Currency: "INR",
                DisplayAmount: "₹1,22,559.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B083ZWJRBK",
      },
      {
        ASIN: "B07NF3SNLT",
        id: 18,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Digital SLR Cameras",
              DisplayName: "Digital SLRs",
              Id: "1389177031",
              IsRoot: false,
              SalesRank: 5,
            },
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Mirrorless System Cameras",
              DisplayName: "Mirrorless System Cameras",
              Id: "1389179031",
              IsRoot: false,
              SalesRank: 6,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 28096,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07NF3SNLT?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/31NjHKSIpZL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/31NjHKSIpZL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/31NjHKSIpZL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41A4eN0zpzL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41A4eN0zpzL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41A4eN0zpzL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41mmVW+grBL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41mmVW+grBL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41mmVW+grBL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41HAh+2SGsL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41HAh+2SGsL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41HAh+2SGsL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41FLSyawaoL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41FLSyawaoL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41FLSyawaoL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41tcMJUebeL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41tcMJUebeL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41tcMJUebeL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41GslUtQf0L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41GslUtQf0L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41GslUtQf0L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41tbMxiJhDL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41tbMxiJhDL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41tbMxiJhDL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41caP-fJiYL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41caP-fJiYL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41caP-fJiYL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31SdiLLDnhL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31SdiLLDnhL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31SdiLLDnhL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41b48dmt7hL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41b48dmt7hL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41b48dmt7hL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41WcR4Lne-L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41WcR4Lne-L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41WcR4Lne-L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41y-TZWgMWL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41y-TZWgMWL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41y-TZWgMWL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41cN4+IS37L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41cN4+IS37L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41cN4+IS37L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41pyDVhHATL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41pyDVhHATL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41pyDVhHATL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Sony",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Sony Imaging Products & Solutions Inc.",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["4548736092082"],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "Real time eye AF and real time tracking",
              "World fastest 0.02 Sec AF speed with 425 phase detection and contrast points",
              "24.2MP, EXMOR CMOS sensor with outstanding light sensitivity",
              "11 FPS continuous shooting with AF/AE",
              "180 degree titltable touch LCD screen",
              "ISO sensitivity up to 102400",
              "High resolution, sensitivity and color reproduction capability",
              "Smooth and stable AF",
              "Durability for up to 200,000 shutter cycles",
              "HLG(hybrid log Gamma ) support for instant HDR workflow",
              "Country of Origin: Thailand",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "ILCE-6400_01",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "ILCE-6400",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "2 years Manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 2.59842,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.32283,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.79145952058,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 4.7244,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Sony Alpha ILCE-6400 24.2MP Mirrorless Digital SLR Camera Body (APS-C Sensor, Real-Time Eye Auto Focus, 4K Vlogging Camera, Tiltable LCD) - Black",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 3,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEgl7%2FZyg3Wb8IHL%2BMFOrEMs5QrPySu%2Bj4oGzbX1l2PBL8qYJp4CO%2B46k82CZhglOk%2BX4251piXuh6GkuDNkqaEhrdbheH45%2BkvKaiAJHVx8vXBe3Cbh216RzkOod%2BwyElKYtPYJ1m%2BVCo9",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64318,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 75703,
                Currency: "INR",
                DisplayAmount: "₹75,703.00",
                Savings: {
                  Amount: 4787,
                  Currency: "INR",
                  DisplayAmount: "₹4,787.00 (6%)",
                  Percentage: 6,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 80490,
                Currency: "INR",
                DisplayAmount: "₹80,490.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 75703,
                Currency: "INR",
                DisplayAmount: "₹75,703.00",
              },
              LowestPrice: {
                Amount: 75703,
                Currency: "INR",
                DisplayAmount: "₹75,703.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B095P9S1CD",
      },
      {
        ASIN: "B083V9RS56",
        id: 19,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Digital SLR Cameras",
              DisplayName: "Digital SLRs",
              Id: "1389177031",
              IsRoot: false,
              SalesRank: 9,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 41924,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B083V9RS56?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 213,
              URL: "https://m.media-amazon.com/images/I/41fembnCh+L._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 68,
              URL: "https://m.media-amazon.com/images/I/41fembnCh+L._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 31,
              URL: "https://m.media-amazon.com/images/I/41fembnCh+L._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/41LBHIIR2wL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/41LBHIIR2wL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/41LBHIIR2wL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/3110dzIR7hL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/3110dzIR7hL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/3110dzIR7hL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31J26H-cNoL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31J26H-cNoL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31J26H-cNoL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41e3BXYEQbL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41e3BXYEQbL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41e3BXYEQbL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 342,
                URL: "https://m.media-amazon.com/images/I/4129eeDNqOL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 109,
                URL: "https://m.media-amazon.com/images/I/4129eeDNqOL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 51,
                URL: "https://m.media-amazon.com/images/I/4129eeDNqOL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Canon",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Canon",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Product Bundle",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "Product 1: DIGIC 8 PROCESSOR;Product 1: APS-C Approx 24.1 mp CMOS Sensor; Product 1: 9-point AF during optica viewfinder shooting;Product 1: Dual Pixel CMOS AF",
              "Product 2: Metal mount with a new exterior design used.The lens is fully compatible with all full range of Canon DSLR Camera;Product 2: Digital era coating suppresses flaring and ghosting, Distance Information: Yes, Lens construction (elements/groups): 6/5",
              "Product 2: By using STM (gear type), AF drive noise is quieter than the existing products DC motor",
              "Product 2: By reducing the minimum focusing distance (45 cm -> 35 cm), maximum magnification is increased (0.15x -> 0.21x)",
              "Compatible Mountings: Canon Ef-S; Hardware Interface: Secure Digital",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            Model: {
              DisplayValue: "EOS 200D II",
              Label: "Model",
              Locale: "en_US",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "black",
              Label: "Color",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 2,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Canon EOS 200D II 24.1MP Digital SLR Camera + EF-S 18-55mm f4 is STM Lens (Black) with Canon EF50MM F/1.8 STM Lens",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 1,
                Message: "Usually dispatched in 3 days.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: false,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEgl5TeBOOOLFjCtidvWto4Fj2C%2BuTAHesQIgHokskaTn%2FlWvYJuNnhoiN8kaG6CK8vok64wHnekG%2B1CxapRphuU%2BnSDbXoNCHltfAX%2FeczzTajCsT%2F2oHYalNazrUA5iHro72B8oX0FU5tdJyzSspsMqc%3D",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64318,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 65494,
                Currency: "INR",
                DisplayAmount: "₹65,494.00",
                Savings: {
                  Amount: 4496,
                  Currency: "INR",
                  DisplayAmount: "₹4,496.00 (6%)",
                  Percentage: 6,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 69990,
                Currency: "INR",
                DisplayAmount: "₹69,990.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 65494,
                Currency: "INR",
                DisplayAmount: "₹65,494.00",
              },
              LowestPrice: {
                Amount: 65494,
                Currency: "INR",
                DisplayAmount: "₹65,494.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B07VQN4FYY",
      },
      {
        ASIN: "B07XQ9M8V1",
        id: 20,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Digital Cameras",
                DisplayName: "Digital Cameras",
                Id: "1389175031",
              },
              ContextFreeName: "Digital SLR Cameras",
              DisplayName: "Digital SLRs",
              Id: "1389177031",
              IsRoot: false,
              SalesRank: 37,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 136613,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07XQ9M8V1?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 333,
              URL: "https://m.media-amazon.com/images/I/31sXxlS5BUL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 106,
              URL: "https://m.media-amazon.com/images/I/31sXxlS5BUL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 49,
              URL: "https://m.media-amazon.com/images/I/31sXxlS5BUL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31KtyMPfAgL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31KtyMPfAgL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31KtyMPfAgL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31DVtbvYc8L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31DVtbvYc8L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31DVtbvYc8L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31QQCuZJLYL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31QQCuZJLYL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31QQCuZJLYL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31RWKWpPXUL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31RWKWpPXUL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31RWKWpPXUL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31Z0uMZON-L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31Z0uMZON-L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31Z0uMZON-L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/41QPdXvDueL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/41QPdXvDueL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/41QPdXvDueL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31h90B8rhSL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31h90B8rhSL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31h90B8rhSL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/31T1pXFL8JL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31T1pXFL8JL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/31T1pXFL8JL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Canon",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Canon Inc.",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["4549292138535"],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "High image quality with 32.5 Megapixel CMOS (APS-C) sensor",
              "High-speed continuous shooting of up to 10 fps",
              "4K UHD 30P/ Full HD 120P video",
              "45-Point All Cross-type AF System* supports up to 27 points with an F/8 metering",
              "Equipped with an approx. 220, 000 Pixel new AE sensor and EOS iTR AF (face detection)",
              "Country of Origin: Japan",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "90DSK 18-135",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "EOS 90D 18-135 IS USM",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1+1 years Manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 6.299212592,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 11.023622036,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 4.3210603352,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 8.661417314,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Canon EOS 90D Digital SLR Camera with 18-135 is USM Lens",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 1,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "3flW1c9bl9iQmOVw2VEglyUNl4%2FbNDD8XJnStoDNw0D%2FEMNOB8xX36%2F0zyetV%2F08%2FA%2BIZzHOHZie4X52oEq229sTiXHJSZlilhEA1VRdeM65ThcE2UzQYJR58k65qXRFPCEbhQPMMnGms9ZmOgVxeRI7wZssV4Og",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64318,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 119990,
                Currency: "INR",
                DisplayAmount: "₹1,19,990.00",
                Savings: {
                  Amount: 17005,
                  Currency: "INR",
                  DisplayAmount: "₹17,005.00 (12%)",
                  Percentage: 12,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 136995,
                Currency: "INR",
                DisplayAmount: "₹1,36,995.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 140500,
                Currency: "INR",
                DisplayAmount: "₹1,40,500.00",
              },
              LowestPrice: {
                Amount: 119990,
                Currency: "INR",
                DisplayAmount: "₹1,19,990.00",
              },
              OfferCount: 4,
            },
          ],
        },
        ParentASIN: "B083ZWJRBK",
      },
      {
        id: 21,
        ASIN: "B095J2WYN4",
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 3,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 5708,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B095J2WYN4?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/517kYSdT8qL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/517kYSdT8qL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/517kYSdT8qL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/61PTnhmsCyS._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/61PTnhmsCyS._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/61PTnhmsCyS._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51M8ofVGlgS._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51M8ofVGlgS._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51M8ofVGlgS._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51x3p8QUH+L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51x3p8QUH+L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51x3p8QUH+L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 450,
                URL: "https://m.media-amazon.com/images/I/51JHJ+gMnRS._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 144,
                URL: "https://m.media-amazon.com/images/I/51JHJ+gMnRS._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 67,
                URL: "https://m.media-amazon.com/images/I/51JHJ+gMnRS._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51LXt6ntCHL._SL500_.jpg",
                Width: 475,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51LXt6ntCHL._SL160_.jpg",
                Width: 152,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51LXt6ntCHL._SL75_.jpg",
                Width: 71,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51E2rqT9yJS._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51E2rqT9yJS._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51E2rqT9yJS._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "CASON",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "CASON",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "4K 24 MP Action Camera: CASON Sports Camera, with 4k/ 30fps smooth video resolution and photographs at 24 MP, can better help you capture every excellent moment while vlog recording,skiing, skating,swimming,snorkeling,diving,and more.",
              "External Microphone: Action cameras with microphone will significantly improve the sound quality of your footage and record a wider range of frequencies for Vlogging,which can capture sound from all directions.(The external mic and waterproof case can't be used together, because the case is totally sealed.)",
              "Electronic Image Stabilization: 4K Camera With EIS tech can counteract any bump, shake, or camera tilt during use, promising your images and videos clear and stable . Just turn on the EIS, and you will never sigh for the blurry shot or video during the bumps and knocks even under the roughest conditions.",
              "High Quality Waterproof Case and Muti-accessories:Sports Action Camera with High-quality waterproof case can make this camera work normally for even underwater 30 meters. Dual 1050mAh batteries and multi accessories can meet your needs for any outdoor activities",
              "Multi-functions & Modes -Awesome functions like 170° wide angle, loop recording, time lapse, burst photo, slow motion, screen saver will satisfy you in all aspects. Recording your wonderful life with CASON CN10 action camera.",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "CSN_CN10_ACTION_CAMERA",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "CN10 4K Action Camera With Mic",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue:
                "1 Year If Manufacturing Defect , Carry In warranty , Not valid if damaged or misused",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 0.9842519675,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.362204722,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Width: {
                DisplayValue: 1.574803148,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "6 x 4 x 2.5 Cm",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          TechnicalInfo: {
            Formats: {
              DisplayValues: ["4K"],
              Label: "Format",
              Locale: "en_IN",
            },
          },
          Title: {
            DisplayValue:
              "Cason CN10 Proffessional 4K 60fps HD 24MP Action Camera For Vlogging with EIS Anti Shake Touch Screen Waterproof Sports Camera With External Mic , 2 x 1350 mAh Battery ,Remote and Accessories Kit (Black)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEJo3mXeIHkqp8%2F5m9OQ9U%2FPdR460nBg1bgH4Nm%2By0MKFzJOzqyv%2BXRR3ym0hf6p3Bu5T3MBUsihxR8uNZxpaN5uQMfsVNVSsHBej3K33qgUdJqZHh1Ff3XiyDRh2n4PKLbr8OhPldUXXUcGh%2BWOxHWIGSCQ%2FHgnwlPfSkCP4nPSp",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 327,
                FeedbackRating: 4.52,
                Id: "A3TGE6ND60CUYK",
                Name: "DOCOSS INDIA",
              },
              Price: {
                Amount: 7889,
                Currency: "INR",
                DisplayAmount: "₹7,889.00",
                Savings: {
                  Amount: 5106,
                  Currency: "INR",
                  DisplayAmount: "₹5,106.00 (39%)",
                  Percentage: 39,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 12995,
                Currency: "INR",
                DisplayAmount: "₹12,995.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 7889,
                Currency: "INR",
                DisplayAmount: "₹7,889.00",
              },
              LowestPrice: {
                Amount: 7889,
                Currency: "INR",
                DisplayAmount: "₹7,889.00",
              },
              OfferCount: 1,
            },
          ],
        },
      },
      {
        id: 22,
        ASIN: "B00NU1CLQM",
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 4,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 6679,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B00NU1CLQM?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/419FyMqMPML._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/419FyMqMPML._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/419FyMqMPML._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/414MT0ZNr9L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/414MT0ZNr9L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/414MT0ZNr9L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41ue95-8WqL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41ue95-8WqL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41ue95-8WqL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51b+UVwsGkL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51b+UVwsGkL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51b+UVwsGkL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "SJCAM",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "SJCAM",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ContentInfo: {
            Languages: {
              DisplayValues: [
                {
                  DisplayValue: "English",
                  Type: "Unknown",
                },
              ],
              Label: "Language",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [
                "0732840552530",
                "6970080834212",
                "0702877258583",
              ],
              Label: "EAN",
              Locale: "en_US",
            },
            UPCs: {
              DisplayValues: ["732840552530", "702877258583"],
              Label: "UPC",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              "Sj4000 wifi is a super great product compare to previous generation cameras, it use ntk96650 chip and ar0330 sensor with a 6g lens, you could take a wide angle. Time Lapse : 2 sec / 3 sec / 5 sec / 10 sec / 20 sec / 30 sec / 60 sec",
              "Water resistant 30 meters: a water-resistant casing that allows you to film fascinating water sports",
              "Support wifi function",
              "Mini appearance, diversified colors available",
              "High definition screen that displays and replays fascinating videos recorded",
              "Support storage cards up to 32GB maximum(Micro SD Card Class6 Above,Not included in package)",
              "Adapter is not included in the package",
              "Product can be charged with USB cable from computer",
              "Burst Mode: 3/5/10 photos per second",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "702877258583",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "SJ4000",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1 Year manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Gold",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 6.299212592,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 9.055118101,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 1.22797479934,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 2.4409448794,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            ReleaseDate: {
              DisplayValue: "2017-01-01T00:00:01Z",
              Label: "ReleaseDate",
              Locale: "en_US",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "SJCAM SJ4000 WiFi 12MP Full HD WiFi Sports Action Camera 170°Wide FOV 30M Waterproof DV Camcorder",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEFP8REtWbGZq1ghcRZrk24vh6r7AVlEuw7ct%2F0%2BPHn7CdUs314Lkfz8THPUFXg3TheRJ1w%2Fj0SCXrVWG7MYhMtH9dfLvZK6kqPd7P%2BO6OKV449zBcKxrNzBs9TmZ%2BibZv5L6X0i7D6Vw",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64000,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 5990,
                Currency: "INR",
                DisplayAmount: "₹5,990.00",
                Savings: {
                  Amount: 3810,
                  Currency: "INR",
                  DisplayAmount: "₹3,810.00 (39%)",
                  Percentage: 39,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 9800,
                Currency: "INR",
                DisplayAmount: "₹9,800.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 5990,
                Currency: "INR",
                DisplayAmount: "₹5,990.00",
              },
              LowestPrice: {
                Amount: 5990,
                Currency: "INR",
                DisplayAmount: "₹5,990.00",
              },
              OfferCount: 2,
            },
          ],
        },
        ParentASIN: "B0891FNF9R",
      },
      {
        id: 23,
        ASIN: "B09P8M6R4D",
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 66,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 87708,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B09P8M6R4D?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 453,
              URL: "https://m.media-amazon.com/images/I/41R0vMQbmmL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 144,
              URL: "https://m.media-amazon.com/images/I/41R0vMQbmmL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 67,
              URL: "https://m.media-amazon.com/images/I/41R0vMQbmmL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 334,
                URL: "https://m.media-amazon.com/images/I/31qW9PrmtAL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31qW9PrmtAL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 50,
                URL: "https://m.media-amazon.com/images/I/31qW9PrmtAL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 334,
                URL: "https://m.media-amazon.com/images/I/41-AaMLuguL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/41-AaMLuguL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 50,
                URL: "https://m.media-amazon.com/images/I/41-AaMLuguL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 409,
                URL: "https://m.media-amazon.com/images/I/411Mg09xUbL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 130,
                URL: "https://m.media-amazon.com/images/I/411Mg09xUbL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 61,
                URL: "https://m.media-amazon.com/images/I/411Mg09xUbL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 392,
                URL: "https://m.media-amazon.com/images/I/51HS5L+fUdL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 125,
                URL: "https://m.media-amazon.com/images/I/51HS5L+fUdL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 58,
                URL: "https://m.media-amazon.com/images/I/51HS5L+fUdL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 351,
                URL: "https://m.media-amazon.com/images/I/41xglw-tOLL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 112,
                URL: "https://m.media-amazon.com/images/I/41xglw-tOLL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 52,
                URL: "https://m.media-amazon.com/images/I/41xglw-tOLL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 226,
                URL: "https://m.media-amazon.com/images/I/41r8RdCSieL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 72,
                URL: "https://m.media-amazon.com/images/I/41r8RdCSieL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 33,
                URL: "https://m.media-amazon.com/images/I/41r8RdCSieL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 334,
                URL: "https://m.media-amazon.com/images/I/31m2lcDQB6L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/31m2lcDQB6L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 50,
                URL: "https://m.media-amazon.com/images/I/31m2lcDQB6L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31AfI-qx2SL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31AfI-qx2SL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31AfI-qx2SL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "SJCAM",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "SJCAM",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "2.33'' and 1.3'' Dual screen SJ8 action camera, presenting more amazing images to you.",
              "Using Novatek chip and professional image sensor Sony IMX335, the highest resolution supports 4K/30FPS.",
              "AI puts your subjects on priority Identifies subjects and adjusts automatically. With a30M waterproof case, you can enjoy a variety of underwater sports.",
              "170° Wide-angle lens Capture everything with a strong sense of visual space.",
              "2.4GHz WiFi module supports fast browsing and downloading videos on mobile phones.",
              "The SJ8 Dual Screen uses reversible type-C USB connector, which brings great convenience to users. It has Higher capacity 1200mAh Removable battery with low power dissipation.",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "SJCAM SJ8 Dual Screen",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "SJCAM SJ8 Dual Screen Camera",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1 year manufacturer warranty",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 1.14173,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.48031,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.1763698096,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 1.61417,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            ReleaseDate: {
              DisplayValue: "2021-12-27T00:00:01Z",
              Label: "ReleaseDate",
              Locale: "en_US",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "SJCAM SJ8 Dual Screen 4K/30fps Sports Action Camera | 2.33'/1.3' Dual Touch Screen Display | 170° Wide-Angle | Super Night Vision | 30m Waterproof | Vlog Camera | Black",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEGHHEJJQsAd44cPPqUEhUeKI%2B0Gh6TSLHi4HeePJCiV4v39dVbBHWIUa%2Fnb5RJOBXi22M5i16JM0JIPojOD7E8KsVyoDbcHSHzDF8gQeCMHHqwNsra3cbsP%2BazqL06mhiqeehye5YKz%2B",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64000,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 14900,
                Currency: "INR",
                DisplayAmount: "₹14,900.00",
                Savings: {
                  Amount: 8900,
                  Currency: "INR",
                  DisplayAmount: "₹8,900.00 (37%)",
                  Percentage: 37,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 23800,
                Currency: "INR",
                DisplayAmount: "₹23,800.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 14900,
                Currency: "INR",
                DisplayAmount: "₹14,900.00",
              },
              LowestPrice: {
                Amount: 14900,
                Currency: "INR",
                DisplayAmount: "₹14,900.00",
              },
              OfferCount: 1,
            },
          ],
        },
      },
      {
        ASIN: "B07X25MNXF",
        id: 24,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 7,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 9653,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B07X25MNXF?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/31Fe6PUgPfL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/31Fe6PUgPfL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/31Fe6PUgPfL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51MCe6OxjEL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51MCe6OxjEL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51MCe6OxjEL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/416mJ3Xy1HL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/416mJ3Xy1HL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/416mJ3Xy1HL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/61dZzhGr+4L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/61dZzhGr+4L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/61dZzhGr+4L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41psd5UGaTL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41psd5UGaTL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41psd5UGaTL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/61VOHukbgBL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/61VOHukbgBL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/61VOHukbgBL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41sKjvaT6ML._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41sKjvaT6ML._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41sKjvaT6ML._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41w4VS83fhL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41w4VS83fhL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41w4VS83fhL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41LqBpqt51L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41LqBpqt51L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41LqBpqt51L._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "PROCUS",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Procus",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "Latest Chipset: Procus Viper comes with the latest chipset that helps capture high- quality videos and images.",
              "Waterproof up to 30m: With the included waterproof case on, you are free to take your Viper Action cam to the swimming pool or in rain.",
              "Supports HDMI and Wi-Fi: Download the XDV app on your smartphone and connect it with Viper Action camera to view all your files right on your Android and iOS devices.",
              "Wide Angle lens: Procus Viper action camera boasts a 140 ° wide angle lens to ensure maximum field coverage.",
              "Powerful Battery: Bid goodbye to all your power woes with a 1050 mAh battery that comes with Procus Viper.",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "PR08",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "PR08",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "6 months",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "New Design",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 1.61417,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 1.173226,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.12786811196,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 2.330704,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "New Design",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Procus Viper 16MP 4K HD Action Camera Waterproof with Wi-Fi",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEK%2BFqBreibIbGUdeKD%2FLuNETqUBZ6VtzUqbkw7wUAKLMNaH%2BkdlJVoE28e98B1PG5vgJaCZwZ4aiRxlErKlRrf9zopPti23yEo5mzGsytTR7HVrqtkOOdFp%2BKfuK%2F%2BQ3HlGUQkdVxcnB",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64000,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 4819,
                Currency: "INR",
                DisplayAmount: "₹4,819.00",
                Savings: {
                  Amount: 3180,
                  Currency: "INR",
                  DisplayAmount: "₹3,180.00 (40%)",
                  Percentage: 40,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 7999,
                Currency: "INR",
                DisplayAmount: "₹7,999.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 4899,
                Currency: "INR",
                DisplayAmount: "₹4,899.00",
              },
              LowestPrice: {
                Amount: 4819,
                Currency: "INR",
                DisplayAmount: "₹4,819.00",
              },
              OfferCount: 2,
            },
          ],
        },
        ParentASIN: "B0891F979W",
      },
      {
        ASIN: "B0B7RGLLYH",
        id: 25,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 11,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 13693,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B0B7RGLLYH?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/31qS76O+w2L._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/31qS76O+w2L._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/31qS76O+w2L._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31goi3gn4RL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31goi3gn4RL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31goi3gn4RL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41tAPoMCZJL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41tAPoMCZJL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41tAPoMCZJL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41G3gIGUH-L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41G3gIGUH-L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41G3gIGUH-L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31kLcI2eKdL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31kLcI2eKdL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31kLcI2eKdL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "PROCUS",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "PROCUS",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "Latest Chipset: Procus Viper comes with the latest chipset that helps capture high- quality videos and images.",
              "Waterproof up to 30m: With the included waterproof case on, you are free to take your Viper Action cam to the swimming pool or in rain.",
              "Supports HDMI and Wi-Fi: Download the XDV app on your smartphone and connect it with Viper Action camera to view all your files right on your Android and iOS devices.",
              "Wide Angle lens: Procus Viper action camera boasts a 140 ° wide angle lens to ensure maximum field coverage.",
              "Powerful Battery: Bid goodbye to all your power woes with a 1050 mAh battery that comes with Procus Viper.",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "PR08",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "PR08",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "6 MONTHS WARRANTY FOR MANUFACTURING DEFECTS ONLY",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Old Design",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 1.61417,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 1.173226,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.12786811196,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 2.330704,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            Size: {
              DisplayValue: "Old Design",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Procus Viper 16MP 4K HD Action Camera Waterproof with Wi-Fi (Old Design)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALELQHzECA4adPZRGjw0nsNiBt9dW4U39I5BlyV23ygNPAZEKv36ZXoJWXk9OfvOM34VT%2FsEIltVz66hthhsDgz%2FpScc2CT6%2Fy4L7NErY2dk1Tn3hCRdKCacU%2FxpXqJFAPnHmTDVxgNKzviTOmrSXsKccfIk3KwWGzF4yGv5u4aKVp",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 890,
                FeedbackRating: 4.57,
                Id: "AIDETOOSMZJQK",
                Name: "Miracle Retail",
              },
              Price: {
                Amount: 2999,
                Currency: "INR",
                DisplayAmount: "₹2,999.00",
                Savings: {
                  Amount: 5000,
                  Currency: "INR",
                  DisplayAmount: "₹5,000.00 (63%)",
                  Percentage: 63,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 7999,
                Currency: "INR",
                DisplayAmount: "₹7,999.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 2999,
                Currency: "INR",
                DisplayAmount: "₹2,999.00",
              },
              LowestPrice: {
                Amount: 2999,
                Currency: "INR",
                DisplayAmount: "₹2,999.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B0B84NZW2F",
      },
      {
        ASIN: "B0B6PBV32V",
        id: 26,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 1,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 4674,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B0B6PBV32V?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/41CzUAiki0L._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/41CzUAiki0L._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/41CzUAiki0L._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41t5IFQAAyL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41t5IFQAAyL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41t5IFQAAyL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41noI37DKaL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41noI37DKaL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41noI37DKaL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51MGqg8FTSL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51MGqg8FTSL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51MGqg8FTSL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/5190JPkgv1L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/5190JPkgv1L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/5190JPkgv1L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/515YAPUUxqL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/515YAPUUxqL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/515YAPUUxqL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41wILLVfqdL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41wILLVfqdL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41wILLVfqdL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "FitSpark",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "FitSpark",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "[ TRUE 4K PROFESSIONAL CAMERA ]: EAGLE i9 PLUS offers Native 4K 30FPS crystal clear videos & features you will love to explore & bring out the Advanced Photographer in you ! [ DUAL FULL FRAME COLOR SCREENS ]: First In-Class Action Camera to offer Dual Screens - An Intuitive 2.0 Rear Screen and a Dazzling New Front Full Frame Color Screen lets you take Selfies in any Extreme Environment",
              "[ 6-AXIS GYRO EIS STABILIZATION ]: With Latest Chipset & Embedded 6-Axis Gyro Chip + Advanced EIS Image Stabilization, EAGLE i9 PLUS gives \"Fluid Super Smooth Movie\" comparable to the most advanced professional action cameras. It offer even better video stability than it's predecessor EAGLE i9. It's the First In-Class Action Camera offering Dual Image Stabilisation",
              "[ DRONE AUTO-CONNECTION ]: The moment you connect to a Drone the action camera will automatically switch to Drone mode & you won't need to take out the camera from the Drone [ WIND NOISE REDUCTION ] A feature on wish list of almost all Vloggers - Reduces unwanted Wind Noise while biking - 1st Action camera to offer this unique feature [ SUPPORTS 2.5mm TRUE EXTERNAL MIC ] Record your precious moments in your own voice - crystal clear audios with reduced ambient noise !",
              "[ NATIVE 4K@30FPS VIDEO RESOLUTION ]: The Latest Chipset gives much better White balance & True 4K color saturation with Next Gen IMX386 CMOS sensor. [ SMARTWATCH STYLE REMOTE ] First time ever Smartwatch style Remote control [ DISTORTION CORRECTION ] Reduces the fish eye effect on normal videos [ TIME LAPSE & SLOW MOTION MODES ]: Discover the Beauty of Every Shot ! Easy and convenient camera control and playback of video image display technology brings excellent perception",
              "[ LARGE CAPACITY 1350 MAH LITHIUM BATTERY ]: The latest chipset consumes much lesser power & gives longer shooting hours. It has Higher capacity 1350 mAh Removable battery with low power dissipation. [ 99 FT DEEP WATERPROOF ]: Water resistant to depth of up to 30 meters to meet most of the underwater sports records (with waterproof case) [ CONNECT WITH WIFI ]: Built-in double ISP, to realize Native 4K@30fps image effects, less power consumption, more stable performance",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "EAGLE i9 PLUS",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "EAGLE i9 PLUS",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1 Year",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 1.71259842345,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.5590551155,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.14550509292,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 1.0236220462,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            ReleaseDate: {
              DisplayValue: "2022-07-20T00:00:01Z",
              Label: "ReleaseDate",
              Locale: "en_US",
            },
            UnitCount: {
              DisplayValue: 20,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "FitSpark Eagle i9 Plus Professional Dual Screen Native 4K 30FPS WiFi Action Camera with Drone Auto-Connection | Improved Anti-Shake | Better 6-Axis Gyro EIS Stabilization | 2.5mm External MIC Support",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "Available to ship in 1-2 days.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEAlrPRCHQbK31BitUE9j5dSuzMDqwR0f8Sn4CUHr7PfELV66EFHC5s1gnooSHcdit2DPhkWCPbEr0vmm%2BDKDOFP3dWXom3rggWzX570Dgt1p5xRK9njGgDj03AxDYsRoAOkQCwxgtluG",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64000,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 9999,
                Currency: "INR",
                DisplayAmount: "₹9,999.00",
                Savings: {
                  Amount: 5991,
                  Currency: "INR",
                  DisplayAmount: "₹5,991.00 (37%)",
                  Percentage: 37,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 15990,
                Currency: "INR",
                DisplayAmount: "₹15,990.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 9999,
                Currency: "INR",
                DisplayAmount: "₹9,999.00",
              },
              LowestPrice: {
                Amount: 9999,
                Currency: "INR",
                DisplayAmount: "₹9,999.00",
              },
              OfferCount: 2,
            },
          ],
        },
      },
      {
        ASIN: "B01N2O9WR9",
        id: 27,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 17,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 18408,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B01N2O9WR9?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/51q72DiEZRL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/51q72DiEZRL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/51q72DiEZRL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/515mAxaSnmL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/515mAxaSnmL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/515mAxaSnmL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41Wo8P4rMVL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41Wo8P4rMVL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41Wo8P4rMVL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51If+PcyyUL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51If+PcyyUL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51If+PcyyUL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51qhrl2K4VL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51qhrl2K4VL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51qhrl2K4VL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41Wi8Qow2hL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41Wi8Qow2hL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41Wi8Qow2hL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51CxBPvgsnL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51CxBPvgsnL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51CxBPvgsnL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51+6CH6Y1aL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51+6CH6Y1aL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51+6CH6Y1aL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "SJCAM",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "SJCAM",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Camera",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: [
                "0732140817483",
                "0725407736221",
                "6970080836117",
              ],
              Label: "EAN",
              Locale: "en_US",
            },
            UPCs: {
              DisplayValues: ["732140817483", "725407736221"],
              Label: "UPC",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              'SJ6 LEGEND has Gyro stabilization for that up and down motion and slow motion record . A dual 2" LCD Touch Screen and a .96" front screen, this camera has it all. SJ6 has a sophisticated look and feels great to hold. The lens has adjustable viewing angles for that perfect shot and a 170 wide angle lens for more in your video or photos.',
              "FEATURES:F2.5 Aperture, and a 166 Ultra Wide Angle the Legend uses an industry-proven MN34120PA 16MP sensor with 7G glass lens allowing the Legend to take crystal clear images. The 16MP lens is perfect for capturing all your unforgettable moments. Download the SJCAM app to your smartphone and control your camera for easy shooting if you aren't near it. An iPad will work above water connected to the camera via Wi-Fi. The battery is a Lithium Ion 1000 mAh and is rechargeable.",
              "EXTERNAL MIC is also available-what everyone has been waiting for! The microphone comes with a clip to attach it closer to the sound you're recording. The Remote watch is compatible with the SJ6 model as well as the Remote Selfie stick. The remote is in a holder which can be easily removed from either the watch or stick to fit in a pocket making it extra versatile. All of these items are sold separately. Maximum Magnification ratio (x)- 0.078x",
              "Between the external microphone capabilities, metal body and dual touch screen the SJ6 Legend will surprise and delight you. Take it with you on your next vacation or for a ride on your bike. SJ6 Legend has it all and is sure to please any outdoor and extreme sport enthusiast. Whether taking still photographs or shooting a video this camera is awesome!! SJCAM honors a 1 Year warranty on camera and 6 months on the battery. Body - Plastic",
              "Included :- 1 x SJ6 Legend Action Cam; 1 x Waterproof Housing; 1 x Bicycle Stand 1 x Frame Mount; 1 x Back Touch Door; 1 x Mini USB Cable 1 x Switch Mount A; 1 x Switch Mount B; 1 x Switch Mount C 1 x Vertical Fixed Base; 1 x Universal 1/4″ Tripod Adapter 1 x 360°Arc Bracket; 1 x 360°Parallel Bracket; 2 x 3M Adhesive Tape 1 x Curved Mount ＆ Flat Mount; 1 x Cleaning Cloth; 1 x Multi-language Manual 1 x Lens Cover; 2 x SJCAM Stickers; 1 x 1000mAh Li-ion Battery(in the camera)",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "SJ6 Legend",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "SJ6 Legend",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue:
                "Manufacturer's Warranty for 1 Year. Covers only manufacturing defects. No physical damage etc",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 0.830707,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.32283,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.1875,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 1.61417,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              'SJCAM Legend SJ6 Sports Gyro Action Camera with 5.08 cm (2") Dual LCD Touch Screen, 1080p Resolution, Black',
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEFOMLCYVmi1worvK%2BVFFm8%2BoWL1WUWhchLJLxfE%2F8ObLK4KqhGqUtDFviVfRgtn3KxJcgOs6bjv58l0m9Hedrw0BI4%2BZaWm%2BRyG7KYC6zhDvAR0Ejk2gTi5FMs1mMBqSqBF5g9mljrjF",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64000,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 10651,
                Currency: "INR",
                DisplayAmount: "₹10,651.00",
                Savings: {
                  Amount: 7149,
                  Currency: "INR",
                  DisplayAmount: "₹7,149.00 (40%)",
                  Percentage: 40,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 17800,
                Currency: "INR",
                DisplayAmount: "₹17,800.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 10651,
                Currency: "INR",
                DisplayAmount: "₹10,651.00",
              },
              LowestPrice: {
                Amount: 10590,
                Currency: "INR",
                DisplayAmount: "₹10,590.00",
              },
              OfferCount: 2,
            },
          ],
        },
        ParentASIN: "B08919LL82",
      },
      {
        ASIN: "B09ZQMQQZJ",
        id: 28,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Security Cameras",
                DisplayName: "Security Cameras",
                Id: "1389203031",
              },
              ContextFreeName: "Spy Cameras",
              DisplayName: "Spy Cameras",
              Id: "1389207031",
              IsRoot: false,
              SalesRank: 55,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 25191,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B09ZQMQQZJ?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/41Z1a4BG9EL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/41Z1a4BG9EL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/41Z1a4BG9EL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41EW60ANL5L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41EW60ANL5L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41EW60ANL5L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41cEiDtYx2L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41cEiDtYx2L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41cEiDtYx2L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 496,
                URL: "https://m.media-amazon.com/images/I/51n5BK9YBUL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 159,
                URL: "https://m.media-amazon.com/images/I/51n5BK9YBUL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 74,
                URL: "https://m.media-amazon.com/images/I/51n5BK9YBUL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41ZYKzLd2AL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41ZYKzLd2AL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41ZYKzLd2AL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41fiuXHHiRL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41fiuXHHiRL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41fiuXHHiRL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/414EmLcJ1WL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/414EmLcJ1WL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/414EmLcJ1WL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "Metronaut",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Metronaut",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "4K Action Camera] This 4K action camera has been upgraded to 4K 60FPS, a faster frame rate improves the smoothness and clarity considerably, plus 20MP image resolution, it can capture every moment in sharp details, our sports camera meets the recording requirements of various scenes. The 170 ° wide angle lens allows you to capture wider scenes and more amazing moments in outdoor sports like hiking or biking.",
              "[Touch Screen & EIS]The waterproof action camera sensitive 2-inch touchscreen not only shows a clear and bright picture, but also offers more convenient, intuitive and faster operation, perfect for outdoor activities. Built-in advanced electronic image stabilization (EIS) anti-shake technology, you can also perform in highly shaky and fast moving situations like jogging, jumping, walking or riding on a smooth and super fluid video bike.",
              "Built in Wifi Sharing]Download the app (DV KING 4K) on your phone or tablet, turn on the Wi-Fi on the sports camera, you can play / download / record videos or take photos on your devices and then share them on social media",
              "[131FT Waterproof ]This 4K action camera lso available with HDMI and USB outputs for connection to computers, televisions and projectors. With the sturdy waterproof case, this action camera can help you enjoy your amazing underwater journey up to 40 m (131 ft) and perfectly protect the camera from stones, dust and scratches. Ideal for rainy days and water sports such as swimming, snorkeling, diving, drifting, surfing, cycling, climbing, skiing, etc.",
              "[Accessories Kits & Pro Service]Multi-function sports camera: slow motion / loop recording / time lapse / burst photo etc, which can meet all your recording needs and achieve satisfactory recording effects. Including 2 x 1350 batteries and practical accessory kits are compatible with most ActionCam brands, including GoPro. Supports SD cards up to 64 GB",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "METRO09",
              Label: "PartNumber",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Action Camera 1080P 12MP Sports Camera Full HD 2.0 Inch Action Cam 30m/98ft Underwater Waterproof Camera with Mounting Accessories (4K Action Camera)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: false,
                IsFreeShippingEligible: false,
                IsPrimeEligible: false,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEAWNxYC6T%2BJh5CZ4e1InF8J%2BF%2B9o4cW5xFuZ09n0KRqzDx%2FqiW6F6idWBVs9AiYUAzX4XJV6MzB0%2FKm6Of5fvTGdSqxAuGVUfFkWXjlnN1vLxZ7yRxNy%2B%2FKrn4GXiZGk4ScdH0BOLsoiFOUMfmem5pSGXbO0mYzp%2FvhzcmROYOCT",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 10,
                FeedbackRating: 3.4,
                Id: "A2EY0WRMJJNVJP",
                Name: "Star House",
              },
              Price: {
                Amount: 2199,
                Currency: "INR",
                DisplayAmount: "₹2,199.00",
                Savings: {
                  Amount: 300,
                  Currency: "INR",
                  DisplayAmount: "₹300.00 (12%)",
                  Percentage: 12,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 2499,
                Currency: "INR",
                DisplayAmount: "₹2,499.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 2199,
                Currency: "INR",
                DisplayAmount: "₹2,199.00",
              },
              LowestPrice: {
                Amount: 2199,
                Currency: "INR",
                DisplayAmount: "₹2,199.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B09ZQM24LD",
      },
      {
        ASIN: "B0B2KD13NP",
        id: 29,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 18,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 20969,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B0B2KD13NP?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 500,
              URL: "https://m.media-amazon.com/images/I/41lUsij8wPL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 160,
              URL: "https://m.media-amazon.com/images/I/41lUsij8wPL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 75,
              URL: "https://m.media-amazon.com/images/I/41lUsij8wPL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41L9M7lSgqL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41L9M7lSgqL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41L9M7lSgqL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41xa6mSireL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41xa6mSireL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41xa6mSireL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51B-zQdya+L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51B-zQdya+L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51B-zQdya+L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41Mlt8ZxxYL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41Mlt8ZxxYL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41Mlt8ZxxYL._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "PROCUS",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "PROCUS",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          Features: {
            DisplayValues: [
              "WITH VIDEO STABILIZATION || Procus RUSH Version 3.0, with an upgraded chipset with EIS (Video Stabilization) to capture high quality videos and images.",
              "EXTERNAL MIC || External 2.5mm Microphone, helps you to capture sound from all directions with crisp details.",
              "WIRELESS WRIST REMOTE || Controlling the camera, start and stop recording, or framing shots at your convenience.",
              "WATERPROOF || Equipped with waterproof case, durable under water to 30m making it ideal for water sports.",
              "BUILT IN WIFI || Download the App “Procus Camera” and connect with the camera. Review the images or videos via Android or iOS devices.",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "PRO5",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "PRO5",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "6 MONTHS WARRANTY",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Black",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Weight: {
                DisplayValue: 0.12786811196,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
            },
            Size: {
              DisplayValue: "Without EIS",
              Label: "Size",
              Locale: "en_IN",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "PROCUS Rush 2.0 (Full Pack) 16MP 4K HD Action Camera Waterproof, External Mic and WiFi (Black) Without EIS (Video Stabilization)",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEDDaFiZb40C1nPVwBHm00JOhHSiZBRPntwfn8OVnLIyY%2B5bl4wevucFA6%2FvQlNZRIvn4uQJIwn9cvBmMudicTHQHBSk6BTVClGhHG1EHHMEweJS4VPVjiYLNz8ms4dvREdHzY4J7FYUgXEsY6NxVXo96jpcxjOhLp0gIyrKxcquX",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 890,
                FeedbackRating: 4.57,
                Id: "AIDETOOSMZJQK",
                Name: "Miracle Retail",
              },
              Price: {
                Amount: 5999,
                Currency: "INR",
                DisplayAmount: "₹5,999.00",
                Savings: {
                  Amount: 7000,
                  Currency: "INR",
                  DisplayAmount: "₹7,000.00 (54%)",
                  Percentage: 54,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 12999,
                Currency: "INR",
                DisplayAmount: "₹12,999.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 5999,
                Currency: "INR",
                DisplayAmount: "₹5,999.00",
              },
              LowestPrice: {
                Amount: 5999,
                Currency: "INR",
                DisplayAmount: "₹5,999.00",
              },
              OfferCount: 1,
            },
          ],
        },
        ParentASIN: "B0B2K9MN9Q",
      },
      {
        ASIN: "B09VLD3C76",
        id: 30,
        BrowseNodeInfo: {
          BrowseNodes: [
            {
              Ancestor: {
                Ancestor: {
                  Ancestor: {
                    Ancestor: {
                      ContextFreeName: "Electronics",
                      DisplayName: "Electronics",
                      Id: "976419031",
                    },
                    ContextFreeName: "Electronics & Photo",
                    DisplayName: "Categories",
                    Id: "976420031",
                  },
                  ContextFreeName: "Cameras & Photography",
                  DisplayName: "Cameras & Photography",
                  Id: "1388977031",
                },
                ContextFreeName: "Action Cameras & Accessories",
                DisplayName: "Action Cameras & Accessories",
                Id: "3403903031",
              },
              ContextFreeName: "Action Cameras",
              DisplayName: "Action Cameras",
              Id: "3404636031",
              IsRoot: false,
              SalesRank: 28,
            },
          ],
          WebsiteSalesRank: {
            ContextFreeName: "Electronics",
            DisplayName: "Electronics",
            SalesRank: 31782,
          },
        },
        DetailPageURL:
          "https://www.amazon.in/dp/B09VLD3C76?tag=amprioz-21&linkCode=osi&th=1&psc=1",
        Images: {
          Primary: {
            Large: {
              Height: 342,
              URL: "https://m.media-amazon.com/images/I/41y3Pajf3GL._SL500_.jpg",
              Width: 500,
            },
            Medium: {
              Height: 109,
              URL: "https://m.media-amazon.com/images/I/41y3Pajf3GL._SL160_.jpg",
              Width: 160,
            },
            Small: {
              Height: 51,
              URL: "https://m.media-amazon.com/images/I/41y3Pajf3GL._SL75_.jpg",
              Width: 75,
            },
          },
          Variants: [
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41wQoakCXVL._SL500_.jpg",
                Width: 479,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41wQoakCXVL._SL160_.jpg",
                Width: 153,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41wQoakCXVL._SL75_.jpg",
                Width: 71,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/51pAk9Gy+xL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/51pAk9Gy+xL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/51pAk9Gy+xL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 281,
                URL: "https://m.media-amazon.com/images/I/51dU+XGbKPL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 89,
                URL: "https://m.media-amazon.com/images/I/51dU+XGbKPL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 42,
                URL: "https://m.media-amazon.com/images/I/51dU+XGbKPL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 333,
                URL: "https://m.media-amazon.com/images/I/41rC919rF2L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 106,
                URL: "https://m.media-amazon.com/images/I/41rC919rF2L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 49,
                URL: "https://m.media-amazon.com/images/I/41rC919rF2L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/31q5q-rYV4L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/31q5q-rYV4L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/31q5q-rYV4L._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41eHd5sRVAL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41eHd5sRVAL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41eHd5sRVAL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41Y4mMAHVxL._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41Y4mMAHVxL._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41Y4mMAHVxL._SL75_.jpg",
                Width: 75,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41lN7eaJzLL._SL500_.jpg",
                Width: 424,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41lN7eaJzLL._SL160_.jpg",
                Width: 135,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41lN7eaJzLL._SL75_.jpg",
                Width: 63,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41w-8OaS1IL._SL500_.jpg",
                Width: 494,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41w-8OaS1IL._SL160_.jpg",
                Width: 158,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41w-8OaS1IL._SL75_.jpg",
                Width: 74,
              },
            },
            {
              Large: {
                Height: 500,
                URL: "https://m.media-amazon.com/images/I/41kKtljoNGL._SL500_.jpg",
                Width: 494,
              },
              Medium: {
                Height: 160,
                URL: "https://m.media-amazon.com/images/I/41kKtljoNGL._SL160_.jpg",
                Width: 158,
              },
              Small: {
                Height: 75,
                URL: "https://m.media-amazon.com/images/I/41kKtljoNGL._SL75_.jpg",
                Width: 74,
              },
            },
            {
              Large: {
                Height: 353,
                URL: "https://m.media-amazon.com/images/I/51Vr9DEHR5L._SL500_.jpg",
                Width: 500,
              },
              Medium: {
                Height: 112,
                URL: "https://m.media-amazon.com/images/I/51Vr9DEHR5L._SL160_.jpg",
                Width: 160,
              },
              Small: {
                Height: 52,
                URL: "https://m.media-amazon.com/images/I/51Vr9DEHR5L._SL75_.jpg",
                Width: 75,
              },
            },
          ],
        },
        ItemInfo: {
          ByLineInfo: {
            Brand: {
              DisplayValue: "insta360",
              Label: "Brand",
              Locale: "en_IN",
            },
            Manufacturer: {
              DisplayValue: "Insta360",
              Label: "Manufacturer",
              Locale: "en_IN",
            },
          },
          Classifications: {
            Binding: {
              DisplayValue: "Electronics",
              Label: "Binding",
              Locale: "en_IN",
            },
            ProductGroup: {
              DisplayValue: "Electronics",
              Label: "ProductGroup",
              Locale: "en_IN",
            },
          },
          ExternalIds: {
            EANs: {
              DisplayValues: ["6970357853335"],
              Label: "EAN",
              Locale: "en_US",
            },
          },
          Features: {
            DisplayValues: [
              '4K Boost Lens- Zero in on the action with the 4K Boost Lens. Rugged and durable, with a new 1/2" 48MP image sensor for more detailed 4K 60fps videos and 48MP photos.',
              "48MP Photos- 4x more megapixels. Capture the extra detail those picture-perfect moments deserve, with better colors and lighting.",
              "6K Widescreen Mode- Shoot ultra-detailed 6K footage that would look right at home in a Hollywood movie, with a classic 2.35:1 ratio for widescreen viewing.",
              "FlowState Stabilization: Buttery smooth image stabilization, no matter what lens you use. The powerful RS Core now delivers FlowState stabilization in camera when paired with the wide-angle lenses. No editing needed.",
              "In the Box: 1x 4K Boost Lens, 1x ONE RS Core, 1x ONE RS Battery Base and 1x ONE RS Mounting Bracket.",
            ],
            Label: "Features",
            Locale: "en_IN",
          },
          ManufactureInfo: {
            ItemPartNumber: {
              DisplayValue: "CINRSGP/E",
              Label: "PartNumber",
              Locale: "en_US",
            },
            Model: {
              DisplayValue: "CINRSGP/E",
              Label: "Model",
              Locale: "en_US",
            },
            Warranty: {
              DisplayValue: "1 year manufacturer",
              Label: "Warranty",
              Locale: "en_IN",
            },
          },
          ProductInfo: {
            Color: {
              DisplayValue: "Standard, Vivid, LOG",
              Label: "Color",
              Locale: "en_IN",
            },
            ItemDimensions: {
              Height: {
                DisplayValue: 1.69291,
                Label: "Height",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Length: {
                DisplayValue: 2.7559,
                Label: "Length",
                Locale: "en_IN",
                Unit: "Inches",
              },
              Weight: {
                DisplayValue: 0.2755778275,
                Label: "Weight",
                Locale: "en_IN",
                Unit: "Pounds",
              },
              Width: {
                DisplayValue: 1.92913,
                Label: "Width",
                Locale: "en_IN",
                Unit: "Inches",
              },
            },
            ReleaseDate: {
              DisplayValue: "2022-04-01T00:00:01Z",
              Label: "ReleaseDate",
              Locale: "en_US",
            },
            UnitCount: {
              DisplayValue: 1,
              Label: "NumberOfItems",
              Locale: "en_US",
            },
          },
          Title: {
            DisplayValue:
              "Insta360 ONE RS 4K Edition – Waterproof 4K 60fps Action Camera with FlowSate Stabilization, 48MP Photo, Active HDR, AI Editing",
            Label: "Title",
            Locale: "en_IN",
          },
        },
        Offers: {
          Listings: [
            {
              Availability: {
                MaxOrderQuantity: 22,
                Message: "In stock.",
                MinOrderQuantity: 1,
                Type: "Now",
              },
              Condition: {
                SubCondition: {
                  Value: "New",
                },
                Value: "New",
              },
              DeliveryInfo: {
                IsAmazonFulfilled: true,
                IsFreeShippingEligible: true,
                IsPrimeEligible: true,
              },
              Id: "L1oPE2sigzG1wdE3Y%2FALEMjrZffHwGwk0nrA4KgmBIlU5TEm1B24yEOrgxInSqL7z0D6BWiYMom6HUTb7Re0wUoEm9bfZqH4bcjnK8GuSBDyLg%2BiEDSogVO%2Bi%2B5XTeg8OjRumxxx1w1F2BMzOK9UA953uwXY0K9u",
              IsBuyBoxWinner: true,
              MerchantInfo: {
                DefaultShippingCountry: "IN",
                FeedbackCount: 64000,
                FeedbackRating: 4.5,
                Id: "A14CZOWI0VEHLG",
                Name: "Appario Retail Private Ltd",
              },
              Price: {
                Amount: 29549,
                Currency: "INR",
                DisplayAmount: "₹29,549.00",
                Savings: {
                  Amount: 5441,
                  Currency: "INR",
                  DisplayAmount: "₹5,441.00 (16%)",
                  Percentage: 16,
                },
              },
              ProgramEligibility: {
                IsPrimeExclusive: false,
                IsPrimePantry: false,
              },
              SavingBasis: {
                Amount: 34990,
                Currency: "INR",
                DisplayAmount: "₹34,990.00",
                PriceType: "LIST_PRICE",
              },
              ViolatesMAP: false,
            },
          ],
          Summaries: [
            {
              Condition: {
                Value: "New",
              },
              HighestPrice: {
                Amount: 29549,
                Currency: "INR",
                DisplayAmount: "₹29,549.00",
              },
              LowestPrice: {
                Amount: 29549,
                Currency: "INR",
                DisplayAmount: "₹29,549.00",
              },
              OfferCount: 1,
            },
          ],
        },
      },
    ],
    SearchRefinements: {
      SearchIndex: {
        Bins: [
          {
            DisplayName: "Electronics",
            Id: "Electronics",
          },
          {
            DisplayName: "Computers & Accessories",
            Id: "Computers",
          },
          {
            DisplayName: "Office Products",
            Id: "OfficeProducts",
          },
          {
            DisplayName: "Industrial & Scientific",
            Id: "Industrial",
          },
          {
            DisplayName: "Home & Kitchen",
            Id: "HomeAndKitchen",
          },
          {
            DisplayName: "Car & Motorbike",
            Id: "Automotive",
          },
          {
            DisplayName: "Sports, Fitness & Outdoors",
            Id: "SportsAndOutdoors",
          },
          {
            DisplayName: "Toys & Games",
            Id: "ToysAndGames",
          },
          {
            DisplayName: "Tools & Home Improvement",
            Id: "ToolsAndHomeImprovement",
          },
          {
            DisplayName: "Musical Instruments",
            Id: "MusicalInstruments",
          },
          {
            DisplayName: "Health & Personal Care",
            Id: "HealthPersonalCare",
          },
          {
            DisplayName: "Appliances",
            Id: "Appliances",
          },
          {
            DisplayName: "Baby",
            Id: "Baby",
          },
          {
            DisplayName: "Beauty",
            Id: "Beauty",
          },
          {
            DisplayName: "Garden & Outdoors",
            Id: "GardenAndOutdoor",
          },
          {
            DisplayName: "Pet Supplies",
            Id: "PetSupplies",
          },
          {
            DisplayName: "Everything Else",
            Id: "EverythingElse",
          },
          {
            DisplayName: "Grocery & Gourmet Foods",
            Id: "GroceryAndGourmetFood",
          },
          {
            DisplayName: "Gift Cards",
            Id: "GiftCards",
          },
          {
            DisplayName: "Collectibles",
            Id: "Collectibles",
          },
        ],
        DisplayName: "Department",
        Id: "SearchIndex",
      },
    },
    SearchURL:
      "https://www.amazon.in/s?k=Cameras&rh=p_n_availability%3A-1&tag=amprioz-21&linkCode=osi",
    TotalResultCount: 306,
  },
};
