import React from "react";
import { Container, Row } from "react-bootstrap";
import RecentPosts from "../blog/RecentPosts";
import { categories } from "../data/blogData";
import BrandFilters from "./BrandFilters";
import CategoryFilters from "./CategoryFilters";
import SidebarAds from "./SidebarAds";

const SideBar = () => {
  return (
    <div className="sidebar-section">
      {" "}
      <Row>
        <RecentPosts categories={categories} />
        <br />
      </Row>
      {/* <Row style={{ alignContent: "center", padding: "45px" }}>
        <BrandFilters />
      </Row>
      <Row style={{ alignContent: "center" }}>
        <SidebarAds />
      </Row>
      <Row style={{ alignContent: "center" }}>
        <CategoryFilters />
      </Row> */}
    </div>
  );
};

export default SideBar;
