import React from "react";
import ScrollButton from "../commons/ScrollButton";
import Banner from "./Banner";
import Footers from "./Footer";
import Headers from "./Headers";
import Navber from "./Navber";
import Subscribe from "./Subscribe";

const Layout = ({ children }) => {
  return (
    <>
      <Headers />
      <Navber />
      <Banner />

      <main>{children}</main>
      <Subscribe />
      <Footers />
      <div style={{ marginRight: "200" }}>
        {" "}
        <ScrollButton />
      </div>
    </>
  );
};

export default Layout;
