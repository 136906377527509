import React from "react";

const ContactForm = () => {
  return (
    <div className="col-lg-4 col-md-4">
      <div className="card text-white bg-primary sign-up-form-sidebar">
        <div className="card-body">
          <h4 className="card-title">Contact Us</h4>
          <p className="card-text">
            If you have any questions regarding this policy, or your dealings
            with our website, please contact us here: To TheDigitalSpecs
          </p>
          {/* <form
            className="contact-form"
            name="contact-tdsin"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact-tdsin" />

            <input
              type="text"
              name="name"
              placeholder="Your name"
              className="form-control "
              required
            />
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Your email"
              required
            />
            <button type="submit" className="btn form-control submit-btn">
              Subscribe
            </button>
          </form> */}
          <form
            className="contact-form"
            name="contact-tp"
            method="POST"
            //netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            {/* <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact-tp" /> */}
            <div className="mb-3">
              <input
                type="text"
                name="name"
                className="form-control form-control-sm"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                className="form-control form-control-sm"
                placeholder="Your Email ID"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="subject"
                className="form-control form-control-sm"
                placeholder="Subject"
              />
            </div>
            <div className="mb-3">
              <textarea
                type="text"
                name="message"
                className="form-control form-control-sm"
                placeholder="Your Message"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="submit"
                className="btn btn-warning btn-block btn-sm"
                value="Send Now"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
