import React from "react";
import SEO from "../commons/SEO";

const IntroBlogs = ({ data }) => {
  const {
    imageMain,
    subCategory,
    topicTitle,
    intro,
    date,
    whySo,
    discuss,
    inbrief,
    keywords = [],
    productsNames,
  } = data;

  return (
    <>
      <div>
        <h2>{topicTitle}</h2>
        <p>{intro}</p>
        <blockquote className="blockquote">
          <br />
          <h3>{whySo}</h3>
          <br />
          <p className="mb-0">{discuss}</p>
        </blockquote>
        <br />
        <h4>{inbrief}</h4>
        <br />
        {productsNames.map((names, index) => {
          return (
            <h5 key={index}>
              {index + 1}
              {". "}
              {names}
            </h5>
          );
        })}
      </div>
    </>
  );
};

export default IntroBlogs;
