import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Subscribe from "./Subscribe";

const Navber = () => {
  const state = useSelector((state) => state.handleCart);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top custom-nav">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            TheDigitalSpecs
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
                <NavLink className="nav-link active" aria-current="page" to="/">
                  Home
                </NavLink>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/products">
                  All Products
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/electronics">
                  Electronics
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/cameras">
                  Cameras
                </Link>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/appliance">
                  Appliances
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/blogs">
                  Blogs
                </NavLink>
              </li>
              {/* <NavLink className="nav-link ms=2" to="/cart">
                <i className="fs fa-shopping-cart me"></i> Cart ({state.length})
              </NavLink> */}
              {/* <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/products/1">
                      Action
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/">
                      Another action
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/">
                      Something else here
                    </NavLink>
                  </li> 
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link disabled"
                  to="/"
                  tabindex="-1"
                  aria-disabled="true"
                >
                  Disabled
                </NavLink>
              </li>*/}
            </ul>
            <div className="d-flex">
              <NavLink
                to="/"
                data-bs-toggle="modal"
                data-bs-target="#register_online"
                className="btn btn-sm btn-success"
                disabled
              >
                {" "}
                SUBSCRIBE US
              </NavLink>
            </div>
            {/* <div className="d-flex">
              <NavLink
                to="/"
                data-bs-toggle="modal"
                data-bs-target="#register_online"
                className="btn btn-sm btn-success"
                disabled
              >
                {" "}
                Register Online
              </NavLink>
            </div> */}
          </div>
        </div>
      </nav>

      <div
        className="modal fade"
        id="register_online"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ alignContent: "center", textAlign: "center" }}
      >
        <div
          className="modal-dialog"
          //style={{ alignContent: "center", textAlign: "center" }}
        >
          <div
            className="modal-content"
            style={{ alignContent: "center", textAlign: "center" }}
          >
            <div
              className="modal-header"
              // style={{ alignContent: "center", textAlign: "center" }}
            >
              <div
                style={{
                  alignContent: "center",
                  textAlign: "center",
                  marginLeft: "1rem",
                }}
              >
                <h5
                  className="modal-title"
                  id="staticBackdropLabel"
                  style={{ alignContent: "center", textAlign: "center" }}
                >
                  TO GET IN TOUCH WITH THEDIGITALSPECS
                </h5>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="custom-devider" />
            <div className="modal-body">
              {/* <div>
                <b>Get all the latest stories to get updates</b>
              </div> */}
              <Subscribe />
              {/* <form
                className="contact-form"
                name="contact-tp-tds-in"
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify="true"
                action="/success"
              >
                <input type="hidden" name="bot-field" />
                <input
                  type="hidden"
                  name="form-name"
                  value="contact-tp-tds-in"
                />
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    placeholder="Your name"
                    className="form-control "
                    required
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="submit"
                    className="fasc-button fasc-size-medium fasc-type-glossy fasc-rounded-medium btn"
                    value="Submit"
                  />
                </div>
              </form> */}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="register_online"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Register Online
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="custom-devider" />
            <div className="modal-body">
              <p>
                Best Night Vision CCTV Camera, We have made NavLink list of
                Night Vision CCTV Cameras that are available in market with
                powerful features.
              </p>
              <form>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    placeholder="email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Your Massage"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="submit"
                    className="btn btn-success btn-block btn-sm"
                    value=" Register Online"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Navber;
