import React from "react";
import { NavLink } from "react-router-dom";
import LayoutOthers from "../layouts/LayoutOthers";

const Success = () => {
  return (
    <LayoutOthers>
      <section className="success-page">
        <div className="page-center">
          <h2>Successfully recieved! </h2>
          <NavLink to="/" className="btn">
            back home
          </NavLink>
        </div>
      </section>
    </LayoutOthers>
  );
};

export default Success;
