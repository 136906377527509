import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ postsPerPage, totalPosts, paginate, path = "/" }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      {pageNumbers.length > 1 && (
        <ul className="pagination">
          {pageNumbers.map((number, index) => (
            <li key={number} id={number} className="page-item">
              <Link
                onClick={() => paginate(number)}
                to={path}
                className="page-link"
              >
                {number}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export default Pagination;
