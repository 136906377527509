import React from "react";
import { NavLink } from "react-router-dom";
import BlogInfo from "../blog/BlogInfo";
import ClassInfo from "./ClassInfo";
//WillBeChanged to respectiveFile name
const Classroom = () => {
  return (
    <section
      className="classroom-training"
      style={{ backgroundColor: "#f0f0f0" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6">
            <div className="classroom-training-text-widget">
              <h2>Blogpost Product Reviews</h2>
              <p>
                Tech news and expert reviews of the latest mobile phones,
                electronics, home appliance. We'll help you buy the right
                product for you
              </p>
              <NavLink to="/blogs" className="btn btn-warning btn-sm">
                View all Blogposts
              </NavLink>
            </div>
          </div>
        </div>
        <BlogInfo />
      </div>
    </section>
  );
};

export default Classroom;
