import React from "react";

const BlogImage = ({ image, title }) => {
  return (
    <div
      style={{
        margin: 20,
        textAlign: "center",
      }}
    >
      <img src={image} width="60%" height="300" alt={title} />
    </div>
  );
};

export default BlogImage;
