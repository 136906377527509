import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "../pagination/Pagigation";
import { blogsData, categories } from "../data/blogData";
import Signup from "../layouts/Signup";
import BlogList from "./BlogList";

const BlogInfo = () => {
  const [posts, setPosts] = useState(categories);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get(`https://fakestoreapi.com/products`);
      setPosts(res.data);
      setLoading(false);
    };

    //fetchPosts();
  }, []);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="row mt-5">
      <Signup />
      <div className="col-lg-8 col-md-8" style={{ minWidth: 400 }}>
        <div className="list-group">
          <div className="list-group-item active">
            Beyond reviews, to guides, gift ideas. Here are our latest articles.
          </div>
          {loading
            ? "Loading..."
            : currentPosts.map((post, index) => (
                <BlogList key={index} post={post} />
              ))}
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={posts.length}
            paginate={paginate}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogInfo;
