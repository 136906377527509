import React from "react";
import { Form, Col, Row, Button } from "react-bootstrap";

const Signup = () => {
  return (
    <div className="col-lg-4 col-md-4">
      <div className="card text-white bg-primary sign-up-form-sidebar">
        <div className="card-body">
          <h4 className="card-title">Contact Us</h4>
          <p className="card-text">
            If you have any questions regarding this policy, or your dealings
            with our website, please contact us here: To TheDigitalSpecs
          </p>
          <form
            name="contact-form-tds-in"
            method="POST"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact-form-tds-in" />
            <div className="mb-3">
              <input
                type="text"
                className="form-control form-control-sm"
                name="name"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                style={{ minHeihgt: "2rem" }}
                className="form-control form-control-sm"
                placeholder="Your Email ID"
                required
              />
            </div>
            {/* <div className="mb-3">
              <input
                type="tel"
                className="form-control form-control-sm"
                placeholder="Phone"
              />
            </div> */}
            <div className="mb-3">
              <textarea
                type="text"
                className="form-control form-control-sm"
                placeholder="Your Message"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="submit"
                className="btn btn-warning btn-block btn-large"
                value="Send"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
