import { useState } from "react";
import { Row } from "react-bootstrap";
import Loading from "../util/Loading";
//import { sanitize } from "../../../utils/miscellaneous";
// import Loading from "../../loading";
import { sanitize } from "../util/miscellaneous";

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return sanitize(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? sanitize(formattedMessage) : null;
  };

  const heading = "Subscribe to newsletter";

  return (
    <div>
      <div style={{ fontFamily: "bold", fontSize: "2rem" }}>{heading}</div>

      <Row style={{ padding: 5 }}>
        <div className="mc-field-group">
          {" "}
          <input
            onChange={(event) => setEmail(event?.target?.value ?? "")}
            type="email"
            style={{ width: "300px" }}
            placeholder="Your email"
            className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-4 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            onKeyUp={(event) => handleInputKeyEvent(event)}
          />
        </div>
      </Row>
      <Row style={{ padding: 5 }}>
        <div>
          <button
            className="fasc-button fasc-size-medium fasc-type-glossy fasc-rounded-medium btn"
            onClick={handleFormSubmit}
          >
            Submit
          </button>
        </div>
      </Row>
      <div className="min-h-42px">
        {"sending" === status ? "Sending..." : null}
        {"error" === status || error ? (
          <div className="text-red-700 pt-2">{message}</div>
        ) : null}
        {"success" === status && "error" !== status && !error && (
          <div className="text-green-200 font-bold pt-2">{message}</div>
        )}
      </div>
    </div>
  );
};

export default NewsletterForm;
