import React, { useEffect } from "react";

const HeaderAds = () => {
  const amazoneScript = () => {
    return (
      <div>
        <script type="text/javascript" language="javascript">
          var aax_size='728x90'; var aax_pubname = 'amprioz-21'; var
          aax_src='302';
        </script>
        <script
          type="text/javascript"
          language="javascript"
          src="http://c.amazon-adsystem.com/aax2/assoc.js"
        ></script>
      </div>
    );
  };
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://c.amazon-adsystem.com/aax2/assoc.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, []);
  return (
    <div style={{ textAlign: "center", alignContent: "center" }}>
      {/* <div>
        <a
          target="_blank"
          href="https://www.amazon.in/b?_encoding=UTF8&tag=amprioz-21&linkCode=ur2&linkId=36c611a03420298b0f20455b24bb13df&camp=3638&creative=24630&node=976416031"
          rel="noreferrer"
        >
          <img
            src="https://m.media-amazon.com/images/I/51ZxH0d54BL._SL1200_.jpg"
            height="200px"
            width={500}
            alt="ABC"
          />
        </a>
      </div> */}

      <iframe
        title="ad"
        src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=amazonkids&banner=0M8Q9CWQXNVEK71HN002&f=ifr&linkID=da8dcb7dc9a58ad043732e149f753524&t=amprioz-21&tracking_id=amprioz-21"
        width="300"
        height="250"
        scrolling="no"
        border="0"
        marginwidth="0"
        style={{ border: "none" }}
        frameborder="0"
        target="_blank"
        rel="nofollow noopener noreferrer"
      ></iframe>
    </div>
  );
};

export default HeaderAds;
