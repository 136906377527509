import { Button } from "bootstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FcRating } from "react-icons/fc";
import Card from "../card/Card";
import { amazonData } from "../data/amazonData";
import Pagination from "../pagination/Pagigation";
import PaapiCard from "./PaapiCard";
const date = new Date();

const AllCameras = () => {
  const [allPosts, setAllPosts] = useState(amazonData.SearchResult.Items);
  const [posts, setPosts] = useState(
    amazonData.SearchResult.Items.sort(function (a, b) {
      if (a.id > b.id) return -1;
      if (a.id < b.b) return 1;
      return 0;
    })
  );

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);
  const [tags, setTags] = useState([]);

  const tagFilter = (val) => {
    let lists = ["ALL PRODUCTS"];
    val.map((item, index) => {
      return lists.push(item.tag);
    });
    const outputArray = Array.from(new Set(lists));
    return outputArray;
  };
  //   useEffect(() => {
  //     const fetchPosts = async () => {
  //       setLoading(true);
  //       const tagList = tagFilter(amazonData);
  //       tagList && setTags(tagList);
  //       // const res = await axios.get(`https://fakestoreapi.com/products`);
  //       // setPosts(res.data);
  //       setLoading(false);
  //     };

  //     fetchPosts();
  //   }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleFilter = (v) => {
    setLoading(true);
    if (v !== "ALL PRODUCTS") {
      const postsItem = allPosts.filter((item) => item.tag === v);
      postsItem && setPosts(postsItem);
    } else {
      setPosts(amazonData.SearchResult.Items);
    }
    setLoading(false);
  };

  return (
    <section className="online-courses" style={{ marginTop: 50 }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12">
            <div className="online-courses-text-widget">
              <h2
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Trending Cameras
              </h2>
              {/* <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                The products on this sections are the best rating <FcRating />{" "}
                products and categories on the best selling platform from{" "}
                {moment(date).subtract(1, "years").format("MMM/YYYY")} to{" "}
                {moment(date).format("MMM/YYYY")}
              </div> */}
              {/* {tags &&
                tags.map((item, index) => {
                  return (
                    <Button
                      value={item}
                      style={{ paddingLeft: 10, marginTop: 5 }}
                      className="btn btn-warning btn-sm link-inline-padding"
                      onClick={() => handleFilter(item)}
                      key={index}
                    >
                      {item.toUpperCase()}
                    </Button>
                  );
                })} */}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          {loading
            ? "Loading..."
            : currentPosts.map((post, index) => (
                <PaapiCard key={index} post={post} />
              ))}
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={posts.length}
            paginate={paginate}
            path="/cameras"
          />
        </div>
      </div>
    </section>
  );
};

export default AllCameras;
