import React from "react";
import { Container } from "react-bootstrap";
import Courses from "../courses/Courses";
import AllElectronics from "../electronics/AllElectronics";
import CategoryProducts from "./CategoryProducts";

const AllProducts = () => {
  const pathname = window.location.pathname;

  return (
    <Container style={{ minHeight: 500 }}>
      {pathname === "/" && <Courses pathName={pathname} />}
      {pathname === "/electronics" && <CategoryProducts pathName={pathname} />}
      {pathname === "/appliance" && <CategoryProducts pathName={pathname} />}
      {pathname === "/drones" && <CategoryProducts pathName={pathname} />}
      {pathname === "/products" && <CategoryProducts pathName={pathname} />}
    </Container>
  );
};

export default AllProducts;
