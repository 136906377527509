import React, { useEffect, useState } from "react";
import history from "../commons/history";
import { Link, useParams, Redirect } from "react-router-dom";
import { Tooltip } from "antd";
import { AiFillStar } from "react-icons/ai";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

import SkeleTon from "react-loading-skeleton";
import LayoutOthers from "../layouts/LayoutOthers";

import { bestRankingData } from "../data/firstPageData";
import SEO from "../commons/SEO";

import { Col, Container, NavLink, Row } from "react-bootstrap";
import { Button } from "bootstrap";
import slugifyUrl from "../util/slugifyUrl";
import { amazonData } from "../data/amazonData";
import LayoutPaapi from "./LayoutPaapi";
const prod = {
  ItemInfo: "Hi",
  DetailPageURL: "",
  Images: "",
  Offers: "",
  ASIN: "NA",
  Features: "",
  Title: "",
  BrowseNodeInfo: "",
};

const SinglePaapi = () => {
  const { id, slug } = useParams();
  const [products, setProducts] = useState(amazonData.SearchResult.Items);
  const [product, setProduct] = useState();

  const [loading, setLoading] = useState(false);
  const [relatedCategory, setRelatedCategory] = useState([]);

  const [fiveLine, setFiveLine] = useState(true);

  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setLoading(true);
    const productItem = products.filter((item) => item.id === parseInt(id));
    productItem && setProduct(...productItem);
    setLoading(false);
  }, []);

  const Loading = () => {
    return (
      <LayoutOthers>
        <div className="container">
          <div className="row mt-5 mb-5 py-4">
            <div className="col-md-6">
              <SkeleTon height={400} />;
            </div>
            <div className="col-md-6">
              <SkeleTon height={50} width={300} />
              <SkeleTon count={200} />
              <SkeleTon height={25} width={150} />
              <SkeleTon height={50} />
              <SkeleTon height={150} />
              <SkeleTon height={50} width={100} />
              <SkeleTon height={50} width={100} style={{ marginLeft: 6 }} />
            </div>
          </div>
        </div>
      </LayoutOthers>
    );
  };

  const ShowProduct = () => {
    const {
      ItemInfo,
      DetailPageURL,
      Images,
      Offers,
      ASIN,
      Features,
      Title,
      BrowseNodeInfo,
    } = product ? product : prod;

    // let descriptionsSubs = descriptions && descriptions.substring(0, 300);
    return (
      <div className="container">
        {loading ? (
          <Loading />
        ) : (
          <>
            {ASIN !== "NA" ? (
              <div className="row mt-5 mb-5 py-3">
                <SEO
                  title={ItemInfo.Title.DisplayValue}
                  description={"product.descriptions"}
                  //image={imageLink}
                  article={true}
                  keywords={[
                    ItemInfo.Title.DisplayValue,
                    ItemInfo.ByLineInfo.Brand.DisplayValue,
                    BrowseNodeInfo.BrowseNodes[0].DisplayName,
                    BrowseNodeInfo.BrowseNodes[0].DisplayName.ContextFreeName,
                  ]}
                />
                <h2
                  style={{
                    fontSize: "1rem",
                    textTransform: "uppercase",
                    marginTop: "2rem",
                    marginLeft: "2rem",
                  }}
                >
                  <b>CATEGORY/ {BrowseNodeInfo.BrowseNodes[0].DisplayName}</b>
                </h2>
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={Images.Primary.Large.URL}
                      alt={ItemInfo.Title.DisplayValue}
                      height={Images.Primary.Large.Height}
                      width={Images.Primary.Large.Width}
                    />
                  </div>
                  <div className="col-md-6">
                    {Images.Variants.map((item, i) => (
                      <div
                        className="col-md-4"
                        style={{ display: "inline-grid", padding: "1px" }}
                      >
                        <img
                          className="zoom-medium"
                          src={item.Medium.URL}
                          alt={ItemInfo.Title.DisplayValue}
                          height={item.Medium.Height}
                          width={item.Medium.Width}
                          // onMouseEnter={(e) => {
                          //   setImageUrl(item.Large.URL);
                          // }}
                          // onMouseLeave={(e) => {
                          //   setImageUrl(item.Medium.URL);
                          // }}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-md-12">
                  <p className="text-uppercase text-black-50">{product.tag}</p>
                  <h3>{ItemInfo.Title.DisplayValue}</h3>
                  <p className="lead fw-bolder">
                    Rating : {Offers.Listings[0].MerchantInfo.FeedbackRating}
                    <AiFillStar />
                  </p>
                  <Tooltip
                    color="orange"
                    title="Click to check update Price"
                    placement="top"
                  >
                    <span>
                      <h4>
                        <b>
                          MRP:{" "}
                          <del>
                            {Offers.Summaries[0].HighestPrice.DisplayAmount}
                          </del>{" "}
                          Buy Price :
                          <a
                            className="btn btn-sm btn-info"
                            target="_blank"
                            href={DetailPageURL}
                            rel="noreferrer"
                          >
                            {Offers.Summaries[0].LowestPrice.DisplayAmount}
                          </a>
                        </b>
                      </h4>
                    </span>
                  </Tooltip>

                  <p className="lead">
                    <ul>
                      {fiveLine && ItemInfo.Features.DisplayValues.length > 5
                        ? ItemInfo.Features.DisplayValues.slice(0, 5).map(
                            (each, i) => {
                              return <li>{each}</li>;
                            }
                          )
                        : ItemInfo.Features.DisplayValues.map((each, i) => {
                            return <li>{each}</li>;
                          })}
                    </ul>
                    {ItemInfo.Features.DisplayValues.length > 5 && (
                      <button
                        className="btn"
                        onClick={() => setFiveLine(!fiveLine)}
                      >
                        {fiveLine ? (
                          <b style={{ color: "blueviolet" }}>.....READMORE</b>
                        ) : (
                          <b style={{ color: "green" }}>.....READLESS</b>
                        )}
                      </button>
                    )}
                  </p>
                  <Row>
                    <Col>
                      <a
                        className="fasc-button fasc-size-medium fasc-type-glossy fasc-rounded-medium btn"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        href={DetailPageURL}
                        style={{ width: "200px" }}
                      >
                        VIEW ON AMAZON
                      </a>
                    </Col>
                    <Col>
                      {" "}
                      <img
                        src={require("../../assets/amazon-icon.jpg")}
                        // className="img-fluid"
                        alt="card"
                        height="80px"
                        width="150px"
                      />
                    </Col>
                  </Row>

                  {/* <NavLink
                  to="/cart"
                  className="btn btn-dark mx-2 px-3 py-2"
                  disabled
                >
                  Go To Cart
                </NavLink> */}
                </div>
                {/* <hr className="mt-4" />
                <div>
                  <Row className="mt-5">
                    <Col xs={12} md={3} style={{ marginTop: "0.5rem" }}>
                      <Link
                        to="/"
                        className="btn btn-outline-success"
                        // onClick={() => {
                        //   history.push(`/`);
                        // }}
                        // disabled
                      >
                        Back To Home
                      </Link>
                    </Col>

                    <Col xs={6} md={2}></Col>
                    <Col xs={12} md={2}></Col>
                    <Col xs={6} md={2}>
                      <NavLink
                        //to="/cart"
                        style={{ marginTop: "0.5rem" }}
                        className="btn btn-outline-success"
                        //disabled={minLim}
                        // onClick={() => {
                        //   setIdNextPre(idNextPre - 1);
                        // }}
                      >
                        <GrFormPrevious /> Previous
                      </NavLink>
                    </Col>
                    <Col xs={6} md={2}>
                      <NavLink
                        style={{ marginTop: "0.5rem" }}
                        className="btn btn-outline-success"
                        // disabled={maxLim}
                        //onClick={navigate}
                        onClick={() => {
                          //setIdNextPre(idNextPre + 1);
                        }}
                        // onClick={() => {
                        //   //
                        // }}
                      >
                        Next
                        <MdNavigateNext />
                      </NavLink>
                    </Col>
                  </Row>
                </div> */}
              </div>
            ) : (
              <Container
                style={{
                  maxWidth: "1260px",
                  minHeight: "500px",
                  marginTop: "100px",
                }}
              >
                <Row className="row mt-5">
                  <Row>
                    <h2
                      style={{
                        fontSize: "2rem",
                        textTransform: "uppercase",
                        marginTop: "2rem",
                        marginLeft: "2rem",
                      }}
                    >
                      <b>PRODUCT NOT FOUND</b>
                    </h2>
                  </Row>
                  <Row>
                    <img
                      src="https://images.pexels.com/photos/365637/pexels-photo-365637.jpeg?cs=srgb&dl=pexels-aphiwat-chuangchoem-365637.jpg&fm=jpg"
                      alt={"dummy"}
                      height="500px"
                      width="400px"
                    />
                  </Row>
                </Row>
              </Container>
              // <div className="row mt-5 not-found">
              //   <div className="col-md-12 mt-5">
              //     <div>NOT FOUND</div>
              //   </div>
              // </div>
            )}
            <hr />

            {/* <div style={{ fontSize: "2rem" }}>
              <b>Related Products</b>
            </div>

            <Row>
              {relatedCategory &&
                relatedCategory.map((item, index) => (
                  <Col
                    xs={12}
                    md={4}
                    key={index}
                    //style={{ border: "2px solid gray" }}
                  >
                    <h6>{item.title.substring(0, 27) + "..."}</h6>
                    <img
                      src={item.imageLink}
                      alt={(item.title, item.category)}
                      height="150px"
                      width="200px"
                    />
                    <div
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        marginBottom: "1rem",
                      }}
                    >
                      {item.rating} <AiFillStar /> out of 5
                    </div>
                    <a
                      href={`/products/${item.category}/${item.id}/${slugifyUrl(
                        item.title
                      )}`}
                      className="btn btn-secondary mx-5 px-3 py-2 mb-2"
                    >
                      See Products
                    </a>
                  </Col>
                ))}
            </Row> */}
          </>
        )}
      </div>
    );
  };

  return (
    <LayoutPaapi>
      <Container
        style={{
          minHeight: 500,
          alignContent: "center",
        }}
      >
        {loading ? <Loading /> : <ShowProduct />}
      </Container>
    </LayoutPaapi>
  );
};

export default SinglePaapi;
