import React from "react";
import SEO from "../commons/SEO";
import slugifyUrl from "../util/slugifyUrl";
import ImagesBlog from "./ImagesBlog";
import ProsCons from "./ProsCons";

const ProductDescription = ({ blogdata, index }) => {
  const { fields, keywords = [] } = blogdata;
  const {
    title,
    company,
    specifications,
    description1,
    description2,
    images,
    pros,
    cons,
    buyUrl,
  } = fields;
  return (
    <>
      <h3 id={slugifyUrl(title)}>
        {" "}
        {index + 1 + ". "} {title}{" "}
      </h3>
      <p>{description1}</p>
      <blockquote>{description2}</blockquote>
      <h4>Key Specs: </h4>
      {specifications.length > 0 && (
        <ul>
          {specifications.map((item, index) => {
            return <li>{specifications[index]}</li>;
          })}

          {/* <li>Processor: AMD Ryzen 7 4800HS - 9 4900HS</li>
          <li>Video card: NVIDIA GeForce RTX 2060</li>
          <li>RAM: 16 to 32 GB</li>
          <li>
            Display: 14-inch matte Full HD IPS display (1,920 x 1,080), 120 Hz -
            14-inch matte WQHD IPS display (2,560 x 1,440), 60Hz -
          </li>
          <li>Storage space: 512GB / 1TB M.2 NVMe PCIe 3.0</li> */}
        </ul>
      )}
      <ImagesBlog images={images} title={title} />
      <ProsCons pros={pros} cons={cons} />
      <p style={{ textAlign: "center" }}>
        <a
          className="fasc-button fasc-size-medium fasc-type-glossy fasc-rounded-medium btn"
          target="_blank"
          rel="nofollow noopener noreferrer"
          href={buyUrl}
        >
          VIEW ON AMAZON
        </a>
      </p>
    </>
  );
};

export default ProductDescription;
