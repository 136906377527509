import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import { bestRankingData } from "./components/data/firstPageData";
import Home from "./components/homes/Home";
import Layout from "./components/layouts/Layout";
import LayoutOthers from "./components/layouts/LayoutOthers";

const NotFound = () => {
  const [allPosts, setAllPosts] = useState(bestRankingData);
  const [posts, setPosts] = useState(bestRankingData);
  const [loading, setLoading] = useState(false);

  const handleFilter = (v) => {
    setLoading(true);
    if (v !== "all") {
      const postsItem = allPosts.filter((item) => item.tag === v);
      postsItem && setPosts(postsItem);
    } else {
      setPosts(bestRankingData);
    }
    setLoading(false);
  };

  return (
    <LayoutOthers>
      <section
        className="error-page"
        style={{ minHeight: 600, alignText: "center" }}
      >
        <div className="page-center">
          <div style={{ alignContent: "center" }}>
            <span>404</span>
            <h3>Sorry, the page you tried doesn't exist.</h3>
            <Link className="btn btn-warning btn-sm" to="/" element={<Home />}>
              Back to Home
            </Link>
          </div>
        </div>
      </section>
    </LayoutOthers>
  );
};

export default NotFound;
