import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SEO from "../commons/SEO";
import { blogsData, categories } from "../data/blogData";
import LayoutOthers from "../layouts/LayoutOthers";
import Pagination from "../pagination/Pagigation";
import slugifyUrl from "../util/slugifyUrl";
import BlogCard from "./BlogCard";

const AllBlogs = () => {
  const [allCategories, setPosts] = useState(categories);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      // const res = await axios.get("https://fakestoreapi.com/products");
      // setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    setLoading(true);

    setLoading(false);
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = allCategories.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  currentPosts.sort(function (a, b) {
    return b.id - a.id;
  });
  let i = 1;

  const keywords = () => {
    let keys = [];
    for (let i = 0; i < currentPosts.length; i++) {
      keys.push(currentPosts[i].topicTitle);
    }
    return keys;
  };

  return (
    <LayoutOthers>
      <Container
        style={{
          minHeight: 500,
          paddingTop: 50,
          paddingBottom: 50,
          marginTop: 50,
        }}
      >
        <SEO
          title={"Blogs"}
          // description={product.descriptions}
          //image={imageLink}
          article={true}
          keywords={keywords()}
        />
        <Row>
          <Col xs={12} md={12} style={{ backgroundColor: "#FFF" }}>
            {currentPosts.map((post) => (
              <li key={post.id} className="list-group-item">
                <BlogCard post={post} />
              </li>
            ))}

            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={allCategories.length}
              paginate={paginate}
              path="/blogs"
            />
          </Col>
          {/* <Col xs={11} md={4}>
            <div>
              <ul>
                <h3>RECENT POSTS</h3>
                {categories.map((post) => (
                  <NavLink
                    to={`/blogs/${post.category}/${slugifyUrl(
                      post.topicTitle
                    )}/${post.id}`}
                    key={post.id}
                    style={{ textDecoration: "none" }}
                  >
                    <p>
                      {i++}
                      {"."}&nbsp;
                      {post.topicTitle.toLocaleUpperCase()}
                    </p>
                  </NavLink>
                ))}
              </ul>

              
            </div>
          </Col> */}
        </Row>
      </Container>
    </LayoutOthers>
  );
};

export default AllBlogs;
