import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollButton from "../commons/ScrollButton";
import Footers from "../layouts/Footer";
import Navber from "../layouts/Navber";
// import Footers from "./Footer";
//import Navber from "./Navber";

const LayoutPaapi = ({ children }) => {
  return (
    <>
      <Navber />
      <Container style={{ minHeight: "500px" }}>
        <Row>
          <Col xs={12} md={12}>
            <main>{children}</main>
          </Col>
        </Row>
      </Container>

      <Footers />
      <div style={{ marginRight: "200px" }}>
        {" "}
        <ScrollButton />
      </div>
    </>
  );
};

export default LayoutPaapi;
