import React, { useEffect, useState } from "react";
//import Card from "../Card";
import axios from "axios";
import moment from "moment";
import { FcRating } from "react-icons/fc";

import Pagination from "../pagination/Pagigation";
import { NavLink } from "react-router-dom";
import { bestRankingData } from "../data/firstPageData";
import { Button } from "react-bootstrap";
import Card from "../card/Card";

const date = new Date();

const CategoryProducts = ({ pathName }) => {
  const [allPosts, setAllPosts] = useState(bestRankingData);
  const [posts, setPosts] = useState(bestRankingData);

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  const tagFilter = (val) => {
    let lists = [];
    val.map((item, index) => {
      return lists.push(item.tag);
    });
    const outputArray = Array.from(new Set(lists));
    return outputArray;
  };

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      if (pathName === "/electronics") {
        const postsItem = allPosts.filter(
          (item) => item.category === "electronics"
        );
        const tagList = tagFilter(postsItem);
        tagList && setTags(tagList);
        postsItem && setPosts(postsItem);
      }

      if (pathName === "/products") {
        const tagList = tagFilter(bestRankingData);
        tagList && setTags(tagList);
        setPosts(bestRankingData);
      }
      if (pathName === "/appliance") {
        const postsItem = allPosts.filter(
          (item) => item.category === "appliance"
        );
        const tagList = tagFilter(postsItem);
        tagList && setTags(tagList);
        postsItem && setPosts(postsItem);
      }

      if (pathName === "/drones") {
        const postsItem = allPosts.filter((item) => item.category === "drones");
        const tagList = tagFilter(postsItem);
        tagList && setTags(tagList);
        postsItem && setPosts(postsItem);
      }
      // const res = await axios.get(`https://fakestoreapi.com/products`);
      // setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  const handleFilter = (v) => {
    setLoading(true);
    if (pathName === "/appliance") {
      const postsItem = allPosts.filter(
        (item) => item.tag === v && item.category === "appliance"
      );
      postsItem && setPosts(postsItem);
    }
    if (pathName === "/electronics") {
      const postsItem = allPosts.filter(
        (item) => item.tag === v && item.category === "electronics"
      );
      postsItem && setPosts(postsItem);
    }
    if (pathName === "/drones") {
      const postsItem = allPosts.filter(
        (item) => item.tag === v && item.category === "drones"
      );
      postsItem && setPosts(postsItem);
    }
    if (pathName === "/products") {
      const postsItem = bestRankingData.filter((item) => item.tag === v);
      postsItem && setPosts(postsItem);
    }
    setLoading(false);
  };
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className="online-courses">
      <div className="row justify-content-center">
        <div className="col-lg-12 col-md-12">
          <div className="online-courses-text-widget">
            {tags &&
              tags.map((item, index) => {
                return (
                  <Button
                    value={item}
                    className="btn btn-warning btn-sm link-inline-padding"
                    onClick={() => handleFilter(item)}
                    style={{ paddingLeft: 10, marginTop: 5 }}
                    key={index}
                  >
                    {item.toUpperCase()}
                  </Button>
                );
              })}
          </div>
        </div>
      </div>
      <div className="row mt-5">
        {loading
          ? "Loading..."
          : currentPosts.map((post, index) => <Card key={index} post={post} />)}
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={posts.length}
          paginate={paginate}
          path={pathName}
        />
      </div>
    </section>
  );
};

export default CategoryProducts;
