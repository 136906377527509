import React from "react";
//import { NavLink } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import slugifyUrl from "../util/slugifyUrl";

const RecentPosts = ({ palce, categories }) => {
  categories.sort(function (a, b) {
    return b.id - a.id;
  });
  let i = 1;

  return (
    <div className="recent-post">
      <ul>
        <h4>RECENT POSTS</h4>
        {categories.slice(0, 6).map((post) => (
          <h6>
            <a
              href={`/blogs/${post.category}/${slugifyUrl(post.topicTitle)}/${
                post.id
              }`}
              key={post.id}
              style={{ textDecoration: "none" }}
            >
              {i++}
              {"."}&nbsp;
              {palce === "blog"
                ? post.topicTitle.toLocaleUpperCase()
                : post.topicTitle.toLocaleUpperCase().slice(0, 20) + "..."}
            </a>
          </h6>
        ))}
      </ul>
    </div>
  );
};

export default RecentPosts;
