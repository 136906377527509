export const bestRankingData = [
  {
    id: 1,
    title: "Samsung 108 cm (43 inches) Crystal 4K Series Ultra HD Smart LED",
    category: "electronics",
    brandName: "Samsung",
    tag: "smartTv",
    specilaCategory: "rated",
    keywords: [
      "Samsung 43 inch AUE60 Crystal 4K UHD Smart TV",
      "43 inch AUE60 Crystal 4K UHD Smart TV",
      "Crystal 4K UHD Smart TV",
      "UHD smart Tv, best uhd smart tv",
      "Samsung 43 inch Crystal 4K UHD Smart TV price",
    ],
    slug: "best-smart-android-tv",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/61GwJAhftvS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-inches-Crystal-Ultra-UA43AUE60AKLXL/dp/B092BJMT8Q/?tag=amprioz-21",
    descriptions:
      "Feel nearer to the virtual world and inundate yourself free and brilliant picture nature of the Samsung 5 108cm (43 Inches) HD television and appreciate seeing substance easily. Your #1 television projects and motion pictures get genuine. The rich and striking Full HD goal with two times the goal of HD television. It's all you really want to find lots of various substance in one spot and get greatest delight. The smooth One Controller keeps it straightforward, permitting you to rapidly look through the entirety of your #1 substance from your set-top box to game control center, applications, and, surprisingly, live television. Reflect your PC's presentation remotely on the television screen. Additionally, tap into your office PC to get to records or flawlessly work on reports utilizing Microsoft Office 365. Express farewell to old hard drives. Since the Home Cloud include allows you consequently to save the main documents from your cell phone to the Samsung Brilliant television. This wonderful thin plan dissolves into any current stylistic theme and raises the style without taking it over.",
  },
  {
    id: 2,
    title: "OnePlus 108 cm (43 inches) Y Series Full HD LED Smart Android TV",
    category: "electronics",
    brandName: "OnePlus",

    specilaCategory: "featured",
    keywords: [
      "Oneplus 43 inch (108 cm) Y Series 43Y1 (Black) (2020 Model) Smart Android Full HD LED TV Price in India",
      "Oneplus 43 inch (108 cm) Y Series 43Y1 (Black) (2020 Model) Smart Android Full HD LED TV Specifications",
      "Oneplus 43 inch (108 cm) Y Series 43Y1 (Black) (2020 Model) Smart Android Full HD LED TV Reviews",
      "Oneplus 43 inch (108 cm) Y Series 43Y1 (Black) (2020 Model) Smart Android Full HD LED TV Offers",
    ],
    tag: "smartTv",
    slug: "best-smart-android-tv",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71vZLIfj5yS._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B08B42HNH7/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 3,
    title: "Redmi 139 cm (55 inches) 4K Ultra HD Android Smart LED TV",
    category: "electronics",
    brandName: "Redmi",
    specilaCategory: "featured",
    keywords: [
      "Redmi Smart Android 55 inch",
      "Redmi LED tv 55 inch price",
      "Redmi tv price",
      "55 inch LED",
      "Vijay Sales",
    ],
    tag: "smartTv",
    slug: "best-smart-android-tv",

    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71m560rMisL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B08Y1JM3FB/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 4,
    title: "LG 108 cm (43 inches) 4K Ultra HD Smart LED TV",
    category: "electronics",
    brandName: "LG",
    specilaCategory: "ranked",
    keywords: [
      "Lg 43 inch (108 cm) 43UP7500PTZ (Rocky Black) (2021 Model) Smart 4K Ultra HD LED TV Price in India",
      "Lg 43 inch (108 cm) 43UP7500PTZ (Rocky Black) (2021 Model) Smart 4K Ultra HD LED TV Specifications",
      "Lg 43 inch (108 cm) 43UP7500PTZ (Rocky Black) (2021 Model) Smart 4K Ultra HD LED TV Reviews",
      "Lg 43 inch (108 cm) 43UP7500PTZ (Rocky Black) (2021 Model) Smart 4K Ultra HD LED TV Offers",
    ],
    tag: "smartTv",
    slug: "best-smart-android-tv",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/81e0B7ek-8L._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B0967NW34T/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 5,
    title: "OnePlus 125.7 cm (50 inches) U Series 4K LED Smart Android TV",
    category: "electronics",
    tag: "smartTv",
    brandName: "OnePlus",
    specilaCategory: "featured",
    keywords: [
      "Oneplus 50 inch (125.7 cm) U Series 50U1S (Black) (2021 Model) Smart Android 4K LED TV Price in India",
      "Oneplus 50 inch (125.7 cm) U Series 50U1S (Black) (2021 Model) Smart Android 4K LED TV Specifications",
      "Oneplus 50 inch (125.7 cm) U Series 50U1S (Black) (2021 Model) Smart Android 4K LED TV Reviews",
      "Oneplus 50 inch (125.7 cm) U Series 50U1S (Black) (2021 Model) Smart Android 4K LED TV Offers",
    ],
    slug: "best-smart-android-tv",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71Sdj04A8dS._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B095JQ8LVB/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 6,
    title: "Sony Bravia 108 cm (43 inches) Full HD Smart LED TV",
    category: "electronics",
    tag: "smartTv",
    brandName: "Sony",
    specilaCategory: "rated",
    keywords: [
      "sony bravia kdl-43w6603, sony bravia kdl-43w6603 price",
      "buy sony bravia kdl-43w6603, buy sony bravia kdl-43w6603 online",
      "sony bravia kdl-43w6603 price in india",
      "sony bravia kdl-43w6603 best price",
      "sony bravia kdl-43w6603 offers",
    ],
    slug: "best-smart-android-tv",
    rating: 4.6,
    imageLink: "https://m.media-amazon.com/images/I/81lpllAGcBL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B086CGNG4T/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 7,
    title: "OnePlus 108 cm (43 inches) Y Series Full HD LED Smart Android TV",
    category: "electronics",
    tag: "smartTv",
    brandName: "OnePlus",
    specilaCategory: "featured",
    keywords: [
      "Oneplus 55 inch (138.7 cm) U Series 55U1S (Black) (2021 Model) Smart Android 4K LED TV Price in India",
      "Oneplus 55 inch (138.7 cm) U Series 55U1S (Black) (2021 Model) Smart Android 4K LED TV Specifications",
      "Oneplus 55 inch (138.7 cm) U Series 55U1S (Black) (2021 Model) Smart Android 4K LED TV Reviews",
      "Oneplus 55 inch (138.7 cm) U Series 55U1S (Black) (2021 Model) Smart Android 4K LED TV Offers",
    ],
    slug: "best-smart-android-tv",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61eTK1VEP0S._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B095JQVC7N/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 8,
    title: "Redmi 126 cm (50 inches) 4K Ultra HD Android Smart LED TV",
    category: "electronics",
    tag: "smartTv",
    brandName: "OnePlus",
    specilaCategory: "rated",
    keywords: [
      "Xiaomi Redmi Smart TV X50 50 inch LED 4K TV",
      "Xiaomi Redmi Smart TV X50 50 inch LED 4K TV Price",
      "Xiaomi Redmi Smart TV X50 50 inch LED 4K TV price in India",
    ],
    slug: "best-smart-android-tv",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71YDOppqAzL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B08Y55LPBF/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 9,
    title: "OnePlus 108 cm (43 inches) Y Series Full HD LED Smart Android TV",
    category: "electronics",
    tag: "smartTv",
    brandName: "OnePlus",
    specilaCategory: "featured",
    keywords: [
      "oneplus 32-inch y-series hd android tv (32y1)",
      "oneplus 32-inch y-series hd android tv (32y1) price",
      "buy oneplus 32-inch y-series hd android tv (32y1)",
      "buy oneplus 32-inch y-series hd android tv (32y1) online",
      "oneplus 32-inch y-series hd android tv (32y1) price in india",
      "oneplus 32-inch y-series hd android tv (32y1) best price",
      "oneplus 32-inch y-series hd android tv (32y1) offers",
    ],
    slug: "best-smart-android-tv",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/81iLglMLueL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B09Q5SWVBJ/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 10,
    title: "Sony Bravia 108 cm (43 inches) 4K Ultra HD Smart Android LED TV",
    category: "electronics",
    tag: "smartTv",
    brandName: "sony",
    specilaCategory: "featured",
    keywords: [
      "Sony BRAVIA KD-43X74 43 inch LED 4K TV",
      "Sony BRAVIA KD-43X74 43 inch LED 4K TV Price",
      "Sony BRAVIA KD-43X74 43 inch LED 4K TV price in India",
    ],
    slug: "best-smart-android-tv",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/81zeKI1IG9L._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B0918S668P/?tag=amprioz-21",
    descriptions:
      "Feel closer to the virtual world and immerse yourself in the clear and bright picture quality of the Samsung 5 108cm (43 Inches) HD TV LED Smart TV and enjoy viewing content with ease. Your favorite TV programs and movies get real. The rich and vivid Full HD resolution with twice the resolution of HD TV. It’s all you need to discover tons of different content in one place and get maximum enjoyment. The sleek One Remote Control keeps it simple, allowing you to quickly search all your favorite content from your set-top box to game console, apps, and even live TV. Mirror your computer’s display wirelessly on the TV screen. Also, tap into your office PC to access files or seamlessly work on documents using Microsoft Office 365. Say goodbye to old hard drives. Because the Home Cloud feature lets you automatically save the most important files from your mobile phone to the Samsung Smart TV. This beautiful slim design melts into any existing décor and elevates the style without taking it over.",
  },
  {
    id: 11,
    title: "BenQ PD3200U 32-inch LED Backlit Computer Monitor",
    category: "electronics",
    tag: "LED Monitor",
    brandName: "BenQ",
    specilaCategory: "rated",
    keywords: [
      "benq pd3200u, 32 inch monitor",
      "100% srgb designer monitor",
      "4ms responce time",
      "4k uhd monitor",
      "ips panel",
      "benq monitor",
      "reviews",
      "ratings",
      "features",
      "specifications and browse more logitech products with fast shipping and top rated customer service in mdcomp",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/61enhEqzqDL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/BenQ-PD3200U-32-inch-Monitor-Black/dp/B01N4AIIIV/?tag=amprioz-21",
    descriptions:
      "BenQ's AQCOLOR Technology gives factory-calibrated 100% sRGB and Rec.709 colour space of accurate colorations for professional innovative work. Its Dual view Function works in two modes such as sRGB and Darkroom Mode besides the need for two screens. Darkroom mode adjusts the brightness and contrast of images giving readability and sharpness to details, creating the ultimate setting for work in darker post-processing environments. Another feature CAD/CAM mode provides superior contrast on lines and shapes of technical illustrations, bringing out the smallest details to view. Animation Mode lightens image areas to better visualize the details hiding in dark shadows. Keyboard Video Mouse (KVM) Switch displays contents from two PC systems using one set of keyboard and mouse.Eye-Care Technology offers Flicker-Free technology which reduces eye discomfort.",
  },
  {
    id: 12,
    title: "Samsung 59.8 cm (23.5 inch) Curved LED Backlit Computer Monitor",
    category: "electronics",
    tag: "LED Monitor",
    brandName: "Samsung",
    specilaCategory: "rated",
    keywords: [
      "Samsung 59.8 cm (23.5 inch) Curved LED Backlit Computer Monitor - Full HD",
      "VA Panel with VGA",
      "HDMI",
      "Audio Ports - LC24F390FHWXXL (Black)",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/71nplbAMwzL._SL1202_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-23-5-inch-Curved-Monitor/dp/B01GFPGHSM/?tag=amprioz-21",
    descriptions:
      "Samsung 59.8 cm (23.5 inch) Curved LED Backlit Computer monitor offers immersive Viewing Experience with Curved Screen. The 1800R curvature of the display offers a truly immersive viewing experience that lets you enjoy big, bold and stunning panoramic views while you work or play. With its design inspired by the curve of the human eye, the Samsung CF390 screen provides a comfortable and exciting viewing experience for your domestic or office use. Another Featuring an ultra-slim and glossy profile the Samsung CF390 monitor measures less than 0.5inch thick. Make a fashionable statement while staying productive with the 24-inch curved screen. The simple circular stand will add a modern-day look to your space. Eye Saver Mode optimizes your viewing comfort by reducing blue light emissions and flickers at the touch of a button. Read documents, play games, watch movies and edit images for long durations of time, and experience a comfortable and beautiful view, without stressful about eye strain or fatigue.",
  },
  {
    id: 13,
    title: "LG 22 inch (55cm) IPS LED Monitor (22MP68VQ) ",
    category: "electronics",
    tag: "LED Monitor",
    brandName: "LG",
    specilaCategory: "rated",
    keywords: [
      "Lg 22 Inch (55cm) Ips Monitor - Full Hd",
      "Ips Panel With Vga, Hdmi",
      "Dvi",
      "Audio Out Ports - 22mp68vq wholesale &amp; wholesale dealers",
      " lg computer monitor",
      "lg computer monitor",
      "computer hardware &amp; peripherals",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61wFnp19zzL._SL1092_.jpg",
    productLink: "https://www.amazon.in/gp/product/B01IBM5V66/?tag=amprioz-21",
    descriptions:
      "Now here’s one of the topmost selling monitors under 10K rupees in India. LG 22 inch (55cm) IPS Monitor has a top rate thin-bezel profile that is as accurate as it can be. It comes with an FHD display that delivers ideal DPI in 22″ size.Screen of LG 22 inch (55cm) IPS Monitor is color calibrated out of the box and is color enriched. The LG display comes with its own powerful split-screen mode other than one commendable by the CPU and as a result giving you greater control over splitting and accessing more than one apps at a time. You are about to get an excellent attractive display that is borderless on three out of four sides which just looks superb.",
  },
  {
    id: 14,
    title: "Dell P Series 24-inch",
    category: "electronics",
    tag: "LED Monitor",
    brandName: "Dell",
    specilaCategory: "rated",
    keywords: [
      "Dell P Series 24-inch Screen Full HD (1080p) LED Monitor with IPS Panel - P2419H wholesale sellers",
      "led monitor",
      "computer monitor",
      "computer hardware &amp; peripherals",
      "Sparrow Systems",
      "Coimbatore",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/91ddm+P-VCL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Dell-Screen-LED-Lit-Monitor-P2419H/dp/B07F8XZN69/?tag=amprioz-21",
    descriptions:
      "Optimize your workspace with this efficient 23.8 display built with an ultrathin bezel design, a small footprint and comfort-enhancing features. Clean and clutter-free: Focus on your work while hiding away cable clutter with an improved cable management design. This TUV3 Certified display has a flicker-free screen with Comfort View, a characteristic that reduces hazardous blue light emissions. It’s designed to optimize eye comfort even over extended viewing. Pivot, tilt, swivel and adjust the height of your display for a comfortable setup all day long. Or choose from a variety of mounts and stands, including VESA, for even extra flexibility.A wide viewing angle enabled by In-Plane Switching (IPS) technology lets you see brilliant colors—no matter where you sit.",
  },
  {
    id: 15,
    title: "Acer 21.5 inch LED Backlit Computer Monitor",
    category: "electronics",
    tag: "LED Monitor",
    brandName: "Acer",
    specilaCategory: "rated",
    keywords: [
      "led monitor",
      "hd led monitor",
      "computer  monitor",
      "Acer 23.8-inch IPS Full HD Ultra Slim LED Monitor",
      "Acer,Acer MONITOR",
      "Acer 27-inch VA Panel Full HD (1920 x 1080) Monitor",
      "Acer 31.5 inch 2560 x 1440 WQHD IPS Panel Monitor",
      "Roll over image to zoom in Acer EB321HQ 31.5-inch (80.01 cm) Full HD IPS Monitor",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/714mr6GxecL._SL1200_.jpg",
    productLink: "https://www.amazon.in/dp/B076PGBM25/?tag=amprioz-21",
    descriptions:
      "Renowned computer company Acer also offers a good decent 1080P Full HD resolution computer monitor. Its ZeroFrame Design makes for a near-seamless look, allowing you to see more of what matters most the screen. It has Acer BlueLightShield technology which reduces the exposure of blue light, which can be potentially harmful, by adjusting color hue and brightness.Companies own Acer Flickerless technology is included in this product that eliminates screen flickering by providing a stable supply of power to the display.Acer BlueLightShield technology reduces the exposure of blue light, which can be doubtlessly harmful, by adjusting colour hue and brightness.",
  },
  {
    id: 16,
    title: "Samsung 27 inch (68.6 cm) LED Flat Computer",
    category: "electronics",
    tag: "LED Monitor",
    brandName: "Samsung",
    specilaCategory: "featured",
    keywords: [
      "SAMSUNG 27 inch Full HD Monitor (LS27R350FHWXXL) Price",
      "SAMSUNG 27 inch Full HD Monitor (LS27R350FHWXXL)",
      "Buy SAMSUNG 27 inch Full HD Monitor (LS27R350FHWXXL) online",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/51wieRVMF+L.jpg",
    productLink:
      "https://www.amazon.in/Samsung-27inch-Side-Bezel-Monitor/dp/B08MQN1NZW/?tag=amprioz-21",
    descriptions:
      "Simplistic design, total sophistication. Exquisite craftsmanship makes the difference in a flat-screen monitor that's serious about style. The 3-sided bezel-less display and slim Y-shaped stand deliver a minimalist look. In a dual monitor setup, the displays line up for a nearly gapless view.Superfluid entertainment experience. AMD Radeon FreeSync keeps your screen and graphics card refresh rate in sync to reduce picture tear and stutter. Watch films and play games without any interruptions. Even quick action scenes look clear and seamless.Now, all the action looks flawless. The 75Hz refresh rate offers a more fluid picture. Whether you're catching up on your favourite TV drama, watching a video, or playing a game, your entertainment is smooth with no lag or ghosting effect. ",
  },
  {
    id: 17,
    title: "LG 25-inch (64.5 cm) UltraWide Multitasking Monitor ",
    category: "electronics",
    specilaCategory: "featured",
    tag: "LED Monitor",
    brandName: "LG",
    keywords: [
      "LG 25 inch HD Monitor (25UM58 25-inch UltraWide Monitor) Price",
      "LG 25 inch HD Monitor (25UM58 25-inch UltraWide Monitor)",
      "Buy LG 25 inch HD Monitor (25UM58 25-inch UltraWide Monitor) online",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/81pv0g8bQhL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-25UM58-25-inch-UltraWide-Monitor/dp/B01BV1XB2K/?tag=amprioz-21",
    descriptions:
      "LG 25-inch (63.5 cm) Ultra Wide Multitasking Monitor (U2415) is a 21:9 ultra-wide full HD IPS display LED monitor. The Ultra Wide 21:9 aspect ratio makes games and movies extra immersive than ever.Visibility of 1080p pixel Full HD resolution with IPS is a game-changer. Simply put, from any viewing angle, everything looks extra crisp and exact in Full HD. This monitors offers sRGB spectrum Over 99% which is a great solution for professional photographers, graphic designers.",
  },

  {
    id: 18,
    title: "Philips Viva Collection HD4928/01 2100-Watt Induction Cooktop",
    category: "appliance",
    tag: "Induction Cooktop",
    specilaCategory: "featured",
    rating: 4.2,
    brandName: "Philips",
    keywords: [
      "PHILIPS HD4928/01 Induction Cooktop Price",
      "PHILIPS HD4928/01 Induction Cooktop ",
      "Induction Cooktop",
    ],
    imageLink: "https://m.media-amazon.com/images/I/61x0Qnm08HS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Philips-Collection-HD4928-01-2100-Watt/dp/B00EDJJ7FS/?tag=amprioz-21",
    descriptions:
      "Electromagnetic induction technology ensures high heating efficiency, cooks food faster than a gas stove. Seals nutrition into the food and prevents vitamin loss.Philips cares about the environment and society at large, and strongly supports eco-design of products.Press cooking mode button after you press the ON -OFF button. If cooking mode button is not pressed within one minute, the induction cooker will switch off automatically.",
  },
  {
    id: 19,
    title: "USHA Cook Joy (3616) 1600-Watt Copper Sealed Induction Cooktop",
    category: "appliance",
    specilaCategory: "featured",
    brandName: "USHA",
    keywords: [
      "USHA Cook Joy - 3616 -1600W Induction Cooktop Price",
      "USHA Cook Joy - 3616 -1600W Induction Cooktop ",
      "Induction Cooktop",
    ],
    tag: "Induction Cooktop",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/610uDLQFxeL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B072PXYG1R/?tag=amprioz-21",
    descriptions:
      "Induction cooktop only heats the area in direct contact with cookware with precise and instant heat application. No wastage of heat , maximum energy utilization as the energy is supplied directly to cooking vessel.It offers safety from open flames and presents no fire hazards or gas leakages.The glass top remains cool ensuring no burnt fingers.",
  },
  {
    id: 20,
    title: "Prestige Induction Cooktop Pic 6.1 V3 2200 Watts",
    category: "appliance",
    tag: "Induction Cooktop",
    specilaCategory: "featured",
    brandName: "Prestige",
    keywords: [
      "Prestige PIC 6.1 V3 Induction Cooktop Price",
      "Prestige PIC 6.1 V3 Induction Cooktop ",
      "Induction Cooktop",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/81z6RfbuUcL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07L12RZXL/?tag=amprioz-21",
    descriptions:
      "It takes care of voltage variance, ensures durability & better performance.Sleek and contemporary design to complement the modern kitchen.Operate with ease using the feather touch button options.Heating is flame free, making the cooking process safe without environmental hazards.Helps prepare authentic Indian food at the touch of a button. Choose from various available presets.Its unique feature that ensures your food is always hot.",
  },
  {
    id: 21,
    title: "Wipro 2000 Watt Induction Cooktop",
    brandName: "Wipro",
    keywords: [
      "WIPRO by Wipro VC061200 Induction Cooktop Price",
      "WIPRO by Wipro VC061200 Induction Cooktop ",
      "Induction Cooktop",
    ],
    category: "appliance",
    tag: "Induction Cooktop",
    rating: 3.6,
    imageLink: "https://m.media-amazon.com/images/I/71TeeorQhWL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B09RK37K93/?tag=amprioz-21",
    descriptions:
      "Its Crystal glass plate provides elegant classy look to transcend style on to your kitchen while enabling comfort with ease of cleaning.Smart-sensor touch control for easy operation.Seven pre-programmed Indian menu function custom made to suit our taste buds.Smart time for handsfree operation .Now cooking on the auto mode enables with the timer function. ",
  },
  {
    id: 22,
    title: "Prestige PIC 20 1600 Watt Induction Cooktop with Push button",
    category: "appliance",
    brandName: "Prestige",
    keywords: [
      "Prestige PIC 20.0 1600 W Induction Cooktop Price",
      "Prestige PIC 20.0 1600 W Induction Cooktop",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/81od9GbumhL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B00YMJ0OI8/?tag=amprioz-21",
    descriptions:
      "Its INDIAN MENU OPTION helps prepare authentic Indian food at the touch of a button. Choose from various available presets.The cook-top is portable and can be used anywhere.Automatic power and temperature adjustment for different foods.The product is designed to block surplus magnetic radiation for a healthy meal without any radiation hazards, making it efficient.Heating is flame free, making the cooking process safe without environmental hazards.",
  },
  {
    id: 23,
    title:
      "PHILIPS Viva Collection HD4938/01 2100-Watt Glass Induction Cooktop",
    category: "appliance",
    brandName: "Philips",
    keywords: [
      "PHILIPS HD4938/01 Induction Cooktop Price",
      "PHILIPS HD4938/01 Induction Cooktop",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/51c3j0ZcEaL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B00EDJIWFY/?tag=amprioz-21",
    descriptions:
      "enables convenient and fast cooking the philips viva collection induction cooktop is a sleek and sturdy glass panel cooktop for convenient and fast cooking. Its electromagnetic induction technology provides high heating efficiency and cooks food faster than the conventional gas stove. Unlike cooking on direct flame, where the vital nutrients and vitamins from the food are lost, this philips induction cooker seals nutrition into the food and aids healthy cooking. Simple to use and maintain, this induction cooktop saves you a lot of money, especially with the rising lpg prices. Besides, its cool touch surface and auto-off program ensure safe cooking.Compatible Utensils: Stainless Steel Pots, Stainless Steel Rice Cookers, Cast Iron Frying Pots, Oil boiling Pots, Stainless Steel Water Jugs, Cast Iron Pots, Color Pots, Grilling Iron Platepreset menus and sensor touch keys choose from over ten preset menus for different indian recipes. Just place the required amount of ingredients per the recipe suggested and press the menu button to get piping hot food ready in minutes. You can select from 0 to 3 hours time setting for cooking food. Furthermore, a 24-hour preset timer function allows for delayed cooking as well. The full glass panel of this philips hd4938/00 induction cooktop features sensor touch keys for feather touch cooking. This philips induction cooktop also delivers up to 2100w of power for fast cooking.",
  },
  {
    id: 24,
    title:
      "iBELL Hold The World. Digitally! 2000 W with Auto Shut Off and Overheat Protection",
    category: "appliance",
    brandName: "iBell",
    keywords: [
      "Ibell 2000 W with Auto Shut Off and Overheat Protection",
      "Induction Cooktop Price",
      "Ibell 2000 W with Auto Shut Off and Overheat Protection",
      "BIS Certified . Induction Cooktop ",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/61CZ2LpIEbL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07MZVMWWC/?tag=amprioz-21",
    descriptions:
      "Induction cooktop with press button control and High quality crystal glass|Certified by Bureau of Indian Standards (IS 302-2-6 : 2009).Super wide voltage adaptability and with multifunctions.Auto shutoff safety protection and Overheat protection.Cooktop helps you to cook your favorite dish perfectly, an automatic voltage regulator, that ensures durability and a power saving technology, to ensure, more food is cooked, while using less power and then there's more.",
  },
  {
    id: 25,
    title: "V-Guard VIC-15 2000-Watt Induction Cooktop ",
    category: "appliance",
    brandName: "V-Guard",
    keywords: [
      "V-Guard VIC 15 (2000 W) Induction Cooktop Price",
      "V-Guard VIC 15 (2000 W) Induction Cooktop ",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/81E2oeieE+L._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B06X9T24XR/?tag=amprioz-21",
    descriptions:
      "V-Guard's VIC 15 delivers on the promise of thoughtful engineering and intelligent design. A must-have for fast and convenient cooking, the VIC 15 is a powerful induction cooktop with a polished finish. Enhance your kitchen with the VIC 15 and make cooking an enjoyable experience.our VIC 15 cooktop comes with a seven segment digital display that shows upto 4 digits. Knowing the working status of induction cooktop ensures your cooking experience is a stress-free, peaceful experience.",
  },
  {
    id: 26,
    title: "Amazonbasics Induction Cooktop 1300 Watts",
    category: "appliance",
    brandName: "Amazonbasics",
    keywords: [
      "AmazonBasics Induction Cooktops",
      "AmazonBasics Induction Cooktops price list in india",
      "AmazonBasics Induction Cooktops price list in india 2022",
      "buy AmazonBasics Induction Cooktops online",
      "compare AmazonBasics Induction Cooktops prices",
      "low price AmazonBasics Induction Cooktops",
      "best selling AmazonBasics Induction Cooktops in india",
      "top AmazonBasics Induction Cooktops in india 2022",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/814Nn3Qa98L._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07YCB2T1V/?tag=amprioz-21",
    descriptions:
      "The 1300 Watt AmazonBasics Induction Cooktop comes with an easy to use micro crystal glass induction surface with a push button control that allows for six stages of power settings. It comes with a 1.35-meter long cable for added convenience to use and clean, and has a low sound output of 65 decibels. This induction cooktop meets Amazon's international packaging ISTA-6A standards to ensure that the product reaches you safely.This induction cooktop meets international quality standards for safety and performance. The plugs and cables are BIS certified for extra reliability. It also has the enhanced Auto Standby safety feature when there is no pan kept on the cooktop. The other safety features include residual heat indicator and fan for auto cooling that keeps working to cool down the residual heat even after the operation is stopped. It is also equipped with resettable thermostat cut-off to protect against overheating and a pause function for additional safety.",
  },
  {
    id: 27,
    brandName: "V-Guard",

    title: "V Guard Induction Cooktop VIC 07",
    category: "appliance",
    keywords: [
      "V-Guard VIC 07 (1600 W) Induction Cooktop Price",
      "V-Guard VIC 07 (1600 W) Induction Cooktop",
      "Induction Cooktop",
    ],
    tag: "Induction Cooktop",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/81vHZnGTXtL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Guard-VIC-07-1600-Watt-Induction-Cooktop/dp/B01N16Q2KR/?tag=amprioz-21",
    descriptions:
      "Today there is a wide variety of induction cooktops, and to find the best amongst them seems like an uphill task. So, discovering the V-Guard VIC 07 with soft switch controls for smooth operations, 4 digit LED display and 24 hours pre-set functioning is a delight in itself. Bring home ultimate style and convenience with this Induction Cooktop that’s built to last. With a sturdy crystalline ceramic glass that is not only attractive but also reliable, an energy efficient performance and stylish design, it’s just what your kitchen needs. A product so effective, so convenient should be a part of your kitchen without any second thoughts. Omit all the extra efforts that go out in the kitchen and give yourself and your family more time without worrying about what’s on stove.",
  },
  {
    id: 28,
    title: "USHA Cook Joy (3616) 1600-Watt Copper Sealed Induction Cooktop",
    brandName: "USHA",
    keywords: [
      "USHA Cook Joy - 3616 -1600W Induction Cooktop Price",
      "USHA Cook Joy - 3616 -1600W Induction Cooktop",
      "Induction Cooktop",
    ],

    category: "appliance",
    tag: "Induction Cooktop",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/610uDLQFxeL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B072PXYG1R/?tag=amprioz-21",
    descriptions:
      "Induction cooktop only heats the area in direct contact with cookware with precise and instant heat application. No wastage of heat , maximum energy utilization as the energy is supplied directly to cooking vessel.It offers safety from open flames and presents no fire hazards or gas leakages.The glass top remains cool ensuring no burnt fingers.",
  },
  {
    id: 29,
    title: "Prestige Induction Cooktop Pic 6.1 V3 2200 Watts",
    category: "appliance",
    brandName: "Prestige",
    keywords: [
      "Prestige PIC 6.1 V3 Induction Cooktop Price",
      "Prestige PIC 6.1 V3 Induction Cooktop",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/81z6RfbuUcL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07L12RZXL/?tag=amprioz-21",
    descriptions:
      "It takes care of voltage variance, ensures durability & better performance.Sleek and contemporary design to complement the modern kitchen.Operate with ease using the feather touch button options.Heating is flame free, making the cooking process safe without environmental hazards.Helps prepare authentic Indian food at the touch of a button. Choose from various available presets.Its unique feature that ensures your food is always hot.",
  },
  {
    id: 30,
    title: "Wipro 2000 Watt Induction Cooktop",
    category: "appliance",
    brandName: "Wipro",
    keywords: [
      "WIPRO by Wipro VC061200 Induction Cooktop Price",
      "WIPRO by Wipro VC061200 Induction Cooktop",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 3.6,
    imageLink: "https://m.media-amazon.com/images/I/71TeeorQhWL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B09RK37K93/?tag=amprioz-21",
    descriptions:
      "Its Crystal glass plate provides elegant classy look to transcend style on to your kitchen while enabling comfort with ease of cleaning.Smart-sensor touch control for easy operation.Seven pre-programmed Indian menu function custom made to suit our taste buds.Smart time for handsfree operation .Now cooking on the auto mode enables with the timer function. ",
  },
  {
    id: 31,
    title: "Prestige PIC 20 1600 Watt Induction Cooktop with Push button",
    category: "appliance",
    brandName: "Prestige",
    keywords: [
      "Prestige PIC 20.0 1600 W Induction Cooktop Price",
      "Prestige PIC 20.0 1600 W Induction Cooktop",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/81od9GbumhL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B00YMJ0OI8/?tag=amprioz-21",
    descriptions:
      "Its INDIAN MENU OPTION helps prepare authentic Indian food at the touch of a button. Choose from various available presets.The cook-top is portable and can be used anywhere.Automatic power and temperature adjustment for different foods.The product is designed to block surplus magnetic radiation for a healthy meal without any radiation hazards, making it efficient.Heating is flame free, making the cooking process safe without environmental hazards.",
  },
  {
    id: 32,
    title:
      "PHILIPS Viva Collection HD4938/01 2100-Watt Glass Induction Cooktop",
    category: "appliance",
    brandName: "PHILIPS",
    keywords: [
      "PHILIPS HD4938/01 Induction Cooktop Price",
      "PHILIPS HD4938/01 Induction Cooktop",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/51c3j0ZcEaL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B00EDJIWFY/?tag=amprioz-21",
    descriptions:
      "enables convenient and fast cooking the philips viva collection induction cooktop is a sleek and sturdy glass panel cooktop for convenient and fast cooking. Its electromagnetic induction technology provides high heating efficiency and cooks food faster than the conventional gas stove. Unlike cooking on direct flame, where the vital nutrients and vitamins from the food are lost, this philips induction cooker seals nutrition into the food and aids healthy cooking. Simple to use and maintain, this induction cooktop saves you a lot of money, especially with the rising lpg prices. Besides, its cool touch surface and auto-off program ensure safe cooking.Compatible Utensils: Stainless Steel Pots, Stainless Steel Rice Cookers, Cast Iron Frying Pots, Oil boiling Pots, Stainless Steel Water Jugs, Cast Iron Pots, Color Pots, Grilling Iron Platepreset menus and sensor touch keys choose from over ten preset menus for different indian recipes. Just place the required amount of ingredients per the recipe suggested and press the menu button to get piping hot food ready in minutes. You can select from 0 to 3 hours time setting for cooking food. Furthermore, a 24-hour preset timer function allows for delayed cooking as well. The full glass panel of this philips hd4938/00 induction cooktop features sensor touch keys for feather touch cooking. This philips induction cooktop also delivers up to 2100w of power for fast cooking.",
  },
  {
    id: 33,
    title:
      "iBELL Hold The World. Digitally! 2000 W with Auto Shut Off and Overheat Protection",
    category: "appliance",
    brandName: "iBELL",
    keywords: [
      "Ibell 2000 W with Auto Shut Off and Overheat Protection",
      "BIS Certified . Induction Cooktop Price",
      "Ibell 2000 W with Auto Shut Off and Overheat Protection",
      "BIS Certified . Induction Cooktop",
      "Induction Cooktop",
    ],

    tag: "Induction Cooktop",
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/61CZ2LpIEbL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07MZVMWWC/?tag=amprioz-21",
    descriptions:
      "Induction cooktop with press button control and High quality crystal glass|Certified by Bureau of Indian Standards (IS 302-2-6 : 2009).Super wide voltage adaptability and with multifunctions.Auto shutoff safety protection and Overheat protection.Cooktop helps you to cook your favorite dish perfectly, an automatic voltage regulator, that ensures durability and a power saving technology, to ensure, more food is cooked, while using less power and then there's more.",
  },
  {
    id: 34,
    title: "V-Guard VIC-15 2000-Watt Induction Cooktop ",
    category: "appliance",
    tag: "Induction Cooktop",
    brandName: "V-Guard",
    keywords: [
      "V-Guard VIC 15 (2000 W) Induction Cooktop Price",
      "V-Guard VIC 15 (2000 W) Induction Cooktop",
      "Induction Cooktop",
    ],

    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/81E2oeieE+L._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B06X9T24XR/?tag=amprioz-21",
    descriptions:
      "V-Guard's VIC 15 delivers on the promise of thoughtful engineering and intelligent design. A must-have for fast and convenient cooking, the VIC 15 is a powerful induction cooktop with a polished finish. Enhance your kitchen with the VIC 15 and make cooking an enjoyable experience.our VIC 15 cooktop comes with a seven segment digital display that shows upto 4 digits. Knowing the working status of induction cooktop ensures your cooking experience is a stress-free, peaceful experience.",
  },
  {
    id: 35,
    title: "Amazonbasics Induction Cooktop 1300 Watts",
    category: "appliance",
    tag: "Induction Cooktop",
    brandName: "Amazonbasics",
    keywords: [
      "AmazonBasics Induction Cooktops",
      "AmazonBasics Induction Cooktops price list in india",
      "AmazonBasics Induction Cooktops price list in india 2022",
      "buy AmazonBasics Induction Cooktops online",
      "compare AmazonBasics Induction Cooktops prices",
      "low price AmazonBasics Induction Cooktops",
      "best selling AmazonBasics Induction Cooktops in india",
      "top AmazonBasics Induction Cooktops in india 2022",
      "Induction Cooktop",
    ],

    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/814Nn3Qa98L._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07YCB2T1V/?tag=amprioz-21",
    descriptions:
      "The 1300 Watt AmazonBasics Induction Cooktop comes with an easy to use micro crystal glass induction surface with a push button control that allows for six stages of power settings. It comes with a 1.35-meter long cable for added convenience to use and clean, and has a low sound output of 65 decibels. This induction cooktop meets Amazon's international packaging ISTA-6A standards to ensure that the product reaches you safely.This induction cooktop meets international quality standards for safety and performance. The plugs and cables are BIS certified for extra reliability. It also has the enhanced Auto Standby safety feature when there is no pan kept on the cooktop. The other safety features include residual heat indicator and fan for auto cooling that keeps working to cool down the residual heat even after the operation is stopped. It is also equipped with resettable thermostat cut-off to protect against overheating and a pause function for additional safety.",
  },
  {
    id: 36,
    title: "V Guard Induction Cooktop VIC 07",
    category: "appliance",
    tag: "Induction Cooktop",
    brandName: "V-Guard",
    keywords: [
      "V-Guard VIC 07 (1600 W) Induction Cooktop Price",
      "V-Guard VIC 07 (1600 W) Induction Cooktop",
      "Induction Cooktop",
    ],

    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/81vHZnGTXtL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Guard-VIC-07-1600-Watt-Induction-Cooktop/dp/B01N16Q2KR/?tag=amprioz-21",
    descriptions:
      "Today there is a wide variety of induction cooktops, and to find the best amongst them seems like an uphill task. So, discovering the V-Guard VIC 07 with soft switch controls for smooth operations, 4 digit LED display and 24 hours pre-set functioning is a delight in itself. Bring home ultimate style and convenience with this Induction Cooktop that’s built to last. With a sturdy crystalline ceramic glass that is not only attractive but also reliable, an energy efficient performance and stylish design, it’s just what your kitchen needs. A product so effective, so convenient should be a part of your kitchen without any second thoughts. Omit all the extra efforts that go out in the kitchen and give yourself and your family more time without worrying about what’s on stove.",
  },
  {
    id: 37,
    title: "Bajaj Rex 750W Mixer Grinder",
    category: "appliance",
    tag: "Mixer Grinder",
    brandName: "Bajaj",
    keywords: [
      "BAJAJ Rex 750 Mixer Grinder (4 Jars, White) Price",
      "Bajaj Rex 750W Mixer Grinder",
      "Bajaj Mixer Grinder",
      "Mixer Grinder",
    ],

    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/51BNv1auG3L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bajaj-Rex-Mixer-Grinder-White/dp/B08G8H8DPL/?tag=amprioz-21",
    descriptions:
      "Its Nutri-Pro* for Nutrition Retention feature enables the mixer grinder to retain the nutritional content and authentic taste of the ingredients regardless of the speed of mixing/grounding. The claim of Nutri-Pro is based on a test conducted on 6 ingredients i.e., rice, black gram, peanut, turmeric, tomato and papaya for higher nutrition retention.",
  },
  {
    id: 38,
    title: "Philips HL7756/00 Mixer Grinder",
    category: "appliance",
    brandName: "Philips",
    keywords: [
      "PHILIPS by Phlips HL7756/00 Daily Collection 750 W Mixer Grinder (3 Jars, Black) Price",
      "Philips HL7756/00 Mixer Grinder",
      "Philips Mixer Grinder",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/51B0MPbLbXL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/Philips-HL7756-00-750-Watt-Grinder/dp/B01GZSQJPA/?tag=amprioz-21",
    descriptions:
      "Newly designed powerful Turbo motor for continuous grinding - 750W Turbo motor that gives you the grinding experience even with tough ingredients like black whole gram (dal) for preparing tasty vadas and dosas.The advanced air ventilation system provides faster cooling of the appliance which ensures longer life of the motor.",
  },
  {
    id: 39,
    title: "USHA RapidMix 500-Watt",
    category: "appliance",
    brandName: "Usha",
    keywords: [
      "USHA RAPIDMIX RM500MX3 500 Mixer Grinder (3 Jars, White) Price",
      "USHA RAPIDMIX Mixer Grinder",
      "USHA Mixer Grinder",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/61RHKD6n89L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/USHA-RapidMix-500-Watt-Copper-Grinder/dp/B08MXJYB2V/?tag=amprioz-21",
    descriptions:
      "Make food preparation convenient with the rapid Mix mixer grinder which offers a 20,000+ high rpm for faster grinding.Grind the toughest ingredients to a fine paste with the food safe hardened 4 fin blade design in a wet jar.",
  },
  {
    id: 40,
    title: "Croma 500W Mixer Grinder ",
    category: "appliance",
    brandName: "Croma",
    keywords: [
      "Croma 500W Mixer Grinder",
      "Croma Mixer Grinder",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/71jhtTqFAFL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Croma-500-Watt-Grinder-CRAK4184-Purple/dp/B08KDBLMQP/?tag=amprioz-21",
    descriptions:
      "Specialized stainless steel blades helps you to cut even the toughest ingredients and gives the finest paste and purees for daily cooking of chutneys, dips, masalas and pastes; Motor overload protection for safety.3 detachable stainless steel jars with polished blades for fine grinding (Wet & Dry Grinding Jar (0.5 Liters), Liquidizing & Blending Jar (1 Liters), Chutney Jar (0.3 Liters)) and The jars are leak-proof for spill-free grinding experience.",
  },
  {
    id: 41,
    title: "Butterfly Smart Mixer Grinder, 750W, 4 Jars ",
    category: "appliance",
    brandName: "Butterfly",
    keywords: [
      "Butterfly Rapid 750 W Juicer Mixer Grinder (4 Jars, Black) Price",
      "Butterfly Mixer Grinder",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/71+cDVETcIL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Butterfly-Smart-750-Watt-Mixer-Grinder/dp/B075JJ5NQC/?tag=amprioz-21",
    descriptions:
      "The Butterfly Smart 750-Watt Mixer Grinder with its stainless steel jars and sleek finish ticks both the boxes of durability and design. Consuming 230 Volts, the mixer grinder is an energy-efficient device. The razor-sharp blades have an RPM of 17000 – 20000 which precisely cuts, chops and mixes ingredients of any shapes or sizes. Beautifully designed, this value-for-money product will surely serve your purpose of reducing preparation time by hours.The speed regulating knob is a smart feature which serves your purpose of adjusting varying speed limits. Now, you can take control over your kitchen by regulating the grinding or mixing process of your food items with the speed regulating option coming in handy. The ‘whip’ feature allows you to run the appliance at its highest speed and allow you to grind chillies coarsely or simply blend lip-smacking milkshakes at a lightning-fast speed.",
  },
  {
    id: 42,
    title: "Bajaj Rex 500W Mixer Grinder",
    category: "appliance",
    brandName: "Bajaj",
    keywords: [
      "BAJAJ Rex 500 W Mixer Grinder (3 Jars, White) Price",
      "BAJAJ Rex 500 W Mixer Grinder",
      "BAJAJ Mixer Grinder",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/51Ck4wmBbSL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bajaj-Rex-500-Watt-Mixer-Grinder/dp/B00HVXS7WC/?tag=amprioz-21",
    descriptions:
      "Take your culinary skills to a different level with the Rex 500W Mixer Grinder! It has been designed to perform a number of functions to assist your cooking and to speed it up. Make this a part of your kitchen today and experience new delights.The 500W motor is powerful enough to carry out several tasks. Further, the appliance comes with vacuum feet for ease of operation, preventing it from sliding.Bajaj Rex mixer grinder sports an elegant design that boasts of sturdiness as well as easy grip handles, on the liquidizing jar.",
  },
  {
    id: 43,
    title: "AmazonBasics Premium 750-Watt Mixer Grinder",
    category: "appliance",
    brandName: "AmazonBasics",
    keywords: [
      "AmazonBasics Premium 750-Watt Mixer Grinder with 3 Jars",
      "AmazonBasics Premium 750-Watt Mixer Grinder",
      "AmazonBasics Mixer Grinder",
      "White and Blue",
      "SmartGadgets",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/71sqx3bKtfL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/AmazonBasics-Premium-750-Watt-Mixer-Grinder/dp/B086T9YP91/ref=sxin_14?asc_contentid=amzn1.osa.34c12c1b-32bc-40e8-ba92-de076bc183e3.A21TJRUUN4KGV.en_IN&asc_contenttype=article&ascsubtag=amzn1.osa.34c12c1b-32bc-40e8-ba92-de076bc183e3.A21TJRUUN4KGV.en_IN&creativeASIN=B086T9YP91&crid=HPOQNHFCTNNL&cv_ct_cx=Mixer+Grinder&cv_ct_id=amzn1.osa.34c12c1b-32bc-40e8-ba92-de076bc183e3.A21TJRUUN4KGV.en_IN&cv_ct_pg=search&cv_ct_we=asin&cv_ct_wn=osp-single-source-earns-comm&keywords=Mixer+Grinder&linkCode=oas&pd_rd_i=B086T9YP91&pd_rd_r=032e041b-038e-4a1f-a364-1298c3598177&pd_rd_w=6XKgr&pd_rd_wg=s6sYe&pf_rd_p=f50b8836-bf86-46c0-a999-ee1dda2b7d1f&pf_rd_r=68VRAXAVA8E6Q96DB6XX&qid=1653012639&sprefix=mixer+grinder%2Caps%2C382&sr=1-1-61f4c597-7a45-4a97-bd75-c52903f8ee93&tag=91articles-21",
    descriptions:
      "Durable Mixer Grinder with three sturdy jars for dry and wet grinding.Made from food-grade stainless steel and multi-functional robust blades for ease of usage.Comes with 3 High Grade SS 202 Stainless steel jars- Liquidizing jar (1.25 L), Dry or wet grinding jar (1 L) and Chutney jar (0.4 L).Food Grade silicon rubber gaskets on jar lids for resistance to daily exposure of oil, chemicals and water; Leak proof grinding and mixing experience for years of usage.",
  },
  {
    id: 44,
    title: "Philips HL7756/00 Mixer Grinder",
    category: "appliance",
    brandName: "Philips",
    keywords: [
      "PHILIPS by Phlips HL7756/00 Daily Collection 750 W Mixer Grinder (3 Jars, Black) Price",
      "PHILIPS HL7756/00 750 W Mixer Grinder (3 Jars, Black) Price",
      "Philips HL7756/00 Mixer Grinder",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/51B0MPbLbXL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/Philips-HL7756-00-750-Watt-Grinder/dp/B01GZSQJPA/?tag=amprioz-21",
    descriptions:
      "Burning smell during first few usage is normal due to varnish coating on motor and will stop after few usage cycles. Your mixer grinder's powerful motor may make some noise. If the noise level/smell seem abnormal, please contact Customer Care.This product does not require installation. Please allow the motor to cool for some time between product uses.New and powerful 750 W Turbo Motor, with advanced air ventilation & sturdy coupler, allows 25 minutes* continuous grinding even for tough ingredients like black gram dal.",
  },
  {
    id: 45,
    title: "Prestige Iris 750 Watt Mixer Grinder",
    category: "appliance",
    brandName: "Prestige",
    keywords: [
      "Prestige Iris with 3 Stainless Steel Jar + 1 Juicer Jar IRIS 750 Juicer Mixer Grinder (3 Jars, White, Blue) Price",
      "Prestige Iris 750 Watt Mixer Grinder",
      "Prestige Iris Mixer Grinder",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/81+M1QrVNIL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Prestige-Iris-Grinder-Stainless-Juicer/dp/B0756K5DYZ/?tag=amprioz-21",
    descriptions:
      "4 Super efficient blades and Stainless Steel jars along With Polycarbonate Jar. ideal for larger fruits and veggies. The grinder features an overload protection switch that cuts off power supply to the appliance to prevent the motor from overheating.Operating noise levels between 80-90 dB; Initial burning smell is to be expected due to evaporating varnish.",
  },
  {
    id: 46,
    title: "Morphy Richards Icon Superb 750W",
    category: "appliance",
    brandName: "Morphy",
    keywords: [
      "Morphy Richards Icon Superb 750 W Mixer Grinder (4 Jars, Silver) Price",
      "Morphy Richards Icon Superb 750W",
      "Morphy Richards Mixer Grinder",
      "Mixer Grinder",
    ],

    tag: "Mixer Grinder",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/516-Jj5nFJL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Morphy-Richards-Icon-Superb-Grinder/dp/B08H673XKN/?tag=amprioz-21",
    descriptions:
      "The incher or pulse feature momentarily runs the blades gently to push the bulk of dry ingredients upwards and free the material stuck on the jar wall.This Mixer Grinder comes with 3 different jars, including a 1.5L liquidizing jar, 1L dry/wet grinding jar, and a 400ml chutney jar. This feature allows you to be carefree while using the mixer grinder as it prevents the motor from burning out or running when the jar is either overloaded or used continuously for a long time at one go.",
  },
  {
    id: 47,
    title: "Eureka Forbes Super Vac 1600W Bagless Vacuum Cleaner",
    category: "appliance",
    brandName: "Eureka Forbes",
    tag: "Vacuum Cleaner",
    specilaCategory: "rated",
    keywords: [
      "EUREKA FORBES Super Clean Dry Vacuum Cleaner Price",
      "EUREKA FORBES Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/51k9Necv-NL._SL1200_.jpg",
    productLink:
      "https://www.amazon.in/Eureka-Forbes-Powerful-Technology-GFCDSFSVL00000/dp/B09FZ89DK6?tag=amprioz-21",
    descriptions:
      "Sure from Forbes Super Vac is powered with best in class 1600 Watts motor delivering powerful suction of 21 KPA to tackle dirt, dust and dust mites. Cyclone System helps in increasing the air flow in the dust tank which in turn separates dust from the air. It also helps in maintaining powerful suction and performance of machine. Super Vac comes with range of accessories for different cleaning needs. It provides solution for efficient and hassle-free cleaning.",
  },
  {
    id: 48,
    title: "AGARO Ace 1600 Watts, 21.5 kPa Suction Power",
    category: "appliance",
    brandName: "AGARO",

    specilaCategory: "featured",
    keywords: [
      "AGARO ACE Wet &amp; Dry Vacuum Cleaner 1600W Wet &amp; Dry Vacuum Cleaner Price",
      "AGARO Ace 1600 Watts, 21.5 kPa Suction Power",
      "AGARO Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    tag: "Vacuum Cleaner",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/71Z8wOB-WTL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Ace-1600-Watt-21-Litre-Stainless-Function/dp/B07RCGTZ4M?tag=amprioz-21",
    descriptions:
      "AGARO Ace wet and dry vacuum cleaner is an all-purpose vacuum cleaner which provides strong suction power of 21.5 kPa and quickly removes both solid particles and liquid at ease. Its blower function easily unsettles the dust particles which helps to get them removed. The 360 degree swivel wheel allows you to conveniently move the unit anywhere you want. The jumbo sized 21 litres dirt collecting capacity lets you to clean large areas without the hassle of emptying the bin repeatedly.",
  },
  {
    id: 49,
    title: "AmazonBasics Vacuum Cleaner with Power Suction",
    category: "appliance",
    brandName: "AmazonBasics",
    specilaCategory: "featured",
    keywords: [
      "Amazonbasics Cylinder Bagless Vacuum Cleaner With Power Suction",
      "Low Sound, High Energy Efficiency And 2 Years Warranty (1.5l, Black)",
      "Amazonbasics Cylinder Bagless Vacuum Cleaner With Power Suction",
      "Low Sound, High Energy Efficiency And 2 Years Warranty (1.5l, Black) user review",
      "Amazonbasics Cylinder Bagless Vacuum Cleaner With Power Suction",
    ],
    tag: "Vacuum Cleaner",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/81jN--SwgEL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07H3X5GTL/?tag=amprioz-21",
    descriptions:
      "Compact and lightweight, this AmazonBasics Bagged Cylinder Vacuum Cleaner moves easily from room to room for thorough cleaning throughout the home. The cylinder vacuum cleaner provides 700 watts of power, smooth-rolling manoeuvrability, and a variety of useful attachments for detailed cleaning.With its sleek, contemporary design, the versatile cleaning machine works well for vacuuming stairs, hard floors, and upholstery and for navigating around furniture. Even more, the vacuum cleaner features an A energy rating for energy efficiency (and lower energy bills)—while still maintaining ultimate power and impressive performance.",
  },
  {
    id: 50,
    title: "AGARO Storm 2000 Watts Vacuum Cleaner",
    category: "appliance",
    brandName: "AGARO",
    specilaCategory: "ranked",
    keywords: [
      "AGARO STORM Vacuum Cleaner 2000W Dry Vacuum Cleaner Price",
      "AGARO Storm 2000 Watts Vacuum Cleaner",
      "AGARO Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    tag: "Vacuum Cleaner",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/71zBG+gNMjL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07RCGZFL9/?tag=amprioz-21",
    descriptions:
      "AGARO storm vacuum cleaner is meant for dry vacuuming and comes with 360 degree front swivel wheel, exhaust HEPA filtration, variable suction power, 5 meters long cord, 4 ltrs dust bag capacity, 2000 watts power.2000 Watts motor gives you a powerful suction pressure of 24 kPa. It quickly removes dirt from any kind of dry surface, leaving your interiors spotlessly clean.The HEPA filter traps the small air borne particles like dust, pollen and moulds. It acts as a dust barrier to prevent the motor from getting clogged, and also prevents any recirculation of the dust particles.",
  },
  {
    id: 51,
    title: "Philips PowerPro FC9352/01 Compact Bagless Vacuum Cleaner",
    category: "appliance",
    tag: "Vacuum Cleaner",
    brandName: "Philips",
    specilaCategory: "featured",
    keywords: [
      "PHILIPS FC9352/01 (883935201280) Bagless Dry Vacuum Cleaner with Powerful Suction,Turbo Brush Price",
      "Philips PowerPro FC9352/01 Compact Bagless Vacuum Cleaner",
      "Philips Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/41xQDop2T5L.jpg",
    productLink:
      "https://www.amazon.in/Philips-PowerPro-FC9352-01-Compact/dp/B072J83V9W?tag=amprioz-21",
    descriptions:
      "Philips PowerPro Compact bagless vacuum cleaner provides strong suction power for top cleaning results. Powerful cyclonic action and advanced design put dirt in its place and keep it there to control dust, even while emptying.Exclusive PowerCyclone 5 technology accelerates air in the cyclonic chamber to separate out dust. Powerful swirling action maximizes airflow and performance for remarkable cleaning results.",
  },
  {
    id: 52,
    title: "AGARO Twister 2200 Watts Dry Vacuum Cleaner",
    category: "appliance",
    tag: "Vacuum Cleaner",
    brandName: "AGARO",
    specilaCategory: "rated",
    keywords: [
      "AGARO TWISTER Dry Vacuum Cleaner Price",
      "AGARO Twister 2200 Watts Dry Vacuum Cleaner",
      "AGARO Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/71nyiKS0uiL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07SVMKKVP/?tag=amprioz-21",
    descriptions:
      "AGARO Twister vacuum cleaner is meant for dry vacuuming and comes with 360 degree front swivel wheel, exhaust HEPA filtration, variable suction power, 5 meters long cord, 6 Litres dust bag capacity, 2200 watts power.The power packed 2200 Watts motor produces 25 kPa of suction pressure which removes deeply embedded dirt from any kind dry surface.The dust bag, made of cloth material, can be cleaned, washed and reused multiple times. Moreover the 6 litres capacity facilitates cleaning large areas at one go.",
  },
  {
    id: 53,
    title: "KENT Force Cyclonic Vacuum Cleaner 2000-Watt",
    category: "appliance",
    tag: "Vacuum Cleaner",
    brandName: "KENT",
    specilaCategory: "featured",
    keywords: [
      "KENT KSL-160 Bagless Dry Vacuum Cleaner Price",
      "KENT Force Cyclonic Vacuum Cleaner 2000-Watt",
      "KENT Force Cyclonic Vacuum Cleaner",
      "KENT Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/717gNQWC7kL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B077467KFX/?tag=amprioz-21",
    descriptions:
      "Cleaning and keeping the house spot-free is a tedious and back-breaking job, but not with the Force Cyclonic Vacuum Cleaner. The bagless design eliminates any hassles with the filter bags and makes it convenient to use. Its amazing features like state-of-art cyclonic technology, advanced HEPA filter, and high power motor calls for efficient cleaning. The accessories like crevice tool, available with the vacuum cleaner, helps you clean various types of surfaces with ease.An energy-efficient vacuum cleaner for your house is designed with advanced cyclonic technology (meaning no filtration bags) for dust collection and HEPA filter for reducing air pollution effectively.",
  },
  {
    id: 54,
    title: "AmazonBasics Wet and Dry Vacuum Cleaner",
    category: "appliance",
    tag: "Vacuum Cleaner",
    brandName: "AmazonBasics",
    specilaCategory: "rated",
    keywords: [
      "Amazonbasics Cylinder Bagless Vacuum Cleaner With Power Suction",
      "AmazonBasics Wet and Dry Vacuum Cleaner",
      "Amazonbasics Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71cT7e-XNIL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B084HZPLD2/?tag=amprioz-21",
    descriptions:
      "The AmazonBasics 1400W 15L Wet and Dry Vacuum Cleaner has a powerful 1400 W suction with a total capacity of 15L and 3L for dust bag which can be used for both wet mopping and dry sweeping.The wet and dry vacuum cleaner consists of an ergonomic handle, lightweight plastic drum and wheels for easy movement, and it also offers the ease of cleaning with 360° rotation.You are provided with a variety of accessories such as two hard floor brushes, one furniture nozzle, one suction nozzle, one dry floor brush, one wet floor brush & 1 dusting brush for cleaning tiles, wooden floors, rugs, carpets, sofas, fabric upholstery and more. The blower function helps to clean in crevices, cracks, corners and other places that are hard to reach.",
  },
  {
    id: 55,
    title: "Forbes Eureka Trendy Steel Vacuum Cleaner",
    category: "appliance",
    tag: "Vacuum Cleaner",
    brandName: "Forbes",
    specilaCategory: "featured",
    keywords: [
      "EUREKA FORBES Trendy Steel Dry Vacuum Cleaner Price",
      "EUREKA FORBES Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/41oFvtuL1EL.jpg",
    productLink:
      "https://www.amazon.in/Eureka-Forbes-Trendy-Steel-1300-Watt/dp/B0095PCPPY/?tag=amprioz-21",
    descriptions:
      "The Eureka Forbes Trendy Steel vacuum cleaner is an excellent device to clean your home. Designed to meet your varied needs, it has a sleek design with a classic steel finish that makes it stand out wherever you keep it. Furthermore, the superior quality of its suction mechanism allows you to remove both solid particles and fluids with equal ease. This Eureka Forbes Trendy vacuum cleaner also comes equipped with a variable power control that helps you customize the power you need to clean different surfaces, helping you do an excellent job.The Eureka Forbes vacuum cleaner has a powerful 1300W motor that is able to draw in every last piece of dirt. With this vacuum cleaner, you can conveniently remove dirt, pet hairs, tough stains and more with complete ease. Operating at just 230V of power, the Eureka Forbes vacuum cleaner Trendy Steel provides impressive airflow rate and water-lift capability. Furthermore, you no more have to worry about the power cord getting tangled as this device comes with an automatic cord winder that allows you to easily store the cord.",
  },
  {
    id: 56,
    title: "Inalsa Vacuum Cleaner Wet and Dry Micro ",
    category: "appliance",
    tag: "Vacuum Cleaner",
    brandName: "Inalsa",
    specilaCategory: "featured",
    keywords: [
      "Inalsa Micro WD10 Wet &amp; Dry Vacuum Cleaner Price",
      "Inalsa Vacuum Cleaner Price",
      "Inalsa Vacuum Cleaner",
      "Vacuum Cleaner",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/61tMhX1c2KL._SL1200_.jpg",
    productLink: "https://www.amazon.in/dp/B07SLNG3LW/?tag=amprioz-21",
    descriptions:
      "No matter it is dust, hair, daily waste, the device can clean the dirt perfectly. You don't need to change filter during operation. Please take out the cloth dust filter and use sponge filter when sucking liquid.The blower function is suitable for drying and cleaning of narrow and hard- to-reach indoor or outdoor areas. For effective blowing results, do not use any filter in vacuum cleaner. To hold a large amount of waste, this vacuum cleaner boasts a container capacity of 10 litres.Equipped with powerful motor delivers 14KPA strong suction power and ensures long time operation. The vacuum cleaner has an impact resistant polymer tank for longer usage life.",
  },
  {
    id: 57,
    title: "boAt Xtend Smartwatch with Alexa Built-in",
    category: "electronics",
    brandName: "boAt",
    tag: "smartwatch",
    specilaCategory: "rated",
    keywords: [
      "boAt,Xtend,Smartwatch,with,Alexa",
      "Heart, &amp, SpO2, Monitoring",
      "14 Sports Modes",
      "Sleep Monitor",
      "5,ATM,Water,Resistance(Pitch,Black)",
      "boAt Xtend Smartwatch",
      "smartwatch",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61IMRs+o0iL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/boAt-Smartwatch-Multiple-Monitoring-Resistance/dp/B096VF5YYF/?tag=amprioz-21",
    descriptions:
      "1.69 inch big square colour LCD display with a round dial features complete capacitive touch experience to let you take control, effortlessly.Its Multiple watch faces with customizable options to match your OOTD, every day.The ambient light display allows automatic adjustment of brightness on the watch, suited to your environment.Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from weather forecasts to live cricket scores at your command.",
  },
  {
    id: 58,
    title:
      "Fire-Boltt Ninja Calling 1.69inch Full Touch Bluetooth Calling Smartwatch",
    category: "electronics",
    brandName: "Fire-Boltt",

    specilaCategory: "featured",
    keywords: [
      "Fire-Boltt Ninja Calling 1.69inch Full Touch Bluetooth Calling Smartwatch",
      "Fire-Boltt Ninja Smartwatch",
      "smartwatch",
    ],
    tag: "smartwatch",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61EeNT6uQPL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Fire-Boltt-Bluetooth-Smartwatch-Monitoring-Assistant/dp/B09RKFBCV7/?tag=amprioz-21",
    descriptions:
      "Fire-Boltt is India' No 1 Wearable Watch Brand Q122 by IDC Worldwide quarterly wearable device tracker Q122.【Bluetooth Calling Watch】- Fire-Boltt Ninja Calling enables you to make and receive calls directly from your watch via the built-in speaker and microphone. This smartwatch features a dial pad, option to access recent calls & sync your phone’s contacts. Track Real Time Heart Rate on the go. Whether you are exercising or you do not feel right, tracking is just a click away. Featuring Blood Oxygen Tracking (SpO2) with optical sensors that give almost acurate results.",
  },
  {
    id: 59,
    title: "Noise ColorFit Pulse Grand Smart Watch with 1.69inch HD Display",
    category: "electronics",
    brandName: "Noise",
    specilaCategory: "featured",
    keywords: [
      "Noise ColorFit Pulse Grand Smart Watch with 1.69inch HD Display",
      "Noise ColorFit Pulse Grand Smart Watch",
      "Noise ColorFit Smart Watch",
      "smartwatch",
    ],
    tag: "smartwatch",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/61pFWFaGy3L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Noise-ColorFit-Display-Monitoring-Smartwatches/dp/B09NVPSCQT/?tag=amprioz-21",
    descriptions:
      "1.69 inch grand display gives the rich immersive viewing experience on the 1.69 inch LCD display.Now enjoy more than a day’s worth/25 hours of battery in just 15 minutes of charge.Choose from 150+ cloud-based and customisable watch faces and change your look to suit your outfit.Noise Health suite provides valuable insights about your health from Noise Health.",
  },
  {
    id: 60,
    title: "Noise ColorFit Pulse Spo2 Smart Watch with 10 days battery life",
    category: "electronics",
    brandName: "Noise",
    specilaCategory: "featured",
    keywords: [
      "Noise ColorFit Pulse Spo2 Smart Watch with 10 days battery life",
      "Heart Rate Monitor Smart Band",
      "Sleep Monitoring Smart Watches",
      "Noise Smart Watch",
      "smartwatch",
    ],
    tag: "smartwatch",
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/61epn29QG0L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Noise-ColorFit-Smartwatch-Monitoring-Waterproof/dp/B097R25DP7/?tag=amprioz-21",
    descriptions:
      "Noise ColorFit Pulse Spo2 Smart Watch with a battery backup of 10 days, just concentrate on your game without stopping.Noise Health Suite option automatically monitor your blood oxygen (Spo2 levels), real-time heart rate, and provide comprehensive analysis of your sleep quality (deep sleep,light sleep and wake up time) on this smart watch.Get the perfect viewing experience on the 1.4 inch display with 240*240 pixels.",
  },
  {
    id: 61,
    title:
      "Fire-Boltt India's No 1 Smartwatch Brand Talk 2 Bluetooth Calling Smartwatch",
    category: "electronics",
    tag: "smartwatch",
    brandName: "Fire-Boltt",
    specilaCategory: "featured",
    keywords: [
      "Fire-Boltt Talk 2 Bluetooth Calling Smartwatch",
      "Fire-Boltt Bluetooth Calling Smartwatch",
      "Fire-Boltt Smartwatch",
      "smartwatch",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61mqaxj-iCL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Fire-Boltt-Bluetooth-Calling-Tracking-Assistance/dp/B09YV4MW2T/?tag=amprioz-21",
    descriptions:
      "Fire-Boltt Talk 2 enables you to make and receive calls directly from your watch via the built-in speaker and microphone. This smartwatch features a dial pad, option to access recent calls & sync your phone’s contacts.This smartwatch has dual buttons to carry out the tasks more efficiently and easily. Use the first button to change the menu style and to return to the first page, use the second button to quickly land to the exercise page.Command your mobile phone with your smartwatch, the watch has a voice assistant built in to make work easy and fast. Tap on the AI feature to activate the mobile phone voice assistant and make calls smoothly, or hear the weather update.",
  },
  {
    id: 62,
    title: "boAt Wave Lite Smartwatch with 1.69 Inches HD Display",
    category: "electronics",
    tag: "smartwatch",
    brandName: "boAt",
    specilaCategory: "rated",
    keywords: [
      "boAt Wave Lite Smartwatch",
      "Heart Rate & SpO2 Level Monitor",
      "Multiple Sports Modes & IP68",
      "boAt Smartwatch",
      "smartwatch",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/61ZM+qKT5UL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/boAt-Wave-Lite-Smartwatch-Activity/dp/B09V175NP7/?tag=amprioz-21",
    descriptions:
      "The 1.69 inch HD full touch display with 500 nits of brightness will bring every detail to life.The ultra slim and lightweight design of the watch is ideal to keep you surfing your wave all day.Monitor your heart rate and blood oxygen levels on-the-go with the heart rate and SpO2 monitor.Get sharper color resolution that brightens your virtual world exponentially.",
  },
  {
    id: 63,
    title:
      "ZEBRONICS ZEB-FIT920CH Smart Fitness Watch with Full Touch Color Display",
    category: "electronics",
    tag: "smartwatch",
    brandName: "ZEBRONICS",
    specilaCategory: "featured",
    keywords: [
      "ZEBRONICS ZEB-FIT920CH Smart Fitness Watch",
      "Smart Fitness Watch",
      "ZEBRONICS ZEB-FIT920CH Smart Fitness Watch with Full Touch Color Display",
      "ZEBRONICS smartwatch",
      "smartwatch",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/61LFFp21QBL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/ZEBRONICS-ZEB-FIT920CH-Customizable-Black-Rectangular/dp/B09NBR6T2B/7?tag=amprioz-21",
    descriptions:
      "A smart fitness band that comes with features like pedometer, heart rate monitor,SpO2,BP monitor, sleep monitor, sedentary reminder, alarm clock, IP67 water proof along with long battery life so you can focus more on your fitness.It tracks multiple sports like badminton, football, cycling, and basketball.The smartwatch comes with custom watch faces. It has about 100+ pre-built watch faces.ZEB-FIT 20 Series Smartphone App tracks your activities like steps, distance, calories, and sleep.",
  },
  {
    id: 64,
    title: "TAGG Verve NEO Smartwatch 1.69 inch HD Display",
    category: "electronics",
    tag: "smartwatch",
    brandName: "TAGG",
    specilaCategory: "rated",
    keywords: [
      "TAGG Verve NEO Smartwatch 1.69 inch HD Display",
      "TAGG Verve NEO Smartwatch",
      "TAGG Smartwatch",
      "Smartwatch",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/711nS-ErjkL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/TAGG-Verve-Smartwatch-Real-Time-Waterproof/dp/B09HHCJ2CK/?tag=amprioz-21",
    descriptions:
      "1.69 inch Ultra Large Display See the bigger and brighter picture with 500 NITS brightness. Polycarbonate Body can withstand the daily rough usage with ease.To keep a track of your health with daily progress reports. Keep track of your sleep with sleep tracking. Female Menstrual cycle tracking helps you to be stress-free always.Never miss out on any message or call or any other important notifications. 500 NITS Brightness, 240x280 Pixels.",
  },
  {
    id: 65,
    title:
      "Fire-Boltt India's No 1 Smartwatch Brand Ninja 2 Max 1.5 inch Full Touch Display",
    category: "electronics",
    tag: "smartwatch",
    brandName: "Fire-Boltt",
    specilaCategory: "featured",
    keywords: [
      "Fire-Boltt India's No 1 Smartwatch Brand Ninja 2 Max",
      "Fire-Boltt Smartwatch Brand Ninja 2 Max",
      "Fire-Boltt Smartwatch",
      "Smartwatch",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61ls76+JYPL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Fire-Boltt-Smartwatch-HeartRate-SportsMode-Resistance/dp/B09PRHNTTT/?tag=amprioz-21",
    descriptions:
      "Fire-Boltt Ninja 2 Max comes with a special 1.5inch Full Touch Screen that supports complete capacitive and responsive touch interface for effortless control. With over 200+ Cloud Watch Faces, flaunt your style at each occasion.Fire-Boltt Ninja 2 Max Smartwatch has real time 24*7 SPO2 / Blood Oxygen tracking, Dynamic Heart Rate Monitoring.The smartwatch supports tracking of 20 sports, not even a single sweat gone waste.With the increase of the necessity of good health, Fire-Boltt Ninja 2 Max brigs to users the benefit of daily breathing exercise through the watch. Watch your oxygen levels and keep your breath at sanity.",
  },
  {
    id: 66,
    title: "boAt Blaze Smart Watch with 1.75 inch HD Display",
    category: "electronics",
    tag: "smartwatch",
    brandName: "boAt",
    specilaCategory: "featured",
    keywords: [
      "boAt Blaze Smart Watch with 1.75inch HD Display",
      "Heart Rate & SpO2 Monitor",
      "boAt Blaze Smart Watch",
      "SmartWatch",
    ],
    rating: 3.8,
    imageLink: "https://m.media-amazon.com/images/I/61Go9-1iPyL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/boAt-Blaze-Watch-Display-Processor/dp/B09RMK9Z9H/?tag=amprioz-21",
    descriptions:
      "1.75 inches HD display with a square dial features a complete capacitive touch experience to let you take control effortlessly.With its high end and accurate apollo 3 blue plus processor the transitions and UI will be smoother and better than anything you have ever experienced.The fast charging watch lets you take complete control of your time.The watch comes with a 24x7 heart rate & SpO2 monitor (blood oxygen levels,) and sleep monitoring features to keep a tab on your overall health.",
  },
  {
    id: 67,
    title: "Sony WH-CH510 Bluetooth Wireless On Ear Headphones",
    category: "electronics",
    brandName: "Sony",
    tag: "headphones",
    specilaCategory: "rated",
    keywords: [
      "SONY WH-CH510 Google Assistant enabled Bluetooth Headset Price",
      "Buy SONY WH-CH510 Google Assistant enabled Bluetooth Headset Online",
      "SONY WH-CH510 Google Assistant enabled Bluetooth Headset Features",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/41i+KMBAFjL.jpg",
    productLink:
      "https://www.amazon.in/SONY-WH-CH510-Wireless-Headphone-Blue/dp/B0817T8FB6/?tag=amprioz-21",
    descriptions:
      "Listen to your favourite tracks wirelessly with a Bluetooth wireless technology by pairing your smartphone or tablet. Conversation flows freely with easy hands-free calling, thanks to the built-in microphone. No need to even take your phone from your pocket. Not only are the WH-CH510 headphones compact and lightweight, but the swivel earcups make it easy to safely pack them away in your bag.",
  },
  {
    id: 68,
    title: "JBL Tune 510BT, On Ear Wireless Headphones with Mic",
    category: "electronics",
    brandName: "JBL",

    specilaCategory: "featured",
    keywords: [
      "JBL Tune 510BT On The Ear Bluetooth Headphones with Mic (Black)",
      "JBL Tune 510BT, On Ear Wireless Headphones with Mic",
      "JBL headphones",
    ],
    tag: "headphones",
    rating: 4.8,
    imageLink: "https://m.media-amazon.com/images/I/61kFL7ywsZS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/JBL-Wireless-Headphones-Bluetooth-Assistant/dp/B08QTYYNDN/?tag=amprioz-21",
    descriptions:
      "Packed with 32mm Dynamic drivers, the JBL Tune 510BT features the renowned JBL Pure Bass sound, which can be found in the most famous venues all around the world.he Multi Point Connectivity (Dual Pairing) on Tune 510BT allows youto effortlessly switch between 2 Bluetooth devices. Its a smooth transition when you have to receive a call on your mobile phone call while you are watching a video on your tab.The button controls on the earcup of Tune 510BT, allows you to easily control your sound and manage your calls from your headphones.",
  },
  {
    id: 69,
    title: "Sony WH-CH710N Active Noise Cancelling Wireless Headphones",
    category: "electronics",
    brandName: "Sony",
    specilaCategory: "featured",
    keywords: [
      "SONY WH-CH710N Active noise cancellation enabled Bluetooth Headset Price",
      "Buy SONY WH-CH710N Active noise cancellation enabled Bluetooth Headset Online",
      "SONY WH-CH710N Active noise cancellation enabled Bluetooth Headset Features",
    ],
    tag: "headphones",
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/61N6Ai7RkJL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Sony-WH-CH710N-Cancelling-Wireless-Headphones/dp/B0872G7SL9/?tag=amprioz-21",
    descriptions:
      "Conversation flows freely with easy, hands-free calling. Leave your phone where it is, just speak. WH-CH710N delivers clearer voice quality to the other person on the phone.Ambient Sound mode puts you in complete control of your listening experience. Switch it on, and you'll be able to listen to your music, while still hearing the essential everyday sounds that keep you safe, like traffic noise and transport announcements.",
  },
  {
    id: 70,
    title:
      "JBL Tune 760NC, Over Ear Active Noise Cancellation Headphones with Mic",
    category: "electronics",
    brandName: "JBL",
    specilaCategory: "featured",
    keywords: [
      "JBL Tune 760NC Active Noise Cancelling",
      "50 Hr Playtime, Fast Pair &amp; Multi Connect Bluetooth Headset Price",
      "Buy JBL Tune 760NC Active Noise Cancelling",
      "50 Hr Playtime, Fast Pair &amp; Multi Connect Bluetooth Headset Online",
      "JBL Tune 760NC Active Noise Cancelling",
      "50 Hr Playtime, Fast Pair &amp; Multi Connect Bluetooth Headset Features",
    ],
    tag: "headphones",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/61HXCeozUjL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/JBL-Cancellation-Headphones-Playtime-Assistant/dp/B096FYLJ6M/?tag=amprioz-21",
    descriptions:
      "For over 70 years, JBL has engineered the precise, impressive sound found in big venues around the world. These Bluetooth headphones reproduce that same JBL sound, punching out bass that’s both deep and powerful.Tune out the Noise & Tune in to your Music with JBL Tune 760NC wireless headset.Listen wirelessly for up to 35 hours with Active Noise Cancellation or up to 50 hours without Noise Cancellation for long-lasting fun. Recharge the battery quickly in 2 hours or enjoy endlessly in wired mode using the detachable AUX cable provided.",
  },
  {
    id: 71,
    title: "JBL T460BT by Harman, Wireless On Ear Headphones with Mic",
    category: "electronics",
    tag: "headphones",
    brandName: "JBL",
    specilaCategory: "featured",
    keywords: [
      "JBL T460BT Bluetooth Headset Price",
      "Buy JBL T460BT Bluetooth Headset Online",
      "JBL T460BT Bluetooth Headset Features",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/51LR5-2F4jS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/JBL-T460BT-Extra-Wireless-Headphones/dp/B07MKRJY53/?tag=amprioz-21",
    descriptions:
      "For over 70 years, JBL has engineered the precise, impressive sound found in big venues around the world. These headphones reproduce that same JBL sound, punching out bass that’s both deep and powerful.Lightweight materials make these headphones comfortable over your ears, and a rugged construction ensures these fold and unfold perfectly over the years.Control music playback and answer calls on the fly with buttons and microphone placed conveniently on the earcup.",
  },
  {
    id: 72,
    title: "boAt Rockerz 450 Bluetooth Wireless On Ear Headphones with Mic",
    category: "electronics",
    tag: "headphones",
    brandName: "boAt",
    specilaCategory: "rated",
    keywords: [
      "boAt Rockerz 450 with Upto 15 Hours Playback Bluetooth Headset Price",
      "Buy boAt Rockerz 450 with Upto 15 Hours Playback Bluetooth Headset Online",
      "boAt Rockerz 450 with Upto 15 Hours Playback Bluetooth Headset Features",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/51xxA+6E+xL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Rockerz-450-Wireless-Bluetooth-Headphone/dp/B07PR1CL3S/?tag=amprioz-21",
    descriptions:
      "The headphones offer a nonstop playback of up to 15 hours on a single charge.Equipped with 40mm drivers, this beauty is here to deliver immersive audio time.The earcups are adaptive and foldable for a seamless usage experience and portability.The ergonomically designed headset is also pretty light in weight.You can control your music without hiccups using the easy access controls, communicate seamlessly using the built-in mic, access voice assistant and always stay in the zone.",
  },
  {
    id: 73,
    title:
      "Zebronics Zeb-Duke1 Bluetooth Wireless Over Ear Headphones with Mic",
    category: "electronics",
    tag: "headphones",
    brandName: "ZEBRONICS",
    specilaCategory: "featured",
    keywords: [
      "ZEBRONICS Zeb Duke 1 Wireless Headphone with Mic Bluetooth Headset Price",
      "Buy ZEBRONICS Zeb Duke 1 Wireless Headphone with Mic Bluetooth Headset Online",
      "ZEBRONICS Zeb Duke 1 Wireless Headphone with Mic Bluetooth Headset Features",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/61zXAtHWaCL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B09JK8D2Y8/?tag=amprioz-21",
    descriptions:
      "ZEB-DUKE1 Comes with Bluetooth v5.0+EDR & AUX cable.40mm drivers and 20Hz-20kHz wide frequency response to produce deep bass and crisp Treble.Charge for 2 Hours and get battery backup of up to 34Hr* (at 50% volume).Voice assistant support with Android and iOS smartphones.Dual pairing function, to enable connection with smart phones and Laptops / Tablets simultaneously.Media and Volume control buttons for ease of use.",
  },
  {
    id: 74,
    title: "OneOdio Over Ear Headphone Studio Wired Bass Headsets",
    category: "electronics",
    tag: "headphones",
    brandName: "OneOdio",
    specilaCategory: "rated",
    keywords: [
      "Oneodio Over Ear Headphone",
      "Wired Bass Headsets with 50mm Driver Wired Headset Price",
      "Buy Oneodio Over Ear Headphone",
      "Wired Bass Headsets with 50mm Driver Wired Headset Online",
      "Oneodio Over Ear Headphone",
      "Wired Bass Headsets with 50mm Driver Wired Headset Features",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71oczOKCdfL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07R2BNDMH/?tag=amprioz-21",
    descriptions:
      "The Hi-Res logo is a of exceptional audio quality, awarded only to audio devices capable of expressing the most excellent art. Unlike ordinary headphones, Pro 10 deliver sound up to 20 KHz for the richest, most nuanced listening experience. Large, 50 millimeter speaker unit drivers combined with neodymium magnets; powerful bass, clear vocal, and crisp high tones form stereo sound.The soft padded ear cushions are designed for monitor headphones comfort and noise isolation. The headband is adjustable and stretchable for you to find the desired angle you like to fit in.",
  },
  {
    id: 75,
    title: "Boat Rockerz 425 Bluetooth Wireless Over Ear Headphones with Mic",
    category: "electronics",
    tag: "headphones",
    brandName: "Boat",
    specilaCategory: "featured",
    keywords: [
      "boAt Rockerz 425 Wireless Headphones",
      "boAt Rockerz 425 Wireless Headphones Price in India",
      "boAt Rockerz 425 Wireless Headphones Price",
      "boAt Rockerz 425 Wireless Headphones Specs",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/61XvYOrqVeL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Rockerz-425-Bluetooth-Headphones-Signature/dp/B09QL3NQHX/?tag=amprioz-21",
    descriptions:
      "It’s time to up the game and switch to Rockerz 425 wireless headphones that comes equipped with our low latency BEAST Mode, the perfect set up for your gaming sessions.Noise Cancelling Calls-With the default ENx tech meant for environmental noise cancellation, you can be heard crystal clear across voice calls.The boAt Signature Sound shines through no matter which genre you play, via the 40mm audio drivers.- You can control playback, pick up calls and command your default voice assistant with ease courtesy the easy-access controls and built-in mic.",
  },
  {
    id: 76,
    title: "Zebronics Zeb-Blitz USB Gaming Wired Over Ear Headphones",
    category: "electronics",
    tag: "headphones",
    brandName: "Zebronics",
    specilaCategory: "featured",
    keywords: [
      "ZEBRONICS Zeb-Blitz Wired Gaming Headset Price",
      "Buy ZEBRONICS Zeb-Blitz Wired Gaming Headset Online",
      "ZEBRONICS Zeb-Blitz Wired Gaming Headset Features",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61Wyl9B6XxL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B09P1LHNTH/?tag=amprioz-21",
    descriptions:
      "Experience DOLBY ATMOS with ZEB-BLITZ USB gaming headphones. Add more detail to the audio and get immersed into the game, like never before.Advanced Windows software, to give you the simulated 7.1 surround sound and much more interesting features like preset audio effects, mic monitor, sensitivity adjustment, voice changer, equalizer setup and profile save options.50mm Neodymium magnet drivers, for the best bass and proper reciprocation of treble. Never miss out on details, let it be movies or games.",
  },
  {
    id: 77,
    title: "Cosmic Byte Headphones",
    category: "electronics",
    tag: "headphones",
    brandName: "CosmicByte",
    specilaCategory: "featured",
    keywords: [
      "Cosmic Byte GS430 Gaming wired over ear Headphone",
      "Cosmic Byte GS410 Wired Over-ear Headphones with Mic and for PS5",
      "Cosmic Byte Titania RGB Gaming Headset with Flexible Microphone",
      "Cosmic Byte Stardust Headset with Flexible Mic for PS4",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/7154EWu-5LL._SL1500_.jpg",
    productLink: "https://amzn.to/3tG5hkO",
    descriptions:
      "IMMERSIVE 3D GAMING SOUND & EXHILARATING GAMEPLAY \n FLEXIBLE NOISE CANCELLATION BOOM MIC:\n MULTI-PLATFORM COMPATIBILITY & PLUG AND PLAY: With 3. 5mm jack, compatible with PS4 Xbox One (Older version of Xbox One needs an extra Microsoft adapter, not included), Xbox One S/X, PC, Laptop, Mac, iPad, Tablets, Mobile phone. The USB is to power the RGB led light \n ",
  },
  {
    id: 78,
    title: "IFB 30 L Convection Microwave Oven",
    category: "appliance",
    brandName: "IFB",
    tag: "microwave",
    specilaCategory: "rated",
    keywords: [
      "IFB 30 L Convection Microwave Oven",
      "IFB Microwave Oven",
      "Microwave Oven",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/71hqJL+egKL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B016XZP3DY/?tag=amprioz-21",
    descriptions:
      "IFB 30 L Convection Microwave Oven suitable for large families.you can Make complex Indian dishes in minutes with the touch of a button.Free your microwave from grease in a matter of minutes using steam.Keeps you informed about cooking time, power level and time at every stage of use.Essential if you have kids! This safety lock prevents microwave accidents when you've got children around.",
  },
  {
    id: 79,
    title: "Samsung 28 L Convection Microwave Oven",
    category: "appliance",
    brandName: "Samsung",

    specilaCategory: "featured",
    keywords: [
      "Samsung 28 LTR CE1041DSB2 Convection Microwave Black Price in India",
      "Samsung 28 LTR CE1041DSB2 Convection Microwave Black Specifications",
      "Samsung 28 LTR CE1041DSB2 Convection Microwave Black Reviews",
      "Samsung 28 L Convection Microwave Oven",
      "Microwave Oven",
    ],
    tag: "microwave",
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/81jv5L8wWAL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Convection-Microwave-CE1041DSB3-TL/dp/B09VH1Q7CM/?tag=amprioz-21",
    descriptions:
      "Enjoy fried food without a deep fryer with the Smart Oven. SLIM FRY technology uses hot air circulation for food that is crispy outside and flavorfully moist inside with only a touch of oil. Generated through microwave, grill and convection heaters, now you can avoid greasy pans and hot oil splatters, and enjoy food cooked with less fat.Ever wonder just how long you should microwave something? Tired of food coming out too hot or still cold? Auto Cook takes the thought out of it. Just tell your microwave what you're cooking (and how much) and it's already preprogrammed for what settings to use.",
  },
  {
    id: 80,
    title: "IFB 23 L Convection Microwave Oven",
    category: "appliance",
    brandName: "IFB",
    specilaCategory: "featured",
    keywords: [
      "IFB 23 litre 23BC4 Convection Microwave Oven Black Price in India",
      "IFB 23 litre 23BC4 Convection Microwave Oven Black Specifications",
      "IFB 23 litre 23BC4 Convection Microwave Oven Black Reviews",
      "IFB 23 L Convection Microwave Oven",
      "Microwave Oven",
    ],
    tag: "microwave",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/8144NPAnWmL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B00OLHQJD0/?tag=amprioz-21",
    descriptions:
      "IFB 23 L Convection Microwave Oven suitable for large families.you can Make complex Indian dishes in minutes with the touch of a button.Free your microwave from grease in a matter of minutes using steam.Keeps you informed about cooking time, power level and time at every stage of use.Essential if you have kids! This safety lock prevents microwave accidents when you've got children around.",
  },
  {
    id: 81,
    title: "Panasonic 27L Convection Microwave Oven",
    category: "appliance",
    brandName: "Panasonic",
    specilaCategory: "featured",
    keywords: [
      "Panasonic 27 Litres NN CT645BFDG Convection Microwave Oven (Black) Price in India",
      "Panasonic 27 Litres NN CT645BFDG Convection Microwave Oven (Black) Specifications",
      "Panasonic 27 Litres NN CT645BFDG Convection Microwave Oven (Black) Reviews",
      "Panasonic 27L Convection Microwave Oven",
      "Microwave Oven",
    ],
    tag: "microwave",
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/61Deo+Kg6WL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Panasonic-Convection-Microwave-NN-CT645BFDG-Black/dp/B016D4KCA4/?tag=amprioz-21",
    descriptions:
      "Healthy recipes, lip-smacking starters, delicious main courses and mouth-watering desserts - cook it, grill it, bake it and serve it in minutes with this feature loaded convection microwave oven from Panasonic.The dual power of the top and back grills work together to make food crispier on the outside and deliciously, juicy on the inside.Quick and even defrosting thaws the food without compromising on the flavour or texture.Small, compact design fits into smaller spaces in kitchens and on countertops.",
  },
  {
    id: 82,
    title: "LG 28 L Convection Microwave Oven",
    category: "appliance",
    tag: "microwave",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 28 litre MC2846SL Convection Microwave Oven Silver Price in India",
      "LG 28 litre MC2846SL Convection Microwave Oven Silver Specifications",
      "LG 28 litre MC2846SL Convection Microwave Oven Silver Reviews",
      "LG 28 L Convection Microwave Oven",
      "Microwave Oven",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71iB3v552hL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Convection-Microwave-MC2846SL-Silver/dp/B01JEKO1WY/?tag=amprioz-21",
    descriptions:
      "With LG all in one microwave oven,now you can prepare delicious paneer and curd at home.Auto cook menu comes with preset menu. Just select the dish you want to cook, press ‘start’ and get something new. *Number of menu will vary model to model.The concealed heater eliminates the risk of any form of hand injury. Faster but also safer to use, it gives you an advantage of making large portions in one go.Stainless steel cavity enables better reflection and the uniform heating ensures a better and even cooking.",
  },
  {
    id: 83,
    title: "Panasonic 23L Convection Microwave Oven",
    category: "appliance",
    tag: "microwave",
    brandName: "Panasonic",
    specilaCategory: "rated",
    keywords: [
      "Panasonic 23 Litres NN CT353BFDG Convection Microwave Oven (Black Mirror) Price in India",
      "Panasonic 23 Litres NN CT353BFDG Convection Microwave Oven (Black Mirror) Specifications",
      "Panasonic 23 Litres NN CT353BFDG Convection Microwave Oven (Black Mirror) Reviews",
      "Panasonic 23L Convection Microwave Oven",
      "Microwave Oven",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/71QX7BQQsOL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B00KPSSPZY/?tag=amprioz-21",
    descriptions:
      "Healthy recipes, lip-smacking starters, delicious main courses and mouth-watering desserts - cook it, grill it, bake it and serve it in minutes with this feature loaded convection microwave oven from Panasonic.Auto programmed reheat and defrost modes ensure even heating or defrosting of food without compromising on the textures of flavour.The dual power of the top and back grills work together to make food crispier on the outside and deliciously, juicy on the inside.Smart, space-saving, compact design fits comfortably in small kitchen spaces and countertops.",
  },
  {
    id: 84,
    title: "LG 32 L Convection Microwave Oven",
    category: "appliance",
    tag: "microwave",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 32 litre MC3286BRUM Convection Microwave Oven Price in India",
      "LG 32 litre MC3286BRUM Convection Microwave Oven Specifications",
      "LG 32 litre MC3286BRUM Convection Microwave Oven Reviews",
      "LG 32 L Convection Microwave Oven",
      "Microwave Oven",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/91fSgD70z0L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Convection-Microwave-MC3286BRUM-Black/dp/B01LWE0QJK/?tag=amprioz-21",
    descriptions:
      "The All-in-One-Microwave Oven lets you churn out soft and delicious Paneer as well as perfectly blended & hygienic Curd sans the chemicals or enzymes! Now indulge in healthy habits at home.A specially coated, non-stick tray for dishes that require extra browning and bottom heating.Enjoy your favorite paneer tikka ,chicken tikka at your home with 360° Motorised Rotisserie that gives you crispy and tasty bar-be-que recipes at a touch of a button without any manual intervention for uniform and even roasting.",
  },
  {
    id: 85,
    title: "Samsung 32 L Convection Microwave Oven ",
    category: "appliance",
    tag: "microwave",
    brandName: "Samsung",
    specilaCategory: "rated",
    keywords: [
      "Samsung MC32J7035CT/TL 32L Convection Microwave Oven (Black/Grey)",
      "Samsung 32 L Convection Microwave Oven ",
      "Samsung Microwave Oven ",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/71K6EvHzpnL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Convection-Microwave-MC32J7035CT-TL/dp/B07ML2W5B8/?tag=amprioz-21",
    descriptions:
      "Samsung 32 L Convection Microwave Oven suitable for large families.Indian recipes for quick prepration, Easy cleaning of interiors with Ceramic Enamel cavity.Can be used for baking along with grilling, reheating, defrosting and cooking.SLIM FRY technology combines a grill with warm air circulation.Choose from a wide variety of pre-programmed local recipes with the simple touch of a button.Unlike conventional grills, a Wide Grill lets you to cook food more evenly.",
  },
  {
    id: 86,
    title: "LG 28 L Charcoal Convection Microwave Oven",
    category: "appliance",
    tag: "microwave",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "Lg 28 Litres MJ2886BFUM Convection Microwave Oven (Black) Price in India",
      "Lg 28 Litres MJ2886BFUM Convection Microwave Oven (Black) Specifications",
      "Lg 28 Litres MJ2886BFUM Convection Microwave Oven (Black) Reviews",
      "LG 28 L Charcoal Convection Microwave Oven",
      "Microwave Oven",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/61B4gL3g+qL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Convection-Microwave-MJ2886BFUM-Black/dp/B076D7JQ4N/?tag=amprioz-21",
    descriptions:
      "LG 28 L Charcoal Convection Microwave Oven maintains natural flavours, keeps the food crunchy outside and juicy inside. So save time and and energy.Diet fry in LG microwave ovens lets you prepare dishes with minimal oil usage.Get rid of all hassles of the conventional milk boiling process, with 'pasteurize milk' feature that retains nutrition and removes bacteria.Easy and hassle free bar-be-queing at home with 360° degree motorized rotessorie for crispy and tasty recipies in one touch.",
  },
  {
    id: 87,
    title: "Samsung 21 L Convection Microwave Oven",
    category: "appliance",
    tag: "microwave",
    brandName: "Samsung",
    specilaCategory: "featured",
    keywords: [
      "Samsung 21 litre CE73JD B/XTL Convection Microwave Oven Black Price in India",
      "Samsung 21 litre CE73JD B/XTL Convection Microwave Oven Black Specifications",
      "Samsung 21 litre CE73JD B/XTL Convection Microwave Oven Black Reviews",
      "Samsung 21 L Convection Microwave Oven",
      "Microwave Oven",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/81dVXJDcKAL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Convection-Microwave-CE73JD-B1-XTL/dp/B09XBLS8XY/?tag=amprioz-21",
    descriptions:
      "It cooks every part of the food evenly with an Aperture Antenna as well as two additional slot antennas so that waves can be more widely distributed.The Innovative Ceramic Enamel Cavity is designed to give you a holistic cooking experience. It is durable, easy to clean, hygienic and 99.9% bacteria free.Your microwave is fitted with special Child Safety programme, which enables the oven to be locked so that children or anyone unfamiliar with it cannot operate it accidentally. The oven can be locked at anytime.",
  },
  {
    id: 88,
    title:
      "Nikon Z50 Mirrorless Camera Body with Z DX 16-50mm f/3.5-6.3 VR Lens",
    category: "electronics",
    brandName: "Nikon",
    tag: "digital camera",
    specilaCategory: "rated",
    keywords: [
      "NIKON Z 50 Mirrorless Camera Body with 16-50mm Lens Price",
      "NIKON Z 50 Mirrorless Camera Body with 16-50mm Lens",
      "Buy NIKON Z 50 Mirrorless Camera Body with 16-50mm Lens online",
      "Nikon Z50 Mirrorless Camera Body",
      "Nikon digital camera",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/61KWy+scEXL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/Nikon-Z50-Mirrorless-16-50mm-3-5-6-3/dp/B084PRG1FY/?tag=amprioz-21",
    descriptions:
      "Nikon Z 50 includes 20 high quality Creative Picture Controls and 10 Special Effects, all of which can be previewed in real-time and applied to both photos and videos.Z 50 delivers the award-winning performance of Z series in the smallest interchangeable lens DX-format camera ever.When shooting photos, Z 50’s outstanding autofocus system can lock onto your subject’s eye and keep focus there, even when they’re in motion, for beautifully focused portraits.",
  },
  {
    id: 89,
    title: "Canon EOS M50 Mark",
    category: "electronics",
    brandName: "Canon",
    specilaCategory: "featured",
    keywords: [
      "Canon EOS M50 Mark II Mirrorless Camera EF-M15-45mm is STM Lens Price",
      "Canon EOS M50 Mark II Mirrorless Camera EF-M15-45mm is STM Lens",
      "Buy Canon EOS M50 Mark II Mirrorless Camera EF-M15-45mm is STM Lens online",
      "Canon digital camera",
    ],
    tag: "digital camera",
    rating: 4.7,
    imageLink: "https://m.media-amazon.com/images/I/71woYLtQs4L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Canon-Mark-EF-M-15-45mm-Black/dp/B08KSKV35C/?tag=amprioz-21",
    descriptions:
      "Lightweight and stylish, the EOS M50 Mark II is fun and easy to use with Wi-Fi connectivity for content creators to stay close to their audience at all times.Keep your social media feeds lit with high-quality visual storytelling in 4K and accurate eye and face detection autofocusing.With an approximately 24.1-megapixel CMOS sensor, the EOS M50 Mark II captures fine details and life-like colors better than your typical smartphone.",
  },
  {
    id: 90,
    title: "Panasonic Lumix FZ80 4K Digital Camera",
    category: "electronics",
    brandName: "Panasonic",
    specilaCategory: "featured",
    keywords: [
      "Panasonic Lumix FZ80 4K Digital Camera",
      "Buy Panasonic Lumix FZ80 4K Digital Camera",
      "Panasonic Digital Camera",
      "Digital Camera",
    ],
    tag: "digital camera",
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/917LEZ+it3L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/PANASONIC-Camera-Megapixels-20-1200mm-F2-8-5-9/dp/B01MS16V42/?tag=amprioz-21",
    descriptions:
      "Perfect for travelers, the Panasonic Lumix Digital Camera DC FZ80K brings the legendary optical performance of a super long 60X (20 1200mm) DC Vario lens with amazingly stable O.I.S. (Optical Image Stabilizer) to a highly portable point and shoot travel camera. With a powerful 18.1 megapixel MOS sensor, the Lumix DC VARIO camera lens features a super bright F2.8 5.9 aperture range that is optically stabilized with Panasonic’s Power O.I.S. technology to add striking depth of field dimension to your photographs even in low light conditions. The high resolution power of 4K video technology captures up to 4X the resolution (3840 x 2160) of standard Full HD, enabling you to create printable photos with 4K photo's blistering 30 frames per second burst feature.",
  },
  {
    id: 91,
    title: "Canon EOS 200D",
    category: "electronics",
    brandName: "Canon",
    specilaCategory: "featured",
    keywords: [
      "Canon EOS 200D II DSLR Camera EF-S18-55mm IS STM Price",
      "Canon EOS 200D II DSLR Camera EF-S18-55mm IS STM",
      "Buy Canon EOS 200D II DSLR Camera EF-S18-55mm IS STM online",
    ],
    tag: "digital camera",
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/81cEKnH692L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Canon-EOS-200D-II-Digital/dp/B07RJWB548/?tag=amprioz-21",
    descriptions:
      "The EOS 200D II comes with enhanced Dual Pixel CMOS AF performance and functionality during live view shooting. It includes Eye Detection AF during Servo AF and Movie Servo AF adding convenience while shooting human subjects and up to 3,975 AF points positions to choose from for AF point manual selection.The Creative Assist feature makes it easy even for beginners to change the look of their photos. Select the effect that you want, choose how much effect you want to apply, and the camera will choose the correct settings. It works in real-time, which means that you can check details like the degree of background blur (bokeh), brightness and color vividness on the rear LCD monitor before you press the shutter button.",
  },
  {
    id: 92,
    title: "Kodak PIXPRO Astro Zoom AZ421 16 MP Digital Camera",
    category: "electronics",
    tag: "digital camera",
    brandName: "Kodak",
    specilaCategory: "featured",
    keywords: [
      "Kodak PIXPRO Astro Zoom AZ421 16 MP Digital Camera",
      "Buy Kodak PIXPRO Astro Zoom AZ421 16 MP Digital Camera",
      "Kodak Digital Camera",
      "Digital Camera",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/81ZZVdIJqoS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Kodak-AZ421-Digital-Opitcal-White/dp/B00M8C7U8I/?tag=amprioz-21",
    descriptions:
      "Passion and performance go hand in hand when with our KODAK PIXPRO AZ421 digital camera from the Astro Zoom Collection. A 42x ultra long zoom lens with optical image stabilization delivers crisp, clear 16 megapixel close-ups, panorama or HD videos. Auto scene, object tracking, post-editing features and a host of powerful yet user-friendly settings make photography easy, fun and zero hassle. KODAK PIXPRO Digital Cameras. ",
  },
  {
    id: 93,
    title: "Canon Power Shot G7X Mark II",
    category: "electronics",
    tag: "digital camera",
    brandName: "Canon",
    specilaCategory: "rated",
    keywords: [
      "Canon PowerShot G7 X Mark ii Price",
      "Canon PowerShot G7 X Mark ii",
      "Buy Canon PowerShot G7 X Mark ii online",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/71ef4iykr5L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/CANON-Power-Digital-20-1-Megapixel-Camera/dp/B01BV14OXA/?tag=amprioz-21",
    descriptions:
      "Stay inspired with the PowerShot G7 X Mark II camera, which combines exhilarating speed and performance with a smart, compact design. It can be ideal for travel, events and anywhere on the go, or as a secondary camera, and features a high-performance 1.0-inch 20.1 Megapixel CMOS sensor as well as a bright f/1.8–2.8 lens to help capture impressive images whenever your creativity sparks, even in low light conditions. The new DIGIC 7 Image Processor immediately pays dividends with improved auto focus tracking, enhanced IS and superb image clarity even when shooting with a high ISO.",
  },
  {
    id: 94,
    title: "Olympus TG – 6 Red Water Proof Camera",
    category: "electronics",
    tag: "digital camera",
    brandName: "Olympus",
    specilaCategory: "featured",
    keywords: [
      "OLYMPUS TG TG-6 Price",
      "OLYMPUS TG TG-6",
      "Buy OLYMPUS TG TG-6 online",
      "Olympus TG – 6 Red Water Proof Camera",
      "Olympus digital Camera",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/71OHoLBK8HL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Olympus-TG-Water-Camera-Screen/dp/B07SLXZCBW/?tag=amprioz-21",
    descriptions:
      "The TG-6 is your ideal partner for capturing every moment of your journey, regardless of the conditions. The latest flagship Tough camera is your reliable companion when it comes to high-quality photos and videos in extreme moments. Take it up a rocky mountain trail one day, scuba diving the next and get spectacular shots every step of the way. Packed with unmatched combinations of features, Tough cameras capture the smallest detail and brightest colours of every exciting moment. The TG-6, available in red or black, is the camera you’ll always want to have with you.",
  },
  {
    id: 95,
    title: "Sony Digital Vlog Camera ZV-1",
    category: "electronics",
    tag: "digital camera",
    brandName: "Sony",
    specilaCategory: "rated",
    keywords: [
      "SONY ZV-E10L Mirrorless Camera Interchangeable-lens Vlog Camera Price",
      "SONY ZV-E10L Mirrorless Camera Interchangeable-lens Vlog Camera",
      "Buy SONY ZV-E10L Mirrorless Camera Interchangeable-lens Vlog Camera online",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/71V-xgyy+3L._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B08JVPJXMT/?tag=amprioz-21",
    descriptions:
      "With AI technology, you can rely on the autofocus sticking firmly to your face and eyes without wandering to other subjects during vlogging. In addition, when the camera recognizes eyes, faces, or subjects, a frame is displayed on each target so you can make sure that the subject is in focus and shoot without worrying.Sony's enhanced color science ensures lively, natural images and is especially strong across the diverse range of skin tones. It is available for both movie and still image capture, and is supported with a Soft Skin option to smooth blemishes.",
  },
  {
    id: 96,
    title: "Kodak AZ421-RD PIXPRO Astro AZ421 16 MP Digital Camera",
    category: "electronics",
    tag: "digital camera",
    brandName: "Kodak",
    specilaCategory: "featured",
    keywords: [
      "Kodak AZ421-RD PIXPRO Astro AZ421 16 MP Digital Camera",
      "Buy Kodak AZ421-RD PIXPRO Astro AZ421 16 MP Digital Camera",
      "Kodak Digital Camera",
      "Digital Camera",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/81PLkVUaNTL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Kodak-AZ421-RD-PIXPRO-Digital-Optical/dp/B00M8C7T70/?tag=amprioz-21",
    descriptions:
      "Passion and performance go hand in hand with our KODAK PIXPRO AZ421 digital camera from the Astro Zoom Collection. A 42X ultra long zoom lens with optical image stabilization delivers crisp, clear 16 megapixel close-ups, panorama or HD videos. Auto scene, object tracking, post-editing features and a host of powerful yet user-friendly settings make photography easy, fun and zero hassle. KODAK PIXPRO Cameras. Tell your story.With plenty of pixels, you can alter your photos by cropping, zooming, or enlarging without losing out on quality. Plus, the CCD sensor ensures your images will be low-noise every time.",
  },
  {
    id: 97,
    title: "Sony W800/S 20.1 MP Digital Camera",
    category: "electronics",
    tag: "digital camera",
    brandName: "Sony",
    specilaCategory: "featured",
    keywords: [
      "SONY CyberShot DSC-W800/BC IN5 Price",
      "SONY CyberShot DSC-W800/BC IN5",
      "Buy SONY CyberShot DSC-W800/BC IN5 online",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/61nE7qX2qiL._SL1200_.jpg",
    productLink:
      "https://www.amazon.in/Sony-W800-Digital-Camera-Silver/dp/B00I8BIC9E/?tag=amprioz-21",
    descriptions:
      "Sony W800/S 20.1 MP Digital Camera MP Super HAD CCD sensor for beautifully detailed images.It Reduced blur with SteadyShot Image stabilization.The Sony Cyber-shot DSC-W800 is a lightweight, easy-to-use camera capable of capturing beautiful 20.1 MP photos and finely detailed HD videos.The Sony lens with 5x optical zoom and 10x Clear Image digital zoom capability lets you capture pictures in excellent image quality even from a distance.",
  },
  {
    id: 98,
    title: "Noise Buds VS201 V2 Truly Wireless Earbuds",
    category: "electronics",
    brandName: "Noise",
    tag: "earbuds",
    specilaCategory: "rated",
    keywords: [
      "Noise Buds VS201 V2 Truly Wireless Earbuds",
      "Buy Noise Buds VS201 V2 Truly Wireless Earbuds",
      "Noise Earbuds",
      "Earbuds",
    ],
    rating: 3.8,
    imageLink: "https://m.media-amazon.com/images/I/51PQG1YYb1L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Noise-Wireless-Equalizer-Resistance-Bluetooth/dp/B098R25TGC/?tag=amprioz-21",
    descriptions:
      "Noise Buds VS201 V2 Truly Wireless Earbuds with Dual Equalizer enjoy your music the way you like it. Switch between bass or normal mode to get the best sound.Want an easy work from home experience? Individual mic in each earbud has got you covered.Buds VS201 offer a play time of up to 4.5 hours on a single charge and an additional 9.5 hours making a total playtime of up to 14 hours with the charging case. Full touch controls: Manage your calls, change tracks, activate your preferred voice assistant and more with the touch controls on the earbuds.",
  },
  {
    id: 99,
    title: "boAt Airdopes 121V2 Bluetooth Truly Wireless in Ear Earbuds",
    category: "electronics",
    brandName: "boAt",
    specilaCategory: "featured",
    keywords: [
      "boAt Airdopes 121V2 Bluetooth Truly Wireless earbuds",
      "boAt Airdopes 121V2 Bluetooth Truly Wireless earbuds",
      "Buy boAt Airdopes 121V2 Bluetooth Truly Wireless earbuds",
      "boAt earbuds",
      "Earbuds",
    ],
    tag: "earbuds",
    rating: 3.8,
    imageLink: "https://m.media-amazon.com/images/I/510+wjzq-vL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Airdopes-121v2-Bluetooth-Immersive-Assistant/dp/B08JQN8DGZ/?tag=amprioz-21",
    descriptions:
      "Airdopes 121v2 offers a nonstop playback of up to 3.5H with each charge and an additional 10.5H playtime with the included charging case.With a dual tone finish on a lightweight ergonomic design weighing just around 4g per earbud, get ready to groove in full flow with Airdopes 121v2v2 TWS earbuds.It possesses powerful 8mm drivers that pump out immersive auditory experience all day long. Its carry cum charge case also has the battery LED indicator that shows the remaining power for the case.",
  },
  {
    id: 100,
    title: "Oneplus Nord Bluetooth Truly Wireless in Ear Earbuds",
    category: "electronics",
    brandName: "Oneplus",
    specilaCategory: "featured",
    keywords: [
      "Oneplus Nord Bluetooth Truly Wireless earbuds",
      "Buy Oneplus Nord Bluetooth Truly Wireless earbuds",
      "Oneplus Nord Bluetooth earbuds",
      "Oneplus earbuds",
      "Earbuds",
    ],
    tag: "earbuds",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/514NPRZ1AQL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bluetooth-Wireless-Cancellation-Ip55Rating-Fastcharging/dp/B09ZHPN8W5/?tag=amprioz-21",
    descriptions:
      "Enjoy Ultra-Fast Charing in the latest OnePlus Nord Buds. From 10mins of charging you get power of 5 hours.The flagship-level battery life for the all-new OnePlus Nord Buds delivers up to 30 hrs of non-stop music on a single charge.AI Noise Cancellation audio algorithm delivers precisely calibrated voice isolation during calls in the all-new OnePlus Nord Buds.For the OnePlus Nord Buds, you get to choose how heavy or light you want your sound with the help of sound master equalizers 3 unique audio profiles - Bold, Bass & Serenade.",
  },
  {
    id: 101,
    title:
      "Boult Audio Airbass Truebuds Bluetooth Truly Wireless in Ear Earbuds",
    category: "electronics",
    brandName: "Boult",
    specilaCategory: "featured",
    keywords: [
      "Boult Audio Airbass Truebuds Bluetooth Truly Wireless earbuds",
      "Boult Audio Airbass Truebuds Bluetooth earbuds",
      "Buy Boult Audio Airbass Truebuds Bluetooth Truly Wireless earbuds",
      "boult Earbuds",
      "Earbuds",
    ],
    tag: "earbuds",
    rating: 3.7,
    imageLink: "https://m.media-amazon.com/images/I/71g02NjbZyL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Boult-Audio-TrueBuds-Wireless-Waterproof/dp/B08CFCK6CW/?tag=amprioz-21",
    descriptions:
      "With one multifunction button, you can play/pause, previous/next track and answer/hang-up calls.Voice assistant function lets you access siri/Google Assistant. 20m Transmission Distance.Automatically pair with your device as soon as you take them out of the case. No need to manually pair to your device each time.Offers playback time of up to 7.5 hours for every charge. Carrying case can give 4 additional charges to the earbuds making total play-time of up to 30 Hours. The earbuds can be used separately after successful pairing. Not only listening to both earbuds in stereo mode, but also it can be used as monopods to enjoy the music & take calls.",
  },
  {
    id: 102,
    title: "boAt Airdopes 175 Bluetooth Truly Wireless in Ear Earbuds ",
    category: "electronics",
    tag: "earbuds",
    brandName: "boAt",
    specilaCategory: "featured",
    keywords: [
      "boAt Airdopes 175 True Wireless Earbuds",
      "boAt Airdopes 175 True Wireless Earbuds Price in India",
      "boAt Airdopes 175 True Wireless Earbuds Price",
      "boAt Airdopes 175 True Wireless Earbuds Specs",
      "boAt earbuds",
      "earbuds",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/5111gqxrFTL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/boAt-Airdopes-175-Technology-Lightweight/dp/B09X6ZFW6P/?tag=amprioz-21",
    descriptions:
      "Clear Voice Calls- Be heard absolutely clear across voice calls wherever you are with the ENx Tech enabled Airdopes 175, that cancels out unwanted background noise during calls.Courtesy our ASAP Charge tech, the earbuds can garner up to 75 minutes of playtime in just 5 minutes of charging. It offers a total playtime of up to 35HRS, including up to 8HRS of playtime per earbud.Delve into the boAt immersive auditory experience on Airdopes 175 true wireless earbuds courtesy its 10mm audio drivers.",
  },
  {
    id: 103,
    title: "TAGG Rogue 100GT Wireless Gaming Earbuds",
    category: "electronics",
    tag: "earbuds",
    brandName: "TAGG",
    specilaCategory: "rated",
    keywords: [
      "TAGG Rogue 100GT True Wireless Earbuds",
      "TAGG Rogue 100GT True Wireless Earbuds Price in India",
      "TAGG Rogue 100GT True Wireless Earbuds Price",
      "TAGG Rogue 100GT True Wireless Earbuds Specs",
      "TAGG earbuds",
      "earbuds",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/616JKu+XmqL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/TAGG-Rogue-100GT-Wireless-Playtime/dp/B0B14SGH8F/?tag=amprioz-21",
    descriptions:
      " TAGG Rogue 100GT earbuds are built to provide the ultimate mobile gaming audio experience with a low 50ms latency. Designed for gaming adventures offers ideal gaming audio so you never miss any game sound effects. Get ready for an exceptional competitive gaming experience through quick sound effect audio.Experience crystal clear voice quality during calls anywhere with Quad Mic equipped with Background Noise Cancellation.The QwikPair Technology makes it easier to connect the earbuds to your smartphone as soon as you open the case lid.",
  },
  {
    id: 104,
    title: "boAt Airdopes 171 Bluetooth Truly Wireless in Ear Earbuds",
    category: "electronics",
    tag: "earbuds",
    brandName: "boAt",
    specilaCategory: "featured",
    keywords: [
      "boAt Airdopes 171 True Wireless Earbuds",
      "boAt Airdopes 171 True Wireless Earbuds Price in India",
      "boAt Airdopes 171 True Wireless Earbuds Price",
      "boAt Airdopes 171 True Wireless Earbuds Specs",
      "boAt earbuds",
      "Earbuds",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61A48m3XfSS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Boat-Airdopes-171-Functionality-Resistance/dp/B086WNLYT8/?tag=amprioz-21",
    descriptions:
      "These true wireless earbuds have powerful 6mm drivers for an enthralling playback experience.Its dual tone colours offer an aesthetical edge and define a true listening time, Charging Time: 2 Hours.Airdopes 171 offers a nonstop playback of up to 3H with each charge and an additional 10H playtime with the included charging case. These true wireless earbuds weigh about 4g per each so you may keep them plugged for longer duration with comfort.boAt Airdopes 171 offers true wireless experience with latest Bluetooth v5.0.",
  },
  {
    id: 105,
    title: "truke Buds S1 True Wireless Earbuds",
    category: "electronics",
    tag: "earbuds",
    brandName: "truke",
    specilaCategory: "rated",
    keywords: [
      "Truke Buds S1 True Wireless Earbuds",
      "Truke Buds S1 True Wireless Earbuds Price in India",
      "Truke Buds S1 True Wireless Earbuds Price",
      "Truke Buds S1 True Wireless Earbuds Specs",
      "Truke earbuds",
      "Earbuds",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/61l0ocLvZKS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/truke-Wireless-Environmental-Cancellation-Bluetooth/dp/B091H9DWL9/?tag=amprioz-21",
    descriptions:
      "Truke seeks to provide High-Quality sound alternatives at affordable range that convey the same listening experience as the high ranged products in its segment.truke Buds S1 comes with Dual-Mic in each earbud and Environmental Noise Cancellation technology. Enjoy a Rich Calling Experience. These Bluetooth Earbuds are equipped with power-efficient Bluetooth v5.1 that supports low power consumption, superior connectivity and compatible with all Android, Windows & IOS devices.",
  },
  {
    id: 106,
    title: "Noise Buds VS104 Truly Wireless",
    category: "electronics",
    tag: "earbuds",
    brandName: "Noise",
    specilaCategory: "featured",
    keywords: [
      "Noise Buds VS202 True Wireless Earbuds",
      "Noise Buds VS202 True Wireless Earbuds Price in India",
      "Noise Buds VS202 True Wireless Earbuds Price",
      "Noise Buds VS202 True Wireless Earbuds Specs",
      "Noise earbuds",
      "Earbuds",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/51cjIEYU8BL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Noise-Wireless-Bluetooth-30-Hours-Instacharge/dp/B09Y5MP7C4/?tag=amprioz-21",
    descriptions:
      "Get set for a day full of music and then some more.Experience sound that hits the right note every single time via the 13mm speaker driver.Colour your music the way you want and mix-match the ear tips with the buds to suit your style.The earbuds are ready to play in a matter of seconds, all thanks to the one-step syncing process.You are in control always no matter how you want to get your work done.",
  },
  {
    id: 107,
    title: "pTron Bassbuds Wave ENC Bluetooth 5.3 Wireless Headphones",
    category: "electronics",
    tag: "earbuds",
    brandName: "pTron",
    specilaCategory: "featured",
    keywords: [
      "pTron Bassbuds Wave True Wireless Earbuds",
      "pTron Bassbuds Wave True Wireless Earbuds Price in India",
      "pTron Bassbuds Wave True Wireless Earbuds Price",
      "pTron Bassbuds Wave True Wireless Earbuds Specs",
      "pTron Earbuds",
      "Earbuds",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/51V-P7alc0L._SL1100_.jpg",
    productLink:
      "https://www.amazon.in/Bassbuds-Bluetooth-Wireless-Headphones-Playtime/dp/B09XBH4F47/?tag=amprioz-21",
    descriptions:
      "TWS earbuds with Environmental Noise Cancellation (ENC) for Noise-free Calling Experience, Lag-free Audio/Video sync, Hi-Fi Audio with Immersive Bass & 40Hrs Total Playtime with Case.Seamless 10meters Wireless Range; Quick 1-Step Pairing, Auto-Reconnect with Last Paired Device; Dual Mic for Stereo Calls; Wide compatibility with Bluetooth enabled devices.8mm drivers for the immersive audio experience; 40mAh battery in each bud; Just 1Hr charge time; Compact 300mAh charging case with Type-C charging; 1.5Hrs charge time; Rounded edges for easy Grip.",
  },
  {
    id: 108,
    title: "Redmi 80 cm (32 inches) Android 11 Series HD Ready Smart LED TV",
    category: "electronics",
    brandName: "Redmi",
    specilaCategory: "featured",
    keywords: [
      "Redmi 32 inch (80 cm) | L32M6 RA (Black) (2021 Model) Android Smart HD Ready LED TV Price in India",
      "Redmi 32 inch (80 cm) | L32M6 RA (Black) (2021 Model) Android Smart HD Ready LED TV Specifications",
      "Redmi 32 inch (80 cm) | L32M6 RA (Black) (2021 Model) Android Smart HD Ready LED TV Reviews",
      "Redmi 32 inch (80 cm) | L32M6 RA (Black) (2021 Model) Android Smart HD Ready LED TV Offers",
      "Redmi 80 cm (32 inches) Android 11 Series HD Ready Smart LED TV",
      "Redmi HD Ready Smart LED TV",
    ],
    tag: "Android Tv",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71L-lTQnJiL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Redmi-inches-Ready-L32M6-RA-Android/dp/B09F9YQQ7B/?tag=amprioz-21",
    descriptions:
      "Experience incredible clarity and sharp imaging that ensure your entertainment is a visual delight.Riveting 20W sound with Dolby Audio support makes for a complete entertainment experience.Navigate through the multiverse of incredible content from more than 30 content partners.Latest and Trending content with curated lists across categories.",
  },
  {
    id: 109,
    title: "TCL 79.9 cm (32 inches) HD Ready Certified Android Smart LED TV",
    category: "electronics",
    brandName: "TCL",
    specilaCategory: "featured",
    keywords: [
      "TCL 32S65A 32 inch LED HD-Ready TV",
      "TCL 32S65A 32 inch LED HD-Ready TV Price",
      "TCL 32S65A 32 inch LED HD-Ready TV price in India",
      "TCL 79.9 cm (32 inches) HD Ready Certified Android Smart LED TV",
      "TCL Smart LED TV",
    ],
    tag: "Android Tv",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/61OLHyD8NVL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/TCL-Inches-Certified-Android-32S65A/dp/B08PCXXMJ6/?tag=amprioz-21",
    descriptions:
      "Enjoy watching your favorite movies in stunning detail, with an accurate reproduction of light and dark shades, which allows you view next-level HDR Ready content from all sources.The A+ Grade Full HD Panel meets the highest industry requirement ensuring clear, detailed and rich picture quality. With the HD Ready resolution you can explore every detail for a crisp picture experience.The Smart Sound feature automatically detects the type of TV program you’re watching (sports, news, movies, etc.,) and accordingly adjusts the sound settings.",
  },
  {
    id: 110,
    title: "OnePlus 80 cm (32 inches) Y Series HD Ready LED Smart Android TV",
    category: "electronics",
    brandName: "Oneplus",
    specilaCategory: "featured",
    keywords: [
      "oneplus 32-inch y-series hd android tv (32y1)",
      "oneplus 32-inch y-series hd android tv (32y1) price",
      "buy oneplus 32-inch y-series hd android tv (32y1)",
      "buy oneplus 32-inch y-series hd android tv (32y1) online",
      "oneplus 32-inch y-series hd android tv (32y1) price in india",
      "oneplus 32-inch y-series hd android tv (32y1) best price",
      "oneplus 32-inch y-series hd android tv (32y1) offers",
      "OnePlus 80 cm (32 inches) Y Series HD Ready LED Smart Android TV",
    ],
    tag: "Android Tv",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71vZypjNkPS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/OnePlus-inches-Ready-Android-32Y1/dp/B08B42LWKN/?tag=amprioz-21",
    descriptions:
      "Rich and powerful, immersive audio. With the 20W box speakers and Dolby Audio enhancement, the OnePlus TV Y Series delivers crystal clear sound quality that you will enjoy.Revel in the finest picture quality with real-time optimisation of your video content. Our advanced Gamma Engine produces unmatched visual quality to bring your experience alive.Treat your eyes to vivid imagery with a high colour range of DCI-P3 93% featuring a 20% wider colour gamut—authentic cinematic colour with the OnePlus TV Y Series.",
  },
  {
    id: 111,
    title: "LG 80 cm (32 inches) HD Ready Smart LED TV",
    category: "electronics",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 80 cm (32 inches) HD Ready Smart LED TV price",
      "LG 80 cm (32 inches) HD Ready Smart LED TV price in india",
      "LG 80 cm (32 inches) HD Ready Smart LED TV offers",
      "LG 80 cm (32 inches) HD Ready Smart LED TV Reviews",
      "LG 80 cm (32 inches) HD Ready Smart LED TV",
      "LG Smart LED TV",
    ],
    tag: "Android Tv",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71uKCdULRgL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-inches-Ready-Smart-32LM563BPTC/dp/B08DPLCM6T/?tag=amprioz-21",
    descriptions:
      "Now enjoy your favourite movies & TV series on Netflix, Disney+ Hotstar, Amazon Prime and many more on your LG Smart TV with WebOS.The advanced image processor adjusts color for richer, more natural images. Enjoy the beauty of nature's true colors on your TV screen.Active HDR optimizes every scene, delivering delicate detail and rich color. The multi-HDR format, including HDR10 and HLG, coupled with LG’s dynamic scene-by-scene adjustment technology, lets you enjoy any video content in amazing HDR quality.",
  },
  {
    id: 112,
    title:
      "Sansui 80cm (32 inches) HD Ready Smart LED TV JSY32SKHD (BLACK) (2021 Model)",
    category: "electronics",
    tag: "Android Tv",
    brandName: "Sansui",
    specilaCategory: "featured",
    keywords: [
      "Sansui 32 inch (80 cm) JSY32SKHD (BLACK) (2021) Smart HD Ready LED TV Price in India",
      "Sansui 32 inch (80 cm) JSY32SKHD (BLACK) (2021) Smart HD Ready LED TV Specifications",
      "Sansui 32 inch (80 cm) JSY32SKHD (BLACK) (2021) Smart HD Ready LED TV Reviews",
      "Sansui 32 inch (80 cm) JSY32SKHD (BLACK) (2021) Smart HD Ready LED TV Offers",
      "Sansui 80cm (32 inches) HD Ready Smart LED TV JSY32SKHD (BLACK) (2021 Model)",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/719WthOLZPL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Sansui-inches-JSY32SKHD-Bezel-less-Design/dp/B09NNJ9WYM/?tag=amprioz-21",
    descriptions:
      "Enjoy a clear and consistent visual of image from all angles with 16:09 display scale and Dynamic Color Enhancer which offers a stunning and rich image quality.Let your ears experience the magic of 20W speakers which offer clear and deep sound and let you get a new perspective of good home audio with theatre-like sound effects.Indulge in an immersive and edge-less viewing experience with a bezel-less design combined with a wide viewing angle.",
  },
  {
    id: 113,
    title:
      "Samsung 80 cm (32 Inches) Wondertainment Series HD Ready LED Smart TV",
    category: "electronics",
    tag: "Android Tv",
    brandName: "Samsung",
    specilaCategory: "rated",
    keywords: [
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv",
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv price",
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv in india",
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv specifications",
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv specs",
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv launch",
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv features",
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv price in india",
      "samsung ua32t4340akxxl 32-inch hd ready smart led tv reviews",
      "Samsung 80 cm (32 Inches) Wondertainment Series HD Ready LED Smart TV",
      "Samsung Smart TV",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/7160TtJzTWL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Inches-Wondertainment-UA32T4340BKXXL-Glossy/dp/B09F6S8BT6/?tag=amprioz-21",
    descriptions:
      "This mode can convert your TV into a quintessential PC. You can do things such as working from the cloud, mirroring your laptop onto the big screen for added convenience and remotely accessing your office computer.This Smart TV from Samsung is here to give you a new visual experience for your playlist. Watch your music come alive as your TV turns into a virtual music system (in three different colour tones). Your beats will not only sound better, but also look better.You can play your music and videos from your smartphone on the big screen to share your fun moments with everyone around you. Work with select Samsung smartphones.",
  },
  {
    id: 114,
    title: "iFFALCON 80 cm (32 inches) HD Ready Smart LED TV 32F52",
    category: "electronics",
    tag: "Android Tv",
    brandName: "iFFALCON",
    specilaCategory: "featured",
    keywords: [
      "iFFALCON by TCL 32F52 32 inch HD Ready LED Smart TV",
      "iFFALCON by TCL 32F52 32 inch HD Ready LED Smart TV Price in India",
      "iFFALCON by TCL 32F52 32 inch HD Ready LED Smart TV Price",
      "iFFALCON by TCL 32F52 32 inch HD Ready LED Smart TV Specs",
      "iFFALCON 80 cm (32 inches) HD Ready Smart LED TV 32F52",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/71U8iCixA-L._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B09F9HBMWB/?tag=amprioz-21",
    descriptions:
      "With Android 9 and built-in Google Assistant, F5A offers an all-new AI solution for you to find the right entertainment, keep yourself updated with the world and also, control your other smart devices with just your voice. The perfect addition to the making of your smart home.F52 offers an all-new AI solution for you to find the right entertainment, keep yourself updated with the world and also, control your other smart devices with just your voice. The perfect addition to the making of your smart home.You can download thousands of cool apps, such as Netflix, YouTube, and Hotstar, using Google Play Store.",
  },
  {
    id: 115,
    title: "Kodak 80 cm (32 inches) HD Ready Certified Android Smart LED TV",
    category: "electronics",
    tag: "Android Tv",
    brandName: "Kodak",
    specilaCategory: "rated",
    keywords: [
      "Kodak 32 inch (80 cm) Certified 32HDX7XPROBL (Black) (2021 Model) Android Smart HD Ready LED TV Price in India",
      "Kodak 32 inch (80 cm) Certified 32HDX7XPROBL (Black) (2021 Model) Android Smart HD Ready LED TV Specifications",
      "Kodak 32 inch (80 cm) Certified 32HDX7XPROBL (Black) (2021 Model) Android Smart HD Ready LED TV Reviews",
      "Kodak 32 inch (80 cm) Certified 32HDX7XPROBL (Black) (2021 Model) Android Smart HD Ready LED TV Offers",
      "Kodak 80 cm (32 inches) HD Ready Certified Android Smart LED TV",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/81y3W2rKmmL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Kodak-inches-Certified-Android-32HDX7XPROBL/dp/B09DSXK8JX/?tag=amprioz-21",
    descriptions:
      "This television, with its elegant and stylish bezel-less design, will blend well with your living room’s aesthetics.The powerful inbuilt 40W output speakers make sure that you hear every sound in detail. With perfection flowing, entertainment at its best is provided.Enjoy and entertain yourself the way you like by the multiple options of connectivity. Plugin your TV with ease with Wi-Fi, Bluetooth, HDMI, and USB.Cortex A53 Quad Code Processor and Mali450 GPU help TV run at lightning-fast speed to make your experience smooth and enjoyable.",
  },
  {
    id: 116,
    title: "SKYWALL 81.28 cm (32 inches) HD Ready Smart LED TV 32SWELS-PRO",
    category: "electronics",
    tag: "Android Tv",
    brandName: "SKYWALL",
    specilaCategory: "featured",
    keywords: [
      "Skywall 32 inch (81.28 cm) 32SWELS PRO (Black) (2021 Model) Smart Full HD LED TV Price in India",
      "Skywall 32 inch (81.28 cm) 32SWELS PRO (Black) (2021 Model) Smart Full HD LED TV Specifications",
      "Skywall 32 inch (81.28 cm) 32SWELS PRO (Black) (2021 Model) Smart Full HD LED TV Reviews",
      "Skywall 32 inch (81.28 cm) 32SWELS PRO (Black) (2021 Model) Smart Full HD LED TV Offers",
      "SKYWALL 81.28 cm (32 inches) HD Ready Smart LED TV 32SWELS-PRO",
    ],
    rating: 3.5,
    imageLink: "https://m.media-amazon.com/images/I/71KVP1kQbyL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Skywall-81-28-inches-Smart-32SWELS-PRO/dp/B08QX1CC14/?tag=amprioz-21",
    descriptions:
      "Skywall 32SW-Els Pro Smart TV runs on the latest Android 9.0, that provides an exclusive UI tailored for enhancing your TV watching experience.Whether you’re gaming or watching a movie using this TV, you will find yourself in the thick of the action - all thanks to this technology that puts up a surround-sound experience.Ambient light reflection, the A+ grade pure prism panel ensures that the integrity of the picture is always deep and detailed, perfect and pure.Once you see high definition tv with vivid and crisp details. Watch your videos on the best-suited Full Screen mode.",
  },
  {
    id: 117,
    title: "Croma 80 cm (32 Inches) HD Ready Certified Android Smart LED TV",
    category: "electronics",
    tag: "Android Tv",
    brandName: "Croma",
    specilaCategory: "featured",
    keywords: [
      "Croma CREL032HOF024601 32 Inch HD Ready Smart LED TV",
      "Croma CREL032HOF024601 32 Inch HD Ready Smart LED TV Price in India",
      "Croma CREL032HOF024601 32 Inch HD Ready Smart LED TV Price",
      "Croma CREL032HOF024601 32 Inch HD Ready Smart LED TV Specs",
      "Croma Smart LED TV",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/61XouL+PH-L._SL1400_.jpg",
    productLink:
      "https://www.amazon.in/Croma-Inches-Certified-Android-CREL032HOF024601/dp/B09X6W3TRF/?tag=amprioz-21",
    descriptions:
      "With the Croma HD Ready LED 81.28 cm (32) Android Smart TV, you can stream your favourite entertainment wirelessly. Its Android operating system includes Google Assistant, allowing you to search for content, such as movies and TV shows using your voice. The HD-ready resolution provides a clear, vibrant display for an immersive viewing experience. Furthermore, this smart TV features an A+ Grade Panel with an up to 20 W audio output, to improve the viewing and sound experience.",
  },
  {
    id: 118,
    title: "JBL Cinema SB231, 2.1 Channel Dolby Digital Soundbar",
    category: "electronics",
    brandName: "JBL",
    specilaCategory: "featured",
    keywords: [
      "JBL Cinema SB231, 2.1 Channel Dolby Digital Soundbar Price in India",
      "JBL Cinema SB231, 2.1 Channel Dolby Digital Soundbar Specifications",
      "JBL Cinema SB231, 2.1 Channel Dolby Digital Soundbar Reviews",
      "JBL Cinema SB231, 2.1 Channel Dolby Digital Soundbar Offers",
      "JBL Cinema SB231, 2.1 Channel Dolby Digital Soundbar",
      "JBL Soundbar",
    ],
    tag: "Soundbar",
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/71VUurS4ByL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/JBL-Cinema-Soundbar-Subwoofer-110Watts/dp/B08CVGB3JZ/8?tag=amprioz-21",
    descriptions:
      "The JBL Cinema SB231 features 110W system power, Dolby Digital, two full range drivers and comes with a wired subwoofer. Enjoy powerful sound with extra deep bass for movies and music entertainment. It’s Bluetooth-enabled so you can play your favorite music wirelessly from any smartphone, tablet or laptop and features a one cable connection with HDMI ARC that ensures a hassle-free user experience.",
  },
  {
    id: 119,
    title: "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar",
    category: "electronics",
    brandName: "boAt",
    specilaCategory: "featured",
    keywords: [
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar price",
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar price in india",
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar reviews",
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar offers",
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar",
      "boAt Soundbar",
    ],
    tag: "Soundbar",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61Qa+zvFzHL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bar-Wireless-Bluetooth-Soundbar-Subwoofer/dp/B07FPLKKCS/?tag=amprioz-21",
    descriptions:
      "The boAt AAVANTE bar comes with 120 watt RMS sound with a bassful subwoofer. The sound output feels real, thrilling bass provided by the 60Watt Subwoofer. Upgrade your music listening experience with the boAt Aavante Bar with down-firing subwoofer which provides bold sound to any seat in the room, giving you an audio experience you can truly hear and feel.2.1 Channel Surround Sound The 2.1 Channel Surround sound in the boAt Aavante Bar provides with a rather cinematic experience, enhances the way you experience entertainment at home and in the cinema by creating the optimal environment for all of your favourite content.",
  },
  {
    id: 120,
    title: "Zebronics Zeb-Juke Bar 9400 Pro Dolby 5.1, 525W 5.1 Soundbar",
    category: "electronics",
    brandName: "Zebronics",
    specilaCategory: "featured",
    keywords: [
      "Zebronics Zeb-Juke Bar 9400 Pro Dolby 5.1, 525W 5.1 Soundbar",
      "Zebronics Zeb-Juke Bar 9400 Pro Dolby 5.1, 525W 5.1 Soundbar price",
      "buy Zebronics Zeb-Juke Bar 9400 Pro Dolby 5.1, 525W 5.1 Soundbar",
      "buy oneplus 32-inch y-series hd android tv (32y1) online",
      "Zebronics Zeb-Juke Bar 9400 Pro Dolby 5.1, 525W 5.1 Soundbar price in india",
      "Zebronics Zeb-Juke Bar 9400 Pro Dolby 5.1, 525W 5.1 Soundbar best price",
      "Zebronics Zeb-Juke Bar 9400 Pro Dolby 5.1, 525W 5.1 Soundbar offers",
      "Zebronics Soundbar",
    ],
    tag: "Soundbar",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/71kHqn8ArKS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Zebronics-Bar-9400-Pro-Dolby/dp/B08B6BTM3J/?tag=amprioz-21",
    descriptions:
      "Enjoy a cinematic audio experience right at your home with hi-fidelity surround sound on our zeb-juke bar 9400 pro dolby 5.1 soundbar with a 15.5' subwoofer and rear speakers to give you a deep bass.output power subwoofer 150 watts soundbar 225 watts satellites 75 watts x 2 total 525 watts.impedance subwoofer 4ω soundbar 8ω satellites 8ω frequency response 45hz-20khz.it comes with multi-connectivity options like usb/aux/hdmi (arc) and optical inputthe soundbar has an led display, volume/media controls, remote control, and comes with a provision for wall mounting.",
  },
  {
    id: 121,
    title: "Infinity (JBL) Sonic B200WL, 160W Soundbar with Wireless Subwoofer",
    category: "electronics",
    brandName: "JBL",
    specilaCategory: "featured",
    keywords: [
      "Infinity (JBL) Sonic B200WL, 160W Soundbar with Wireless Subwoofer price",
      "Infinity (JBL) Sonic B200WL, 160W Soundbar with Wireless Subwoofer price in india",
      "Infinity (JBL) Sonic B200WL, 160W Soundbar with Wireless Subwoofer offers",
      "Infinity (JBL) Sonic B200WL, 160W Soundbar with Wireless Subwoofer Reviews",
      "Infinity (JBL) Sonic B200WL, 160W Soundbar with Wireless Subwoofer",
      "Infinity (JBL) Soundbar",
    ],
    tag: "Soundbar",
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/6133wPU+tUL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Infinity-B200WL-Channel-Bluetooth-Wireless/dp/B086Z933SS/?tag=amprioz-21",
    descriptions:
      "Experience powerful immersive sound that creates a cinematic experience and a concert-like effect.Bluetooth connectivity allows you to pair and stream from any compatible device effortlessly.Extra deep bass delivers extraordinary sound for movies, music and even video games.For those who like to keep things simple, the Infinity Sonic B200 has a range of sound output options, from Aux, USB and optical input, enabling you to connect to various devices.",
  },
  {
    id: 122,
    title:
      "Samsung HW-A550/XL with Wireless Subwoofer 410 W Bluetooth Soundbar",
    category: "electronics",
    tag: "Soundbar",
    brandName: "Samsung",
    specilaCategory: "featured",
    keywords: [
      "Samsung HW-A550/XL with Wireless Subwoofer 410 W Bluetooth Soundbar Price in India",
      "Samsung HW-A550/XL with Wireless Subwoofer 410 W Bluetooth Soundbar Specifications",
      "Samsung HW-A550/XL with Wireless Subwoofer 410 W Bluetooth Soundbar Reviews",
      "Samsung HW-A550/XL with Wireless Subwoofer 410 W Bluetooth Soundbar Offers",
      "Samsung HW-A550/XL with Wireless Subwoofer 410 W Bluetooth Soundbar",
      "Samsung Soundbar",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/61A8oSHughL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-HW-A550-XL-Subwoofer-Bluetooth/dp/B09BFWSC8B/?tag=amprioz-21",
    descriptions:
      "The power to make surround sound come to life. Samsung Soundbar supports DTS Virtual :X, which recreates sound to bring it to new heights and take your audio to 3D.When you want to turn up the bass for maximum immersion, activate Bass Boost mode on the Soundbar. The included subwoofer takes the powerful, deeper bass to the next level, surrounding you with a fuller range of sound.Kick your listening experience up a notch with the subwoofer included with your Soundbar. It emphasizes bass for incredible sound that hits deeper and smoother notes — delivering theater-quality audio with your Soundbar.",
  },
  {
    id: 123,
    title:
      "JBL Bar 2.1 Deep Bass, Dolby Digital Soundbar with Wireless Subwoofer",
    category: "electronics",
    tag: "Soundbar",
    brandName: "JBL",
    specilaCategory: "rated",
    keywords: [
      "JBL Bar 2.1 Deep Bass, Dolby Digital Soundbar with Wireless Subwoofer Price in India",
      "JBL Bar 2.1 Deep Bass, Dolby Digital Soundbar with Wireless Subwoofer Price",
      "buy JBL Bar 2.1 Deep Bass, Dolby Digital Soundbar with Wireless Subwoofer",
      "JBL Bar 2.1 Deep Bass, Dolby Digital Soundbar with Wireless Subwoofer specs",
      "JBL Soundbar with Wireless Subwoofer",
      "JBL Soundbar ",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/7133Wa9--iL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/JBL-Bar-2-1-Deep-Bass/dp/B0827SG8J8/?tag=amprioz-21",
    descriptions:
      "JBL Bar 2.1 Deep Bass, Dolby Digital Soundbar with Wireless Subwoofer captivating sound with Dolby Digital / Digital Plus technology gets you set for an alternate dimension, with an ethereal sound quality that adds colour to your audio as well as visual experience. Bluetooth Range 10M.The sleek and premium styled soundbar adds to the beauty of your home as much as it adds to the immersive auditory experience.Control your playback via easy operational controls and the master remote control device, Frequency Response 80Hz-20KHz.",
  },
  {
    id: 124,
    title:
      "Sony HT-S20R Real 5.1ch Dolby Digital Soundbar for TV with subwoofer",
    category: "electronics",
    tag: "Soundbar",
    brandName: "Sony",
    specilaCategory: "featured",
    keywords: [
      "Sony HT-S20R Real 5.1ch Dolby Digital Soundbar for TV with subwoofer",
      "Sony HT-S20R Real 5.1ch Dolby Digital Soundbar for TV with subwoofer Price in India",
      "Sony HT-S20R Real 5.1ch Dolby Digital Soundbar for TV with subwoofer Price",
      "Sony HT-S20R Real 5.1ch Dolby Digital Soundbar for TV with subwoofer Specs",
      "Sony HT-S20R Real 5.1ch Dolby Digital Soundbar for TV with subwoofer",
      "Sony Soundbar",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/71+s6K1eovL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Sony-HT-S20R-Soundbar-Bluetooth-Connectivity/dp/B084685MT1/?tag=amprioz-21",
    descriptions:
      "Experience real 5.1 channels of real surround sound. Wired rear speaker, a 3-ch soundbar and wired external subwoofer combine to produce powerful cinematic sound while Bluetooth and USB connectivity make it easy to play your favorite music.Give movies the soundtrack they deserve with 5.1 channels of real surround sound. Rear speakers and an external subwoofer work with a 3-ch soundbar to deliver dynamic, immersive, cinematic sound.The HT-S20R is almost ready to go straight out of the box. Simply plug it in, wire up your rear speakers and external subwoofer, and enjoy 5.1 channels of real surround sound.",
  },
  {
    id: 125,
    title: "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar",
    category: "electronics",
    tag: "Soundbar",
    brandName: "boAt",
    specilaCategory: "rated",
    keywords: [
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar Price in India",
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar Specifications",
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar Reviews",
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar Offers",
      "boAt Aavante Bar 1500 2.1 Channel Home Theatre Soundbar",
      "boAt Soundbar",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61Qa+zvFzHL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bar-Wireless-Bluetooth-Soundbar-Subwoofer/dp/B07FPLKKCS/?tag=amprioz-21",
    descriptions:
      "Sound with 120 Watt rms and subwoofer The boAt AAVANTE bar comes with 120 watt RMS sound with a bassful subwoofer. The sound output feels real, thrilling bass provided by the 60Watt Subwoofer. Upgrade your music listening experience with the boAt Aavante Bar with down-firing subwoofer which provides bold sound to any seat in the room, giving you an audio experience you can truly hear and feel.2.1 Channel Surround Sound The 2.1 Channel Surround sound in the boAt Aavante Bar provides with a rather cinematic experience, enhances the way you experience entertainment at home and in the cinema by creating the optimal environment for all of your favourite content.",
  },
  {
    id: 126,
    title: "Zebronics Zeb Jukebar 9200 DWS Dolby Digital Plus Soundbar ",
    category: "electronics",
    tag: "Soundbar",
    brandName: "Zebronics",
    specilaCategory: "featured",
    keywords: [
      "Zebronics Zeb Jukebar 9200 DWS Dolby Digital Plus Soundbar ",
      "Zebronics Zeb Jukebar 9200 DWS Dolby Digital Plus Soundbar Specifications",
      "Zebronics Zeb Jukebar 9200 DWS Dolby Digital Plus Soundbar Reviews",
      "Zebronics Zeb Jukebar 9200 DWS Dolby Digital Plus Soundbar Offers",
      "Zebronics Soundbar ",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/813oEQBq+FL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Zebronics-Soundbar-Supporting-Bluetooth-mountable/dp/B08SVS3RKL/?tag=amprioz-21",
    descriptions:
      "Enjoy a powerful sound experience with ZEB-Juke Bar 9200DWS Pro Dolby at home. Its a soundbar with subwoofer, the soundbar is powered by quad mid range 6.9cms drivers along with dual 1.9cms tweeters, the subwoofer comes with a large 16.5cms driver for scintillating bass.The soundbar comes with quad 6.9cms drivers and dual 1.9cms tweeters. This combination gives you best experience for high and mid range frequencies, while lower frequencies are handled by the subwoofer.Enjoy the immersive audio experience with Dolby Audio. Dial up the impact of your entertainment with the enhanced sound of Dolby Audio. It delivers crystal clarity, easy-to-hear dialogue, great detail, and realistic surround sound to make the entertainment you love even better.",
  },
  {
    id: 127,
    title: "boAt AAVANTE Bar 1700D 120W 2.1 Channel Bluetooth Soundbar",
    category: "electronics",
    tag: "Soundbar",
    brandName: "boAt",
    specilaCategory: "featured",
    keywords: [
      "Boat Aavante Bar 1700d 120w 2.1 Channel Bluetooth Soundbar With Dolby Audio",
      "Boat Signature Sound, Wired Subwoofer, Multiple Connectivity Modes",
      "Entertainment Modes And Bluetooth V5.0 Black wholesale sellers",
      "boAt soundbar",
      "boAt AAVANTE Bar 1700D 120W 2.1 Channel Bluetooth Soundbar reviews",
      "buy boAt AAVANTE Bar 1700D 120W 2.1 Channel Bluetooth Soundbar",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61s86BzQP2L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bar-1700D-Bluetooth-Connectivity-Entertainment/dp/B08C6G66SR/r?tag=amprioz-21",
    descriptions:
      "It is time to experience the inexperienced and feel the unimaginable with the soundbar of your choice - Aavante 1700D. With Dolby Digital technology, immerse into 3D cinematic experience like never before. It is time to elevate to a different world with this High Definition Soundbar which comes with a wired subwoofer to deliver heavy bass along with the 120 watt RMS resonant sound. With multiple EQ Modes- Movie, News, Music and 3D, match the modes with your mood. With an air of sophistication, this elegant soundbar makes for a perfect style statement in any modern home.With a wired 60W subwoofer, get a sensational sound with incredible clarity delivered to you. With an ethereal sound quality, experience every moment come alive.",
  },
  {
    id: 128,
    title: "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Price in India",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Specifications",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Reviews",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Offers",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator",
      "LG Refrigerator",
    ],
    tag: "Refrigerator",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71if9IRsHlL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Inverter-Frost-Free-Refrigerator-GL-I292RPZX/dp/B08X72GY5Q/?tag=amprioz-21",
    descriptions:
      "LG Refrigerators with Smart Inverter Compressor are designed to give energy efficiency, longer freshness retention and less noise.Door Cooling+ feature helps provide air flow to the front of the fridge for enhanced cooling in the door area.Multiple cooling air vents distribute and circulate cool air to every corner of the refrigerator, ensuring proper cooling.LG's Smart Diagnosis helps diagnose and solve any problems you may have with your refrigerator quickly and correctly.",
  },
  {
    id: 129,
    title: "Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator",
    category: "appliance",
    brandName: "Whirlpool",
    specilaCategory: "featured",
    keywords: [
      "Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator price",
      "Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator price in india",
      "Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator reviews",
      "Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator offers",
      "Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator",
      "Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator",
    ],
    tag: "Refrigerator",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/51NPjlmGGjL._SL1100_.jpg",
    productLink:
      "https://www.amazon.in/Whirlpool-Refrigerator-WDE-205-CLS/dp/B083KW4D6D/?tag=amprioz-21",
    descriptions:
      "Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator easy defrosting mechanism ensures hassle free use of the refrigerator along with effective cooling.In Insulated Capillary Technology, the capillary which carries the refrigerant from the compressor to the freezer is surrounded by super cold gas leading to benefits such better compressor efficiency, faster cooling and 9 Hours* cooling retention in the refrigerator.Vegetable crisper with honey comb moisture lock-in technology maintains optimum moisture in your vegetables and keeps them fresher for longer.",
  },
  {
    id: 130,
    title: "Samsung 253 L 3 Star with Inverter Double Door Refrigerator",
    category: "appliance",
    brandName: "Samsung",
    specilaCategory: "featured",
    keywords: [
      "Samsung 253 L 3 Star with Inverter Double Door Refrigerator",
      "Samsung 253 L 3 Star with Inverter Double Door Refrigerator price",
      "buy Samsung 253 L 3 Star with Inverter Double Door Refrigerator",
      "Samsung 253 L 3 Star with Inverter Double Door Refrigerator price in india",
      "Samsung 253 L 3 Star with Inverter Double Door Refrigerator best price",
      "Samsung 253 L 3 Star with Inverter Double Door Refrigerator offers",
      "Samsung Refrigerator",
    ],
    tag: "Refrigerator",
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61r7yN0Nk5L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Inverter-Refrigerator-RT28A3453S8-HL/dp/B08SBSL8Y2/?tag=amprioz-21",
    descriptions:
      "Enjoy greater energy efficiency, less noise and a long-lasting performance. The Digital Inverter Compressor automatically adjusts its speed in response to cooling demand.Prevent food from being ruined during power cuts. When electricity outages happen, a Cool Pack in the freezer keeps food frozen at below 0°C for up to 12 hours, so it doesn’t go to waste.Protect the refrigerator from power fluctuations. The Stabilizer Free Operation keeps it working steadily and reliably. If the voltage increases too much it automatically cuts the power to prevent electrical damage.",
  },
  {
    id: 131,
    title: "AmazonBasics 564 L Side-by-Side Door Refrigerator",
    category: "appliance",
    brandName: "AmazonBasics",
    specilaCategory: "featured",
    keywords: [
      "AmazonBasics 564 L Side-by-Side Door Refrigerator price",
      "AmazonBasics 564 L Side-by-Side Door Refrigerator price in india",
      "AmazonBasics 564 L Side-by-Side Door Refrigerator offers",
      "AmazonBasics 564 L Side-by-Side Door Refrigerator Reviews",
      "AmazonBasics 564 L Side-by-Side Door Refrigerator",
      "AmazonBasics Refrigerator",
    ],
    tag: "Refrigerator",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71fa4e1KQsL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B07R9NJCVZ/?tag=amprioz-21",
    descriptions:
      "The aesthetically designed AmazonBasics Side by Side Door refrigerator is packed with the latest features in refrigeration technology. Make it the star of your kitchen today, without putting a strain on your budget.Comes with energy efficient technology that reduces consumption, making it lighter on the pocket.No more long waits for ice cubes with the Super Freeze feature that turns water into ice in no time.Equipped with a door alarm that alerts you in case the door is accidentally left open.",
  },
  {
    id: 132,
    title: "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    tag: "Refrigerator",
    brandName: "Haier",
    specilaCategory: "featured",
    keywords: [
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator Price in India",
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator Specifications",
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator Reviews",
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator Offers",
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator",
      "Haier Refrigerator",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/616nhDl2o8L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Haier-Frost-Free-Refrigerator-HEF-25TDS-Convertible/dp/B08448TTJM/?tag=amprioz-21",
    descriptions:
      "The Haier TM(Top Mount) Double Door Refrigerator presents a sleek and aesthetic design with attractive colors that adds a modern and lavish look to your kitchen.Keep your perishable food and fresh greens in the large vegetable box. Its spacious & bigger design along with a multi air flow keeps your food fresher for longer.Haier's Twin Inverter Technology is a new generation of inverter compressors for refrigerators. This technology ensures that the compressor & fan can run at different speeds according on the load required by the fridge, making it cost & energy efficient.",
  },
  {
    id: 133,
    title: "Samsung 192 L 2 Star Direct Cool Single Door Refrigerator",
    category: "appliance",
    tag: "Refrigerator",
    brandName: "Samsung",
    specilaCategory: "rated",
    keywords: [
      "Samsung 192 L 2 Star Direct Cool Single Door Refrigerator Price in India",
      "Samsung 192 L 2 Star Direct Cool Single Door Refrigerator Price",
      "buy Samsung 192 L 2 Star Direct Cool Single Door Refrigerator",
      "Samsung 192 L 2 Star Direct Cool Single Door Refrigerator specs",
      "Samsung 192 L 2 Star Direct Cool Single Door Refrigerator",
      "Samsung Refrigerator",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/81FyYXoQF-L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Direct-Refrigerator-RR19A241BGS-NL/dp/B08SC2ZTDP/?tag=amprioz-21",
    descriptions:
      "In India, power cuts are common across regions. Smart Connect Inverter refrigerators run even during power cuts, making sure your food always remains as fresh as ever.Its Safe Clean Back is a smooth safety cover for its internal vital components that can be easily wiped clean. It also looks neat and provides added durability by protecting them from accidental bumps and knocks.An Anti Bacterial Gasket helps keep the door liner clean and prevents the build-up of fungi and bacteria inside the refrigerator. So everything is more hygienic and food is less likely to go off quickly.",
  },
  {
    id: 134,
    title: "Godrej 236 L 2 Star Inverter Frost Free Double Door Refrigerator ",
    category: "appliance",
    tag: "Refrigerator",
    brandName: "Godrej",
    specilaCategory: "featured",
    keywords: [
      "Godrej 236 L 2 Star Inverter Frost Free Double Door Refrigerator",
      "Godrej 236 L 2 Star Inverter Frost Free Double Door Refrigerator Price in India",
      "Godrej 236 L 2 Star Inverter Frost Free Double Door Refrigerator Price",
      "Godrej 236 L 2 Star Inverter Frost Free Double Door Refrigerator Specs",
      "Buy Godrej 236 L 2 Star Inverter Frost Free Double Door Refrigerator",
      "Godrej Refrigerator",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/716wdy9sWuL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Godrej-Frost-Free-Refrigerator-EON-236B/dp/B083SR3XRX/?tag=amprioz-21",
    descriptions:
      "Godrej has been manufacturing refrigerators ever since 1958. It has been thoughtfully innovating unique features to make their refrigerators exclusive in the segment.You can never have enough of storage space for vegetables in your fridge. With Godrej, you can get the jumbo vegetable tray to store all of your everyday veggies.Technology DC Silver Ions in the air duct and resistance in the gasket helps keep the food germ-free and fresh.The bright and efficient LED lighting ensures that the compartments inside the refrigerator are well illuminated and lit.",
  },
  {
    id: 135,
    title: "LG 190 L 4 Star Inverter Direct-Cool Single Door Refrigerator",
    category: "appliance",
    tag: "Refrigerator",
    brandName: "LG",
    specilaCategory: "rated",
    keywords: [
      "LG 190 L 4 Star Inverter Direct-Cool Single Door Refrigerator Price in India",
      "LG 190 L 4 Star Inverter Direct-Cool Single Door Refrigerator Specifications",
      "LG 190 L 4 Star Inverter Direct-Cool Single Door Refrigerator Reviews",
      "LG 190 L 4 Star Inverter Direct-Cool Single Door Refrigerator Offers",
      "LG 190 L 4 Star Inverter Direct-Cool Single Door Refrigerator",
      "LG Refrigerator",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71PjRr580lL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Inverter-Direct-Cool-Refrigerator-GL-D201ASCY/dp/B084YC1FHT/?tag=amprioz-21",
    descriptions:
      "Smart inverter compressor is designed to give unmatched performance, great savings, super silent operation and stabilizer free operation.The revolutionary smart connect technology helps you connect your refrigerator to home inverter in case of power cuts. Thus providing you the convenience of storing food for long without spoilage.A special lattice-type box cover which maintains moisture at an optimum level when moisture from stored food is evaporated and then condensed on the lattice.LG Direct Cool Refrigerators come with base stand drawer for extra storage to keep onion, potatoes and other vegetables.",
  },
  {
    id: 136,
    title:
      "Whirlpool 340 L 3 Star Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    tag: "Refrigerator",
    brandName: "Whirlpool",
    specilaCategory: "featured",
    keywords: [
      "Whirlpool 340 L 3 Star Inverter Frost-Free Double Door Refrigerator",
      "Whirlpool 340 L 3 Star Inverter Frost-Free Double Door Refrigerator Specifications",
      "Whirlpool 340 L 3 Star Inverter Frost-Free Double Door Refrigerator Reviews",
      "Whirlpool 340 L 3 Star Inverter Frost-Free Double Door Refrigerator Offers",
      "Whirlpool Refrigerator",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/51EyoX3OoYL._SL1248_.jpg",
    productLink:
      "https://www.amazon.in/Whirlpool-Refrigerator-INV-CNV-355/dp/B083JNJZ9Y/?tag=amprioz-21",
    descriptions:
      "The advanced IntelliSense Invertor Technology efficiently adapts the cooling according to internal load. It not only reduces energy consumption, but also ensures matchless performances even during power cuts.Continuously analysis data to ensure optimum cooling for long lasting freshness.Vegetable Crisper with Honey Comb Moisture Lock-in Technology condenses the evaporated moisture from the food to ensure balanced air and optimum freshness.Freshonizer helps to reduces oxidation to maintain freshness of fruits and vegetables.",
  },
  {
    id: 137,
    title: "Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator ",
    category: "appliance",
    tag: "Refrigerator",
    brandName: "Godrej",
    specilaCategory: "featured",
    keywords: [
      "Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator Price in India",
      "Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator Specifications",
      "Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator Offers",
      "Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator price",
      "Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator reviews",
      "buy Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator",
      "Godrej Refrigerator",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71gkLeZUjIL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Godrej-Direct-Cool-Refrigerator-1905-PTDI/dp/B0897ZPGYK/?tag=amprioz-21",
    descriptions:
      "Inverter Technology uses the variable speed compressor which adjusts its operations intelligently, resulting in greater efficiency, durability and silent operation.A dry storage drawer located at the bottom of the refrigerator lets you store vegetables that do not require refrigeration like onions, potatoes and garlic.Made with toughened glass, each shelf has the strength to hold weight up to 150 kg.The insulation under the chiller tray ensures no water droplets formation.",
  },
  {
    id: 138,
    title:
      "Samsung 6.0 Kg Inverter 5 star Fully-Automatic Front Loading Washing Machine",
    category: "appliance",
    brandName: "Samsung",
    specilaCategory: "featured",
    keywords: [
      "SAMSUNG 6 kg 5 Star With Hygiene Steam and Ceramic Heater Fully Automatic Front Load with In-built Heater Silver Price",
      "SAMSUNG 6 kg 5 Star With Hygiene Steam and Ceramic Heater Fully Automatic Front Load with In-built Heater Silver",
      "Buy SAMSUNG 6 kg 5 Star With Hygiene Steam and Ceramic Heater Fully Automatic Front Load with In-built Heater Silver online",
      "Samsung 6.0 Kg Inverter 5 star Fully-Automatic Front Loading Washing Machine",
    ],
    tag: "Washing Machine",
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/71m+C6830pL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Inverter-Fully-Automatic-WW60R20GLSS-TL/dp/B08DDTG2XX/?tag=amprioz-21",
    descriptions:
      "Whether your laundry is heavily stained or lightly soiled, the Samsung WW60R20GLSS/TL Washing Machine ensures that every piece of your garment is spotless. This home appliance features a Digital Inverter Technology for energy-efficient performance, a Diamond Drum to gently wash your clothes, and a Child Lock to ensure that your kid can not accidentally change the wash cycle settings.The durable Digital Inverter Technology delivers an energy-efficient performance. Also, it makes use of magnets to ensure that the washing machine does not make much noise while operating.",
  },
  {
    id: 139,
    title:
      "LG 6 Kg 5 Star Inverter Fully-Automatic Front Loading Washing Machine",
    category: "appliance",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 6 kg With Smart Diagnosis Fully Automatic Front Load with In-built Heater White Price",
      "LG 6 kg With Smart Diagnosis Fully Automatic Front Load with In-built Heater White",
      "Buy LG 6 kg With Smart Diagnosis Fully Automatic Front Load with In-built Heater White online",
      "LG 6 Kg 5 Star Inverter Fully-Automatic Front Loading Washing Machine",
    ],
    tag: "Washing Machine",
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/7108LHrN2eL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Inverter-Fully-Automatic-Loading-FHM1006SDW/dp/B09LLMH3CQ/?tag=amprioz-21",
    descriptions:
      "LG Front load has its motor attached directly to the drum that reduces the Noise, vibration & the Wear and Tear.LG Steam helps remove house dust mites, pet and pollen allergen in clothes requiring extra care.Any problem with your washing machine? Just connect it to the SmartThinQ app and know the problem. You can diagnose up to 86 errors.It heats water at 85°C and your tub is clean along with the drum. Later, the dirt and water stains are removed with RPM speed.",
  },
  {
    id: 140,
    title:
      "Bosch 7 kg 5 Star Inverter Touch Control Fully Automatic Front Loading Washing Machine",
    category: "appliance",
    brandName: "Bosch",
    specilaCategory: "featured",
    keywords: [
      "Bosch 7 kg 5 Star Inverter Touch Control Fully Automatic Front Loading Washing Machine",
      "Bosch 7 kg 5 Star Inverter Touch Control Fully Automatic Front Loading Washing Machine Price",
      "Bosch 7 kg 5 Star Inverter Touch Control Fully Automatic Front Loading Washing Machine price in india",
      "buy Bosch 7 kg 5 Star Inverter Touch Control Fully Automatic Front Loading Washing Machine",
      "Bosch 7 kg 5 Star Inverter Touch Control Fully Automatic Front Loading Washing Machine reviews",
    ],
    tag: "Washing Machine",
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/71laDnTjKPL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bosch-Inverter-Control-Automatic-Loading/dp/B08SR372S7/?tag=amprioz-21",
    descriptions:
      "Our special side walls gives stable & noise free operation, so that you can do your washing without disturbing your loved ones.Uniquely designed drums are tough on stains & gentle on your clothes.The VarioInverter Drive is quiet, powerful and durable. It ensures low power consumption and high cleaning efficiency.You can add or remove items during the wash cycle by pausing the running cycle, then simply reload your additional items.",
  },
  {
    id: 141,
    title: "IFB 6.5 Kg Fully-Automatic Front Loading Washing Machine",
    category: "appliance",
    brandName: "IFB",
    specilaCategory: "featured",
    keywords: [
      "IFB 6.5 Kg Fully-Automatic Front Loading Washing Machine price",
      "IFB 6.5 Kg Fully-Automatic Front Loading Washing Machine price in india",
      "IFB 6.5 Kg Fully-Automatic Front Loading Washing Machine offers",
      "IFB 6.5 Kg Fully-Automatic Front Loading Washing Machine Reviews",
      "IFB 6.5 Kg Fully-Automatic Front Loading Washing Machine",
      "IFB Washing Machine",
    ],
    tag: "Washing Machine",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71hrC9lDoVL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/IFB-Fully-Automatic-Loading-Washing-Machine/dp/B097K5RD6S/?tag=amprioz-21",
    descriptions:
      "IFB 6.5 Kg Fully-Automatic Front Loading Washing Machine removes allergens, giving you fresh, clean clothes that keep allergies at bay. Essential for homes with kids.Water is energized by this built-in device. The filter treatment dissolves detergent better to give clothes a softer wash.Built with innovative crescent moon drum design that creates a gentle water cushion, preventing damage to fabrics & optimally placed holes provide the mechanical action required to get the desired wash results.A revolutionary wash system with dynamic water jets and showers from paddles that completely soak clothes and optimally dissolve detergent for the most complete wash.",
  },
  {
    id: 142,
    title:
      "Samsung 7 Kg 5 Star AI Control & Wi-Fi, Fully-Automatic Front Loading Washing Machine",
    category: "appliance",
    tag: "Washing Machine",
    brandName: "Samsung",
    specilaCategory: "featured",
    keywords: [
      "SAMSUNG 7 kg 5 Star With AI Control &amp; Wi-Fi and Digital Inverter Fully Automatic Front Load with In-built Heater Silver Price",
      "SAMSUNG 7 kg 5 Star With AI Control &amp; Wi-Fi and Digital Inverter Fully Automatic Front Load with In-built Heater Silver",
      "Buy SAMSUNG 7 kg 5 Star With AI Control &amp; Wi-Fi and Digital Inverter Fully Automatic Front Load with In-built Heater Silver online",
      "Samsung 7 Kg 5 Star AI Control & Wi-Fi, Fully-Automatic Front Loading Washing Machine",
      "Samsung Washing Machine",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61rAWcbWF2L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Control-Fully-Automatic-WW70T502NAN1TL-Inverter/dp/B09KGYCR7Z/?tag=amprioz-21",
    descriptions:
      "The Hygiene Steam function helps in deep and hygienic cleaning of your clothes using steam. In the powerful Steam cycle, steam is released from the bottom of the drum which helps remove ingrained dirt and up to 99.9% of bacteria as well as inactivate allergens, ensuring that your clothes are thoroughly cleaned.Thanks to the powerful AI Control, this washing machine ensures that you can get clean and effectively washed clothes. This function helps personalize your washing as it remembers your habits and helps suggest wash cycles and also displays timely information. You can use the SmartThings app on your smartphone to get information on wash cycles, planning, and troubleshooting. What's more, this function also selects the perfect drying course, making it a delight to complete your laundry chores.",
  },
  {
    id: 143,
    title: "IFB 8 Kg 5 Star Fully-Automatic Front Loading Washing Machine",
    category: "appliance",
    tag: "Washing Machine",
    brandName: "IFB",
    specilaCategory: "rated",
    keywords: [
      "IFB 8 kg with Steam Wash, Aqua Energie, Anti-Allergen Fully Automatic Front Load with In-built Heater Silver Price",
      "IFB 8 kg with Steam Wash, Aqua Energie, Anti-Allergen Fully Automatic Front Load with In-built Heater Silver",
      "Buy IFB 8 kg with Steam Wash, Aqua Energie, Anti-Allergen Fully Automatic Front Load with In-built Heater Silver online",
      "IFB 8 Kg 5 Star Fully-Automatic Front Loading Washing Machine",
    ],
    rating: 3.6,
    imageLink: "https://m.media-amazon.com/images/I/61O45iumZVL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/IFB-Fully-Automatic-Senator-SXS-8014/dp/B09R71QWKB/?tag=amprioz-21",
    descriptions:
      "You can effectively wash your clothes with the help of the IFB 8 kg Senator Plus SXS Front-loading Washing Machine. With its Power Steam Wash programme, this washing machine safeguards your laundry against germs. Thanks to its Anti-allergen programme, this washing machine helps prevent allergens in your laundry. Its inbuilt filter helps effectively dissolve the detergent so that there is no detergent residue on your clothes. Additionally, you can pause this washing machine's cycle and easily add a forgotten towel or shirt even after the wash cycle has begun.",
  },
  {
    id: 144,
    title:
      "LG 8 Kg 5 Star Inverter Touch Control Fully-Automatic Front Load Washing Machine",
    category: "appliance",
    tag: "Washing Machine",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 8 Kg 5 Star Inverter Touch Control Fully-Automatic Front Load Washing Machine",
      "LG 8 Kg 5 Star Inverter Touch Control Fully-Automatic Front Load Washing Machine Price in India",
      "LG 8 Kg 5 Star Inverter Touch Control Fully-Automatic Front Load Washing Machine Price",
      "LG 8 Kg 5 Star Inverter Touch Control Fully-Automatic Front Load Washing Machine Specs",
      "LG 8 Kg 5 Star Inverter Touch Control Fully-Automatic Front Load Washing Machine",
      "LG Washing Machine",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61HYe0mzhIL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Inverter-Control-Fully-Automatic-FHM1408BDW/dp/B0B1DNMQG6/?tag=amprioz-21",
    descriptions:
      "LG Front load has its motor attached directly to the drum reducing the Noise, vibration & Wear and Tear.LG Steam helps remove house dust mites, pet and pollen allergen in clothes requiring extra care.Any problem with your washing machine? Just connect it to the SmartThinQ app and know the problem. You can diagnose up to 86 errors.No more drying of wet hands. We have a waterproof touch panel with a seamless design.",
  },
  {
    id: 145,
    title:
      "Bosch 8 kg 5 Star Touch Control Fully Automatic Front Load Washing Machine",
    category: "appliance",
    tag: "Washing Machine",
    brandName: "Bosch",
    specilaCategory: "rated",
    keywords: [
      "BOSCH 8 kg 5 Star INVERTER TOUCH CONTROL Fully Automatic Front Load with In-built Heater White Price",
      "BOSCH 8 kg 5 Star INVERTER TOUCH CONTROL Fully Automatic Front Load with In-built Heater White",
      "Buy BOSCH 8 kg 5 Star INVERTER TOUCH CONTROL Fully Automatic Front Load with In-built Heater White online",
      "Bosch 8 kg 5 Star Touch Control Fully Automatic Front Load ",
      "Bosch 8 kg 5 Star Touch Control Fully Automatic Front Load ",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/71HfSlWiOSS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bosch-Control-Automatic-Heater-WAJ2426AIN/dp/B08SR4L6X2/?tag=amprioz-21",
    descriptions:
      "Bosch 8 kg 5 Star Touch Control Fully Automatic Front Load minimise tangeles in laundry by up to 50 % with AntiTangle.Shortest wash cycle upto 65% faster, without compromising on cleaning quality.A combination of 256 load sensing levels, optimised water usage and our unique drum system.Enjoy hygienically clean laundry with AntiBacteria.Our special side walls gives stable & noise free operation, so that you can do your washing without disturbing your loved ones.",
  },
  {
    id: 146,
    title:
      "Samsung 8 Kg 5 Star Inverter, Hygiene Steam Fully-Automatic Front Loading Washing Machine",
    category: "appliance",
    tag: "Washing Machine",
    brandName: "Samsung",
    specilaCategory: "featured",
    keywords: [
      "SAMSUNG 8 kg 5 Star With Hygiene Steam and Digital Inverter Fully Automatic Front Load with In-built Heater White Price",
      "SAMSUNG 8 kg 5 Star With Hygiene Steam and Digital Inverter Fully Automatic Front Load with In-built Heater White",
      "Buy SAMSUNG 8 kg 5 Star With Hygiene Steam and Digital Inverter Fully Automatic Front Load with In-built Heater White online",
      "Samsung 8 Kg 5 Star Inverter, Hygiene Steam Fully-Automatic Front Loading Washing Machine",
      "Samsung Washing Machine",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/61Qt1PoCbrL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Inverter-Hygiene-Fully-Automatic-WW80T4040CE1TL/dp/B09KGW3NWL/?tag=amprioz-21",
    descriptions:
      "From the bottom of the drum, a powerful steam comes out for a hygienic and deep washing of your clothes. This steam thoroughly saturated the laundry to remove up to 99.9% of bacteria, ingrained dirt, and to deactivate allergens.This technology uses sturdy magnets to ensure that this washing machine functions quietly while consuming less power.",
  },
  {
    id: 147,
    title: "LG 9 KG Fully Automatic Front Load Washing Machine",
    category: "appliance",
    tag: "Washing Machine",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 9 kg Fully Automatic Front Load with In-built Heater Black Price",
      "LG 9 kg Fully Automatic Front Load with In-built Heater Black",
      "Buy LG 9 kg Fully Automatic Front Load with In-built Heater Black online",
      "LG 9 KG Fully Automatic Front Load Washing Machine",
      "LG Washing Machine",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61nSL8WEk4L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Automatic-Washing-Machine-FHV1409ZWB/dp/B08X7B5G49/?tag=amprioz-21",
    descriptions:
      "LG 9 kg 5 Star Fully Automatic Front Load Washing Machine online, which comes with direct drive motor that powers our washing machines is super reliable and really quiet. The spin speed is 1400 RPM. LG ThinQ with Wi-Fi makes laundry much more convenient. It is equipped with an elegant tempered glass door and developed hygienic and durable stainless lifter. It comes with a capacity of 9 Kg. the 5-star rating makes it more energy efficient. It is equipped with a child lock feature as well. The wash programs are Cotton, Mix, Allergy Care, Delicate, TurboWash 59, Download Cycle, Speed14, Wool (Hand/Wool), Tub Clean, Easy Care, Cotton Plus.",
  },

  {
    id: 148,
    title:
      "Hp Victus Latest AMD Ryzen 5 5600H Processor 16.1 Inches Fhd Gaming Laptop",
    category: "electronics",
    brandName: "Hp",
    specilaCategory: "featured",
    keywords: [
      "Hp Ryzen 5 Hexa Core Victus 16 e0075AX Gaming Laptop Price in India",
      "Hp Ryzen 5 Hexa Core Victus 16 e0075AX Gaming Laptop Comparable Price List",
      "Hp Ryzen 5 Hexa Core Victus 16 e0075AX Gaming Laptop Price List in India",
      "Hp Ryzen 5 Hexa Core Victus 16 e0075AX Gaming Laptop Specifications",
      "Hp Ryzen 5 Hexa Core Victus 16 e0075AX Gaming Laptop Reviews",
    ],
    tag: "gaming laptop",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71D9lGfC5qL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Processor-Geforce-Backlit-Keyboard-16-E0301Ax/dp/B09RX35BKN/?tag=amprioz-21",
    descriptions:
      "Boost up your computing experience as the HP Victus Ryzen 5 Windows 10 Home Gaming Laptop features the latest advancements delivering efficient performance. Loaded with Windows 10 Home operating system helps you perform user-friendly operations. Witness the super-smooth operation with the AMD Ryzen 5 5600H processor that takes your performance to a whole new level. It never lets you slow down in terms of efficiency so that the quality and quantity of your work are never hampered. Equipped with one of the best-in-class graphical performances, this laptop is all ready to step up your computing experience.Enjoy super-fast speed as soon as you buy HP Victus Ryzen 5 Windows 10 Home Gaming Laptop. This laptop comes with a serious storage capacity of 512GB SSD to make it more efficient and productive which lets you store your games within this laptop. Now experiencing speedy performance with advanced specifications, this laptop is one of the best laptops available in the market. Having 8 GB DDR4-3200 MHz RAM, you can revel in powerful performance without interrupting your entertainment. The anti-glare coating may almost reduce light reflection from your glasses. When using a laptop screen, this helps you to see clearly without having to squint, making your eyes more comfortable.",
  },
  {
    id: 149,
    title:
      "ASUS TUF Gaming F15 (2021), 15.6 inch (39.62 cms) FHD 144Hz Gaming Laptop",
    category: "electronics",
    brandName: "ASUS",
    specilaCategory: "featured",
    keywords: [
      "ASUS TUF Gaming F15 Core i5 10th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/144 Hz) FX506LH-HN258T Gaming Laptop Price",
      "ASUS TUF Gaming F15 Core i5 10th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/144 Hz) FX506LH-HN258T Gaming Laptop Specifications",
      "ASUS TUF Gaming F15 Core i5 10th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/144 Hz) FX506LH-HN258T Gaming Laptop Features",
      "ASUS TUF Gaming F15 (2021), 15.6 inch (39.62 cms) FHD 144Hz Gaming Laptop",
      "ASUS Gaming Laptop",
    ],
    tag: "gaming laptop",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/710NRdecQtL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/ASUS-i5-10300H-Graphics-Windows-FX506LH-HN258W/dp/B09RMTMBSM/?tag=amprioz-21",
    descriptions:
      "Geared for serious gaming and real-world durability, the TUF Gaming F15 is a fully-loaded Windows 10 gaming laptop that can carry you to victory. Powered by the latest up to 10th Gen Intel® Core™ i7 CPU and up to GeForce® GTX 1660 Ti GPU, action-packed gameplay is fast, fluid, and fully saturates speedy IPS-level displays up to 144Hz. Even while boasting a smaller and more portable chassis than its predecessors, this gaming laptop also features a large 90Wh battery for superior battery life. Efficient self-cleaning cooling combines with TUF's signature military-grade durability to make this battle-tested road warrior a powerful ally for any gamer.",
  },
  {
    id: 150,
    title:
      "Lenovo IdeaPad Gaming 3 Intel Core i5 10th Gen 15.6 inch (39.62cm) FHD IPS Gaming Laptop",
    category: "electronics",
    brandName: "Lenovo",
    specilaCategory: "featured",
    keywords: [
      "Lenovo IdeaPad Gaming 3 Core i5 10th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/120 Hz) 15IMH05 Gaming Laptop Price",
      "Lenovo IdeaPad Gaming 3 Core i5 10th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/120 Hz) 15IMH05 Gaming Laptop Specifications",
      "Lenovo IdeaPad Gaming 3 Core i5 10th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/120 Hz) 15IMH05 Gaming Laptop Features",
      "Lenovo IdeaPad Gaming 3 Intel Core i5 10th Gen 15.6 inch (39.62cm) FHD IPS Gaming Laptop",
    ],
    tag: "gaming laptop",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/61fpo-87DyL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/Lenovo-IdeaPad-15-6-inch-Windows-81Y4017UIN/dp/B094XF92HG/?tag=amprioz-21",
    descriptions:
      "Up to 10th Gen Intel Core i5 H-series processor is here to deliver you a gaming experience like never before. 8 GB 2933Mhz DDR4 RAM, expandable up to 16GB, allows you to maintain the perfect balance between demanding games and intense deadlines without any fall back. Highly reliable 512 GB SSD hybrid storage gives you that extra space to keep all your media in one place.Hype up the intensity and let the virtual world come alive with the latest Nvidia GeForce GTX 1650 4GB GDDR6 graphics card and a refresh rate of 120Hz. Equipped with superior graphical performance, this laptop is all ready to step up your gaming experience.",
  },
  {
    id: 151,
    title:
      "ASUS TUF Gaming A15 (2021), 15.6-inch (39.62 cm) FHD 144Hz, AMD Ryzen 7 gaming laptop",
    category: "electronics",
    brandName: "ASUS",
    specilaCategory: "featured",
    keywords: [
      "ASUS TUF A15 FA506IC-HN005T Gaming Laptop (2021) | (FHD 144Hz / AMD Ryzen 7 4800H / RTX 3050 4GB Graphics/ 8GB /512GB SSD/ Windows 10) offer &amp; details",
      "ASUS TUF A15 FA506IC-HN005T Gaming Laptop (2021) | (FHD 144Hz / AMD Ryzen 7 4800H / RTX 3050 4GB Graphics/ 8GB /512GB SSD/ Windows 10) price in india",
      "ASUS TUF A15 FA506IC-HN005T Gaming Laptop (2021) | (FHD 144Hz / AMD Ryzen 7 4800H / RTX 3050 4GB Graphics/ 8GB /512GB SSD/ Windows 10) on Laptops",
      "ASUS TUF Gaming A15 (2021), 15.6-inch (39.62 cm) FHD 144Hz, AMD Ryzen 7 gaming laptop",
    ],
    tag: "gaming laptop",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/91zVSkGGZbS._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/ASUS-15-6-inch-NVIDIA-GeForce-RTX-3050-Graphics-FA506IC-HN005W/dp/B09RQKK6CZ/?tag=amprioz-21",
    descriptions:
      "Always ready for action, the TUF Gaming A15 excels at gaming, streaming, and everything in between. Up to AMD Ryzen 5000-H Series CPU to fly through multitasking and more. Gameplay graphics are smooth with the latest GeForce RTX 30-Series GPU which reliably delivers high frame rates for a ton of modern games. A superfast NVMe PCIe SSD speeds up load times, while an open SSD slot makes it easy to add terabytes more space for all your games.Game at pro-speed with a fast, IPS-level panel up to 240Hz (optional). With Adaptive-Sync, the display’s refresh rate synchronizes with the GPU’s frame rate to reduce lag, minimize stuttering, and eliminate visual tearing for ultra-smooth and immersive gameplay.",
  },
  {
    id: 152,
    title: "Hp Pavilion Gaming 15-Amd Ryzen 5 15.6 Inches gaming laptop",
    category: "electronics",
    tag: "gaming laptop",
    brandName: "Hp",
    specilaCategory: "featured",
    keywords: [
      "HP Pavilion Ryzen 7 Octa Core 5800H - (16 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce RTX 3050/144 Hz) 15-EC2076AX Gaming Laptop Price",
      "HP Pavilion Ryzen 7 Octa Core 5800H - (16 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce RTX 3050/144 Hz) 15-EC2076AX Gaming Laptop Specifications",
      "HP Pavilion Ryzen 7 Octa Core 5800H - (16 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce RTX 3050/144 Hz) 15-EC2076AX Gaming Laptop Features",
      "Hp Pavilion Gaming 15-Amd Ryzen 5 15.6 Inches gaming laptop",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/51DmOWr3rnL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/Pavilion-Micro-Edge-Anti-Glare-Graphics-15-Ec2048Ax/dp/B09SQ2KK4B/?tag=amprioz-21",
    descriptions:
      "The thin and powerful HP Pavilion Gaming 15 Laptop lets you experience high-grade graphics and processing power that meets your gaming and multitasking needs.It has AMD Ryzen 5 5600H (up to 4.2 GHz max boost clock(2i) 16 MB L3 cache, 6 cores, 12 threads) Processor.Operating System & Pre-installed Software: Windows 11 Home 64 Single Language| Microsoft Office Home & Student 2019 pre-installed.Full-size, ultra violet backlit, shadow black keyboard with numeric keypad, HP Imagepad with multi-touch gesture support.Camera: HP TrueVision HD Camera| Microphone: Integrated Dual array digital microphones| Audio: Audio by Bang & Olufsen; Dual speakers| Networking: Integrated.",
  },
  {
    id: 153,
    title: "Acer Aspire 5 A515-57G Gaming (Steel Gray) laptop",
    category: "electronics",
    tag: "gaming laptop",
    brandName: "Acer",
    specilaCategory: "rated",
    keywords: [
      "Acer Aspire 5 A515-57G (NX.K9TSI.001) (Core i5 12th Gen/8 GB/512 GB SSD/Windows 11)",
      "Acer Aspire 5 A515-57G Gaming",
      "Acer Aspire 5 A515-57G (NX.K9TSI.001) (Core i5 12th Gen/8 GB/512 GB SSD/Windows 11) specifications",
      "Acer Aspire 5 A515-57G (NX.K9TSI.001) (Core i5 12th Gen/8 GB/512 GB SSD/Windows 11) reviews",
      "Acer Aspire 5 A515-57G (NX.K9TSI.001) (Core i5 12th Gen/8 GB/512 GB SSD/Windows 11) offers",
    ],
    rating: 5,
    imageLink: "https://m.media-amazon.com/images/I/71pQf8makPL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Acer-A515-57G-Display-i5-1240P-Processor/dp/B0B45RSRQL/?tag=amprioz-21",
    descriptions:
      "Slay your enemies while you stay cool with the Air inlet keyboard design by expelling 10% more heat than others. The dual fans supporting multiple cooling modes and the dual copper thermal pipe enhance the performance.Get ready to face the competition and experience ultimate gaming performance with boasting 15.6 inch FHD IPS and 81.18% screen-to-body ratio display.Amplify the action with the blazing sound from built-in stereo speakers. Intensify your gaming experience and heighten your sense of awareness with two-way AI noise cancelation technology.",
  },
  {
    id: 154,
    title: "Dell New G15 5511 Gaming Laptop Intel I5-11400H",
    category: "electronics",
    tag: "gaming laptop",
    brandName: "Dell",
    specilaCategory: "featured",
    keywords: [
      "Dell New G15 5511 Gaming Laptop Intel I5-11400H",
      "Dell New G15 5511 Gaming Laptop Intel I5-11400H Price in India",
      "Dell New G15 5511 Gaming Laptop Intel I5-11400H Price",
      "Dell New G15 5511 Gaming Laptop Intel I5-11400H Specs",
      "Dell New G15 5511 Gaming Laptop",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61B+PQExFWL._SL1080_.jpg",
    productLink:
      "https://www.amazon.in/Dell-G15-5511-I5-11400H-D560639Win9B/dp/B09S3Z95F1/?tag=amprioz-21",
    descriptions:
      "With up to 11th Gen Intel Core i5 processors, you can revel in powerful performance without interrupting your gaming, streaming or videos. Storage makes all the difference: Experience faster boot-ups with M.2 PCIe 512GB NVMe SSD. Full speed ahead: Intel Wi-Fi 6 AX201 ensure low latency gaming, smooth HD streaming video, and interruption-free voice and video chats.The Alienware-inspired thermal design incorporates a dual air-intake from the top of the keyboard and the bottom of the G15. The latest advancements to the thermal design give the Dell G15 the headroom to deliver ample power while adapting to a changing system load. This allows components to stay cooler, and clock speeds to remain higher while running even the most demanding applications.",
  },
  {
    id: 155,
    title:
      "ASUS ROG Strix G17 (2021) 17.3-inch (43.94 cms) FHD 144Hz gaming laptop",
    category: "electronics",
    tag: "gaming laptop",
    brandName: "ASUS",
    specilaCategory: "rated",
    keywords: [
      "Asus ROG Strix G17 G713IH-HX020T Gaming Laptop (Ryzen 7 4800H/ 8GB/ 512GB SSD/ Win10/ 4GB Graph)",
      "Asus ROG Strix G17 G713IH-HX020T Gaming Laptop (Ryzen 7 4800H/ 8GB/ 512GB SSD/ Win10/ 4GB Graph) Price in India",
      "Asus ROG Strix G17 G713IH-HX020T Gaming Laptop (Ryzen 7 4800H/ 8GB/ 512GB SSD/ Win10/ 4GB Graph) Price",
      "Asus ROG Strix G17 G713IH-HX020T Gaming Laptop (Ryzen 7 4800H/ 8GB/ 512GB SSD/ Win10/ 4GB Graph) Specs",
      "ASUS ROG Strix G17 (2021) 17.3-inch (43.94 cms) FHD 144Hz gaming laptop",
    ],
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/61bwqXfWiaL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/ASUS-17-3-inch-GTX-1650-Graphics-G713IH-HX020T/dp/B09CBZFPZR/?tag=amprioz-21",
    descriptions:
      "From its aluminum-capped lid to its textured base, this laptop blends everyday durability with athletic style. The metal top resists knocks and bumps while also enabling slimmer bezels. Illuminate your life in RGB. A redesigned light bar increases the density of LEDs to create a more refined underglow beneath the chassis.Keep a low profile with quieter, more effective cooling. Liquid metal thermal compound pulls more heat away from the CPU. Arc Flow fans spin 84 blades each with a new design that pushes more air with less turbulence. Air flows through up to 4 heatsinks and fan outlets to rapidly expel heat. These and other improvements allow ROG Boost to overclock the GPU in Turbo mode, and they open up extra thermal headroom to pursue even higher GPU clocks and also overclock the CPU.",
  },
  {
    id: 156,
    title:
      "Lenovo Legion 5 AMD Ryzen 5 4600H 15.6 inch (39.62 cm) FHD IPS Gaming Laptop",
    category: "electronics",
    tag: "gaming laptop",
    brandName: "Lenovo",
    specilaCategory: "featured",
    keywords: [
      "Lenovo Legion 5 Ryzen 5 Hexa Core 4600H - (8 GB/1 TB HDD/256 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/120 Hz) 15ARH05 Gaming Laptop Price",
      "Lenovo Legion 5 Ryzen 5 Hexa Core 4600H - (8 GB/1 TB HDD/256 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/120 Hz) 15ARH05 Gaming Laptop Specifications",
      "Lenovo Legion 5 Ryzen 5 Hexa Core 4600H - (8 GB/1 TB HDD/256 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce GTX 1650/120 Hz) 15ARH05 Gaming Laptop Features",
      "Lenovo Legion 5 AMD Ryzen 5 4600H 15.6 inch (39.62 cm) FHD IPS Gaming Laptop",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/61ItfhQmaFL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/Lenovo-Windows-Graphics-Phantom-82B500BHIN/dp/B08GG8WCW7/?tag=amprioz-21",
    descriptions:
      "The all new Legion 5 is a seamless fusion of practicality, precision and power. An improved light refracting hinge, vertical vents, top-placed webcam with privacy shutter and the subtle design touches with iridescent logo finish on an anti-smudge Phantom Black body makes this laptop a ground-breaking addition to the era of gaming machines.Experience ultimate gaming performance with AMD Ryzen 5 4600H processor. Play the most intense games with 8 GB RAM DDR4 3200Mhz. The 1 TB HDD + 256 GB SSD storage gives you that extra space to download, explore & store all your games in one place.The Nvidia GeForce GTX 1650 4GB GDDR6 graphics and a refresh rate of 120Hz makes you ready to face your competition head on. The razor thin 4-sided bezel on a 15.6” Full HD IPS antiglare display gives your gaming machine a borderless feel with 84% screen-to-body ratio.",
  },
  {
    id: 157,
    title:
      "Lenovo IdeaPad Gaming 3 Intel Core i5 11th Gen 15.6 inch (39.62cm) FHD IPS Gaming Laptop",
    category: "electronics",
    tag: "gaming laptop",
    brandName: "Lenovo",
    specilaCategory: "featured",
    keywords: [
      "Lenovo IdeaPad Gaming 3 Core i5 11th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce RTX 3050/120 Hz) 15IHU6 Gaming Laptop Price",
      "Lenovo IdeaPad Gaming 3 Core i5 11th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce RTX 3050/120 Hz) 15IHU6 Gaming Laptop Specifications",
      "Lenovo IdeaPad Gaming 3 Core i5 11th Gen - (8 GB/512 GB SSD/Windows 10 Home/4 GB Graphics/NVIDIA GeForce RTX 3050/120 Hz) 15IHU6 Gaming Laptop Features",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71--IQUHVwL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Lenovo-IdeaPad-39-62cm-Backlit-82K10168IN/dp/B09SKV8TXD/?tag=amprioz-21",
    descriptions:
      "The 11th generation Intel i5 processor is always ready to deliver power when needed, but that’s not the only thing that’s packed in the IdeaPad gaming. The NVIDIA RTX 3050 4GB GDDR6 GPU with Max TGP of 90W featuring 2nd generation raytracing, 3rd generation tensor cores as well as NVIDIA’s DLSS, Reflex, Broadcast and Studio is always there to give the added power for hardcore gaming and streaming.The new and improved thermals ensure 100% better ventilation rates compared to its predecessors as well as 3 heat pipes that dissipate 35% more efficiently. Now you don’t need to worry about thermal throttling and overheating as you game. Get unthrottled performance in every session with 21% larger thermal area.",
  },

  {
    id: 1480,
    title: "gas oven auto ignition",
    category: "appliance",
    tag: "Gas Stove",
    brandName: "Prestige",
    specilaCategory: "featured",
    keywords: [
      "Lifelong LLGS303 Auto Ignition, High Efficiency 3 Burner Gas Stove with Toughened Glass Top",
      "Prestige Marvel Plus Auto Ignition 2 Burner Glass top GTM 02 AI Black",
      "Elica Hob 4 Burner Auto Ignition Glass Top - 3 Mini Triple Ring Brass Burner",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/614Q2qMRVBL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/s?k=gas+oven+auto+ignition&crid=EVL6TIENJ55F&sprefix=gas+oven+%2Caps%2C2169&ref=nb_sb_ss_fb_1_9_ts-doa-p?tag=amprioz-21",
    descriptions:
      "Spill Proof Design - For hastle free cooking and a Cleaner Kitchen. Tri-Pin Burners - For high Efficiency and faster cooking. Ergonomic Design - For easy handling and Maintainance. Toughned Black Glass top - Resists Scratches and withstands everyday wear and tear",
  },

  {
    id: 1490,
    title: "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan",
    category: "appliance",
    tag: "BLDC Ceiling Fan",
    brandName: "Havells",
    specilaCategory: "featured",
    keywords: [
      "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan",
      "Havells Efficiencia Neo 1200mm BLDC with alexa support",
      "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan with remote",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/41ZtHebyhuL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Havells-Efficiencia-Neo-1200-Ceiling/dp/B081HKZB56/?tag=amprioz-21",
    descriptions:
      "Least power consuming fan - 26 w per hour only, 65 percent power saving as compared to a regular fan of 75 w power consumption. Rated frequency : 50 Hz. Reversible Rotation:No",
  },
  {
    id: 158,
    title: "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Price in India",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Price List",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator features",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Specifications",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Reviews",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator",
    ],
    tag: "Double Door Refrigerator",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71if9IRsHlL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Inverter-Frost-Free-Refrigerator-GL-I292RPZX/dp/B08X72GY5Q/?tag=amprioz-21",
    descriptions:
      "LG Refrigerators with Smart Inverter Compressor are designed to give energy efficiency, longer freshness retention and less noise.Door Cooling+ feature helps provide air flow to the front of the fridge for enhanced cooling in the door area.Multiple cooling air vents distribute and circulate cool air to every corner of the refrigerator, ensuring proper cooling.LG's Smart Diagnosis helps diagnose and solve any problems you may have with your refrigerator quickly and correctly.LG Refrigerators come with specialized Trimless tempered Glass Shelves that are meant to carry heavy load without any spillage. Now store more without any worry.",
  },
  {
    id: 159,
    title: "Whirlpool 240 L Frost Free Multi-Door Refrigerator",
    category: "appliance",
    brandName: "Whirlpool",
    specilaCategory: "featured",
    keywords: [
      "Whirlpool 240 L Frost Free Multi-Door Refrigerator Price",
      "Whirlpool 240 L Frost Free Multi-Door Refrigerator Specifications",
      "Whirlpool 240 L Frost Free Multi-Door Refrigerator Features",
      "Whirlpool 240 L Frost Free Multi-Door Refrigerator price in india",
      "Whirlpool 240 L Frost Free Multi-Door Refrigerator",
    ],
    tag: "Double Door Refrigerator",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71O1V7baJSL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Whirlpool-Refrigerator-263D-PROTTON-ROY/dp/B07NBZ43PH/?tag=amprioz-21",
    descriptions:
      "The deli zone comes with a customizable temperature knob for storing delicacies like cheese, berries, chocolates and other dairy products.Controls the circulation of cold air so that each compartment receives uniform cooling that it requires.This refrigerator's Active Fresh Zone for fruits and vegetables provides upto 32L of large storage space.Unique and separate storage zone created for fruits, it prevents odour mixing with other edibles.The cold air flow around the compartment retains the moisture and freshness of the contents.",
  },
  {
    id: 160,
    title:
      "Panasonic 309 L 3 Star 6-Stage Smart Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    brandName: "Panasonic",
    specilaCategory: "featured",
    keywords: [
      "Panasonic 309 L 3 Star 6-Stage Smart Inverter Frost-Free Double Door Refrigerator Price",
      "Panasonic 309 L 3 Star 6-Stage Smart Inverter Frost-Free Double Door Refrigerator Specifications",
      "Panasonic 309 L 3 Star 6-Stage Smart Inverter Frost-Free Double Door Refrigerator Features",
      "Panasonic 309 L 3 Star 6-Stage Smart Inverter Frost-Free Double Door Refrigerator",
    ],
    tag: "Double Door Refrigerator",
    rating: 4.4,
    imageLink: "https://m.media-amazon.com/images/I/71V5f+-XO3L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Panasonic-Frost-Free-Refrigerator-NR-TG321CUSN-Vegetable/dp/B09GVM87LS/?tag=amprioz-21",
    descriptions:
      "The large 35 L capacity vegetable case stores vegetables and fruits in the optimum conditions with ideal humidity and constant temperature to keep them fresh and juicy longer.The INVERTER compressor varies power to provide more energy during day to adapt to frequent use and less at night. This results in extremely efficient operation to achieve energy savings, less noise and quick, powerful cooling.Door pockets are designed to enable neat vertical/horizontal storage of boxed spices in the fridge and vertical storage in the freezer.Cold air from the rear panel is blown to the sides to surround stored items. This surround cooling airflow softly wraps each of the items and cools them evenly.",
  },
  {
    id: 161,
    title:
      "Samsung 345 L 3 Star Digital Inverter Frost Free Double Door Refrigerator",
    category: "appliance",
    brandName: "Samsung",
    specilaCategory: "featured",
    keywords: [
      "Samsung 345 L 3 Star Digital Inverter Frost Free Double Door Refrigerator details",
      "Samsung 345 L 3 Star Digital Inverter Frost Free Double Door Refrigerator price in india",
      "Samsung 345 L 3 Star Digital Inverter Frost Free Double Door Refrigerator feature",
      "Samsung 345 L 3 Star Digital Inverter Frost Free Double Door Refrigerator offers",
      "Samsung 345 L 3 Star Digital Inverter Frost Free Double Door Refrigerator",
    ],
    tag: "Double Door Refrigerator",
    rating: 3.4,
    imageLink: "https://m.media-amazon.com/images/I/51q1Mo7oWVL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Refrigerator-RT37A4513BX-HL-Convertible/dp/B097T1PBVF/?tag=amprioz-21",
    descriptions:
      "Our Convertible 5in1 Refrigerators understand your life. Powered by the revolutionary Twin Cooling Plus Technology, this refrigerator has 5 conversion modes to take care of all your refrigeration needs.Ensure your food stays fresh for twice as long. Twin Cooling Plus preserves food in the optimal conditions by maintaining a humidity level of up to 70% *in the fridge. So perishable food stays moist and fresh for longer, which helps to reduce wastage.Enjoy greater energy efficiency, less noise and a long-lasting performance. The Digital Inverter Compressor automatically adjusts its speed in response to cooling demand.",
  },
  {
    id: 162,
    title: "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    tag: "Double Door Refrigerator",
    brandName: "Haier",
    specilaCategory: "featured",
    keywords: [
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator Price",
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator Specifications",
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator Features",
      "Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/616nhDl2o8L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Haier-Frost-Free-Refrigerator-HEF-25TDS-Convertible/dp/B08448TTJM/?tag=amprioz-21",
    descriptions:
      "The Haier TM(Top Mount) Double Door Refrigerator presents a sleek and aesthetic design with attractive colors that adds a modern and lavish look to your kitchen.This mode allows you to organize and store your food items conveniently without worrying about the refrigeration needs. Its 5 modes are innovatively designed to cater to your usage patterns.Keep your perishable food and fresh greens in the large vegetable box. Its spacious & bigger design along with a multi air flow keeps your food fresher for longer.Haier's Twin Inverter Technology is a new generation of inverter compressors for refrigerators. This technology ensures that the compressor & fan can run at different speeds according on the load required by the fridge, making it cost & energy efficient.",
  },
  {
    id: 163,
    title: "Samsung 345L 3 Star Inverter Frost Free Double Door Refrigerator",
    category: "appliance",
    tag: "Double Door Refrigerator",
    brandName: "Samsung",
    specilaCategory: "rated",
    keywords: [
      "Samsung 345L 3 Star Inverter Frost Free Double Door Refrigerator",
      "Samsung 345L 3 Star Inverter Frost Free Double Door Refrigerator price in india",
      "Samsung 345L 3 Star Inverter Frost Free Double Door Refrigerator specifications",
      "Samsung 345L 3 Star Inverter Frost Free Double Door Refrigerator reviews",
      "Samsung 345L 3 Star Inverter Frost Free Double Door Refrigerator offers",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/71pfYtV2upL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Samsung-Refrigerator-RT37T4513S8-HL-Convertible/dp/B08345Q835/?tag=amprioz-21",
    descriptions:
      "Our Convertible 5 in1 Refrigerators understand your life. Powered by the Twin Cooling Plus Technology, this refrigerator has 5 conversion modes to take care of all your refrigeration needs.Twin Cooling Plus is an independent cooling system with separate airflows in the fridge and freezer.At the touch of a button, Power Freeze makes ice 31% faster.Movable Ice Maker is easy to use. Simple twist is all you need to dispense ice cubes.",
  },
  {
    id: 164,
    title: "Godrej 236 L 2 Star Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    tag: "Double Door Refrigerator",
    brandName: "Godrej",
    specilaCategory: "featured",
    keywords: [
      "Godrej 236 L 2 Star Inverter Frost-Free Double Door Refrigerator",
      "Godrej 236 L 2 Star Inverter Frost-Free Double Door Refrigerator Price in India",
      "Godrej 236 L 2 Star Inverter Frost-Free Double Door Refrigerator Price",
      "Godrej 236 L 2 Star Inverter Frost-Free Double Door Refrigerator Specs",
      "Godrej 236 L 2 Star Inverter Frost-Free Double Door Refrigerator features",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/517G6s0PEML._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Godrej-Frost-Free-Refrigerator-EON-236B/dp/B083SQFT52/?tag=amprioz-21",
    descriptions:
      "Godrej 236 L 2 Star Inverter Frost-Free Double Door Refrigerator made with toughened glass, each shelf has the strength to hold weight up to 150 kg.If extra storage space is something you've always craved, the jumbo vegetable tray of our refrigerator gives enough space for all your veggies.Silver ions in the air duct and anti microbial resistance in the gasket keeps food germ free and fresh.2.25 litre bottle storage space ensures you can store jumbo sized juices & aerated drinks.",
  },
  {
    id: 165,
    title: "Haier 256 L 3 Star Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    tag: "Double Door Refrigerator",
    brandName: "Haier",
    specilaCategory: "rated",
    keywords: [
      "Haier 256 L 3 Star Inverter Frost-Free Double Door Refrigerator",
      "Haier 256 L 3 Star Inverter Frost-Free Double Door Refrigerator Price in India",
      "Haier 256 L 3 Star Inverter Frost-Free Double Door Refrigerator Price",
      "Haier 256 L 3 Star Inverter Frost-Free Double Door Refrigerator Specs",
      "Haier 256 L 3 Star Inverter Frost-Free Double Door Refrigerator features",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/611egf0ZcfL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Haier-Frost-Free-Refrigerator-HEB-25TDS-Convertible/dp/B084499HPN/?tag=amprioz-21",
    descriptions:
      "The Haier BM(Bottom Mounted) Double Door Refrigerator presents a sleek and aesthetic design with attractive colors that adds a modern and lavish look to your kitchen.This mode allows you to organize and store your food items conveniently without worrying about the refrigeration needs. Its 8 modes are innovatively designed to cater to your usage patterns.Haier's Twin Inverter Technology is a new generation of inverter compressors for refrigerators. This technology ensures that the compressor & fan can run at different speeds according on the load required by the fridge, making it cost & energy efficient.",
  },
  {
    id: 166,
    title:
      "Whirlpool 265 L 3 Star Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    tag: "Double Door Refrigerator",
    brandName: "Whirlpool",
    specilaCategory: "featured",
    keywords: [
      "Whirlpool 265 L 3 Star Inverter Frost-Free Double Door Refrigerator Price",
      "Whirlpool 265 L 3 Star Inverter Frost-Free Double Door Refrigerator Specifications",
      "Whirlpool 265 L 3 Star Inverter Frost-Free Double Door Refrigerator Features",
      "Whirlpool 265 L 3 Star Inverter Frost-Free Double Door Refrigerator price in india",
      "Whirlpool 265 L 3 Star Inverter Frost-Free Double Door Refrigerator",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/51ERFJHqKvL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Whirlpool-Refrigerator-INV-CNV-278/dp/B083JN87RJ/?tag=amprioz-21",
    descriptions:
      "The advanced IntelliSense Invertor Technology efficiently adapts the cooling according to internal load. It not only reduces energy consumption, but also ensures matchless performances even during power cuts.Whirlpool 265 L 3 Star Inverter Frost-Free Double Door Refrigerator Vegetable Crisper with Honey Comb Moisture Lock-in Technology condenses the evaporated moisture from the food to ensure balanced air and optimum freshness.Continuously analysis data to ensure optimum cooling for long lasting freshness.Scientifically designed air tower and strategically placed vents that let out cool and fresh air into different sections of the refrigerator providing uniform cooling for long lasting freshness.",
  },
  {
    id: 167,
    title: "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator",
    category: "appliance",
    tag: "Double Door Refrigerator",
    brandName: "LG",
    specilaCategory: "featured",
    keywords: [
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Price",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Specifications",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator Features",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator price in india",
      "LG 260L 3 Star Smart Inverter Frost-Free Double Door Refrigerator",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/71nd0B5mhFL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/LG-Frost-Free-Refrigerator-GL-S292RDSX-Convertible/dp/B08X711N4H/?tag=amprioz-21",
    descriptions:
      "LG Refrigerators with Smart Inverter Compressor are designed to give energy efficiency, longer freshness retention and less noise.The revolutionary technology helps convert the freezer to fridge, thus increasing the storage capacity of your refrigerator with just one touch.Multiple cooling air vents distribute and circulate cool air to every corner of the refrigerator, ensuring proper cooling.LG's Smart Diagnosis helps diagnose and solve any problems you may have with your refrigerator quickly and correctly.",
  },
  {
    id: 168,
    title:
      "Atomberg Renesa 1200mm BLDC Motor with Remote 3 Blade Energy Saving Ceiling Fan",
    category: "appliance",
    brandName: "Atomberg",
    specilaCategory: "featured",
    keywords: [
      "Atomberg Renesa 1200mm BLDC Motor with Remote 3 Blade Energy Saving Ceiling Fan Price in India",
      "Atomberg Renesa 1200mm BLDC Motor with Remote 3 Blade Energy Saving Ceiling Fan Price List",
      "Atomberg Renesa 1200mm BLDC Motor with Remote 3 Blade Energy Saving Ceiling Fan features",
      "Atomberg Renesa 1200mm BLDC Motor with Remote 3 Blade Energy Saving Ceiling Fan Specifications",
      "Atomberg Renesa 1200mm BLDC Motor with Remote 3 Blade Energy Saving Ceiling Fan Reviews",
      "Atomberg Renesa 1200mm BLDC Motor with Remote 3 Blade Energy Saving Ceiling Fan",
    ],
    tag: "BLDC Ceiling Fan",
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/510MGW+X78L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Gorilla-Renesa-Energy-Ceiling-Control/dp/B07K5CK3WX/?tag=amprioz-21",
    descriptions:
      "The Renesa is built on that belief. All our fans are powered by BLDC technology: Brushless Direct Current. Which means that they consume only 28 to 32 watts while running at the highest speed. Which in turn means that you can save up to Rs.1500 per fan per year on your electricity bills! It doesn’t end there. It runs almost three times longer on inverter. It can be easily operated by a smart remote which has a boost, sleep and timer mode. It is just what your smart home deserves. Now that we have discussed the high-tech stuff, let us tell you about how why our fans are so gorgeous and functional at the same time. This variant has aluminium blades which are completely rust free. The only thing you will get distracted by is the stunning LED indication for speed on the motor. It is just what your gorgeous home deserves. And that is what we do. Make smart things without compromising on the aesthetics.",
  },
  {
    id: 169,
    title:
      "Crompton Silent Pro Enso 1225 mm (48 inch) ActivBLDC Remote Controlled Ceiling Fan",
    category: "appliance",
    brandName: "Crompton",
    specilaCategory: "featured",
    keywords: [
      "Crompton Silent Pro Enso 1225 mm (48 inch) ActivBLDC Remote Controlled Ceiling Fan Price",
      "Crompton Silent Pro Enso 1225 mm (48 inch) ActivBLDC Remote Controlled Ceiling Fan Specifications",
      "Crompton Silent Pro Enso 1225 mm (48 inch) ActivBLDC Remote Controlled Ceiling Fan Features",
      "Crompton Silent Pro Enso 1225 mm (48 inch) ActivBLDC Remote Controlled Ceiling Fan price in india",
      "Crompton Silent Pro Enso 1225 mm (48 inch) ActivBLDC Remote Controlled Ceiling Fan",
    ],
    tag: "BLDC Ceiling Fan",
    rating: 3.4,
    imageLink: "https://m.media-amazon.com/images/I/31aplD+qomL.jpg",
    productLink:
      "https://www.amazon.in/Crompton-Silent-Anti-Dust-Ceiling-Charcoal/dp/B0845FGHCW/?tag=amprioz-21",
    descriptions:
      "SilentPro is designed to offer superior air comfort with the noise level of a Quiet Home. Most conventional fans operate at 64 dB. Every increase of 10 dB in noise level is perceived to be twice as loud. SilentPro operates at 52 dB thereby making it twice more silent than its conventional counterparts. The fluidic seamless design of the blades is modelled to ensure high air delivery - delivering comfort to you when you need it.Crompton's ActivBLDC Motor is known for its high Energy Efficiency. It is designed to work efficiently between a Wide Voltage Range of 90V - 300V with a high Power Factor of 0.98.",
  },
  {
    id: 170,
    title:
      "Crompton Energion HS 1200 mm (48 inch) Energy Efficient 5 Star Rated High Speed BLDC Ceiling Fan",
    category: "appliance",
    brandName: "Crompton",
    specilaCategory: "featured",
    keywords: [
      "Crompton Energion HS 1200 mm (48 inch) Energy Efficient 5 Star Rated High Speed BLDC Ceiling Fan Price",
      "Crompton Energion HS 1200 mm (48 inch) Energy Efficient 5 Star Rated High Speed BLDC Ceiling Fan Specifications",
      "Crompton Energion HS 1200 mm (48 inch) Energy Efficient 5 Star Rated High Speed BLDC Ceiling Fan Features",
      "Crompton Energion HS 1200 mm (48 inch) Energy Efficient 5 Star Rated High Speed BLDC Ceiling Fan price in india",
      "Crompton Energion HS 1200 mm (48 inch) Energy Efficient 5 Star Rated High Speed BLDC Ceiling Fan",
    ],
    tag: "BLDC Ceiling Fan",
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/51jQhUN6oDL._SL1080_.jpg",
    productLink:
      "https://www.amazon.in/Crompton-Energion-HS-Efficient-Brown/dp/B082W5B3DM/?tag=amprioz-21",
    descriptions:
      "Energion HS is powered by ActivBLDC technology which has low power consumption thereby ensuring energy efficiency. It saves more than 50% on your electricity bill.Energion HS saves your money through minimum power consumption. Reducing your monthly electricity bill has never been this easy.This fan is a friend to your wallet. It ensures monthly returns with its energy savings. This is a fan you don't just buy. Rather, you invest in it.Crompton's ActivBLDC motor stands for its high efficiency. This motor is designed to ensure minimal power consumption of 35W and work efficiently between a wide voltage range of 90V-300V.",
  },
  {
    id: 171,
    title: "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan",
    category: "appliance",
    brandName: "Havells",
    specilaCategory: "featured",
    keywords: [
      "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan details",
      "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan price in india",
      "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan feature",
      "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan offers",
      "Havells Efficiencia Neo 1200mm BLDC Motor with remote Ceiling Fan",
    ],
    tag: "BLDC Ceiling Fan",
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/41ZtHebyhuL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Havells-Efficiencia-Neo-1200-Ceiling/dp/B081HKZB56/?tag=amprioz-21",
    descriptions:
      "The Havells Low Power Consumption fans are built with advanced technology that deliver high-speed performance while consuming lower energy than ever.Energy saver ceiling fans are the best buy for their efficient performance and savings on power consumption.Least power consuming fan - 26 W per hour only, Run 3 times longer than regular fan while on invertor power.Ribbed blades and beautiful bottom motor cover.Easy to operate with remote control.",
  },
  {
    id: 172,
    title:
      "Atomberg Renesa+ 1200 mm BLDC Motor with Remote 3 Blade Ceiling Fan",
    category: "appliance",
    tag: "BLDC Ceiling Fan",
    brandName: "Atomberg",
    specilaCategory: "featured",
    keywords: [
      "Atomberg Renesa+ 1200 mm BLDC Motor with Remote 3 Blade Ceiling Fan Price",
      "Atomberg Renesa+ 1200 mm BLDC Motor with Remote 3 Blade Ceiling Fan Specifications",
      "Atomberg Renesa+ 1200 mm BLDC Motor with Remote 3 Blade Ceiling Fan Features",
      "Atomberg Renesa+ 1200 mm BLDC Motor with Remote 3 Blade Ceiling Fan price in india",
      "Atomberg Renesa+ 1200 mm BLDC Motor with Remote 3 Blade Ceiling Fan",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/51j-oeuggQL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Atomberg-Renesa-Motor-Remote-Ceiling/dp/B08ZNP3PK1/?tag=amprioz-21",
    descriptions:
      "The Renesa+ is built on that belief. All our fans are powered by BLDC technology: Brushless Direct Current. Which means that they consume only 28 to 32 watts while running at the highest speed. Which in turn means that you can save up to Rs.1500 per fan per year on your electricity bills! It doesn’t end there. It runs almost three times longer on inverter. It can be easily operated by a smart remote which has a boost, sleep and timer mode. It is just what your smart home deserves. Now that we have discussed the high-tech stuff, let us tell you about how why our fans are so gorgeous and functional at the same time. This variant has a sleek metallic & wooden finish which is easy to clean. Say no to rust and dust! The longer span of the blades results in wider air spread and better air delivery. The only thing you will get distracted by is the stunning LED indication for speed on the motor. It is just what your gorgeous home deserves.",
  },
  {
    id: 173,
    title:
      "Orient Electric I-Tome 1200mm 26W Intelligent BLDC Energy Saving Ceiling Fan",
    category: "appliance",
    tag: "BLDC Ceiling Fan",
    brandName: "Orient",
    specilaCategory: "rated",
    keywords: [
      "Orient Electric I-Tome 1200mm 26W Intelligent BLDC Energy Saving Ceiling Fan",
      "Orient Electric I-Tome 1200mm 26W Intelligent BLDC Energy Saving Ceiling Fan price in india",
      "Orient Electric I-Tome 1200mm 26W Intelligent BLDC Energy Saving Ceiling Fan specifications",
      "Orient Electric I-Tome 1200mm 26W Intelligent BLDC Energy Saving Ceiling Fan reviews",
      "Orient Electric I-Tome 1200mm 26W Intelligent BLDC Energy Saving Ceiling Fan offers",
    ],
    rating: 3.7,
    imageLink: "https://m.media-amazon.com/images/I/617-auOrlHL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Orient-Electric-1200mm-ceiling-Remote/dp/B09BJG5K8J/?tag=amprioz-21",
    descriptions:
      "Energy-efficient motor consumes only 26 W of energy at the highest speed; being a 5-star rated fan.Enjoy silent operation of fan through intelligent BLDC motor.Runs consistently at the same speed even at low and fluctuating voltages between 140-270 W.Control your fan at the click of a button! Orient’s smart remote comes with 5 speed settings and smart features like Boost mode and Timer functionality for 2, 4, 6 and 8 hours.Get your I-Tome installed for free just by calling our customer care number.",
  },
  {
    id: 174,
    title:
      "Polycab Eteri BLDC Energy Efficient 5 Star Rated 1200 mm High Speed Ceiling Fan",
    category: "appliance",
    tag: "BLDC Ceiling Fan",
    brandName: "Polycab",
    specilaCategory: "featured",
    keywords: [
      "Polycab Eteri BLDC Energy Efficient 5 Star Rated 1200 mm High Speed Ceiling Fan",
      "Polycab Eteri BLDC Energy Efficient 5 Star Rated 1200 mm High Speed Ceiling Fan Price in India",
      "Polycab Eteri BLDC Energy Efficient 5 Star Rated 1200 mm High Speed Ceiling Fan Price",
      "Polycab Eteri BLDC Energy Efficient 5 Star Rated 1200 mm High Speed Ceiling Fan Specs",
      "Polycab Eteri BLDC Energy Efficient 5 Star Rated 1200 mm High Speed Ceiling Fan features",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/41vkF7XCuGL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Polycab-Energy-Efficient-Ceiling-warranty/dp/B09QC7NQBT/?tag=amprioz-21",
    descriptions:
      "Polycab India Limited is India’s largest manufacturer of Wires and Cables and one of the fastest growing consumer electrical goods company. Polycab is at the forefront of providing innovative, safe and energy efficient products to a diverse set of customers via a strong distribution network. Its consumer portfolio includes Fans, Lighting and Luminaries, Switches, Switchgears, Water Heaters and Irons.",
  },
  {
    id: 175,
    title: "Halonix Plasma BLDC 1200mm 32W Remote Controlled Ceiling Fan",
    category: "appliance",
    tag: "BLDC Ceiling Fan",
    brandName: "Halonix",
    specilaCategory: "rated",
    keywords: [
      "Halonix Plasma BLDC 1200mm 32W Remote Controlled Ceiling Fan",
      "Halonix Plasma BLDC 1200mm 32W Remote Controlled Ceiling Fan Price in India",
      "Halonix Plasma BLDC 1200mm 32W Remote Controlled Ceiling Fan Price",
      "Halonix Plasma BLDC 1200mm 32W Remote Controlled Ceiling Fan Specs",
      "Halonix Plasma BLDC 1200mm 32W Remote Controlled Ceiling Fan features",
    ],
    rating: 4.3,
    imageLink: "https://m.media-amazon.com/images/I/51QjpNsX42L._SL1278_.jpg",
    productLink:
      "https://www.amazon.in/Halonix-Plasma-1200mm-Ceiling-Remote/dp/B07NBWNKNC/?tag=amprioz-21",
    descriptions:
      "Halonix Plasma Fan with its unique zero-friction technology saves upto 70 percent electricity and increases durability by upto 2x. With the added convenience of a remote control, Halonix Plasma is financially and environmentally the smart choice for your dream homeSave money per year on your electricity bill with each BLDC ceiling fan.Consumes only 28W on the highest Speed;Smart Remote control ceiling fan with a feature like Speed Control.Runs consistently at the same speed even with fluctuating input voltage.A perfect remote control fan makes it an ideal choice for the home.",
  },
  {
    id: 176,
    title: "Atomberg Studio+ 1200mm 32W BLDC Motor Energy Saving Ceiling Fan",
    category: "appliance",
    tag: "BLDC Ceiling Fan",
    brandName: "Atomberg",
    specilaCategory: "featured",
    keywords: [
      "Atomberg Studio+ 1200mm 32W BLDC Motor Energy Saving Ceiling Fan Price",
      "Atomberg Studio+ 1200mm 32W BLDC Motor Energy Saving Ceiling Fan Specifications",
      "Atomberg Studio+ 1200mm 32W BLDC Motor Energy Saving Ceiling Fan Features",
      "Atomberg Studio+ 1200mm 32W BLDC Motor Energy Saving Ceiling Fan price in india",
      "Atomberg Studio+ 1200mm 32W BLDC Motor Energy Saving Ceiling Fan",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/51KAeTwaG-L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Atomberg-Studio-Ceiling-Formerly-Gorilla/dp/B086Z83226/?tag=amprioz-21",
    descriptions:
      "The Studio+ is built on that belief. All our fans are powered by BLDC technology: Brushless Direct Current. Which means that they consume only 32 watts while running at the highest speed. Which in turn means that you can save up to Rs.1500 per fan per year on your electricity bills! It doesn’t end there. It runs almost three times longer on inverter. It can be easily operated by a smart remote which has a boost, sleep and timer mode. It is just what your smart home deserves. Now that we have discussed the high-tech stuff, let us tell you about how why our fans are so gorgeous and functional at the same time. This variant has a sleek metallic finish that is easy to clean. Say no to rust and dust! The longer span of the blades results in wider air spread and better air delivery. The only thing you will get distracted by is the stunning LED indication for speed on the motor. It is just what your gorgeous home deserves. And that is what we do. Make smart things without compromising on the aesthetics.",
  },
  {
    id: 177,
    title:
      "Luminous Potentia 1200mm BLDC Motor with Remote 3 BLADE Ceiling Fan",
    category: "appliance",
    tag: "BLDC Ceiling Fan",
    brandName: "Luminous",
    specilaCategory: "featured",
    keywords: [
      "Luminous Potentia 1200mm BLDC Motor with Remote 3 BLADE Ceiling Fan Price",
      "Luminous Potentia 1200mm BLDC Motor with Remote 3 BLADE Ceiling Fan Specifications",
      "Luminous Potentia 1200mm BLDC Motor with Remote 3 BLADE Ceiling Fan Features",
      "Luminous Potentia 1200mm BLDC Motor with Remote 3 BLADE Ceiling Fan price in india",
      "Luminous Potentia 1200mm BLDC Motor with Remote 3 BLADE Ceiling Fan",
    ],
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61ABmNkd0YL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Luminous-Potentia-1200mm-Remote-Ceiling/dp/B08D4CDG9Y/?tag=amprioz-21",
    descriptions:
      "It includes 1 1200mm BLDC Motor with Remote 3 BLADE Ceiling Fan.Fan can easily be controlled with remote control with Timer Mode and speed control and is voltage fluctuation proof.It is 50% Energy Saving fan.Fan is fantastic and most important part was that It was installed my Luminous care without charging any fee.",
  },
  {
    id: 178,
    title: "Lifelong LLGS303 Auto Ignition High Efficiency 3 Burner Gas Stove",
    category: "appliance",
    brandName: "Lifelong",
    specilaCategory: "featured",
    keywords: [
      "Lifelong LLGS303 Auto Ignition High Efficiency 3 Burner Gas Stove Price in India",
      "Lifelong LLGS303 Auto Ignition High Efficiency 3 Burner Gas Stove Price List",
      "Lifelong LLGS303 Auto Ignition High Efficiency 3 Burner Gas Stove features",
      "Lifelong LLGS303 Auto Ignition High Efficiency 3 Burner Gas Stove Specifications",
      "Lifelong LLGS303 Auto Ignition High Efficiency 3 Burner Gas Stove Reviews",
      "Lifelong LLGS303 Auto Ignition High Efficiency 3 Burner Gas Stove",
    ],
    tag: "Gas Stove",
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/71VOALdwbKL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Lifelong-LLGS303-Ignition-Toughened-Certified/dp/B0922WMT73/?tag=amprioz-21",
    descriptions:
      "The Gas Stove is a versatile kitchen appliance that is built for convenience, built for India. Designed to make cooking convenient, efficient and safe, this gas stove is engineered to avoid any gas leakage. It is equipped with burners made of high efficiency burners and a gas inlet nozzle on the backside. Trusted by loyal users for over five years, we offer you great value for money. With more than 100 innovative products available across categories, it’s no wonder millions of shoppers choose Lifelong.The purpose of these supports is to provide ample support at the base of the pan such that they maintain their balance on the stove and do not fall off easily.Push the Knob and rotate to Switch-On the product. Auto-ignition making the stove easy to use.",
  },
  {
    id: 179,
    title: "Pigeon by Stovekraft Favourite Glass Top 3 Burner Gas Stove",
    category: "appliance",
    brandName: "Pigeon",
    specilaCategory: "featured",
    keywords: [
      "Pigeon by Stovekraft Favourite Glass Top 3 Burner Gas Stove Price",
      "Pigeon by Stovekraft Favourite Glass Top 3 Burner Gas Stove Specifications",
      "Pigeon by Stovekraft Favourite Glass Top 3 Burner Gas Stove Features",
      "Pigeon by Stovekraft Favourite Glass Top 3 Burner Gas Stove price in india",
      "Pigeon by Stovekraft Favourite Glass Top 3 Burner Gas Stove",
    ],
    tag: "Gas Stove",
    rating: 3.8,
    imageLink: "https://m.media-amazon.com/images/I/61+Ig+JW73L._SL1390_.jpg",
    productLink:
      "https://www.amazon.in/Pigeon-Favourite-Burner-Black-stove/dp/B00WKVDRH0/?tag=amprioz-21",
    descriptions:
      "The Pigeon Favourite Gas Stove is designed to fulfill your needs both in terms of efficiency as well as style. The gas stove is quite compact with enough space for you to use larger dishes with strong tabular legs. The even distribution of the flame ensures a perfect cooking experience and optimum fuel utilization every time.The Toughened glass gives your kitchen a touch of elegance and a premium feel.The pan supports provides ample support to balance the pan.The ergonomically designed knob ensures smooth functioning and easy to handle.",
  },
  {
    id: 180,
    title: "Sunflame PRIDE 3 Burner Gas Stove",
    category: "appliance",
    brandName: "Sunflame",
    specilaCategory: "featured",
    keywords: [
      "Sunflame PRIDE 3 Burner Gas Stove Price",
      "Sunflame PRIDE 3 Burner Gas Stove Specifications",
      "Sunflame PRIDE 3 Burner Gas Stove Features",
      "Sunflame PRIDE 3 Burner Gas Stove price in india",
      "Sunflame PRIDE 3 Burner Gas Stove",
    ],
    tag: "Gas Stove",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/511ZOC9mTJL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Sunflame-Pride-Burner-Stove-Black/dp/B07CWW6QK2/?tag=amprioz-21",
    descriptions:
      "Sunflame PRIDE 3BR BK comes with Powder coated Steel Metal Base which makes finished surfaces resistant to scratching, wearing, fading and chipping. Overall it makes the Sunflame cooktop much hard and durable to last really long.Sunflame High efficiency brass gas burners are more suitable as they are more durable and resistant to corrosion and high heat With these High efficiency brass burners even small utensils can be used and it helps in cooking faster and uniform heat distribution.Overall , these brass burners are more corrosion resistant and have a higher life.",
  },
  {
    id: 181,
    title: "Elica Vetro Glass Top 3 Burner Gas Stove",
    category: "appliance",
    brandName: "Elica",
    specilaCategory: "featured",
    keywords: [
      "Elica Vetro Glass Top 3 Burner Gas Stove details",
      "Elica Vetro Glass Top 3 Burner Gas Stove price in india",
      "Elica Vetro Glass Top 3 Burner Gas Stove feature",
      "Elica Vetro Glass Top 3 Burner Gas Stove offers",
      "Elica Vetro Glass Top 3 Burner Gas Stove",
    ],
    tag: "Gas Stove",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/61khxNM45oL._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/Elica-Burner-703-CT-VETRO/dp/B07C1ZMK8B/?tag=amprioz-21",
    descriptions:
      "Highlighted with quality toughened glass top that will surely blend with all modern kitchen décor. The toughened glass will not only impress you with its beauty but also will surprise you with its durability.Designed with popular durable finish standards to ensure genuine & rigid performance. The Euro coated grid supports also prevents the pan from chipping off while providing astonishing care while you cook.Crafted using toughened glass top which not only makes it durable and long-lasting but also beautifies the aesthetics of your kitchen. The material with which the glass top is made imparts a lot of strength to it and prevents minor breakage with ease.",
  },
  {
    id: 182,
    title: "Butterfly Smart Glass 3 Burner Gas Stove",
    category: "appliance",
    tag: "Gas Stove",
    brandName: "Butterfly",
    specilaCategory: "featured",
    keywords: [
      "Butterfly Smart Glass 3 Burner Gas Stove Price",
      "Butterfly Smart Glass 3 Burner Gas Stove Specifications",
      "Butterfly Smart Glass 3 Burner Gas Stove Features",
      "Butterfly Smart Glass 3 Burner Gas Stove price in india",
      "Butterfly Smart Glass 3 Burner Gas Stove",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/51TYBmz4JXL._SL1184_.jpg",
    productLink:
      "https://www.amazon.in/Butterfly-Smart-Glass-Burner-Stove/dp/B07KMD9Y6C/?tag=amprioz-21",
    descriptions:
      "If you are looking for a gas stove which will help you save both time and energy in the kitchen, opt for the Smart Glass Top stove by Butterfly. The product has quality burners, ranging from small to big size options for regulating your cooking requirements appropriately. The sleek look of the burner adds a modern dimension to your kitchen, while also serving the purpose of fast cooking. The product has an easy-to-use smart lock pan support and spill tray which ensures your convenience at the kitchen.",
  },
  {
    id: 183,
    title: "Lifelong LLGS18 Glass Top 3 Burner Gas Stove",
    category: "appliance",
    tag: "Gas Stove",
    brandName: "Lifelong",
    specilaCategory: "rated",
    keywords: [
      "Lifelong LLGS18 Glass Top 3 Burner Gas Stove",
      "Lifelong LLGS18 Glass Top 3 Burner Gas Stove price in india",
      "Lifelong LLGS18 Glass Top 3 Burner Gas Stove specifications",
      "Lifelong LLGS18 Glass Top 3 Burner Gas Stove reviews",
      "Lifelong LLGS18 Glass Top 3 Burner Gas Stove offers",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/51p4f4EpcML._SL1000_.jpg",
    productLink:
      "https://www.amazon.in/Lifelong-Burner-warranty-Doorstep-Service/dp/B077X9YMCN/?tag=amprioz-21",
    descriptions:
      "The gas stove is an elegant product that gives you great value for money. Designed to make cooking convenient, efficient and safe, this gas stove is engineered to avoid any gas leakage. It is equipped with burners made of brass. With more than 100 innovative products, it is no wonder that millions of customers continue to choose Lifelong! It has strongly been trusted by loyal consumers for over five years. Made for you, and made especially for your culinary convenience.It’s equipped with burners made of premium quality of brass. The burners are also designed in a way to enhance even distribution of the flame and heat.",
  },
  {
    id: 184,
    title: "Prestige IRIS LPG Gas Stove, 3 Burner",
    category: "appliance",
    tag: "Gas Stove",
    brandName: "Prestige",
    specilaCategory: "featured",
    keywords: [
      "Prestige IRIS LPG Gas Stove, 3 Burner",
      "Prestige IRIS LPG Gas Stove, 3 Burner Price in India",
      "Prestige IRIS LPG Gas Stove, 3 Burner Price",
      "Prestige IRIS LPG Gas Stove, 3 Burner Specs",
      "Prestige IRIS LPG Gas Stove, 3 Burner features",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/614Q2qMRVBL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Prestige-IRIS-Stove-Burner-Black/dp/B08HNHNHSS/?tag=amprioz-21",
    descriptions:
      "Prestige is one of India’s largest kitchen appliance brand.It is built on the pillars of safety, innovation, durability & trust. Continuous market research & analysis is done to modify product offerings, introduce brand extensions and innovate new models that dictate the needs of an evolving consumer.Its Spill-proof design for hassle-free cooking and cleaner kitchen.Tri-pin brass burners of different sizes for all your cooking needs.Ergonomic knob design makes turning the knob, easy on the fingers.Toughened black-glass top resists scratches and withstands everyday wear and tear.",
  },
  {
    id: 185,
    title: "Thermador Toughened ISI Certified 3 Brass Burner Glass Gas Stove",
    category: "appliance",
    tag: "Gas Stove",
    brandName: "Thermador",
    specilaCategory: "rated",
    keywords: [
      "Thermador Toughened ISI Certified 3 Brass Burner Glass Gas Stove",
      "Thermador Toughened ISI Certified 3 Brass Burner Glass Gas Stove Price in India",
      "Thermador Toughened ISI Certified 3 Brass Burner Glass Gas Stove Price",
      "Thermador Toughened ISI Certified 3 Brass Burner Glass Gas Stove Specs",
      "Thermador Toughened ISI Certified 3 Brass Burner Glass Gas Stove features",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/61uQak884oL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Thermador-Toughened-Glass-Burner-Ignition/dp/B08CKYNY18/?tag=amprioz-21",
    descriptions:
      "Combining Elegance with highly efficient brass burners along with a shatterproof black design glass top, Thermador gives you a product suited best for your kitchen. Thermador gas stoves are designed to blend in your kitchen and make it safe.It is Heavy Duty Pan Supports for Stable cooking pots.Designed to accommodate big utensils easily. The body material is Mild steel, the top is toughened glass & the burners are made of brass.Its Royal black coating on the body for rust free long life.",
  },
  {
    id: 186,
    title:
      "MILTON Premium 3 Burner Blue Manual Ignition LPG Glass Top Gas Stove",
    category: "appliance",
    tag: "Gas Stove",
    brandName: "MILTON",
    specilaCategory: "featured",
    keywords: [
      "MILTON Premium 3 Burner Blue Manual Ignition LPG Glass Top Gas Stove Price",
      "MILTON Premium 3 Burner Blue Manual Ignition LPG Glass Top Gas Stove Specifications",
      "MILTON Premium 3 Burner Blue Manual Ignition LPG Glass Top Gas Stove Features",
      "MILTON Premium 3 Burner Blue Manual Ignition LPG Glass Top Gas Stove price in india",
      "MILTON Premium 3 Burner Blue Manual Ignition LPG Glass Top Gas Stove",
    ],
    rating: 4,
    imageLink: "https://m.media-amazon.com/images/I/71kXl-AOoML._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Milton-Premium-Burner-Burners-Certified/dp/B082XDCG6V/?tag=amprioz-21",
    descriptions:
      "If you are looking for a Gas Stove which will help you save both Time and Energy in the Kitchen, opt for the Premium Glass Top Gas Stove by Milton Home Appliances. The product has high quality Brass Burners, ranging from small to big size options for regulating your cooking requirements appropriately. The sleek look of the burner adds a modern dimension to your kitchen, while also serving the purpose of fast cooking. Milton gas stoves are designed to blend in your kitchen and make it safe & look beautiful. It is easy to maintain & clean.Modernize your kitchen space with Milton 3 burner Gas Stove and embrace a stylish design along with advanced features. Milton eases your everyday cooking efficiently and comes in a premium finish body to make your kitchen spacious and appealing. You can easily place this over your kitchen counter without taking much space and the burners of the gas stove ensure even heat distribution for an excellent cooking at low gas consumption.",
  },
  {
    id: 187,
    title: "Amazon Brand - Solimo 3 Burner Glass Top Gas Stove ",
    category: "appliance",
    tag: "Gas Stove",
    brandName: "Amazon Brand - Solimo",
    specilaCategory: "featured",
    keywords: [
      "Amazon Brand - Solimo 3 Burner Glass Top Gas Stove Price",
      "Amazon Brand - Solimo 3 Burner Glass Top Gas Stove Specifications",
      "Amazon Brand - Solimo 3 Burner Glass Top Gas Stove Features",
      "Amazon Brand - Solimo 3 Burner Glass Top Gas Stove price in india",
      "Amazon Brand - Solimo 3 Burner Glass Top Gas Stove",
    ],
    rating: 3.9,
    imageLink: "https://m.media-amazon.com/images/I/71UpfJsKIYL._SL1500_.jpg",
    productLink: "https://www.amazon.in/dp/B08GSY7PDP/?tag=amprioz-21",
    descriptions:
      "Solimo Gas Stoves come with a spill-proof drip tray design free from openings to avoid any overflow below the gas stove to keep it easy to clean and maintain. The 360 degree swivel type gas inlet is provided on the back towards the right side for easy accessibility.The gas stove has a reliable rigid construction and is made with a black steel base enhanced with 6 mm toughened steel glass for added durability.The burners are designed with 3 concentric arrays of flame exits and made of brass for uniform heat distribution and efficient long lasting usage.The Pan Supports are designed to accommodate all major sizes of pans and topes.",
  },
  {
    id: 188,
    title: "Panasonic SR-WA22H (E) Automatic Rice Cooker",
    category: "appliance",
    brandName: "Panasonic",
    specilaCategory: "featured",
    keywords: [
      "Panasonic SR-WA22H (E) Automatic Rice Cooker Price in India",
      "Panasonic SR-WA22H (E) Automatic Rice Cooker Price List",
      "Panasonic SR-WA22H (E) Automatic Rice Cooker features",
      "Panasonic SR-WA22H (E) Automatic Rice Cooker Specifications",
      "Panasonic SR-WA22H (E) Automatic Rice Cooker Reviews",
      "Panasonic SR-WA22H (E) Automatic Rice Cooker",
      "Rice Cooker",
    ],
    tag: "Rice Cooker",
    rating: 4.2,
    imageLink: "https://m.media-amazon.com/images/I/419H62Is66L.jpg",
    productLink:
      "https://www.amazon.in/Panasonic-SR-WA22H-5-4-Litre-Automatic-Cooker/dp/B00A328ENA/?tag=amprioz-21",
    descriptions:
      "The Panasonic rice cooker has a sturdy make and elegant design which adds to the look of your kitchen. It comes with high quality anodized aluminium cooking pan, cooking plate, measuring cup and one scoop.The Panasonic Electric Cooker has a good capacity of 2.2 liter which is ideal for a family of 4-5, or any small house party. Cook the delicious food for family guest and make them happy. You can cook up to 1.25 Kg of rice at a time in this Cooker.The body of the cooker is made of premium quality CRCA, food-grade material and the lid is made of tough stainless steel. The cooking pan of cooker is made of anodized aluminium which is extremely durable. This is a ROHS compliant product.",
  },
  {
    id: 189,
    title: "Bajaj RCX 5 1.8 Liters Rice Cooker, White",
    category: "appliance",
    brandName: "Bajaj",
    specilaCategory: "featured",
    keywords: [
      "Bajaj RCX 5 1.8 Liters Rice Cooker, White Price",
      "Bajaj RCX 5 1.8 Liters Rice Cooker, White Specifications",
      "Bajaj RCX 5 1.8 Liters Rice Cooker, White Features",
      "Bajaj RCX 5 1.8 Liters Rice Cooker, White price in india",
      "Bajaj RCX 5 1.8 Liters Rice Cooker, White Reviews",
      "Bajaj RCX 5 1.8 Liters Rice Cooker, White",
      "Rice Cooker",
    ],
    tag: "Rice Cooker",
    rating: 3.7,
    imageLink: "https://m.media-amazon.com/images/I/41iUSwlbh5L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bajaj-RCX-1-8-Litre-Rice-Cooker/dp/B00650QJ8G/?tag=amprioz-21",
    descriptions:
      "Cooking rice is now a matter of minutes that requires no supervision at all with the RCX5 majestic rice cooker from Bajaj. The innovative cooker is fashioned in a supreme all white outer body. Hassle free mechanism requires one to pour the requisite amount of water inside the cooker and add the raw rice in it to cook it evenly. It consumes just 700 watts of electricity every hour and delivers well cooked rice in under a few minutes. Once cooked it is ready for consumption right out of the container.This Bajaj RCX 5 1.8-Litre Rice Cooker houses an aluminium cooking bowl with anodized finish. This anodized finish gives the bowl durability and makes it corrosion resistant. Also, the anodized aluminium bowl absorbs heats fast and saves cooking time.",
  },
  {
    id: 190,
    title: "USHA RC18GS2 Steamer 700 Watt Automatic Rice Cooker",
    category: "appliance",
    brandName: "USHA",
    specilaCategory: "featured",
    keywords: [
      "USHA RC18GS2 Steamer 700 Watt Automatic Rice Cooker Price",
      "USHA RC18GS2 Steamer 700 Watt Automatic Rice Cooker Specifications",
      "USHA RC18GS2 Steamer 700 Watt Automatic Rice Cooker Features",
      "USHA RC18GS2 Steamer 700 Watt Automatic Rice Cooker price in india",
      "USHA RC18GS2 Steamer 700 Watt Automatic Rice Cooker",
      "Rice Cooker",
    ],
    tag: "Rice Cooker",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/71fjmZgxuoL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/USHA-Multi-3718-700-Watt-Cooker-White/dp/B07M5ZHXZ1/?tag=amprioz-21",
    descriptions:
      "You can easily and effortlessly prepare the dishes of your choice in the Usha RC18GS2 Rice Cooker. Its tempered glass lid allows you to keep an eye on how far your food has cooked without removing the lid. In addition, the lid is sturdy and the steel rim around it ensures that it can withstand everyday hits for enduring performance. Moreover, featuring two heating elements, this electric cooker offers separate cooking and heating elements to cook food and then automatically keep food warm for up to five hours at up to 60°C.Available with two heating elements, this electric cooker offers separate cooking and heating elements to cook food and then automatically keep food warm for up to five hours at up to 60°C.",
  },
  {
    id: 191,
    title: "Prestige Delight PRWO Electric Rice Cooker",
    category: "appliance",
    brandName: "Prestige",
    specilaCategory: "featured",
    keywords: [
      "Prestige Delight PRWO Electric Rice Cooker details",
      "Prestige Delight PRWO Electric Rice Cooker price in india",
      "Prestige Delight PRWO Electric Rice Cooker feature",
      "Prestige Delight PRWO Electric Rice Cooker offers",
      "Prestige Delight PRWO Electric Rice Cooker",
      "Rice Cooker",
    ],
    tag: "Rice Cooker",
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/51ERw-rWRPL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Prestige-Delight-PRWO-1-Litre-Electric/dp/B01M6453MB/?tag=amprioz-21",
    descriptions:
      "Presenting an exciting range of electric rice cookers that not only cook rice but an entire menu. Cook porridge, soup, stew, pulao, idlis, boil vegetables in a convenient and hassle-free way. With its advanced features, Prestige Rice Cooker is an indispensable kitchen appliance to own. It comes with 1 Litre capacity and 400 watts motor.Prestige electric Rice Cooker has durable double wall strong body which protects the cooker from dents and damages there by enhancing the life of the cooker.Convenient cool touch handles make it easy to carry the cooker, even when the food inside it is hot. Thus making it extremely convenient to use.",
  },
  {
    id: 192,
    title: "Panasonic Automatic Cooker SR-WA10 E",
    category: "appliance",
    tag: "Rice Cooker",
    brandName: "Panasonic",
    specilaCategory: "featured",
    keywords: [
      "Panasonic Automatic Cooker SR-WA10 E Price",
      "Panasonic Automatic Cooker SR-WA10 E Specifications",
      "Panasonic Automatic Cooker SR-WA10 E Features",
      "Panasonic Automatic Cooker SR-WA10 E price in india",
      "Panasonic Automatic Cooker SR-WA10 E",
      "Rice Cooker",
    ],
    rating: 4.1,
    imageLink: "https://m.media-amazon.com/images/I/91KWaLClV3L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Panasonic-SR-WA10-450-Watt-Automatic-without/dp/B00A7PK0Z8/?tag=amprioz-21",
    descriptions:
      "The Panasonic Automatic Cooker has a good capacity of 1 liter which is ideal for a small nuclear family to make delicious food. You can cook up to 0.6 Kg of rice at a time in this cooker.The body of the cooker is made of premium quality CRCA, food-grade material and the lid is made of tough stainless steel. The cooking pan of rice cooker is made of anodized aluminium which is extremely durable.Panasonic automatic cooker has a good capacity of 1 liter and can cook up to 0.6 Kg of Raw Rice. It has a sturdy make and elegant design which adds to the look of your kitchen. It comes with high quality anodized aluminium cooking pan, cooking plate, measuring cup and one scoop.",
  },
  {
    id: 193,
    title:
      "CUCKOO Multifunctional Rice Cooker with 13 Menu options & Digital Display",
    category: "appliance",
    tag: "Rice Cooker",
    brandName: "CUCKOO",
    specilaCategory: "rated",
    keywords: [
      "CUCKOO Multifunctional Rice Cooker with 13 Menu options & Digital Display",
      "CUCKOO Multifunctional Rice Cooker with 13 Menu options & Digital Display price in india",
      "CUCKOO Multifunctional Rice Cooker with 13 Menu options & Digital Display specifications",
      "CUCKOO Multifunctional Rice Cooker with 13 Menu options & Digital Display reviews",
      "CUCKOO Multifunctional Rice Cooker with 13 Menu options & Digital Display offers",
      "Rice Cooker",
    ],
    rating: 4.5,
    imageLink: "https://m.media-amazon.com/images/I/618t2JVy-kL._SL1496_.jpg",
    productLink:
      "https://www.amazon.in/CUCKOO-Multifunctional-options-Digital-Nonstick/dp/B08WFNV82W/?tag=amprioz-21",
    descriptions:
      "Preparing delicious meals is made easy with the CR-0675F Rice Cooker. Ranging from rice dishes to savory stews, the versatile rice cooker includes versatile menu options that can help cook your favorite cuisine. Cook the rice you enjoy with the 3 rice cooking options sticky, soft, and savory. The detachable lid, non-stick inner pot, excess water drainage system, and automatic steam clean program helps keep your rice cooker clean and your dishes tasting great.This Cuckoo Rice Cooker provides a variety of menu options and cooking functions to help you prepare delicious dishes for you and your family. Easily navigate through the many features with the touch control panel.",
  },
  {
    id: 194,
    title: "Brayden Rizo 500 Watts Electric Rice Cooker",
    category: "appliance",
    tag: "Rice Cooker",
    brandName: "Brayden",
    specilaCategory: "featured",
    keywords: [
      "Brayden Rizo 500 Watts Electric Rice Cooker",
      "Brayden Rizo 500 Watts Electric Rice Cooker Price in India",
      "Brayden Rizo 500 Watts Electric Rice Cooker Price",
      "Brayden Rizo 500 Watts Electric Rice Cooker Specs",
      "Brayden Rizo 500 Watts Electric Rice Cooker features",
      "Rice Cooker",
    ],
    rating: 3.5,
    imageLink: "https://m.media-amazon.com/images/I/6155tAJ79vL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Brayden-Electric-One-Step-Automatic-Cooking/dp/B07VD64TKZ/?tag=amprioz-21",
    descriptions:
      "Fluffy tender rice can be cooked in just the flick of a switch. Keep warm is a bounty.Busy bee like you need an automatic cooker. Auto shut off features cuts the power once soft puffy rice is ready.Larger 1.5 litre cooking bowl makes cooking better with 500W power. Durable Anodised Aluminium Pan to Cook & Keep Warm of Oatmeal, soups, stew, quinoa, pasta and even steamed veggies.White, Brown, Yellow, Short or Long grain rice is easy deal. Make even oatmeal, Soups, Stew, Quinoa, Pasta-food that you'll love.",
  },
  {
    id: 195,
    title: "Bajaj RCX 7 1.8 Liters Rice Cooker, Multicolour",
    category: "appliance",
    tag: "Rice Cooker",
    brandName: "Bajaj",
    specilaCategory: "rated",
    keywords: [
      "Bajaj RCX 7 1.8 Liters Rice Cooker, Multicolour",
      "Bajaj RCX 7 1.8 Liters Rice Cooker, Multicolour Price in India",
      "Bajaj RCX 7 1.8 Liters Rice Cooker, Multicolour Price",
      "Bajaj RCX 7 1.8 Liters Rice Cooker, Multicolour Specs",
      "Bajaj RCX 7 1.8 Liters Rice Cooker, Multicolour features",
      "Rice Cooker",
    ],
    rating: 3.8,
    imageLink: "https://m.media-amazon.com/images/I/6113uy3e1fL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Bajaj-RCX-1-8-Litre-550-Watt-Multi-colour/dp/B009SZFWDI/?tag=amprioz-21",
    descriptions:
      "Hardly any Indian is unaware of what a pressure cooker looks or sounds like. The sound of its whistle automatically makes our mouth water at the thought of the delicious food cooking inside it. Be it dal, chhole, rajma or meat, a pressure cooker would make a short work of cooking them all. Bajaj Pressure Cooker range is designed to cater to every budget and type of consumer. One can choose from a whole host of features like different capacities, options of outer or inner lid and even different bases (induction, non-induction). Safe to use and easy to clean, all Bajaj Pressure Cooker come with a 2-year warranty.",
  },
  {
    id: 196,
    title: "Prestige PRWO 1.8 Liters Red Colour Rice Cooker",
    category: "appliance",
    tag: "Rice Cooker",
    brandName: "Prestige",
    specilaCategory: "featured",
    keywords: [
      "Prestige PRWO 1.8 Liters Red Colour Rice Cooker Price",
      "Prestige PRWO 1.8 Liters Red Colour Rice Cooker Specifications",
      "Prestige PRWO 1.8 Liters Red Colour Rice Cooker Features",
      "Prestige PRWO 1.8 Liters Red Colour Rice Cooker price in india",
      "Prestige PRWO 1.8 Liters Red Colour Rice Cooker",
      "Rice Cooker",
    ],
    rating: 3.7,
    imageLink: "https://m.media-amazon.com/images/I/71LbskCbkhL._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Prestige-PRWO-1-8-Colour-Cooker/dp/B083KGRPZF/?tag=amprioz-21",
    descriptions:
      "Presenting an exciting range of electric rice cookers that not only cook rice but an entire menu. Cook porridge, soup, stew, pulao, idlis, boil vegetables in a convenient and hassle-free way. With its advanced features, Prestige Rice Cooker is an indispensable kitchen appliance to own. It comes with 1.8 Litre capacity and 700 watts motor.Prestige Delight Electric Rice Cooker has durable double wall strong body which protects the cooker from dents and damages there by enhancing the life of the cooker.Convenient cool touch handles make it easy to carry the cooker, even when the food inside it is hot. Thus making it extremely convenient to use.",
  },
  {
    id: 197,
    title: "Prestige PRWO 2.8-2 2.80 Liters Rice Cooker",
    category: "appliance",
    tag: "Rice Cooker",
    brandName: "Prestige",
    specilaCategory: "featured",
    keywords: [
      "Prestige PRWO 2.8-2 2.80 Liters Rice Cooker Price",
      "Prestige PRWO 2.8-2 2.80 Liters Rice Cooker Specifications",
      "Prestige PRWO 2.8-2 2.80 Liters Rice Cooker Features",
      "Prestige PRWO 2.8-2 2.80 Liters Rice Cooker price in india",
      "Prestige PRWO 2.8-2 2.80 Liters Rice Cooker",
      "Rice Cooker",
    ],
    rating: 3.8,
    imageLink: "https://m.media-amazon.com/images/I/71I6vBAvT6L._SL1500_.jpg",
    productLink:
      "https://www.amazon.in/Prestige-Electric-PRWO-2-8-2-aluminium/dp/B00935MD7G/?tag=amprioz-21",
    descriptions:
      "Prestige PRWO 2.8-2 2.80 Liters Rice Cooker's Convenient cool touch handles make it easy to carry the cooker.It Comes with high quality stainless steel lid. The lid is provided with steam vent to allow the steam to escape.An additional cooking pan has been provided for your cooking convenience. The graduated aluminium cooking pans allow uniform distribution of heat for efficient and even cooking of rice. The cooking pans can be stacked and stored in the cooker, thereby saving space.The Controls switch lever helps in automatic cooking, i.e., after the rice is cooked, it automatically shifts to “Keep Warm” mode. It also houses 2 indicators- Cook and Warm.",
  },
];
