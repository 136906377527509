import React from "react";
import { Container } from "react-bootstrap";
import LayoutOthers from "../layouts/LayoutOthers";
import AllProducts from "../products/AllProducts";
import Products from "../products/Products";

const AllElectronics = () => {
  const pathname = window.location.pathname;

  return (
    <LayoutOthers>
      <Container style={{ minHeight: 500, marginTop: 50 }}>
        <AllProducts/>
      </Container>
    </LayoutOthers>
  );
};

export default AllElectronics;
