import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
const ProsCons = ({ pros = [], cons = [] }) => {
  return (
    <div
      className="i2-pros-cons-icons i2-pros-cons-main-wrapper theme-i2pc-theme-1000 no-title no-button no-space-between-column no-outer-border no-round-corner no-heading-icon"
      style={{ textAlign: "center" }}
    >
      <div className="i2-pros-cons-wrapper">
        <div className="i2-pros">
          <strong className="i2-pros-title">Pros</strong>
          <div className="section">
            <ul className="has-icon">
              {pros.length > 0 &&
                pros.map((item, index) => {
                  return (
                    <li key={index}>
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        style={{ color: "green" }}
                      />{" "}
                      {pros[index]}
                    </li>
                  );
                })}
              {/* <li>
                <FontAwesomeIcon icon={faThumbsUp} style={{ color: "green" }} />{" "}
                Excellent performance
              </li>
              <li>
                <FontAwesomeIcon icon={faThumbsUp} style={{ color: "green" }} />{" "}
                Light and thin
              </li> */}
            </ul>
          </div>
        </div>
        <div className="i2-cons">
          <strong className="i2-cons-title">Cons</strong>
          <div className="section">
            <ul className="has-icon">
              {cons.length > 0 &&
                cons.map((item, index) => {
                  return (
                    <li key={index}>
                      <FontAwesomeIcon
                        icon={faThumbsDown}
                        style={{ color: "red" }}
                      />{" "}
                      {cons[index]}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProsCons;
