import React from "react";
import Classroom from "../courses/Classroom";
import Feature from "../layouts/Feature";

import Layout from "../layouts/Layout";
import AllProducts from "../products/AllProducts";
import CategorySection from "./CategorySection";
const Home = () => {
  return (
    <Layout>
      <Feature />
      {/* <CategorySection /> */}
      <AllProducts />
      <Classroom />
    </Layout>
  );
};

export default Home;
