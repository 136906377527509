import { Button } from "bootstrap";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import slugify from "react-slugify";
import { NavLink } from "react-router-dom";

const BlogCard = ({ post }) => {
  const { id, topicTitle, intro, imageMain, category, subCategory } = post;
  //   const slugifyCategory = slugify(category, " ");
  return (
    <Container>
      <Row>
        <Col>
          <img src={imageMain} alt={topicTitle} width={250} height={200} />
        </Col>
        <Col>
          <h3 style={{ textAlign: "center" }}>{category}</h3>
          <h6>{topicTitle}</h6>
          <p>{intro.substring(0, 100) + "..."}</p>
          <a
            className="btn btn-sm btn-primary"
            href={`/blogs/${category}/${slugify(topicTitle)}/${id}`}
          >
            Read More..
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogCard;
