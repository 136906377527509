import "./App.css";

import { Routes, Route, Link } from "react-router-dom";
import Home from "./components/homes/Home";
import About from "./components/commons/About";
import Privacy from "./components/commons/Privacy";
import NotFound from "./NotFound";
import Products from "./components/products/Products";
import Product from "./components/products/Product";
import Cart from "./components/cart/Cart";
import Contact from "./components/commons/Contact";
import Affiliates from "./components/commons/Affiliates";
import AllBlogs from "./components/blog/AllBlogs";
import Blog from "./components/blog/Blog";
import BlogsByCategory from "./components/blog/BlogsByCategory";
import AllElectronics from "./components/electronics/AllElectronics";
import { useEffect } from "react";
import Success from "./components/commons/Success";
import Headers from "./components/layouts/Headers";
import SEO from "./components/commons/SEO";
import ScrollButton from "./components/commons/ScrollButton";
import SingleProduct from "./components/products/SingleProduct";
import AllPaapi from "./components/paapi/AllPaapi";
import SinglePaapi from "./components/paapi/SinglePaapi";

const App = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://use.typekit.net/foobar.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <SEO />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route exact path="/products" element={<AllElectronics />} />
        <Route exact path="/electronics" element={<AllElectronics />} />
        <Route exact path="/appliance" element={<AllElectronics />} />
        <Route exact path="/cameras" element={<AllPaapi />} />
        <Route exact path="/cameras/:id/:slug" element={<SinglePaapi />} />
        <Route path="/cart/:site" component={<Cart />} />
        <Route
          path="/products/:category/:id/:slug"
          element={<SingleProduct />}
        />
        <Route path="/blogs" element={<AllBlogs />} />
        <Route path="/blogs/:id" element={<AllBlogs />} />
        <Route path="/blog/:category/:id" element={<BlogsByCategory />} />
        <Route path="blogs/:category/:slug/:id" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/affiliate" element={<Affiliates />} />
        <Route path="/success" element={<Success />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
