import React from "react";
import { BsInfo } from "react-icons/bs";
import FeatureCard from "../card/FeatureCard";

const Feature = () => {
  return (
    <section className="features">
      <div className="container">
        <div className="row">
          <FeatureCard
            icons="BiTrendingUp"
            title="Trending Products"
            content="Check out Trending products before decided"
          />
          <FeatureCard
            icons="FcRating"
            title="Best Rating Products"
            content="Check out best rating products before you buy anything"
          />
          <FeatureCard
            icons="GiRank3"
            title="Ranked Products"
            content="Check out ranked products before you buy anything"
          />
          <FeatureCard
            icons="feature"
            title="Featured Products"
            content="Check out feature products before you buy anything"
          />
        </div>
      </div>
    </section>
  );
};

export default Feature;
