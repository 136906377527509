import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FcRating } from "react-icons/fc";
import { AiFillStar } from "react-icons/ai";
import slugifyUrl from "../util/slugifyUrl";

import SEO from "../commons/SEO";
import { Col } from "react-bootstrap";

const Card = (props) => {
  let navigate = useNavigate();
  const pathname = window.location.pathname;
  const {
    id,
    title,
    category,
    rating,
    imageLink,
    productLink,
    keywords = [],
  } = props.post;
  const titleString = title.substring(0, 30) + "..";
  const slug = slugifyUrl(title);
  return (
    <>
      {pathname === "/" ? (
        <SEO />
      ) : (
        <SEO
          title={title}
          description={title}
          //image={imageLink}
          article={true}
          keywords={keywords}
        />
      )}
      <div className="col-lg-4 col-md-4" key={id}>
        <div className="card online-course-card content-align">
          <img
            src={imageLink}
            // className="img-fluid"
            alt="card"
            height="200px"
            width="250px"
          />
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{titleString}</p>
            <div className="row">
              <Col>
                <div style={{ color: "blue", fontWeight: "bold" }}>
                  {rating} <AiFillStar /> out of 5
                </div>
              </Col>
              <Col>
                <img
                  src={require("../../assets/amazon-icon.jpg")}
                  // className="img-fluid"
                  alt="card"
                  height="60px"
                  width="100px"
                />
              </Col>
            </div>

            <div className="row">
              <Col className="col-lg-7 col-md-5">
                <NavLink
                  to={`/products/${category}/${id}/${slug}`}
                  className="btn btn-sm btn-primary"
                >
                  View Details
                </NavLink>
              </Col>
              <Col className="col-lg-5">
                <a
                  className="btn btn-sm btn-secondary"
                  target="_blank"
                  href={productLink}
                  rel="noreferrer"
                >
                  Buy Now
                </a>
              </Col>
            </div>
            {/* <Link
              to={`/products/${category}/${id}/${slug}`}
              className="btn btn-sm btn-primary"
            >
              View Products
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
