import React from "react";
import { Container } from "react-bootstrap";
import LayoutOthers from "../layouts/LayoutOthers";

const privacy = () => {
  return (
    <LayoutOthers>
      <Container style={{ margin: 100 }}>
        <h1>PRIVACY POLICY </h1>
        <p>
          TheDigitalSpecs.in, we would like to promise that we always do our
          best to protect your data and keep your details safe. How we will use
          your is described in this privacy policy and if you have any questions
          regarding how we use or store your data, please feel free to contact
          us for assistance on contact@thedigitalspecs.in. By continuing to use
          our site or services, you are approving that you agree to the use and
          storage of your personal data as defined in this policy. Definitions
          For the rest of this artifact, the following characterizations apply.
          You/Data Subject/User – The person whose data we are processing and
          or/storing We/Us – The company who owns the website,
          TheDigitalSpecs.in Child – Anyone under the age of eighteen years
          Personal Data – Refers to any data which can be used to identify a
          person, for example, name, email address etc. Processing – Mentions to
          any action or group of actions that are performed on personal data for
          the sake of loading or providing a service to the user. Principals The
          Digital Specs will always struggle to ensure we act within the law
          when processing and/or storing data and we promise to always adhere to
          the following: We will always process your data within the rule and
          consider your rights before processing any private data. We will only
          process your data in the way in which you have agreed to us doing We
          will only collect the personal data which we need and you will not be
          asked to provide personal data that’s irrelevant to the service you
          require We will never process or store your data for longer than is
          needed We will always ensure your privacy is our top priority and we
          will never pass your personal data on to a third party without your
          express, written agreement We will always guarantee your personal data
          is accurate and stored safely We will be happy to provide you with
          full details of what personal data of yours we store and amend or
          delete your data as requested. Contact us at admin@thedigitalspecs.in
          to request your personal data or to delete or amend it within our
          records. More information on this process can be found below Your
          Rights Regarding Your Data As a user of our sites, you have certain
          rights relating to your data and how we process it: Your right to
          access information – You have the right to know exactly what data we
          hold on you, where it was obtained from and how and why it is handled.
          You can request this information using the email address above and
          always contact you through email only will never share it to anyone
          without your permission. You have the right to request this data be
          obtained in a machine-readable format where this is possible. Your
          right to alter your data – You have the right to request that
          incorrect data held about you be rectified within an appropriate
          manner. You have the right to withdraw your permission for your
          personal data to be processed at any time. You also have the right for
          such personal data to be removed from our processing and storing
          systems. Please note that by doing so, some or all of our websites may
          no longer be available to you, for example, members-only access areas,
          forums etc. You can request to delete your data to be deleted by
          communicating with us on the email address above. You have the right
          to restrict or object to your data being processed – You have the
          right to confine the use of your data, for example, if you no longer
          want to receive marketing emails from us. To do so, simply click
          unsubscribe us in any of the emails. Please note that by doing that,
          you will no longer receive communications from us and you may miss out
          on special offers that are only for our subscribers or partners. Your
          right to object to automated processing – You have the right to
          withdraw your personal data from any computerized profiling where the
          result of such directly affects you as a user. To choose out of such
          outlining, email us on the email address above. Your right to file a
          complaint with us and escalate it – You have the right to file a
          complaint with us in the event that any requests about your personal
          data are unfulfilled. You will be given a full explanation of why your
          request hasn’t been completed. You can file this complaint using the
          email address above mentioned. is stored. Information you have
          provided to us only contains information such as your name, address,
          email address etc. if you requested. We also save personal data to
          increase your experience on our site and for your convenience, for
          example, when commenting on one of our blog posts, your name and email
          address will be stored to make other comments quicker and easier for
          you to complete while it’s required. We will never use this
          information for any other purposes without your express, written
          accord. Information that is automatically collected – This information
          is things such as your IP address etc in the near future. Presently
          its just a review and product info site. that is collected through
          cookies when you browse our site. You will find more information on
          this in our cookies section. Information from our associates – We may,
          on occasion, collect data from reliable partners. This data will only
          be accepted by us if the partner has legal grounds to share it and has
          your written consent for sharing information with third parties’
          venders. Publicly available information – We may collect publicly
          accessible information about you. Technical data we collect – When you
          visit our website, we may collect the following information to ensure
          that your experience is the best it can be: IP address, browser type
          and version, operating system and platform, the URLs you clicked
          through our website. Information for commenting – If you choose to
          leave a comment on our website, we may collect the following
          information: your login details, IP address and browser user string.
          Information from media uploads – If you choose to upload images to the
          website, ensure they aren’t geotagged as this information will be
          publicly available on the website. Information provided through
          contact forms – Information you provide through our contact forms may
          be used to contact you to discuss the original reason you contacted
          us. This information won’t be stored or used for any other purpose
          without your express, written permission to do so. Third Party
          Information Gathered Information for Gravatar comments – In some
          instances, we may also send a hash to the Gravatar service. This is to
          check if you use the service – if you don’t, your information won’t be
          used. If you do, this enables Gravatar to display your profile picture
          and information in line with the permissions you have approved them.
          Once approved by us and made visibly, your comment will then show your
          Gravatar information. To change how your information is displayed
          through Gravatar, check their privacy policy section. Content from
          other sites – We may on occasion share embedded content from other
          sites (for example, video content) and we might provide links to
          external sites. Those sites are not within our control nor are we
          responsible for how they choose to manage your personal data. To find
          out more about this, check the privacy policies of the individual
          sites. Data Collected Through Advertising We use Google AdSense and
          various affiliate schemes, including but not limited to Amazon
          affiliates, to provide third party advertising services. Below you
          find more details about how this affects you and your personal data.
          Google AdSense Google AdSense uses a DART cookie to track users’
          browsing on our website and other websites to ensure that ads are
          tailored and relevant to each individual user. We also use cookies
          such as Google Analytics to collect non-identifying data about our
          website visitors to measure the effectiveness of our ads for our
          website monitoring. As an end-user, Users can set their preferences
          for how this data is collected and stored and/or to opt out of this
          processing of data overall by visiting the Google Ad Settings page.
          Please note that by doing so, you might find that some of our websites
          is unavailable to you. Amazon As an Amazon Associate The Digital Specs
          earns from meet the requirements purchases from affiliate partners.
          The Digital Specs.in is an affiliate advertising program designed to
          provide a means for sites to earn advertising fees by advertising and
          linking to Amazon.in. Third Party Affiliate Ads We may use on occasion
          for the affiliate advertising on the website. This means that if you
          click through and purchase something from these links, we will make a
          small profit (at no extra cost to you). We are not responsible nor
          liable for the third party’s handling of your data and we advise that
          you check out their privacy policy before making a purchase if you are
          concerned about how they will use your personal data. How We Use Your
          Personal Data We will only ever use your personal data in ways that
          you have provided express written consent for us to do so, plus to
          provide an effective service to you. This can include: On-site uses:
          Confirming the use of our website at full functionality Providing you
          with information about products and services that you have purchased
          or requested Improving customer services and satisfaction Improving
          our website to make it a better user experience Improving our
          website’s security Personalising your experience on our website
          ensuring that content relevant to your interests is displayed first
          but it is ruled by the theme we installed for our site. Off-site uses:
          Where you have granted permission for us for doing so, we may use your
          email address to send you the following: Newsletters Notices of new
          blog posts/comments being published Marketing communications regarding
          our products and services Marketing communications regarding third
          party products and services Any other purposes you have consented to
          We may also on occasion use your personal data to contact your
          directly in certain circumstances (for example if you have made a
          complaint or asked a personal question via email etc.). On occasion,
          the ways in which we use your personal data may be subject to change.
          You will be informed of such changes via this privacy policy, which we
          reserve the right to amend at any time and without notice. If such
          change occurs, you have the right to request your personal data be
          removed from our processing systems by contacting us: add email
          address here. Third Party Access to Your Data We will never sell your
          personal data. Your personal data will never be shared with anyone
          outside of our trusted circle of associates and then it will only be
          with your express, a written agreement if it is to be used for
          marketing purposes. Exceptions to this include: Services such as
          PayPal, where you use the service to pay for things you order for.
          Third-party suppliers where you request that service (for example, if
          we were to run an Amazon voucher giveaway, your email address would be
          provided to Amazon in order for your prize to be delivered) Legal
          reasons – we may be required by law to hand over personal data
          relating to a person or person in regard to an ongoing investigation.
          In that example, once we were given a permit to request such
          information, we will comply with any and all legal requests. We may
          share non-personal data about you with certain other third parties in
          the interest of tracking our website’s users so we can build up a
          picture of our audience as a whole. All of those data will be totally
          anonymous and you won’t be able to be recognized from this, nor will
          you be communicated regarding it. These third parties include: Google
          Analytics Google AdSense Amazon Affiliates Other third-party
          affiliates Details on how to opt-out of data sharing can be found in
          our cookies section. Although, please note that by opting out of
          certain cookies, some or all of our website may be unreachable to you
          and/or may not function as intended. How We Keep Your Data Safe We
          will always endeavour to keep your personal data safe and secure. We
          use encryption software to confirm that all data is stored anonymously
          and we confirm that we have a secure connection like https. For
          potential security threats, our system is periodically monitored and
          any which are identified are resolved as a top priority to remove
          them. Please be aware that we are not answerable for information that
          is broken before it is stored in our system. It is your responsibility
          to guarantee that your own connection and browser is secure and safe
          and protected before entering any personal data in our website. While
          we do our best to guarantee the security of our website at all times,
          in the unlikely event of a data breach, we will inform this to the
          relevant authorities and in the case of your personal data being
          breached, we will inform you at the earliest possible time. If you
          have an account with us, it is your responsibility to keep your
          username and password confidential and if you choose to share it with
          anyone, we cannot accept responsibility for anything that happens to
          your data in those circumstances. Children and Their Data Our website
          is designed for the Audience of eighteen plus. We expect the audience
          should be more than eighteen years. We will never knowingly collect
          data from children and we recommend that parents keep a close eye on
          their children’s browsing history and ensure that they are not sharing
          their personal data with third parties. In the event that you discover
          that a child has provided us with personal data, please contact us at
          admin@thedigitalspecs.in and the information will be removed from our
          processes immediately. Cookie and Tachnology Policy Cookies are small
          information of data those are stored on your system. Cookies are also
          used to help make websites function smoothly and all features work of
          the sites correctly. We do sometimes use cookies to personalize your
          experience on our website for analysis. Cookies are used by us in the
          following below ways: To ensure the website displays and runs
          correctly To track user’s movements across our site To identify the
          site’s users come from and go to around their visit to us. Analyze
          behavior (for example, to see at what point the popular of users click
          away from a page point) Collect information This information is used
          to make sure your experience on our website is the best it can be.
          Only the website administrator (i.e. us) has access to these cookies.
          You can select to turn off some or all cookies through your browser’s
          settings page, but please note that if you select to do so, some or
          all of our page websites may not function as intended and you may find
          that some or all of it is unavailable to you. Through it is your
          browser settings, you can also have the option to choose to be
          notified every time a cookie is being sent to your computer and choose
          how to arrange with it on an individual basis. You can delete cookies
          from your browser at any time you want with the help of the browser
          menu. Please note that if you do this by choosing so, re-login is
          required to our site and any template data you chose to save may be
          lost while deleting the cookie. When you first land on our website, a
          temporary cookie will be sent to you. This first cookie is to test
          whether or not your browser allows cookies or not. Regardless of the
          findings, the cookie won’t gather any data and it won’t be stored on
          your computer as per programming. Once you close the browser, it will
          be deleted. We use the following cookies on our website: Essential
          Cookies – These cookies are essential cookies that make our website
          work smoothly and correctly. These cookies don’t collect or store any
          data about you. Functional Cookies – These cookies are used to
          personalize your experience on our website and tailor our content to
          you based on your past visits etc. They may also store information
          such as your name, country, etc. to make leaving comments quicker and
          easier as the boxes will autofill for you as that may take longer than
          the required time. These cookies will store this information for one
          year unless you choose to delete them from your browser in the
          meantime. Analytical Cookies – These cookies don’t gather any
          personally identifiable data; they merely count you as a user and
          track the use and performance of the website. We use Google Analytics
          for this purpose. To opt-out of these cookies, see the details above
          where we talk about third party access to your data. Advertising
          Cookies – These cookies are provided by third party advertising
          services (for example, Google AdSense). They are used to tailor
          advertising content to your interests and they monitor how often you
          are shown a particular ad to ensure you aren’t bombarded with the same
          ad every time you visit a website. You can disable them by going to
          the provider’s website and following their instructions. Changes and
          Amendments, we reserve the right to change or amend our privacy and/or
          cookie policy at any time without previous notice. Any changes to the
          policy will be documented here in the future and we recommend you
          check the policy from time to time to keep update up with any changes.
          By continuing to use this website, you are approving that you have
          read and understood this policy. If you have any questions regarding
          this policy or your personal data, you can contact us anytime at
          contact@thedigitalspecs.in Last updated: Dec 20, 2021
        </p>
      </Container>
    </LayoutOthers>
  );
};

export default privacy;
