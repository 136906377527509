import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { blogsData, categories } from "../data/blogData";
import LayoutOthers from "../layouts/LayoutOthers";
import HeaderAds from "../ads/HeaderAds";
import IntroBlogs from "./IntroBlogs";
import ProductDescription from "./ProductDescription";
import BlogImage from "./BlogImage";
import { useParams } from "react-router-dom";
import RecentPosts from "./RecentPosts";
import SEO from "../commons/SEO";
import ScrollButton from "../commons/ScrollButton";

const Blog = ({ blogdata = blogsData }) => {
  const { category, id } = useParams();
  const { imageMain, subCategory, topicTitle, productsNames, discuss } =
    categories[id - 1];

  const keywords = [topicTitle, subCategory, ...productsNames];

  const filteredData = blogdata.filter(
    (item) => item.category === category && item.subCategory === subCategory
  );
  return (
    <LayoutOthers>
      <SEO
        title={topicTitle}
        description={discuss && discuss.substring(0, 20)}
        //image={imageLink}
        article={true}
        keywords={keywords}
      />
      <Container
        style={{ minHeight: 500, marginTop: 80, alignContent: "center" }}
      >
        {/* Stack the columns on mobile by making one full-width and the other half-width */}
        <Row>
          <Col xs={12} md={12} style={{ backgroundColor: "#FFF" }}>
            <div>
              <div style={{ backgroundColor: "#F0F0F0" }}>
                <HeaderAds />
              </div>
              <br />
              <BlogImage image={imageMain} />
            </div>
            <IntroBlogs data={categories[id - 1]} />
            <br />
            {filteredData.length > 0 &&
              filteredData.map((blog, index) => {
                return (
                  <ProductDescription
                    blogdata={blog}
                    index={index}
                    key={index}
                  />
                );
              })}
          </Col>
          {/* <Col xs={11} md={4}>
            <RecentPosts palce="blog" categories={categories} />
          </Col>{" "} */}
        </Row>
      </Container>
    </LayoutOthers>
  );
};

export default Blog;
