import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FcRating } from "react-icons/fc";
import { AiFillStar } from "react-icons/ai";
import slugifyUrl from "../util/slugifyUrl";

import SEO from "../commons/SEO";
import { Col } from "react-bootstrap";
const PaapiCard = (props) => {
  let navigate = useNavigate();

  const {
    id = 1,
    ASIN,
    title = "abc",
    category = "abc",
    rating = 5,
    imageLink = "",
    productLink = "djkda",
    keywords = [],
    DetailPageURL,
    Images,
    ItemInfo,
    Offers,
  } = props.post;
  const titleString = ItemInfo.Title.DisplayValue.substring(0, 25) + "..";
  const slug = slugifyUrl(ItemInfo.Title.DisplayValue);
  return (
    <>
      <SEO
        title={ItemInfo.Title.DisplayValue}
        description={ItemInfo.Title.DisplayValue}
        //image={imageLink}
        article={true}
        keywords={[
          ItemInfo.Title.DisplayValue,
          ItemInfo.ByLineInfo.Brand.DisplayValue,
        ]}
      />
      <div className="col-lg-4 col-md-4" key={id}>
        <div className="card online-course-card content-align">
          <img
            src={Images.Primary.Large.URL}
            // className="img-fluid"
            alt="card"
            height="180px"
            width="200px"
          />
          <div className="card-body">
            <h5 className="card-title">
              <strong>Brand </strong>: {ItemInfo.ByLineInfo.Brand.DisplayValue}
            </h5>
            <p className="card-text">{titleString}</p>
            <div className="row">
              <Col className="col-lg-8">
                <div style={{ color: "blue", fontWeight: "bold" }}>
                  {Offers.Listings[0].MerchantInfo.FeedbackRating}
                  <AiFillStar /> out of 5
                </div>
              </Col>
              <Col className="col-lg-3">
                <img
                  src={require("../../assets/amazon-icon.jpg")}
                  // className="img-fluid"
                  alt="card"
                  height="30px"
                  width="50px"
                />
              </Col>
            </div>
            <div className="row">
              <Col className="col-lg-8 col-md-5">
                <NavLink
                  to={`/cameras/${id}/${slugifyUrl(
                    ItemInfo.Title.DisplayValue
                  )}`}
                  className="btn btn-sm btn-primary"
                >
                  View Details
                </NavLink>
              </Col>
              <Col className="col-lg-3">
                <a
                  className="btn btn-sm btn-primary"
                  target="_blank"
                  href={DetailPageURL}
                  rel="noreferrer"
                >
                  Buy
                </a>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaapiCard;
